/*
 *
 * This is the `IndexedDb Provider Return Provider Component` component.
 *
 */

/*
 *
 * custom components
 *
 * start
 *
 */

import ConsoleService from './consoleServiceModules/ConsoleService';

import ConsoleServiceIndexedDbModuleProviderComponent from "./consoleServiceModules/ConsoleServiceIndexedDbModuleProviderComponent";

import { indexed_db_name, indexed_db_version } from './../IndexedDbComponent';

/*
 *
 * custom components
 *
 * stop
 *
 */

// the main class

const execute = (provider_object_store_id) => {

    let flag_console_log = ConsoleServiceIndexedDbModuleProviderComponent;

    ConsoleService('', flag_console_log);
    ConsoleService('IndexedDbProviderReturnProviderComponent', flag_console_log);

    ConsoleService('IndexedDbProviderReturnProviderComponent / provider_object_store_id', flag_console_log);
    ConsoleService(provider_object_store_id, flag_console_log);

    let idb_name = indexed_db_name;

    let version = indexed_db_version;

    /*
     *
     * trying to save the provider data
     * 
     * we still have to figure out if we are on the "create" or "update" scenario
     *
     * start
     * 
     */

    return new Promise(
        (resolve, reject) => {

            ConsoleService('IndexedDbProviderReturnProviderComponent / new promise', flag_console_log);

            let indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;

            /*
             *
             * request
             *
             * start
             * 
             */

            let request = indexedDB.open(idb_name, version);

            /*
             * request / on success / callback
             *
             * start
             * 
             */

            request.onsuccess = function (event) {

                ConsoleService('IndexedDbProviderReturnProviderComponent / request / on success', flag_console_log);

                ConsoleService('IndexedDbProviderReturnProviderComponent / request / on success / event', flag_console_log);
                ConsoleService(event, flag_console_log);

                let db = event.target.result;

                ConsoleService('IndexedDbProviderReturnProviderComponent / request / on success / db', flag_console_log);
                ConsoleService(db, flag_console_log);

                /*
                 *
                 * transaction
                 *
                 * start
                 * 
                 */

                /*
                 *
                 * transaction
                 * 
                 * open a read/write db transaction, ready for adding the data
                 * 
                 * https://developer.mozilla.org/en-US/docs/Web/API/IDBDatabase/transaction
                 * 
                 */

                let transaction = db.transaction(['provider_object_store'], 'readonly');

                ConsoleService('IndexedDbProviderReturnProviderComponent / request / on success / transaction', flag_console_log);
                ConsoleService(transaction, flag_console_log);

                /*
                 *
                 * transaction / on complete
                 * 
                 * report on the success of opening the transaction
                 * 
                 * start
                 * 
                 */

                transaction.oncomplete = function (event) {

                    ConsoleService('IndexedDbProviderReturnProviderComponent / request / on success / transaction / on complete', flag_console_log);

                    ConsoleService('IndexedDbProviderReturnProviderComponent / request / on success / transaction / on complete / event', flag_console_log);
                    ConsoleService(event, flag_console_log);

                    //

                }

                /*
                 *
                 * transaction / on complete
                 * 
                 * stop
                 * 
                 */

                /*
                 *
                 * transaction / on error
                 * 
                 * start
                 * 
                 */

                transaction.onerror = function (event) {

                    ConsoleService('IndexedDbProviderReturnProviderComponent / request / on success / transaction / on error', flag_console_log);

                    ConsoleService('IndexedDbProviderReturnProviderComponent / request / on success / transaction / on error / event', flag_console_log);
                    ConsoleService(event, flag_console_log);

                    reject(event);

                    //

                }

                /*
                 *
                 * transaction / on error
                 * 
                 * stop
                 * 
                 */

                /*
                 *
                 * transaction
                 *
                 * stop
                 * 
                 */

                let objectStore = transaction.objectStore('provider_object_store');

                ConsoleService('IndexedDbProviderReturnProviderComponent / request / on success / transaction / objectStore', flag_console_log);
                ConsoleService(objectStore, flag_console_log);

                let index = objectStore.index('provider_object_store_id');

                ConsoleService('IndexedDbProviderReturnProviderComponent / request / on success / transaction / objectStore / index', flag_console_log);
                ConsoleService(index, flag_console_log);

                /*
                 *
                 * req
                 *
                 * trying to load the provider object by using the PK
                 * 
                 * start
                 * 
                 */

                let req = index.get(provider_object_store_id);

                ConsoleService('IndexedDbProviderReturnProviderComponent / request / on success / transaction / objectStore / index / req', flag_console_log);
                ConsoleService(req, flag_console_log);

                /*
                 *
                 * req / on success
                 *
                 * start
                 * 
                 */

                req.onsuccess = function (event) {

                    ConsoleService('IndexedDbProviderReturnProviderComponent / request / on success / transaction / objectStore / index / req / on success', flag_console_log);

                    ConsoleService('IndexedDbProviderReturnProviderComponent / request / on success / transaction / objectStore / index / req / on success / event', flag_console_log);
                    ConsoleService(event, flag_console_log);

                    let result = event.target.result;

                    ConsoleService('IndexedDbProviderReturnProviderComponent / request / on success / transaction / objectStore / index / req / on success / result', flag_console_log);
                    ConsoleService(result, flag_console_log);

                    if (
                        result !== undefined
                    ) {

                        // ok, we have the record

                        ConsoleService('result !== undefined', flag_console_log);

                        resolve(event);

                    } else {

                        // ok, no record

                        ConsoleService('result === undefined', flag_console_log);

                        reject(event);

                    }

                }

                /*
                 *
                 * req / on success
                 *
                 * stop
                 * 
                 */

                /*
                 *
                 * req / on error
                 *
                 * start
                 * 
                 */

                req.onerror = function (event) {

                    /*
                    *
                    * error
                    * 
                    */

                    ConsoleService('IndexedDbProviderReturnProviderComponent / request / on success / transaction / objectStore / index / req / on error');

                    ConsoleService('IndexedDbProviderReturnProviderComponent / request / on success / transaction / objectStore / index / req / on error / event', flag_console_log);
                    ConsoleService(event, flag_console_log);

                    reject(event);

                    //

                }

                /*
                 *
                 * req / on error
                 *
                 * stop
                 * 
                 */

                /*
                 *
                 * req
                 *
                 * stop
                 * 
                 */

            }

            /*
             * request / on success / callback
             *
             * stop
             * 
             */

            /*
             * request / on error / callback
             *
             * start
             * 
             */

            request.onerror = function (event) {

                /*
                *
                * error
                * 
                */

                ConsoleService('IndexedDbProviderReturnProviderComponent / request / on error', flag_console_log);

                ConsoleService('IndexedDbProviderReturnProviderComponent / request / on error / event', flag_console_log);
                ConsoleService(event, flag_console_log);

                reject();

                //

            }

            /*
             * request / on error / callback
             *
             * stop
             * 
             */

            /*
             * request
             *
             * stop
             * 
             */

        }
    ).then(
        (data) => {

            /*
            *
            * ok
            * 
            */

            ConsoleService('IndexedDbProviderReturnProviderComponent / new promise result => then', flag_console_log);

            ConsoleService('IndexedDbProviderReturnProviderComponent / new promise result => then / data', flag_console_log);
            ConsoleService(data, flag_console_log);

            return data;

        }
    ).catch(
        (data) => {

            /*
            *
            * error
            * 
            */

            ConsoleService('IndexedDbProviderReturnProviderComponent / new promise result => catch', flag_console_log);

            ConsoleService('IndexedDbProviderReturnProviderComponent / new promise result => catch / data', flag_console_log);
            ConsoleService(data, flag_console_log);

            return data;

        }
    );

    // !!! CAUTION !!! unreacheble code, because of return !!!

    /*
     *
     * trying to load the provider data
     *
     * stop
     * 
     */

}

export default execute;