const arr = [

    {
        value: 'fr', label: 'France'
    },
    {
        value: 'be', label: 'Belgium'
    }

];

export default arr;