import { Component } from 'react';

import ConsoleService from './components/consoleServiceModules/ConsoleService';

class ModuleFauth extends Component {

    module_name = 'ModuleFauth';

    flag_console_log = false;

    state = {};

    constructor(props) {

        super(props);

        ConsoleService(this.module_name + ' / constructor', this.flag_console_log);

        this.state = {

            language: props.language,

        }

    }

    /* DELIMITER */

    componentDidMount() {

        ConsoleService(this.module_name + ' / component did mount', this.flag_console_log);

        this.fetchUserData();

    }

    /* DELIMITER */

    fetchUserData() {

        ConsoleService(this.module_name + ' / fetchUserData', this.flag_console_log);

        fetch(process.env.REACT_APP_API_URL + `login/facebook/callback` + this.props.location.search)
            .then(res => res.json())
            .then(
                (result) => {
                    ConsoleService(this.module_name + ' fetchUserData / then / result', this.flag_console_log);
                    ConsoleService(result, this.flag_console_log);

                    let user_data = JSON.stringify(result);

                    localStorage.setItem('react-billableme-user', user_data);

                    window.emitService.emit('loggedIn');

                    this.props.history.push("/");
                }
            )
            .catch(
                (error) => {
                    ConsoleService(this.module_name + ' fetchUserData / catch / error', this.flag_console_log);
                    ConsoleService(error, this.flag_console_log);
                }
            );
    }

    render() {

        return null;

    }

}

export default ModuleFauth;