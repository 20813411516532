import { store as notification } from 'react-notifications-component';

import ConsoleService from '../consoleServiceModules/ConsoleService';

import translation from '../../TranslationComponent';

const execute = (t) => {

    ConsoleService('', t.flag_console_log);
    ConsoleService('ModuleProvider / component_form_submit_validation / execute()', t.flag_console_log);

    ConsoleService('t.state', t.flag_console_log);
    ConsoleService(t.state, t.flag_console_log);

    let flag_permission = true;

    let message = '';

    let newState = Object.assign({}, t.state);

    let flag_empty = false;

    /* DELIMITER */

    /*
    *
    * validating the attributes
    *
    * start
    * 
    */

    Object.keys(t.component_form_attributes_to_be_validated).map(
        (attribute) => {

            ConsoleService('attribute', t.flag_console_log);
            ConsoleService(attribute, t.flag_console_log);

            if (
                t.state.form.attributes[attribute] === ''
            ) {

                // error

                ConsoleService('validation failed at : ' + attribute, t.flag_console_log);

                flag_empty = true;

                newState.form.touched[attribute] = true;// set attribute as "touched"

                newState.form.errors[attribute] = true;// set attribute with "error"

                if (flag_permission) {

                    message = translation[t.state.language][t.module_name]['notification_' + attribute];

                    flag_permission = false;

                    //

                }

                //

            }

            return [];

            //
        }
    );

    /*
    *
    * validating the attributes
    *
    * stop
    * 
    */

    /* DELIMITER */

    if (
        flag_empty
    ) {

        // error
        // the attribute value is empty

        t.setState(newState);

        //

    }

    if (
        !flag_permission &&
        message !== ''
    ) {

        // the invoice form is not valid

        notification.addNotification(
            {
                title: "Error",
                message: message,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            }
        );

        //

    }

    return flag_permission;

    //

}

export default execute;