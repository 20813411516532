import React, { Component } from 'react';

/*
*
* css files
*
* start
*
*/

import '../../css/ModuleInvoice.css';

/*
*
* css files
*
* stop
*
*/

/*
*
* custom components
*
* start
*
*/

import ModuleInvoiceComponentRenderJsxReadOnly from '../moduleInvoiceReadOnly/ModuleInvoiceComponentRenderJsxReadOnly';

import ConsoleService from '../consoleServiceModules/ConsoleService';

import ConsoleServiceModuleInvoice from "../consoleServiceModules/ConsoleServiceModuleInvoice";

/*
*
* Indexed DB custom components
*
* start
*
*/

import IndexedDbComponent from '../../IndexedDbComponent';

import DexieDbComponent from '../../DexieDbComponent';

import IndexedDbModuleInvoicesComponent from '../../IndexedDbModuleInvoicesComponent';

import IndexedDbProviderReturnProviderComponent from '../IndexedDbProviderReturnProviderComponent';

/*
*
* Indexed DB custom components
*
* stop
*
*/

import default_language, { arr_languages } from '../../LanguageComponent';

import translation from '../../TranslationComponent';
//import translationENComponent from '../translation/translationENComponent';

/*
*
* custom components
*
* stop
*
*/

let d = new Date();// ok 14.11.2019

/* DELIMITER */

// the main class
class ModuleInvoiceReadOnly extends Component {

    module_name = 'ModuleInvoice';// ok 14.11.2019

    idb_for_module_invoices = null;// ok 14.11.2019

    container_width = 914;// ok 14.11.2019

    indexed_db_invoice_object_store_name = 'invoice_object_store';// ok 14.11.2019

    flag_console_log = ConsoleServiceModuleInvoice;// ok 14.11.2019

    default_language = default_language;// ok 14.11.2019

    recipients = [];// ok 14.11.2019

    provider = null;

    state = {};// ok 14.11.2019

    /* DELIMITER */

    getInitialState() {

        //

        const initialState = {

            language: this.default_language,

            currency: { value: 'EUR', symbol: '€', label: '€ ( EUR )' },

            /* DELIMITER */

            /*
            *
            * form attributes
            *
            * start
            * 
            */

            form: {

                select: {

                    /*
                    *
                    * recipient account type
                    *
                    * start
                    * 
                    */

                    recipient_account_type: null,

                    recipient_account_type_select_options: translation[this.default_language][this.module_name].recipient_account_type_select_option,

                    /*
                    *
                    * recipient account type
                    *
                    * stop
                    * 
                    */

                },

                labels: {

                    // invoice number
                    invoice_number_label: translation[this.default_language][this.module_name].invoice_number_label,

                    // invoice date
                    invoice_date_label: translation[this.default_language][this.module_name].invoice_date_label,

                    /*
                    *
                    * item labels
                    * 
                    * start
                    *
                    */

                    // item description
                    item_description_label: translation[this.default_language][this.module_name].item_description_label,

                    // item quantity
                    item_quantity_label: translation[this.default_language][this.module_name].item_quantity_label,

                    // item price
                    item_price_label: translation[this.default_language][this.module_name].item_price_label,

                    // item vat
                    item_vat_label: translation[this.default_language][this.module_name].item_vat_label,

                    /*
                    *
                    * item labels
                    * 
                    * stop
                    *
                    */

                    /*
                    *
                    * subtotal, vat, total
                    * 
                    * start
                    *
                    */

                    // invoice "subtotal" label
                    invoice_subtotal_label: translation[this.default_language][this.module_name].invoice_subtotal_label,

                    // invoice "total without vat" label
                    invoice_total_without_vat_label: translation[this.default_language][this.module_name].invoice_total_without_vat_label,

                    invoice_subtotal_without_vat_for_vat_x: translation[this.default_language].general.invoice['invoice_subtotal_without_vat_for_vat_x'],
                    invoice_total_vat_for_vat_x: translation[this.default_language].general.invoice['invoice_total_vat_for_vat_x'],

                    // invoice "total vat" label
                    invoice_total_vat_label: translation[this.default_language][this.module_name].invoice_total_vat_label,

                    // invoice "total" label
                    invoice_total_label: translation[this.default_language][this.module_name].invoice_total_label,

                    /*
                    *
                    * subtotal, vat, total
                    * 
                    * stop
                    *
                    */

                },

                placeholders: {

                    /*                
                    *
                    * invoice placeholders
                    *
                    * start
                    * 
                    */

                    // invoice number
                    invoice_number: translation[this.default_language][this.module_name].placeholder_invoice_number,

                    // invoice number label
                    invoice_number_label: translation[this.default_language][this.module_name].placeholder_invoice_number_label,

                    // invoice date
                    invoice_date: translation[this.default_language][this.module_name].placeholder_invoice_date,

                    // invoice date label
                    invoice_date_label: translation[this.default_language][this.module_name].placeholder_invoice_date_label,

                    // invoice type
                    invoice_type: translation[this.default_language][this.module_name].placeholder_invoice_type,

                    // invoice company name
                    //invoice_company_name: translation[this.default_language][this.module_name].placeholder_invoice_company_name,

                    // invoice company info
                    //invoice_company_info: translation[this.default_language][this.module_name].placeholder_invoice_company_info,

                    /*                
                    *
                    * invoice placeholders
                    *
                    * stop
                    * 
                    */

                    /*
                    *
                    * provider placeholders
                    * 
                    * start
                    * 
                    */

                    provider_company_name: translation[this.default_language][this.module_name].placeholder_provider_company_name,// ok 20.11.2019 / this is a mandatory field
                    provider_vat_nr: translation[this.default_language][this.module_name].placeholder_provider_vat_nr,// ok 20.11.2019 / this is a mandatory field
                    provider_phone_nr: translation[this.default_language][this.module_name].placeholder_provider_phone_nr,// ok 20.11.2019 / this is a mandatory field
                    provider_bank_account: translation[this.default_language][this.module_name].placeholder_provider_bank_account,// ok 20.11.2019 / this is a mandatory field
                    provider_bank_name: translation[this.default_language][this.module_name].placeholder_provider_bank_name,// ok 20.11.2019 / this is a mandatory field

                    provider_address_country_key: translation[this.default_language][this.module_name].placeholder_provider_address_country_key,// ok 20.11.2019 / this is a mandatory field
                    provider_address_county: translation[this.default_language][this.module_name].placeholder_provider_address_county,// ok 20.11.2019
                    provider_address_city: translation[this.default_language][this.module_name].placeholder_provider_address_city,// ok 20.11.2019 / this is a mandatory field
                    provider_address_street_address: translation[this.default_language][this.module_name].placeholder_provider_address_street_address,// ok 20.11.2019 / this is a mandatory field
                    provider_address_zip: translation[this.default_language][this.module_name].placeholder_provider_address_zip,// ok 20.11.2019 / this is a mandatory field

                    /*
                    *
                    * provider placeholders
                    * 
                    * stop
                    * 
                    */

                    /*
                    *
                    * recipient placeholders
                    *
                    * start
                    * 
                    */

                    // recipient company name
                    recipient_company_name: translation[this.default_language][this.module_name].placeholder_recipient_company_name,

                    // recipient account type
                    recipient_account_type: translation[this.default_language][this.module_name].placeholder_recipient_account_type,

                    // recipient vat nr
                    recipient_vat_nr: translation[this.default_language][this.module_name].placeholder_recipient_vat_nr,

                    // recipient email
                    recipient_email: translation[this.default_language][this.module_name].placeholder_recipient_email,

                    // recipient phone nr
                    recipient_phone_nr: translation[this.default_language][this.module_name].placeholder_recipient_phone_nr,

                    // recipient addresses country key
                    recipient_addresses_country_key: translation[this.default_language][this.module_name].placeholder_recipient_addresses_country_key,

                    // recipient addresses city
                    recipient_addresses_city: translation[this.default_language][this.module_name].placeholder_recipient_addresses_city,

                    // recipient addresses street address
                    recipient_addresses_street_address: translation[this.default_language][this.module_name].placeholder_recipient_addresses_street_address,

                    // recipient addresses zip
                    recipient_addresses_zip: translation[this.default_language][this.module_name].placeholder_recipient_addresses_zip,

                    /*
                    *
                    * recipient placeholders
                    *
                    * stop
                    * 
                    */

                    /*
                    *
                    * invoice item labels
                    *
                    * start
                    *
                    */

                    // item description label
                    item_description_label: translation[this.default_language][this.module_name].placeholder_item_description_label,

                    // item quantity label
                    item_quantity_label: translation[this.default_language][this.module_name].placeholder_item_quantity_label,

                    // item price label
                    item_price_label: translation[this.default_language][this.module_name].placeholder_item_price_label,

                    // item vat label
                    item_vat_label: translation[this.default_language][this.module_name].placeholder_item_vat_label,

                    /*
                    *
                    * invoice item labels
                    *
                    * stop
                    *
                    */

                    // invoice subtotal
                    invoice_subtotal_label: translation[this.default_language][this.module_name].placeholder_invoice_subtotal,

                    // invoice total without vat
                    invoice_total_without_vat_label: translation[this.default_language][this.module_name].placeholder_invoice_total_without_vat,

                    // invoice total vat
                    invoice_total_vat_label: translation[this.default_language][this.module_name].placeholder_invoice_total_vat,

                    // invoice total
                    invoice_total_label: translation[this.default_language][this.module_name].placeholder_invoice_total,

                },

                attributes: {

                    // set the PK
                    invoice_object_store_id: 0,

                    // invoice type
                    invoice_type: translation[this.default_language][this.module_name].invoice_type,

                    // company name
                    //invoice_company_name: '',

                    // company info
                    //invoice_company_info: '',// TOGO 20.11.2019

                    /*
                    *
                    * official provider attributes
                    *
                    * start
                    * 
                    */

                    // the FK from "provider_object_store"
                    provider_object_store_id: 1,// this attribute value should always be equal to 1

                    provider_external_id: 0,

                    provider_company_name: '',
                    provider_vat_nr: '',
                    provider_phone_nr: '',
                    provider_bank_account: '',
                    provider_bank_name: '',

                    provider_address_country_key: '',
                    provider_address_country_name: '',

                    provider_address_county: '',
                    provider_address_city: '',
                    provider_address_street_address: '',
                    provider_address_zip: '',

                    /*
                    *
                    * official provider attributes
                    *
                    * stop
                    * 
                    */

                    /*
                    *
                    * official recipient attributes
                    * 
                    * !!! DO NOT TOUCH !!!
                    *
                    * start
                    * 
                    */

                    recipient_account_type: '',
                    recipient_account_type_name: '',

                    recipient_company_name: '',
                    recipient_vat_nr: '',
                    recipient_email: '',
                    recipient_phone_nr: '',

                    recipient_addresses_country_key: '',
                    recipient_addresses_country_name: '',

                    recipient_addresses_city: '',
                    recipient_addresses_street_address: '',
                    recipient_addresses_zip: '',

                    /*
                    *
                    * official recipient attributes
                    * 
                    * stop
                    * 
                    */

                    /*
                    *
                    * unofficial recipient attributes
                    * 
                    * the following recipient attributes are hardcoded
                    * the following recipient attributes might be served by the recipients module
                    * we do not use these attributes on the screen
                    * 
                    * !!! DO NOT TOUCH !!!
                    * 
                    * start
                    * 
                    */

                    // the FK from "recipient_object_store"
                    recipient_object_store_id: null,

                    recipient_external_account_id: 0,

                    recipient_addresses_is_billing: 1,
                    recipient_addresses_is_delivery: 1,
                    recipient_addresses_is_primary: 1,
                    recipient_addresses_is_site: 1,

                    recipient_addresses_is_customer: 1,
                    recipient_addresses_is_supplier: 0,

                    /*
                    *
                    * unofficial recipient attributes
                    * 
                    * stop
                    * 
                    */

                    invoice_serial: 'ser',
                    invoice_number: '123',

                    invoice_date: d.getDate() + ' ' + translation[this.default_language].general.months[d.getMonth()] + ', ' + d.getFullYear(),

                    // items
                    invoice_items: [
                        {
                            description: '',
                            description_placeholder: translation[this.default_language].general.item_description,
                            quantity: 0.00,
                            price: 0.00,
                            vat: 0.00
                        }
                    ],

                    invoice_vat_type: [],

                    // subtotal
                    invoice_subtotal: 0.00,

                    // vat
                    invoice_vat_value: 0.00,

                    // invoice total without vat
                    invoice_total_without_vat: 0.00,

                    // invoice total vat
                    invoice_total_vat: 0.00,

                    // total
                    invoice_total: 0.00,

                    // the currency
                    invoice_currency: 'EUR',

                    // the currency symbol
                    invoice_currency_symbol: '€'

                },

                errors: {

                    invoice_type: true,

                    /* DELIMITER */

                    /*
                    *
                    * official recipient attributes
                    *
                    * start
                    * 
                    */

                    recipient_account_type: true,
                    recipient_company_name: true,
                    recipient_vat_nr: true,
                    recipient_email: true,
                    recipient_phone_nr: true,

                    recipient_addresses_country_key: true,
                    recipient_addresses_city: true,
                    recipient_addresses_street_address: true,
                    recipient_addresses_zip: true,

                    /*
                    *
                    * official recipient attributes
                    * 
                    * stop
                    * 
                    */

                    /* DELIMITER */

                    /*
                    *
                    * invoice number and date
                    * 
                    * start
                    * 
                    */

                    // invoice number
                    invoice_number_label: true,
                    invoice_serial: true,
                    invoice_number: true,

                    // invoice date
                    invoice_date_label: true,
                    invoice_date: true,

                    /*
                    *
                    * invoice number and date
                    * 
                    * stop
                    * 
                    */

                    /* DELIMITER */

                    /*
                    *
                    * official provider attributes
                    *
                    * start
                    * 
                    */

                    provider_company_name: true,

                    provider_vat_nr: true,
                    provider_phone_nr: true,
                    provider_bank_account: true,
                    provider_bank_name: true,

                    provider_address_country_key: true,

                    provider_address_county: true,
                    provider_address_city: true,
                    provider_address_street_address: true,
                    provider_address_zip: true,

                    /*
                    *
                    * official provider attributes
                    *
                    * stop
                    * 
                    */

                },

                /* DELIMITER */

                /*
                *
                * the form attributes that can be "touched"
                *
                * start
                * 
                */

                touched: {

                    // invoice type
                    invoice_type: false,

                    /*
                    *
                    * official recipient attributes
                    *
                    * start
                    * 
                    */

                    recipient_account_type: false,

                    recipient_company_name: false,

                    recipient_vat_nr: false,
                    recipient_email: false,
                    recipient_phone_nr: false,

                    recipient_addresses_country_key: false,

                    recipient_addresses_city: false,
                    recipient_addresses_street_address: false,
                    recipient_addresses_zip: false,

                    /*
                    *
                    * official recipient attributes
                    * 
                    * stop
                    * 
                    */

                    /* DELIMITER */

                    /*
                    *
                    * invoice number and date
                    * 
                    * start
                    * 
                    */

                    invoice_number_label: false,
                    invoice_serial: false,
                    invoice_number: false,

                    invoice_date_label: false,
                    invoice_date: false,

                    /*
                    *
                    * invoice number and date
                    * 
                    * stop
                    * 
                    */

                    /* DELIMITER */

                    /*
                    *
                    * official provider attributes
                    *
                    * start
                    * 
                    */

                    provider_company_name: false,

                    provider_vat_nr: false,
                    provider_phone_nr: false,
                    provider_bank_account: false,
                    provider_bank_name: false,

                    provider_address_country_key: false,

                    provider_address_county: false,
                    provider_address_city: false,
                    provider_address_street_address: false,
                    provider_address_zip: false,

                    /*
                    *
                    * official provider attributes
                    *
                    * stop
                    * 
                    */

                }

                /*
                *
                * the form attributes that can be "touched"
                *
                * stopF
                * 
                */

            },

            /*
            *
            * form attributes
            *
            * stop
            * 
            */

            container_scenario: 'left-middle-right',

            flag_permission_loading_element_animation: true,// ?

            /*
            * default value should be set to false
            */

            loading_element: false,// ok 14.11.2019

            arr_languages: arr_languages,// ok 14.11.2019

            button_save_invoice_label: translation[this.default_language][this.module_name].button_save_invoice_label,// ok 14.11.2019
            button_new_bill_label: translation[this.default_language][this.module_name].button_new_bill_label,// ok 14.11.2019
            button_download_pdf_label: translation[this.default_language][this.module_name].button_download_pdf_label// ok 14.11.2019

        };

        return initialState;

        //

    }

    /* DELIMITER */

    constructor(props) {

        super(props);

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleInvoiceReadOnly / construct', this.flag_console_log);

        ConsoleService('ModuleInvoiceReadOnly / props', this.flag_console_log);
        ConsoleService(props, this.flag_console_log);

        this.default_language = props.language;

        let state = this.getInitialState();

        ConsoleService('ModuleInvoiceReadOnly / initial state', this.flag_console_log);
        ConsoleService(state, this.flag_console_log);

        state.language = props.language;

        this.state = state;

    }

    /* DELIMITER */

    componentDidMount() {

        ConsoleService('ModuleInvoiceReadOnly / component did mount', this.flag_console_log);

        ConsoleService('this.state', this.flag_console_log);
        ConsoleService(this.state, this.flag_console_log);

        this.idb_for_module_invoices = null;

        if (
            !('indexedDB' in window)
        ) {

            ConsoleService('This browser doesn\'t support IndexedDB', this.flag_console_log);

        } else {

            ConsoleService('This browser supports IndexedDB', this.flag_console_log);

            /*
            * !! DO NOT DELETE !! THE DB SHOULD BE CREATED FOR ANY NEW USER !!
            */

            new IndexedDbComponent();

            new DexieDbComponent();

            this.idb_for_module_invoices = new IndexedDbModuleInvoicesComponent();

        }

        this.detect_prop_invoice_update_state({}, this.props);

        let t = this;

        IndexedDbProviderReturnProviderComponent(1).then(
            (data) => {

                /*
                *
                * ok
                * 
                */

                ConsoleService('ModuleInvoiceReadOnly / getInitialState() / IndexedDbProviderReturnProviderComponent / new promise then', t.flag_console_log);

                ConsoleService('ModuleInvoiceReadOnly / getInitialState() / IndexedDbProviderReturnProviderComponent / new promise => then / data', t.flag_console_log);
                ConsoleService(data, t.flag_console_log);

                if (
                    data.target.result !== undefined
                ) {

                    // ok

                    let data_provider = data.target.result;

                    let newState = Object.assign({}, this.state);

                    newState.form.attributes.provider_company_name = data_provider.provider_company_name;

                    newState.form.attributes.provider_vat_nr = data_provider.provider_vat_nr;
                    newState.form.attributes.provider_phone_nr = data_provider.provider_phone_nr;
                    newState.form.attributes.provider_bank_account = data_provider.provider_bank_account;
                    newState.form.attributes.provider_bank_name = data_provider.provider_bank_name;

                    // set the value as "fr" for the attribute
                    newState.form.attributes.provider_address_country_key = data_provider.provider_address_country_key;
                    // set the value as "France" for the attribute
                    newState.form.attributes.provider_address_country_name = data_provider.provider_address_country_name;

                    newState.form.attributes.provider_address_county = data_provider.provider_address_county;
                    newState.form.attributes.provider_address_city = data_provider.provider_address_city;
                    newState.form.attributes.provider_address_street_address = data_provider.provider_address_street_address;
                    newState.form.attributes.provider_address_zip = data_provider.provider_address_zip;

                    this.setState(newState);

                }

                return true;

                //

            }
        ).catch(
            (data) => {

                /*
                *
                * error
                * 
                */

                ConsoleService('ModuleInvoiceReadOnly / getInitialState() / IndexedDbProviderReturnProviderComponent / new promise => catch', t.flag_console_log);

                ConsoleService('ModuleInvoiceReadOnly / getInitialState() / IndexedDbProviderReturnProviderComponent / new promise => catch / data', t.flag_console_log);
                ConsoleService(data, t.flag_console_log);

                return false;

                //

            }
        );

    }

    /* DELIMITER */

    detect_prop_invoice_update_state = (prevProps, props) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleInvoiceReadOnly / detect_prop_invoice_update_state', this.flag_console_log);

        ConsoleService('ModuleInvoiceReadOnly / detect_prop_invoice_update_state props', this.flag_console_log);
        ConsoleService(props, this.flag_console_log);

        let flag_permission = true;

        if (
            flag_permission
        ) {

            // ok

            ConsoleService('ModuleInvoiceReadOnly / detect_prop_invoice_update_state / the invoice data will be loaded within the state', this.flag_console_log);

            /*
            *
            * update the state
            * 
            * start
            * 
            */

            let newState = Object.assign({}, this.state);

            // PK
            newState.form.attributes.invoice_object_store_id = props.invoice.invoice_object_store_id;

            // currency coin and currency symbol
            newState.form.attributes.invoice_currency = props.invoice.invoice_currency;
            newState.form.attributes.invoice_currency_symbol = props.invoice.invoice_currency_symbol;

            newState.form.attributes.invoice_type = props.invoice.invoice_type;

            /*
            *
            * provider attributes
            * 
            * start
            * 
            */

            newState.form.attributes.provider_object_store_id = props.invoice.provider_object_store_id;// this attribute value should always be equal to 1

            newState.form.attributes.provider_external_id = props.invoice.provider_external_id;

            newState.form.attributes.provider_company_name = props.invoice.provider_company_name;
            newState.form.attributes.provider_vat_nr = props.invoice.provider_vat_nr;
            newState.form.attributes.provider_phone_nr = props.invoice.provider_phone_nr;
            newState.form.attributes.provider_bank_account = props.invoice.provider_bank_account;
            newState.form.attributes.provider_bank_name = props.invoice.provider_bank_name;

            // set the value as "France" for the attribute
            newState.form.attributes.provider_address_country_name = props.invoice.provider_address_country_name;

            newState.form.attributes.provider_address_county = props.invoice.provider_address_county;
            newState.form.attributes.provider_address_city = props.invoice.provider_address_city;
            newState.form.attributes.provider_address_street_address = props.invoice.provider_address_street_address;
            newState.form.attributes.provider_address_zip = props.invoice.provider_address_zip;

            /*
            *
            * provider attributes
            * 
            * stop
            * 
            */

            /*
            *
            * recipient data
            *
            * start
            * 
            */

            // PK
            newState.form.attributes.recipient_object_store_id = props.invoice.recipient_object_store_id;

            // external id
            newState.form.attributes.recipient_external_account_id = props.invoice.recipient_external_account_id;

            newState.form.attributes.recipient_company_name = props.invoice.recipient_company_name;

            // set the value as "individual" for the attribute
            newState.form.attributes.recipient_account_type = newState.form.select.recipient_account_type;
            // set the value as "Individual" for the attribute
            newState.form.attributes.recipient_account_type_name = props.invoice.recipient_account_type_name;

            newState.form.attributes.recipient_addresses_city = props.invoice.recipient_addresses_city;

            // set the value as "fr" for the attribute
            newState.form.attributes.recipient_addresses_country_key = props.invoice.recipient_addresses_country_name;
            // set the value as "France" for the attribute
            newState.form.attributes.recipient_addresses_country_name = props.invoice.recipient_addresses_country_name;

            newState.form.attributes.recipient_addresses_street_address = props.invoice.recipient_addresses_street_address;
            newState.form.attributes.recipient_addresses_zip = props.invoice.recipient_addresses_zip;

            newState.form.attributes.recipient_vat_nr = props.invoice.recipient_vat_nr;

            newState.form.attributes.recipient_email = props.invoice.recipient_email;

            newState.form.attributes.recipient_phone_nr = props.invoice.recipient_phone_nr;

            /*
            *
            * recipient data
            *
            * stop
            * 
            */

            // invoice serial & number
            newState.form.labels.invoice_number_label = props.invoice.invoice_number_label;
            newState.form.attributes.invoice_serial = props.invoice.invoice_serial;
            newState.form.attributes.invoice_number = props.invoice.invoice_number;

            // invoice date
            newState.form.labels.invoice_date_label = props.invoice.invoice_date_label;
            newState.form.attributes.invoice_date = props.invoice.invoice_date;

            // invoice items labels
            newState.form.labels.item_description_label = props.invoice.item_description_label;
            newState.form.labels.item_price_label = props.invoice.item_price_label;
            newState.form.labels.item_quantity_label = props.invoice.item_quantity_label;
            newState.form.labels.item_vat_label = props.invoice.item_vat_label;

            // the invoice items
            newState.form.attributes.invoice_items = props.invoice.invoice_items;

            // custom partial text
            newState.form.labels.invoice_subtotal_without_vat_for_vat_x = props.invoice.invoice_subtotal_without_vat_for_vat_x;
            newState.form.labels.invoice_total_vat_for_vat_x = props.invoice.invoice_total_vat_for_vat_x;

            // total without vat
            newState.form.labels.invoice_total_without_vat_label = props.invoice.invoice_total_without_vat_label;// label
            newState.form.attributes.invoice_total_without_vat = props.invoice.invoice_total_without_vat;// value

            // total vat
            newState.form.attributes.invoice_total_vat_label = props.invoice.invoice_total_vat_label;// label
            newState.form.attributes.invoice_total_vat = props.invoice.invoice_total_vat;// value

            // vat type
            newState.form.attributes.invoice_vat_type = props.invoice.invoice_vat_type;// value

            // total
            newState.form.labels.invoice_total_label = props.invoice.invoice_total_label;// label
            newState.form.attributes.invoice_total = props.invoice.invoice_total;// value

            ConsoleService('newState', this.flag_console_log);
            ConsoleService(newState, this.flag_console_log);

            this.setState(newState);

            /*
            *
            * update the state
            * 
            * stop
            * 
            */

        }

        //

    }

    render() {

        ConsoleService('ModuleInvoiceReadOnly / render() / this.state', this.flag_console_log);
        ConsoleService(this.state, this.flag_console_log);

        let container_bill_styles = { margin: '0 auto' };

        return (
            <div className="ModuleInvoice ModuleInvoiceReadOnly">
                {/* ModuleInvoice */}

                {/* container-invoice */}
                <div id="container-invoice">

                    {/* container bill */}
                    <div id="container-bill" style={container_bill_styles}>

                        <div className="content">

                            <div className="options">

                                {this.state.form.attributes.invoice_object_store_id === 0 ? " " : null}

                                {this.state.form.attributes.invoice_object_store_id !== 0 ? " " : null}

                            </div>

                            <div id="page">

                                <ModuleInvoiceComponentRenderJsxReadOnly t={this} />

                            </div>

                        </div>

                    </div>
                    {/* /container bill */}

                </div>
                {/* /container-invoice */}

                {/* /ModuleInvoice */}
            </div >
        );

    }

}

export default ModuleInvoiceReadOnly;