import React, { Component } from "react";

import ReactSelect from 'react-select';

import Modal from 'react-bootstrap/Modal';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import ModuleProvider from './ModuleProvider';
import ModuleInvoices from './ModuleInvoices';
import ModuleReceiptsV2 from './ModuleReceiptsV2';
import ModuleRecipients from './ModuleRecipients';
import ModuleSettings from './ModuleSettings';
import ModuleGauth from './ModuleGauth';
import ModuleFauth from './ModuleFauth';

import localStorage_getData from './components/LocalStorageGetComponent';

import "./css/App.css";

/*
*
* "react notification component" docs available at :
*
* https://www.npmjs.com/package/react-notifications-component
*
*/

import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

import ConsoleService from './components/consoleServiceModules/ConsoleService';

import ModuleBill from "./ModuleBill";

import default_language, { arr_languages } from './LanguageComponent';

import translation from './TranslationComponent';

import arr_currency from './components/dataProviders/const_arr_currency_data';

const eventEmitter = require('events');
window.emitService = new eventEmitter();

//console.log(setting_property);

class App extends Component {

    module_name = 'ModuleBill';

    default_language = default_language;

    flag_console_log = false;

    state = {

        language: default_language,

        locale: 'en',

        arr_languages: arr_languages,

        local_storage: {},

        local_storage_user: {},

        /* DELIMITER */

        currency: { value: 'EUR', symbol: '€', label: '€ ( EUR )' },

        currency_select_options: arr_currency,

        /* DELIMITER */

        click_on_invoices_counter: 0,

        click_on_recipients_counter: 0,

        show_modal: false,

        show_navbar: false,

    }

    constructor(props) {

        super(props);

        ConsoleService(this.module_name + ' / constructor', this.flag_console_log);

        ConsoleService(this.state.language, this.flag_console_log);

        ConsoleService(translation, this.flag_console_log);

    }

    componentDidMount() {

        ConsoleService(this.module_name + ' / component did mount', this.flag_console_log);

        ConsoleService('this.state', this.flag_console_log);
        ConsoleService(this.state, this.flag_console_log);

        window.emitService.on('loggedIn', () => {
            ConsoleService('emitService on / loggedIn', this.flag_console_log);

            let newState = Object.assign({}, this.state);

            let data = localStorage.getItem('react-billableme-user');

            if (data) {
                data = JSON.parse(data);
                if (
                    data.hasOwnProperty('token')
                ) {
                    newState.local_storage_user = data;
                }
            }

            ConsoleService('local storage user', this.flag_console_log);
            ConsoleService(newState.local_storage_user, this.flag_console_log);

            this.setState(newState);
        });

        let newState = Object.assign({}, this.state);

        newState.local_storage = localStorage_getData();

        ConsoleService('local storage', this.flag_console_log);
        ConsoleService(newState.local_storage, this.flag_console_log);

        let data = localStorage.getItem('react-billableme-user');

        if (data) {
            data = JSON.parse(data);
            if (
                data.hasOwnProperty('token')
            ) {
                newState.local_storage_user = data;

                this.checkUserToken(data.token);
            }
        }

        ConsoleService('local storage user', this.flag_console_log);
        ConsoleService(newState.local_storage_user, this.flag_console_log);

        this.setState(newState);

    }

    componentDidUpdate() {

        ConsoleService('', this.flag_console_log);
        ConsoleService(this.module_name + ' component did update', this.flag_console_log);

        ConsoleService('this.state', this.flag_console_log);
        ConsoleService(this.state, this.flag_console_log);

    }

    componentWillUnmount() {

        ConsoleService('', this.flag_console_log);
        ConsoleService(this.module_name + ' component will unmount', this.flag_console_log);

        window.emitService.removeListener('loggedIn', () => {
            //
        });

    }

    /* DELIMITER */

    elementSelectCurrencyChangeHandler = (event) => {

        ConsoleService(this.module_name + ' / element select currency change hHandler', this.flag_console_log);

        ConsoleService('event', this.flag_console_log);
        ConsoleService(event, this.flag_console_log);

        let newState = Object.assign({}, this.state);

        // { name : 'United States Dollar', symbol : '$', value : '$ ( USD )' }
        newState.currency = event;

        ConsoleService('newState', this.flag_console_log);
        ConsoleService(newState, this.flag_console_log);

        this.setState(newState);

    }

    /* DELIMITER */

    /*
    *
    * language change handler
    * 
    * start
    * 
    */

    languageChangedHandler = (event, language) => {

        event.preventDefault();

        ConsoleService(this.module_name + ' / language changed handler', this.flag_console_log);

        ConsoleService(this.module_name + ' / language changed handler language', this.flag_console_log);
        ConsoleService(language, this.flag_console_log);

        let flag_permission = true;

        if (
            flag_permission
        ) {

            // ok

            if (
                this.state.language !== language
            ) {

                // ok

            } else {

                /*
                * error
                *
                * its the same language
                * 
                * do not reload !!!
                * 
                */

                flag_permission = false;

                ConsoleService("ModuleBill / language changed handler / it's the same language", this.flag_console_log);

            }

        }

        if (
            flag_permission
        ) {

            // ok

            /*
            *
            * assign the new state
            *
            * start
            * 
            */

            let newState = Object.assign({}, this.state);

            newState.language = language;

            if (language === 'fr') {

                newState.locale = 'fr';

            } else if (language === 'ro') {

                newState.locale = 'ro';

            } else {
                newState.locale = 'en';
            }

            this.setState(newState);

            /*
            *
            * assign the new state
            *
            * stop
            * 
            */

        }

        return true;

        //

    }

    /*
    *
    * language changed handler
    * 
    * stop
    * 
    */

    /* DELIMITER */

    invoicesOnClickHandler = () => {

        ConsoleService(this.module_name + 'on click handler', this.flag_console_log);

        let click_on_invoices_counter = this.state.click_on_invoices_counter + 1;

        this.setState(

            {
                click_on_invoices_counter: click_on_invoices_counter,// inc
            }

        );

    }

    recipientsOnClickHandler = () => {

        ConsoleService(this.module_name + 'on click handler', this.flag_console_log);

        let click_on_recipients_counter = this.state.click_on_recipients_counter + 1;

        this.setState(

            {
                click_on_recipients_counter: click_on_recipients_counter,// inc
            }

        );

    }

    /* DELIMITER */

    loginOnClickHandler = () => {

        ConsoleService(this.module_name + ' on click handler', this.flag_console_log);

        this.setState(

            {
                show_modal: true,
            }

        );

    }

    logoutOnClickHandler = () => {

        ConsoleService(this.module_name + ' on click handler', this.flag_console_log);

        this.logoutUser();

    }

    /* DELIMITER */

    modal_close_handle = () => {

        ConsoleService(this.module_name + ' on click handler', this.flag_console_log);

        this.setState(

            {
                show_modal: false,
            }

        );
    }

    /* DELIMITER */

    loginGoogleOnClickHandler = () => {

        ConsoleService(this.module_name + ' on click handler', this.flag_console_log);

        fetch(process.env.REACT_APP_API_URL + 'login/google')
            .then(res => res.json())
            .then(
                (result) => {
                    window.location.href = result.redirect;
                }
            )
            .catch(
                (error) => {
                    ConsoleService(this.module_name + ' loginGoogleOnClickHandler / catch / error', this.flag_console_log);
                    ConsoleService(error, this.flag_console_log);
                }
            );

    }

    loginFacebookOnClickHandler = () => {

        ConsoleService(this.module_name + ' on click handler', this.flag_console_log);

        fetch(process.env.REACT_APP_API_URL + 'login/facebook')
            .then(res => res.json())
            .then(
                (result) => {
                    window.location.href = result.redirect;
                }
            )
            .catch(
                (error) => {
                    ConsoleService(this.module_name + ' loginFacebookOnClickHandler / catch / error', this.flag_console_log);
                    ConsoleService(error, this.flag_console_log);
                }
            );
    }

    /* DELIMITER */

    checkUserToken = (token) => {

        ConsoleService(this.module_name + ' / check user token', this.flag_console_log);

        var obj = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        };
        fetch(process.env.REACT_APP_API_URL + 'checkUserToken', obj)
            .then(res => res.json())
            .then(
                (result) => {
                    ConsoleService(this.module_name + ' checkUserToken / then / result', this.flag_console_log);
                    ConsoleService(result, this.flag_console_log);
                }
            )
            .catch(
                (error) => {
                    ConsoleService(this.module_name + ' checkUserToken / catch / error', this.flag_console_log);
                    ConsoleService(error, this.flag_console_log);
                }
            );
    }

    /* DELIMITER */

    logoutUser = () => {

        var obj = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.state.local_storage_user.token
            }
        };

        fetch(process.env.REACT_APP_API_URL + 'logout', obj)
            .then(res => res.json())
            .then(
                (result) => {
                    ConsoleService(this.module_name + ' logoutUser / then / result', this.flag_console_log);
                    ConsoleService(result, this.flag_console_log);

                    this.setState(

                        {
                            local_storage_user: {},
                        }

                    );

                    localStorage.removeItem('react-billableme-user');
                }
            )
            .catch(
                (error) => {
                    ConsoleService(this.module_name + ' logoutUser / catch / error', this.flag_console_log);
                    ConsoleService(error, this.flag_console_log);
                }
            );
    }

    /* DELIMITER */

    navbarOnClickHandler = () => {

        this.setState(

            {
                show_navbar: !this.state.show_navbar
            }

        );

    }

    /* DELIMITER */

    render() {
        ConsoleService(this.module_name + ' render', this.flag_console_log);

        ConsoleService('state', this.flag_console_log);
        ConsoleService(this.state, this.flag_console_log);

        const languages = Object.keys(this.state.arr_languages).map(
            (language, key) => {
                return (
                    <React.Fragment key={key}>

                        {

                            this.state.local_storage.hasOwnProperty('language') &&
                                this.state.local_storage.language.hasOwnProperty(language) &&
                                this.state.local_storage['language'][language] === 1 ?

                                <span>
                                    &nbsp;
                                    <img
                                        src={require('./pictures/flag_' + language + '.png')}
                                        alt="Info"
                                        height="20"
                                        onClick={(event) => this.languageChangedHandler(event, language)} />
                                </span>

                                : null

                        }

                    </React.Fragment>
                );
            }
        );

        let currency_select_options_styles = { width: '125px' };

        let footer_date = new Date();

        footer_date = footer_date.getFullYear();

        return (
            <div id="ModuleApp" className="App">

                <Router>

                    <ReactNotification />

                    {/* spacer-element-header */}
                    <div id="spacer-element-header"></div>
                    {/* /spacer-element-header */}

                    {/* container-header */}
                    <div id="container-header">

                        <div className="row">

                            <div className="col-12">

                                <nav className="navbar navbar-expand-lg navbar-light bg-light">

                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={this.navbarOnClickHandler}>
                                        <span className="navbar-toggler-icon"></span>
                                    </button>

                                    <div className={this.state.show_navbar ? 'navbar-collapse' : 'collapse navbar-collapse'} id="navbarSupportedContent">

                                        <ul className="navbar-nav mr-auto">

                                            <li>
                                                <button className="btn">
                                                    LOGO
                                                </button>
                                            </li>

                                            <li className="nav-item ">

                                                <Link to="/" className="btn btn-md">
                                                    {translation[this.state.language][this.module_name].button_new_invoice_label}
                                                </Link>

                                            </li>

                                        </ul>

                                        <ul id="headerMenuNavBarRight" className="list-inline">

                                            <li className="list-inline-item">

                                                <Link to="/provider" className="btn btn-md">
                                                    {translation[this.state.language].general.my_details}
                                                </Link>

                                            </li>

                                            <li className="list-inline-item">

                                                <Link to="/invoices" className="btn btn-md" onClick={this.invoicesOnClickHandler}>
                                                    {translation[this.state.language][this.module_name].button_invoices_label}
                                                </Link>

                                            </li>

                                            {

                                                /* this feature is available only if the romanian language is available and active */

                                                this.state.local_storage.hasOwnProperty('language') &&
                                                    this.state.local_storage.language.hasOwnProperty('ro') &&
                                                    this.state.local_storage.language.ro === 1 ?

                                                    <li className="list-inline-item">

                                                        <Link to="/receipts" className="btn btn-md">
                                                            Chitante
                                                        </Link>

                                                    </li>

                                                    : null

                                            }

                                            <li className="list-inline-item">

                                                <Link to="/recipients" className="btn btn-md" onClick={this.recipientsOnClickHandler}>
                                                    {translation[this.state.language][this.module_name].button_recipients_label}
                                                </Link>

                                            </li>

                                            <li className="list-inline-item" style={currency_select_options_styles}>

                                                <ReactSelect
                                                    id="react_select_invoice_currency"
                                                    name="curency"
                                                    value={this.state.currency}
                                                    onChange={this.elementSelectCurrencyChangeHandler}
                                                    options={this.state.currency_select_options} />

                                            </li>

                                            <li className="list-inline-item">

                                                {languages}

                                            </li>

                                            <li className="list-inline-item">

                                                <Link to="/settings" className="btn btn-md">
                                                    <i className="fa fa-cog"></i>
                                                </Link>

                                            </li>

                                            {this.state.local_storage_user.hasOwnProperty('token') ?
                                                <li className="list-inline-item">

                                                    <button className="btn btn-primary" type="button" onClick={this.logoutOnClickHandler}>{translation[this.state.language][this.module_name].button_logout_label}</button>

                                                </li>
                                                :
                                                <li className="list-inline-item">

                                                    <button className="btn btn-primary" type="button" onClick={this.loginOnClickHandler}>{translation[this.state.language][this.module_name].button_login_label}</button>

                                                </li>}

                                        </ul>

                                    </div>
                                </nav>

                            </div>

                        </div>

                        {/* modal login / start */}

                        {

                            this.state.show_modal ?

                                <Modal show={this.state.show_modal} onHide={this.modal_close_handle}>

                                    <Modal.Header closeButton>

                                        <Modal.Title>{translation[this.state.language].general.login}</Modal.Title>

                                    </Modal.Header>

                                    <Modal.Body>

                                        {/* DELIMITER */}

                                        {/* login with google */}
                                        <button className="btn btn-primary btn-login btn-login-g" onClick={this.loginGoogleOnClickHandler}><i className="fa fa-google"></i> {translation[this.state.language][this.module_name].button_login_google_label}</button>
                                        {/* /login with google */}

                                        {/* login with facebook */}
                                        <button className="btn btn-primary btn-login" onClick={this.loginFacebookOnClickHandler}><i className="fa fa-facebook"></i> {translation[this.state.language][this.module_name].button_login_facebook_label}</button>
                                        {/* /login with facebook */}

                                        {/* DELIMITER */}

                                    </Modal.Body>

                                    <Modal.Footer>

                                        <button variant="secondary" onClick={this.modal_close_handle}>
                                            {translation[this.state.language].general.close}
                                        </button>

                                    </Modal.Footer>

                                </Modal>

                                : null

                        }

                        {/* modal form invoice / stop */}

                    </div>
                    {/* /container-header */}

                    {/* spacer-element-footer */}
                    <div id="spacer-element"></div>
                    {/* /spacer-element-footer */}

                    {/* DELIMITER */}

                    {/* container-main */}
                    <div id="container-main">

                        <div id="spacer-element-white"></div>

                        <Switch>

                            <Route
                                path="/provider"
                                render={(props) => <ModuleProvider language={this.state.language} currency={this.state.currency} />}>
                            </Route>

                            <Route
                                path="/invoices"
                                render={(props) => <ModuleInvoices
                                    language={this.state.language}
                                    currency={this.state.currency}
                                    click_on_invoices_counter={this.state.click_on_invoices_counter} />}>
                            </Route>

                            <Route path="/receipts">

                                <ModuleReceiptsV2 />

                            </Route>

                            <Route
                                path="/recipients"
                                render={(props) => <ModuleRecipients
                                    language={this.state.language}
                                    click_on_recipients_counter={this.state.click_on_recipients_counter} />}>
                            </Route>

                            <Route
                                path="/settings"
                                render={(props) => <ModuleSettings language={this.state.language} />}>
                            </Route>

                            <Route
                                path="/gauth"
                                render={(props) => <ModuleGauth {...props} language={this.state.language} />}>
                            </Route>

                            <Route
                                path="/fauth"
                                render={(props) => <ModuleFauth {...props} language={this.state.language} />}>
                            </Route>

                            <Route
                                path="/"
                                render={(props) => <ModuleBill language={this.state.language} />}>
                            </Route>

                        </Switch>

                    </div>
                    {/* /container-main */}

                    {/* DELIMITER */}

                    {/* spacer-element-footer */}
                    <div id="spacer-element"></div>
                    {/* /spacer-element-footer */}

                    {/* spacer-element-footer */}
                    <div id="spacer-element-footer"></div>
                    {/* /spacer-element-footer */}

                    {/* container-footer */}
                    <div id="container-footer">

                        &copy; {footer_date}

                    </div>
                    {/* /container-footer */}

                </Router>

            </div>
        );

    }

}

export default App;
