import React, { Component } from 'react';

import ReactSelect from 'react-select';

import ReactPaginate from 'react-paginate';

/*
*
* css files
*
* start
*
*/

import "./css/ModuleReceipts.css";

/*
*
* css files
*
* stop
*
*/

import ConsoleService from './components/consoleServiceModules/ConsoleService';//ok 12.12.2019

import ConsoleServiceModuleReceipts from './components/consoleServiceModules/ConsoleServiceModuleReceipts';//ok 12.12.2019

import serviceGenerateRandomString from './serviceGenerateRandomString';

import arr_items_perPage from './components/dataProviders/const_arr_items_per_page_data';

import translation from './TranslationComponent';

/*
*
* Indexed DB custom components
*
* start
*
*/

import IndexedDbComponent from './IndexedDbComponent';// 12.12.2019

import DexieDbComponent from './DexieDbComponent';

import IndexedDbReturnReceiptsComponent from './components/IndexedDbReturnReceiptsComponent';

import IndexedDbModuleInvoicesComponent from './IndexedDbModuleInvoicesComponent';

/*
*
* Indexed DB custom components
*
* stop
*
*/

//import ModuleReceipt from './ModuleReceipt';

import ModuleReceiptV2 from './ModuleReceiptV2';

import PdfMakeConvertReceiptToPdf from './components/PdfMakeConvertReceiptToPdf';

class ModuleReceiptsV2 extends Component {

    module_name = 'ModuleReceipts';

    idb_for_module_receipts = false;// ok 12.12.2019

    idb_for_module_invoices = null;

    flag_console_log = ConsoleServiceModuleReceipts;

    state = {

        language: 'ro',

        flag_render_type: 'list',

        // the receipts list
        receipt_items: [],
        receipt_items_count: 0,

        click_on_receipts_counter: 0,// ok 12.12.2019

        // the invoice record will be stored here
        invoice: {},

        receipt_hash: serviceGenerateRandomString(32),

        invoice_filter: '',

        invoice_filter_option: null,

        invoice_filter_select_options: [],

        invoices: [],

        /*
        *
        * pagination info
        * 
        * start
        * 
        */

        receipts_list_pageCount: 0,
        receipts_list_perPage: { value: 15, label: '15' },
        receipts_list_perPage_select_options: arr_items_perPage,
        receipts_list_offset: 0,
        receipts_list_currentPage: 0,

        /*
        *
        * pagination info
        * 
        * stop
        * 
        */

        receipt: {},

    };

    constructor(props) {

        super(props);

        ConsoleService(this.module_name + ' / constructor', this.flag_console_log);

        ConsoleService('props', this.flag_console_log);
        ConsoleService(props, this.flag_console_log);

    }

    componentDidMount() {

        //

        ConsoleService(this.module_name + ' / componentDidMount', this.flag_console_log);

        ConsoleService('this.state', this.flag_console_log);
        ConsoleService(this.state, this.flag_console_log);

        this.idb_for_module_receipts = false;

        if (
            !('indexedDB' in window)
        ) {

            //

            ConsoleService('This browser doesn\'t support IndexedDB', this.flag_console_log);

            //

        } else {

            //

            ConsoleService('This browser supports IndexedDB', this.flag_console_log);

            /*
            * !! DO NOT DELETE !! THE DB SHOULD BE CREATED FOR ANY NEW USER !!
            */

            new IndexedDbComponent();

            new DexieDbComponent();

            this.idb_for_module_receipts = true;

            //

        }

        this.load_all_the_receipt_store_objects();

        //

    }

    /* DELIMITER */

    componentDidUpdate(prevProps, prevState) {

        ConsoleService("", this.flag_console_log);
        ConsoleService("ModuleReceipts / componentDidUpdate()", this.flag_console_log);

        ConsoleService('prevState', this.flag_console_log);
        ConsoleService(prevState, this.flag_console_log);

        ConsoleService('this.state', this.flag_console_log);
        ConsoleService(this.state, this.flag_console_log);

        ConsoleService('prevProps', this.flag_console_log);
        ConsoleService(prevProps, this.flag_console_log);

        ConsoleService('this.props', this.flag_console_log);
        ConsoleService(this.props, this.flag_console_log);

        if (
            this.props.hasOwnProperty('click_on_receipts_counter')
        ) {

            // ok, the prop exists

            if (
                parseInt(this.props.click_on_receipts_counter) !== parseInt(this.state.click_on_receipts_counter)
            ) {

                // ok

                // !!! PAY ATTENTION FOR ENDLESS LOOP !!!

                if (
                    parseInt(this.props.click_on_receipts_counter) > parseInt(this.state.click_on_receipts_counter)
                ) {

                    // ok

                    let newState = Object.assign({}, this.state);

                    newState.click_on_receipts_counter = parseInt(this.props.click_on_receipts_counter);

                    newState.flag_render_type = 'list';

                    this.setState(newState);

                    //

                }

                //

            }

            //

        }

        //

    }

    /* DELIMITER */

    /* DELIMITER */

    set_receipts_list_per_page = (payload) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleInvoices / set_receipts_list_per_page', this.flag_console_log);

        ConsoleService('payload', this.flag_console_log);
        ConsoleService(payload, this.flag_console_log);

        let option = {};

        Object.keys(arr_items_perPage).map(
            (index) => {

                ConsoleService('ModuleInvoices / set_receipts_list_per_page / object.keys.map / index', this.flag_console_log);
                ConsoleService(index, this.flag_console_log);

                ConsoleService('ModuleInvoices / set_receipts_list_per_page / object.keys.map / object', this.flag_console_log);
                ConsoleService(arr_items_perPage[index], this.flag_console_log);

                if (
                    arr_items_perPage[index].value === payload
                ) {
                    option = arr_items_perPage[index];
                }

                return true;

            }
        );

        return option;

    }

    /* DELIMITER */

    flag_render_type_handler = (event) => {

        event.preventDefault();

        ConsoleService(this.module_name + ' / flag_render_type_handler');

        let newState = Object.assign({}, this.state);

        let flag_render_type = ((this.state.flag_render_type === 'list') ? 'form' : 'list');

        newState.flag_render_type = flag_render_type;

        newState.invoice = {};

        newState.receipt_hash = serviceGenerateRandomString(32);

        newState.receipt = {};

        this.setState(newState);

        if (flag_render_type === 'form') {

            //

            this.idb_for_module_invoices = new IndexedDbModuleInvoicesComponent();

            this.idb_for_module_invoices.load_all_store_objects().then(
                (data) => {

                    //

                    ConsoleService(this.module_name + ' / IndexedDbModuleInvoicesComponent.load_all_store_objects() promise / then', this.flag_console_log);

                    ConsoleService(this.module_name + ' / IndexedDbModuleInvoicesComponent.load_all_store_objects() promise / then / data', this.flag_console_log);
                    ConsoleService(data, this.flag_console_log);

                    if (
                        data.length
                    ) {

                        // ok

                        let invoices = [];
                        let invoice_filter_select_options = [];

                        for (let i = 0; i < data.length; i++) {

                            console.log(i);
                            console.log(data[i]);

                            let invoice = data[i];

                            invoices.push(invoice);


                            let d = new Date(invoice.invoice_date);

                            let invoice_date_format = d.getDate() + ' ' + translation['ro'].general.months[d.getMonth()] + ', ' + d.getFullYear();

                            invoice_filter_select_options.push(
                                {
                                    value: invoice.invoice_object_store_id,
                                    label: 'Factura "' + invoice.invoice_number + '" din data de "' + invoice_date_format + '" pentru compania "' + invoice.recipient_company_name + '"'
                                }
                            );

                        }

                        let newState = Object.assign({}, this.state);

                        newState.invoices = invoices;

                        newState.invoice_filter_select_options = invoice_filter_select_options;

                        this.setState(newState);

                        //

                    }

                    //                    

                }
            ).catch(
                (data) => {

                    //

                    ConsoleService(this.module_name + ' / IndexedDbModuleInvoicesComponent.load_all_store_objects() promise / catch', this.flag_console_log);

                    ConsoleService(this.module_name + ' / IndexedDbModuleInvoicesComponent.load_all_store_objects() promise / catch / data', this.flag_console_log);
                    ConsoleService(data, this.flag_console_log);

                    // nothing to do here

                }
            );

            //

        }

    }

    /* DELIMITER */

    close_receipt_element = (event) => {

        event.preventDefault();

        ConsoleService(this.module_name + ' / close_receipt_element', this.flag_console_log);

        let newState = Object.assign({}, this.state);

        newState.flag_render_type = 'list';

        newState.invoice = {};

        this.setState(newState);

        this.load_all_the_receipt_store_objects();

    }

    /* DELIMITER */

    // form select element "invoice filter" changed handler
    formSelectElementInvoiceFilterChangeHandler = (newValue, actionMeta) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService(this.module_name + ' / formSelectElementInvoiceFilterChangeHandler', this.flag_console_log);

        ConsoleService('this.state.invoice_filter', this.flag_console_log);
        ConsoleService('*' + this.state.invoice_filter + '*', this.flag_console_log);

        ConsoleService('newValue', this.flag_console_log);
        ConsoleService('*' + newValue + '*', this.flag_console_log);
        ConsoleService(newValue, this.flag_console_log);

        ConsoleService('actionMeta', this.flag_console_log);
        ConsoleService(actionMeta, this.flag_console_log);

        /*
        
        update the state
        
        start
        
        */

        let newState = Object.assign({}, this.state);

        newState.invoice_filter_option = newValue;

        Object.keys(this.state.invoices).map(
            (index) => {

                ConsoleService(this.module_name + ' / object.keys.map / index', this.flag_console_log);
                ConsoleService(index, this.flag_console_log);

                ConsoleService(this.module_name + ' / object.keys.map / object', this.flag_console_log);
                ConsoleService(this.state.invoices[index], this.flag_console_log);

                if (
                    parseInt(this.state.invoices[index].invoice_object_store_id) === parseInt(newValue.value)
                ) {

                    // ok

                    /*
    
                    the ID's match
    
                    */

                    newState.invoice = this.state.invoices[index];

                } else {

                    // ok

                    /*
    
                    the ID's don't match
    
                    */

                }

                return true;

            }
        );

        newState.receipt_hash = serviceGenerateRandomString(32);

        newState.receipt = {};

        this.setState(newState);

        /*
        
        update the state
        
        stop
        
        */

    }

    /* DELIMITER */

    /*
     *
     * general handler
     * 
     * form react select element on blur handler
     * 
     * start
     * 
     */

    formReactSelectElementOnBlurHandler = (event, attribute = '') => {

        ConsoleService('', this.flag_console_log);
        ConsoleService(this.module_name + ' / formReactSelectElementOnBlurHandler', this.flag_console_log);

        ConsoleService('event', this.flag_console_log);
        ConsoleService(event, this.flag_console_log);

        ConsoleService('attribute', this.flag_console_log);
        ConsoleService(attribute, this.flag_console_log);

        //

    }

    /*
     *
     * general handler
     * 
     * form react select element on blur handler
     * 
     * stop
     * 
     */

    /* DELIMITER */

    receiptView = (event, item) => {

        event.preventDefault();

        ConsoleService('receiptView()', this.flag_console_log);

        ConsoleService('item', this.flag_console_log);
        ConsoleService(item, this.flag_console_log);

        let newState = Object.assign({}, this.state);

        newState.flag_render_type = 'form';

        newState.receipt = item;

        this.setState(newState);

    }

    /* DELIMITER */

    formSelectReceiptsListPerPageChangedHandler = (event) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService(this.module_name + ' formSelectReceiptsListPerPageChangedHandler', this.flag_console_log);

        ConsoleService('event', this.flag_console_log);
        ConsoleService(event, this.flag_console_log);

        let newState = Object.assign({}, this.state);

        newState.receipts_list_perPage = this.set_receipts_list_per_page(event.value);

        ConsoleService(this.module_name + ' formSelectReceiptsListPerPageChangedHandler newState', this.flag_console_log);
        ConsoleService(newState, this.flag_console_log);

        this.setState(newState);

        this.load_all_the_receipt_store_objects();

    }

    /* DELIMITER */

    load_all_the_receipt_store_objects = () => {

        ConsoleService("", this.flag_console_log);
        ConsoleService("ModuleReceipts / load_all_the_receipt_store_objects()", this.flag_console_log);

        if (
            this.idb_for_module_receipts
        ) {

            // ok

            /*

            load all receipts

            */

            IndexedDbReturnReceiptsComponent().then(
                (data) => {

                    //

                    ConsoleService(this.module_name + ' / IndexedDbReturnReceiptsComponent promise / then', this.flag_console_log);

                    ConsoleService(this.module_name + ' / IndexedDbReturnReceiptsComponent promise / then / data', this.flag_console_log);
                    ConsoleService(data, this.flag_console_log);

                    let perPage = this.state.receipts_list_perPage.value;

                    let newState = Object.assign({}, this.state);

                    newState.receipt_items = data;
                    newState.receipt_items_count = data.length;
                    newState.receipts_list_pageCount = Math.ceil(data.length / perPage);

                    this.setState(newState);

                    //

                }
            ).catch(
                (data) => {

                    //

                    ConsoleService(this.module_name + ' / IndexedDbReturnReceiptsComponent promise / catch', this.flag_console_log);

                    ConsoleService(this.module_name + ' / IndexedDbReturnReceiptsComponent promise / catch / data', this.flag_console_log);
                    ConsoleService(data, this.flag_console_log);

                    // nothing to do here

                }
            );

            //

        }

        //

    }

    /* DELIMITER */

    handlePageClick = data => {

        let selected = data.selected;
        let offset = Math.ceil(selected * this.state.receipts_list_perPage.value);

        this.setState({
            receipts_list_offset: offset,
            receipts_list_currentPage: selected
        });

    };

    /* DELIMITER */

    render() {

        ConsoleService('', this.flag_console_log);
        ConsoleService(this.module_name + ' render', this.flag_console_log);

        ConsoleService('this.state');
        ConsoleService(this.state);

        // this is where we slice the invoice list
        let current_receipts = this.state.receipt_items.slice(this.state.receipts_list_offset, this.state.receipts_list_offset + this.state.receipts_list_perPage.value);

        ConsoleService('render() current_receipts', this.flag_console_log);
        ConsoleService(current_receipts, this.flag_console_log);

        const downloadItem = (event, receipt) => {

            event.preventDefault();

            ConsoleService('download Receipt as PDF', this.flag_console_log);

            console.log(receipt);

            PdfMakeConvertReceiptToPdf(receipt);

        }

        let receipt_items = null;

        if (
            this.state.receipt_items.length
        ) {

            /*
        
            ok
        
            */

            ConsoleService("ModuleReceipts / render() / this.state.receipt_items", this.flag_console_log);
            ConsoleService(this.state.receipt_items, this.flag_console_log);

            receipt_items = (
                <React.Fragment>

                    {/* receipts list a.k.a. "items" / start */}

                    <table className="table table-bordered table-striped table-hover" id="receipts_items">

                        <thead>

                            <tr>

                                <th>
                                    <b>Companie</b>
                                </th>

                                <th>
                                    <b>Serie</b>
                                </th>

                                <th>
                                    <b>Data</b>
                                </th>

                                <th>
                                    <b>Total</b>
                                </th>

                                <th>
                                    Moneda
                                </th>

                                <th>
                                    <b>actiuni</b>
                                </th>

                            </tr>

                        </thead>

                        <tbody>

                            {

                                current_receipts.map(
                                    (item, index) => {

                                        //

                                        ConsoleService("ModuleReceipts / render() / state.receipt_items.map() / item");
                                        ConsoleService(item);

                                        ConsoleService("ModuleReceipts / render() / state.receipt_items.map() / item / index");
                                        ConsoleService(index);

                                        let d = new Date(item.receipt_date);

                                        let receipt_date_format = d.getDate() + ' ' + translation['ro'].general.months[d.getMonth()] + ', ' + d.getFullYear();

                                        return (
                                            <React.Fragment key={index}>

                                                <tr>

                                                    <td>

                                                        {item.recipient_company_name}

                                                    </td>

                                                    <td>

                                                        {item.receipt_serial + '-' + item.receipt_number}

                                                    </td>

                                                    <td>
                                                        {receipt_date_format}
                                                    </td>

                                                    <td>
                                                        {item.receipt_total}
                                                    </td>

                                                    <td>
                                                        RON
                                                    </td>

                                                    <td>

                                                        <button className="btn btn-info btn-sm" onClick={(event) => { this.receiptView(event, item) }}>
                                                            <i className="fa fa-eye"></i>
                                                        </button>

                                                        &nbsp;

                                                        <button className="btn btn-info btn-sm" onClick={(event) => { downloadItem(event, item) }}>
                                                            <i className="fa fa-download"></i> PDF
                                                        </button>

                                                    </td>

                                                </tr>

                                            </React.Fragment>
                                        );

                                        //

                                    }
                                )

                            }

                        </tbody>

                    </table>

                    {/* receipts list a.k.a. "items" / stop */}

                </React.Fragment >
            );

            //

        } else {

            //

            receipt_items = (
                <React.Fragment>
                    <span>nu exista chitante</span>
                </React.Fragment>
            )

            //

        }

        return (
            <React.Fragment>

                {

                    /* list / start */

                    this.state.flag_render_type === 'list' ?

                        <div id="ModuleReceipts">
                            {/* module receipts / start */}

                            <div className="row">

                                <div className="col-6">

                                    <div className="m5 p5">

                                        <h1>Chitante ( {this.state.receipt_items_count} )</h1>

                                    </div>

                                </div>

                                <div className="col-6">

                                    <div className="m5 p5">

                                        <button className="btn btn-primary btn-recipient" onClick={this.flag_render_type_handler}>
                                            + chitanta
                                        </button>

                                    </div>

                                </div>

                                {/* DELIMITER */}

                                <div className="col-12">

                                    <div className="container-alert">

                                        <div className="alert alert-success" role="alert">
                                            <h4 className="alert-heading">Info ;)</h4>
                                            <p>text goes here</p>
                                        </div>

                                    </div>

                                </div>

                                {/* DELIMITER */}

                                {/* receipts list container / start */}

                                <div className="col-12">

                                    {

                                        this.state.receipt_items.length ?

                                            <div className="m5 p5">

                                                <ReactSelect
                                                    id="receipts_list_perPage"
                                                    name="receipts_list_perPage"
                                                    className=""
                                                    value={this.state.receipts_list_perPage}
                                                    onChange={this.formSelectReceiptsListPerPageChangedHandler}
                                                    options={this.state.receipts_list_perPage_select_options} />

                                            </div>

                                            : null

                                    }

                                    <div id="receipts-container">

                                        {receipt_items}

                                    </div>

                                    {

                                        this.state.receipts_list_pageCount > 1 ?

                                            /* ReactPaginate / start */

                                            <div className="m5 p5">

                                                <ReactPaginate
                                                    previousLabel={translation[this.state.language].general.previous}
                                                    nextLabel={translation[this.state.language].general.next}
                                                    breakLabel={'...'}
                                                    breakClassName={'break-me'}
                                                    breakLinkClassName={'page-link'}
                                                    pageCount={this.state.receipts_list_pageCount}
                                                    forcePage={this.state.receipts_list_currentPage}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={5}
                                                    onPageChange={this.handlePageClick}
                                                    containerClassName={'pagination page-' + this.state.receipts_list_pageCount}
                                                    subContainerClassName={'pages pagination'}
                                                    activeClassName={'active'}
                                                    pageClassName={'page-item'}
                                                    pageLinkClassName={'page-link'}
                                                    previousLinkClassName={'page-link'}
                                                    nextLinkClassName={'page-link'}
                                                />

                                            </div>

                                            /* ReactPaginate / stop */

                                            : null

                                    }

                                </div>

                                {/* receipts list container / stop */}

                            </div>

                            {/* module receipts / stop */}
                        </div>

                        : null

                    /* list / stop */

                }

                {

                    /* receipt form container / start */

                    this.state.flag_render_type === 'form' ?

                        <div id="ModuleReceiptsModuleReceipt">

                            <div id="container-receipt-main">

                                <div className="row">

                                    <div className="col-6">

                                        <h1>
                                            Chitanta
                                        </h1>

                                    </div>

                                    <div className="col-6">

                                        <div className="loadingElementClose">
                                            <span onClick={this.close_receipt_element}><i className="fa fa-times" aria-hidden="true"></i></span>
                                        </div>

                                    </div>

                                    <div className="col-12">

                                        <div id="container-receipt">

                                            <div className="body">

                                                <ModuleReceiptV2
                                                    receipt_hash={this.state.receipt_hash}
                                                    receipt={this.state.receipt}
                                                    module_origin="fromReceipts" />

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                        : null

                    /* receipt form container / stop */

                }

            </React.Fragment>
        )

    }

}

export default ModuleReceiptsV2;