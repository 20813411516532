import React, { Component } from "react";

/*
 *
 * custom components
 *
 * start
 *
 */

import ConsoleService from "./components/consoleServiceModules/ConsoleService";

import ConsoleServiceModuleBill from "./components/consoleServiceModules/ConsoleServiceModuleBill";

import translation from './TranslationComponent';// ok 14.11.2019

/*
 *
 * custom components
 *
 * stop
 *
 */

const flag_console_log = ConsoleServiceModuleBill;

class ElementItemBill extends Component {

    state = {}

    /* DELIMITER */

    constructor(props) {

        super(props);

        ConsoleService("ElementItemBill / constructor", flag_console_log);

        ConsoleService("props", flag_console_log);
        ConsoleService(props, flag_console_log);

        //

    }

    /* DELIMITER */

    itemRemoveClick(event) {
        event.preventDefault();
        this.props.itemRemoveClick();
    }

    shouldMarkError = (field_a, field_b) => {

        // field_a => error
        // field_b => touched

        return field_a ? field_b : false;

    }

    render() {

        return (
            <tr>

                <td className="action"></td>

                <td className="description">

                    <textarea
                        name={"items[" + this.props.item_index + "][description]"}
                        id={"invoiceItemDescription_" + this.props.item_index}
                        className={this.shouldMarkError(this.props.invoice_items_obj_errors.description, this.props.invoice_items_obj_touched.description) ? 'field_error item_description' : 'item_description'}
                        value={this.props.item.description}
                        onChange={this.props.itemDescriptionChange}
                        placeholder={translation[this.props.language].general.item_description}></textarea>

                </td>

                <td>

                    <input
                        type="text"
                        name={"items[" + this.props.item_index + "][quantity]"}
                        className={this.props.invoice_items_obj_errors.quantity && this.props.invoice_items_obj_touched.quantity ? 'field_error itemQuantity' : 'itemQuantity'}
                        value={this.props.item.quantity}
                        onChange={this.props.eventItemQuantityChange}
                        onBlur={this.props.eventItemQuantityOnBlur}
                        placeholder="0.00" />

                </td>

                <td>

                    <input
                        type="text"
                        name={"items[" + this.props.item_index + "][price]"}
                        className={this.shouldMarkError(this.props.invoice_items_obj_errors.price, this.props.invoice_items_obj_touched.price) ? 'field_error itemPrice' : 'itemPrice'}
                        value={this.props.item.price}
                        onChange={this.props.itemPriceChange}
                        onBlur={this.props.eventItemPriceOnBlur}
                        placeholder="0.00" />

                </td>

                {

                    this.props.flag_should_the_vat_be_taken_in_consideration ?

                        <td>

                            <input
                                type="text"
                                name={"items[" + this.props.item_index + "][vat]"}
                                className={this.shouldMarkError(this.props.invoice_items_obj_errors.vat, this.props.invoice_items_obj_touched.vat) ? 'field_error itemVat' : 'itemVat'}
                                value={this.props.item.vat}
                                onChange={this.props.itemVatChange}
                                onBlur={this.props.eventItemVatOnBlur}
                                placeholder="0.00" />

                        </td>

                        : null

                }

                <td className="action">
                    <button id="removeItem" onClick={this.itemRemoveClick}>x</button>
                </td>

            </tr >
        )

    }

}

export default ElementItemBill;