import ConsoleService from '../consoleServiceModules/ConsoleService';

function get_total(subtotal, item) {

    var line_vat = (
        (
            (
                parseFloat(item.quantity) * parseFloat(item.price)
            ) * parseFloat(item.vat)
        ) / 100
    );

    if (
        item.hasOwnProperty('flag_should_the_vat_be_taken_in_consideration') &&
        parseInt(item.flag_should_the_vat_be_taken_in_consideration) === parseInt(0)
    ) {

        /*

        ok

        the flag 'flag_should_the_vat_be_taken_in_consideration' is available

        we should NOT take the VAT in consideration

        */

        line_vat = 0.0;

        //

    }

    return (
        subtotal + (
            (
                parseFloat(item.quantity) * parseFloat(item.price)
            ) + line_vat
        )
    );

}

const execute = (t, invoice_items) => {

    ConsoleService('', t.flag_console_log);
    ConsoleService('ModuleInvoice / component_compute_total / execute', t.flag_console_log);

    let total = parseFloat((invoice_items.reduce(get_total, 0)).toFixed(2));

    ConsoleService('TOTAL', t.flag_console_log);
    ConsoleService(total, t.flag_console_log);

    return total;

}

export default execute;