import ConsoleService from './consoleServiceModules/ConsoleService';

import IndexedDbSettingsValidateInvoiceSerialAndInvoiceNumberComponent from './IndexedDbSettingsValidateInvoiceSerialAndInvoiceNumberComponent';

import IndexedDbSettingsCreateOrUpdateComponent from './IndexedDbSettingsCreateOrUpdateComponent';

const execute = (payload) => {

    let flag_console_log = true;

    ConsoleService('IndexedDbSettingsDetectRecordReturnRecordComponent / new promise then', flag_console_log);

    IndexedDbSettingsValidateInvoiceSerialAndInvoiceNumberComponent(payload)
        .then(
            (data) => {

                /*
                *
                * ok
                * 
                */

                ConsoleService('IndexedDbSettingsDetectRecordReturnRecordComponent / new promise then', flag_console_log);

                ConsoleService('IndexedDbSettingsDetectRecordReturnRecordComponent / new promise => then / data', flag_console_log);
                ConsoleService(data, flag_console_log);

                //

                if (
                    data.target.result !== undefined
                ) {

                    /*

                    ok

                    the setting exists

                    here is where we know the record PK/id

                    */

                    payload.settings_object_store_id = data.target.result.settings_object_store_id;

                } else {

                    /*

                    ok

                    the setting !exists

                    nothing to do here, just continue ...

                    */

                }

                IndexedDbSettingsCreateOrUpdateComponent(payload);

                //

            }
        )
        .catch(
            (data) => {

                /*
                *
                * error
                * 
                */

                ConsoleService('IndexedDbSettingsDetectRecordReturnRecordComponent / new promise => catch', flag_console_log);

                ConsoleService('IndexedDbSettingsDetectRecordReturnRecordComponent / new promise => catch / data', flag_console_log);
                ConsoleService(data, flag_console_log);

                //                

            }
        );

}

export default execute;