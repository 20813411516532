const arr = [

    {
        value: 'EUR',
        name: 'Euro',
        symbol: '€',
        label: '€ ( EUR )',
    },

    {
        value: 'USD',
        name: 'United States Dollar',
        symbol: '$',
        label: '$ ( USD )'
    }

];

export default arr;