import arr_country from './const_arr_country_data';// ok 25.11.2019

const execute = (payload) => {

    //console.log('get_country_name / execute()');

    let result = '';

    arr_country.map(
        (item) => {

            //console.log(item);

            if (
                payload === item.value
            ) {

                result = item.label;

            }

            return true;

        }
    );

    return result;

}

export default execute;