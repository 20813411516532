import React, { Component } from 'react';

/*
*
* "react notification component" docs available at :
*
* https://www.npmjs.com/package/react-notifications-component
*
*/

import { store as notification } from 'react-notifications-component';

/*
*
* css files
*
* start
*
*/

import './css/ModuleBill.css';

/*
*
* css files
*
* stop
*
*/

/*
*
* custom components
*
* start
*
*/

import ConsoleService from './components/consoleServiceModules/ConsoleService';

import ConsoleServiceModuleBill from "./components/consoleServiceModules/ConsoleServiceModuleBill";

import ModuleInvoice from './ModuleInvoice';

import default_language, { arr_languages } from './LanguageComponent';

import translation from './TranslationComponent';

import arr_currency from './components/dataProviders/const_arr_currency_data';

import localStorage_getData from './components/LocalStorageGetComponent';

import IndexedDbPopulateWIthDefaultDataComponent from './components/IndexedDbPopulateWIthDefaultDataComponent';

/*
*
* custom components
*
* stop
*
*/

// the main class
class ModuleBill extends Component {

    module_name = 'ModuleBill';

    container_width = 914;

    flag_console_log = ConsoleServiceModuleBill;

    default_language = default_language;

    state = {

        language: default_language,

        locale: 'en',

        /* DELIMITER */

        currency: { value: 'EUR', symbol: '€', label: '€ ( EUR )' },

        currency_select_options: arr_currency,

        /* DELIMITER */

        container_scenario: 'left-middle-right',

        /*
        
        flag permissions to mount modules
        
        START
        
        */

        flag_permission_akti: false,

        flag_permission_loading_element_animation: true,

        module_mounted: 'module-invoice',

        /*
        
        flag permissions to mount modules
        
        STOP
        
        */

        /*
        * default value should be set to false
        */

        loading_element: false,

        arr_languages: arr_languages,

        local_storage: {}

    };

    /* DELIMITER */

    constructor(props) {

        super(props);

        ConsoleService('ModuleBill / constructor', this.flag_console_log);

    }

    /* DELIMITER */

    componentDidMount() {

        ConsoleService('ModuleBill / component did mount', this.flag_console_log);

        ConsoleService('this.state', this.flag_console_log);
        ConsoleService(this.state, this.flag_console_log);

        IndexedDbPopulateWIthDefaultDataComponent();

        let newState = Object.assign({}, this.state);

        newState.local_storage = localStorage_getData();

        ConsoleService('local storage', this.flag_console_log);
        ConsoleService(newState.local_storage, this.flag_console_log);

        this.setState(newState);

        window.addEventListener(
            'resize', () => {

                ConsoleService('window resize', this.flag_console_log);

                /*
                *
                * container header
                *
                * start
                *
                */

                let container_header = document.getElementById('container-header');

                let spacer_el_header_height = container_header.clientHeight;

                ConsoleService('spacer_el_header_height', this.flag_console_log);
                ConsoleService(spacer_el_header_height, this.flag_console_log);

                document.getElementById('spacer-element-header').style.height = (spacer_el_header_height + 10) + 'px';

                /*
                *
                * container header
                *
                * stop
                *
                */

                /*
                *
                * container header
                *
                * start
                *
                */

                let container_footer = document.getElementById('container-footer');

                let spacer_el_header_footer = container_footer.clientHeight;

                ConsoleService('spacer_el_header_footer', this.flag_console_log);
                ConsoleService(spacer_el_header_footer, this.flag_console_log);

                document.getElementById('spacer-element-footer').style.height = (spacer_el_header_footer + 5) + 'px';

                /*
                *
                * container header
                *
                * stop
                *
                */

            }
        );

    }

    /* DELIMITER */

    componentDidUpdate(prevProps, prevState) {

        ConsoleService('ModuleBill / component did update', this.flag_console_log);

        ConsoleService('prevState', this.flag_console_log);
        ConsoleService(prevState, this.flag_console_log);

        ConsoleService('this.state', this.flag_console_log);
        ConsoleService(this.state, this.flag_console_log);

        ConsoleService('prevProps', this.flag_console_log);
        ConsoleService(prevProps, this.flag_console_log);

        ConsoleService('this.props', this.flag_console_log);
        ConsoleService(this.props, this.flag_console_log);

        if (
            this.props.hasOwnProperty('language')
        ) {

            // ok

            /*
            *
            * within the props, we have also received the "language" prop
            * 
            */

            ConsoleService(this.module_name + ' / component did update / props.language exists', this.flag_console_log);

            ConsoleService('props.language', this.flag_console_log);
            ConsoleService(this.props.language, this.flag_console_log);

            if (
                this.state.language !== this.props.language
            ) {

                // ok

                /*
                *
                * we need to update the translations
                * 
                */

                ConsoleService(this.module_name + ' / component did update / the translations will be updated', this.flag_console_log);

                //this.default_language = this.props.language;

                let newState = Object.assign({}, this.state);

                newState.language = this.props.language;

                this.setState(newState);

                //

            }

            //

        }

    }

    /* DELIMITER */

    providersOnClickHandler = () => {

        ConsoleService('ModuleBill / show/mount providers modules on click handler', this.flag_console_log);

        this.setState(

            {
                module_mounted: 'module-providers',
            }

        );

    }

    /* DELIMITER */

    showInvoiceFormOnClickHandler = () => {

        ConsoleService('ModuleBill / show/mount invoice form on click handler', this.flag_console_log);

        this.setState(

            {
                module_mounted: 'module-invoice',
            }

        );

    }

    /* DELIMITER */

    settingsOnClickHandler = () => {

        ConsoleService('ModuleBill / show/mount settings on click handler', this.flag_console_log);

        this.setState(

            {
                module_mounted: 'module-settings',
            }

        );

    }

    /* DELIMITER */

    showAktiFormOnClickHandler = () => {

        ConsoleService('ModuleBill / show/mount Akti form on click handler', this.flag_console_log);

    }

    /* DELIMITER */

    /*
    *
    * callback methods
    *
    * start
    * 
    */

    /*
    *
    * invoices callbacks
    *
    * start
    * 
    */

    callback_method_origin_module_bill_destination_module_invoices_return_invoice_data = (child_data) => {

        ConsoleService('ModuleBill / callback_method_origin_module_bill_destination_module_invoices_return_invoice_data()', this.flag_console_log);

        ConsoleService('ModuleBill / callback_method_origin_module_bill_destination_module_invoices_return_invoice_data() / child data', this.flag_console_log);
        ConsoleService(child_data, this.flag_console_log);

        /*
        *
        * assign the new state
        *
        * start
        * 
        */

        let newState = Object.assign({}, this.state);

        newState.form.attributes = child_data;

        newState.loading_element = false;

        this.setState(newState);

        /*
        *
        * assign the new state
        *
        * stop
        * 
        */

        /*
        * Info notification load invoice data
        */

        let message = translation[this.state.language][this.module_name].notification_invoice_data_loaded;

        notification.addNotification(
            {
                title: "Info",
                message: message,
                type: "info",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 1000,
                    onScreen: true
                }
            }
        );

    }

    /*
    *
    * invoices callbacks
    *
    * stop
    * 
    */

    /* DELIMITER */

    closeLoadingElement = (event) => {

        ConsoleService('ModuleBill / close loading element', this.flag_console_log);

        ConsoleService('ModuleBill / closeLoadingElement() / loading element', this.flag_console_log);

        ConsoleService(this.state.loading_element, this.flag_console_log);

        this.setState(
            {
                loading_element: false
            }
        );
    }

    /* DELIMITER */

    elementSelectCurrencyChangeHandler = (event) => {

        ConsoleService('ModuleBill / element select currency change hHandler', this.flag_console_log);

        ConsoleService('event', this.flag_console_log);
        ConsoleService(event, this.flag_console_log);

        let newState = Object.assign({}, this.state);

        // { name : 'United States Dollar', symbol : '$', value : '$ ( USD )' }
        newState.currency = event;

        ConsoleService('newState', this.flag_console_log);
        ConsoleService(newState, this.flag_console_log);

        this.setState(newState);

    }

    /*
    *
    * callback methods
    *
    * stop
    * 
    */

    /* DELIMITER */

    render() {

        ConsoleService('ModuleBill / render() / this.state', this.flag_console_log);
        ConsoleService(this.state, this.flag_console_log);

        ConsoleService('local_storge', this.flag_console_log);
        ConsoleService(this.state.local_storage, this.flag_console_log);

        return (
            <div className="ModuleBill">
                {/* ModuleBill */}

                {/* DELIMITER */}

                {/* container-main */}
                <div id="container-main">

                    <div id="spacer-element-white"></div>

                    <div className="m5 p5">

                        {/* DELIMITER */}

                        {/* container-invoice */}
                        {

                            this.state.module_mounted === 'module-invoice' ?

                                <ModuleInvoice
                                    language={this.state.language}
                                    invoice={null}
                                    currency={this.state.currency}
                                    locale={this.state.locale} />

                                : null

                        }
                        {/* /container-invoice */}

                        {/* DELIMITER */}

                    </div>

                    <div id="spacer-element-white"></div>

                </div>
                {/* /container-main */}

                {/* DELIMITER */}

                {

                    this.state.loading_element ?

                        <div className="loadingElement">

                            <div id="loadingElementClose">
                                <span onClick={this.closeLoadingElement}><i className="fa fa-times" aria-hidden="true"></i></span>
                            </div>

                            {

                                this.state.flag_permission_loading_element_animation ? 'loading the recipients list ...' : null

                            }

                        </div>

                        : null

                }

                {/* /ModuleBill */}
            </div >
        );

    }

}

export default ModuleBill;