const data = {

    language: {

        en: 1,// active by default
        fr: 1,// active by default
        ro: 1,// active by default

    }

}

export default data;