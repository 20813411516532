/*

language : FR

*/

export default {

    required: 'The field [attribute] is required. FR',

    email: 'Make sure that you enter a valid email address. FR'

}