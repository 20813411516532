import { store as notification } from 'react-notifications-component';

import ConsoleService from '../consoleServiceModules/ConsoleService';

import translation from '../../TranslationComponent';

import component_form_errors_invoice_items_validation from './component_form_errors_invoice_items_validation';

import IndexedDbSettingsValidateInvoiceSerialAndInvoiceNumberComponent from './../IndexedDbSettingsValidateInvoiceSerialAndInvoiceNumberComponent';

import IndexedDbInvoiceLoadComponent from './../IndexedDbInvoiceLoadComponent';

async function execute(t) {

    ConsoleService('', t.flag_console_log);
    ConsoleService('ModuleInvoice / component_form_submit_validation / execute', t.flag_console_log);

    ConsoleService('t.state', t.flag_console_log);
    ConsoleService(t.state, t.flag_console_log);

    let flag_permission = true;

    let message = '';

    let newState = Object.assign({}, t.state);

    let flag_empty = false;

    /* DELIMITER */

    /*
    *
    * validating the attributes
    *
    * start
    * 
    */

    Object.keys(t.component_form_attributes_to_be_validated).map(
        (attribute) => {

            ConsoleService('attribute', t.flag_console_log);
            ConsoleService(attribute, t.flag_console_log);

            if (
                t.state.form.attributes[attribute] === ''
            ) {

                // error

                ConsoleService('validation failed at : ' + attribute, t.flag_console_log);

                flag_empty = true;

                newState.form.touched[attribute] = true;// set attribute as "touched"

                newState.form.errors[attribute] = true;// set attribute with "error"

                if (flag_permission) {

                    message = translation[t.state.language][t.module_name]['notification_' + attribute];

                    flag_permission = false;

                    //

                }

                //

            }

            return true;

            //
        }
    );

    /*
    *
    * validating the attributes
    *
    * stop
    * 
    */

    /* DELIMITER */

    /*
    *
    * validating the labels
    *
    * start
    * 
    */

    Object.keys(t.component_form_labels_to_be_validated).map(
        (attribute) => {

            ConsoleService('attribute', t.flag_console_log);
            ConsoleService(attribute, t.flag_console_log);

            if (
                t.state.form.labels[attribute] === ''
            ) {

                // error

                ConsoleService('validation failed at : ' + attribute, t.flag_console_log);

                flag_empty = true;

                newState.form.touched[attribute] = true;// set attribute as "touched"

                newState.form.errors[attribute] = true;// set attribute with "error"

                if (flag_permission) {

                    // do we have errors for labels ?
                    //message = translation[t.state.language][t.module_name]['notification_' + attribute];

                    message = 'Ups ;)';

                    flag_permission = false;

                    //

                }

                //

            }

            return true;

            //
        }
    );

    /*
    *
    * validating the labels
    *
    * stop
    * 
    */

    /* DELIMITER */

    /*
    *
    * validating the labels
    *
    * start
    * 
    */

    let arr = t.state.form.attributes.invoice_items;

    for (let i = 0; i < arr.length; i++) {

        //

        //console.log("arr[i]['description']");
        //console.log(arr[i]['description']);
        //console.log(arr[i]['description'].length);

        if (
            arr[i]['description'] !== ''
        ) {

            /*
            
            ok

            */

        } else {

            /*
            
            error
            
            */

            flag_permission = false;

            message = 'Ups ;)';

        }

        //

    }

    /*
    *
    * validating the labels
    *
    * stop
    * 
    */

    /* DELIMITER */

    //console.log('t.state.form.attributes.invoice_serial');
    //console.log(t.state.form.attributes.invoice_serial);
    //console.log(typeof t.state.form.attributes.invoice_serial);

    if (
        flag_permission
    ) {

        /*
    
        ok
    
        the permission to continue is still granted
    
        */

        if (
            t.state.form.attributes.invoice_serial === '' ||
            t.state.form.attributes.invoice_number === ''
        ) {

            /*

            error

            */

            flag_permission = false;

            //

        }

        //

    }

    let flag_check_if_invoice_serial_and_number_belong_to_the_current_invoice = false;

    if (
        flag_permission
    ) {

        /*

        ok

        the permission to continue is still granted

        */

        if (
            t.state.form.attributes.invoice_object_store_id > 0
        ) {

            /*

            ok

            we need to validate the invoice serial and number

            but, we need to know the original invoice serial and number, because we might be in "edit" mode

            if the actual and original values are the same, we need to skip the invoice serial and number validation

            */

            let payload = {
                invoice_object_store_id: t.state.form.attributes.invoice_object_store_id
            }

            await IndexedDbInvoiceLoadComponent(payload)
                .then(
                    (data) => {

                        /*
                        *
                        * ok
                        * 
                        */

                        ConsoleService('ModuleInvoice / component_form_submit_validation() / IndexedDbInvoiceLoadComponent / new promise then', t.flag_console_log);

                        ConsoleService('ModuleInvoice / component_form_submit_validation() / IndexedDbInvoiceLoadComponent / new promise => then / data', t.flag_console_log);
                        ConsoleService(data, t.flag_console_log);

                        if (
                            t.state.form.attributes.invoice_serial === data.target.result.invoice_serial &&
                            t.state.form.attributes.invoice_number === data.target.result.invoice_number
                        ) {

                            /*

                            ok

                            the invoice serial and invoice number belong to this invoice

                            */

                            flag_check_if_invoice_serial_and_number_belong_to_the_current_invoice = true;

                        } else {

                            /*

                            error

                            the invoice serial and invoice number already exists

                            */

                        }

                        //

                    }
                )
                .catch(
                    (data) => {

                        /*
                        *
                        * error ?
                        * 
                        * the invoice record does not exist ?
                        * 
                        */

                        ConsoleService('ModuleInvoice / component_form_submit_validation() / IndexedDbInvoiceLoadComponent / new promise => catch', t.flag_console_log);

                        ConsoleService('ModuleInvoice / component_form_submit_validation() / IndexedDbInvoiceLoadComponent / new promise => catch / data', t.flag_console_log);
                        ConsoleService(data, t.flag_console_log);

                        //      

                    }
                );

        }

        //

    }

    if (
        flag_permission &&
        flag_check_if_invoice_serial_and_number_belong_to_the_current_invoice === false
    ) {

        /*

        ok

        the permission to continue is still granted

        */

        let payload = {
            invoice_object_store_id: t.state.form.attributes.invoice_object_store_id,
            group: 'invoice_serial_and_invoice_number',
            key: t.state.form.attributes.invoice_serial,
            value: t.state.form.attributes.invoice_number
        };

        await IndexedDbSettingsValidateInvoiceSerialAndInvoiceNumberComponent(payload)
            .then(
                (data) => {

                    /*
                    *
                    * ok
                    * 
                    */

                    ConsoleService('ModuleInvoice / component_form_submit_validation() / IndexedDbSettingsValidateInvoiceSerialAndInvoiceNumberComponent / new promise then', t.flag_console_log);

                    ConsoleService('ModuleInvoice / component_form_submit_validation() / IndexedDbSettingsValidateInvoiceSerialAndInvoiceNumberComponent / new promise => then / data', t.flag_console_log);
                    ConsoleService(data, t.flag_console_log);

                    //

                    if (
                        data.target.result !== undefined
                    ) {

                        /*
    
                        ok
    
                        the setting exists
    
                        here is where we know the PK/id of the setting record
    
                        */

                    } else {

                        /*
    
                        ok
    
                        the setting !exists
    
                        nothing to do here, just continue ...
    
                        */

                    }

                    //

                }
            ).catch(
                (data) => {

                    /*
                    *
                    * error
                    * 
                    */

                    ConsoleService('ModuleInvoice / component_form_submit_validation() / IndexedDbSettingsValidateInvoiceSerialAndInvoiceNumberComponent / new promise => catch', t.flag_console_log);

                    ConsoleService('ModuleInvoice / component_form_submit_validation() / IndexedDbSettingsValidateInvoiceSerialAndInvoiceNumberComponent / new promise => catch / data', t.flag_console_log);
                    ConsoleService(data, t.flag_console_log);

                    flag_permission = false;

                    message = 'wrong invoice serial';

                    //                

                }
            );

        //

    }

    /* DELIMITER */

    /*
    *
    * validating the invoice items
    *
    * start
    * 
    */

    let obj_result = component_form_errors_invoice_items_validation(t.state.form);

    ConsoleService('obj_error', t.flag_console_log);
    ConsoleService(obj_result, t.flag_console_log);

    newState.form.errors.invoice_items = obj_result.flag_error;
    newState.form.touched.invoice_items = obj_result.flag_error;

    newState.form.errors.invoice_items_data = obj_result.arr_result;
    newState.form.touched.invoice_items_data = obj_result.arr_result;

    /*
    *
    * validating the invoice items
    *
    * stop
    * 
    */

    /* DELIMITER */

    if (
        flag_empty
    ) {

        // error
        // the attribute value is empty

        t.setState(newState);

        //

    }

    if (
        !flag_permission &&
        message !== ''
    ) {

        // the invoice form is not valid

        notification.addNotification(
            {
                title: "Error",
                message: message,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            }
        );

        //

    }

    return flag_permission;

    //

}

export default execute;