import ConsoleService from '../consoleServiceModules/ConsoleService';

function get_total_without_vat(subtotal, item) {

    return (
        subtotal + (
            (
                parseFloat(item.quantity) * parseFloat(item.price)
            )
        )
    );

}

const execute = (t, invoice_items) => {

    ConsoleService('', t.flag_console_log);
    ConsoleService('ModuleInvoice / component_compute_total_without_vat / execute', t.flag_console_log);

    let total_without_vat = parseFloat((invoice_items.reduce(get_total_without_vat, 0)).toFixed(2));

    ConsoleService('TOTAL without VAT', t.flag_console_log);
    ConsoleService(total_without_vat, t.flag_console_log);

    return total_without_vat;

}

export default execute;