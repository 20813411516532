import { Component } from "react";

import ConsoleService from "./components/consoleServiceModules/ConsoleService";

import ConsoleServiceIndexedDbComponent from "./components/consoleServiceModules/ConsoleServiceIndexedDbComponent";

const indexed_db_version = 106;// this value must be of type unsingned long long

const indexed_db_name = 'react_billable';

// the main class
class IndexedDbComponent extends Component {

    version = indexed_db_version;

    db_name = indexed_db_name;

    flag_console_log = ConsoleServiceIndexedDbComponent;

    /*
     *
     * constructor
     *
     * start
     *
     */

    constructor(props) {

        super(props);

        ConsoleService("", this.flag_console_log);
        ConsoleService("IndexedDbComponent / constructor", this.flag_console_log);

        ConsoleService("props", this.flag_console_log);
        ConsoleService(props, this.flag_console_log);

        ConsoleService("version", this.flag_console_log);
        ConsoleService(this.version, this.flag_console_log);

        let t = this;

        let indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;

        /*
        * 
        * request
        * 
        * start
        * 
        */

        let request = indexedDB.open(this.db_name, this.version);

        /*
        * 
        * request / on success
        * 
        * start
        * 
        */

        request.onsuccess = function (event) {

            ConsoleService("IndexedDbComponent / constructor / request / on success", this.flag_console_log);

            ConsoleService("event", this.flag_console_log);
            ConsoleService(event, this.flag_console_log);

        };

        /*
        * 
        * request / on success
        * 
        * stop
        * 
        */

        /*
        * 
        * request / on error
        * 
        * start
        * 
        */

        request.onerror = function (event) {

            ConsoleService("IndexedDbComponent / constructor / request / on error", this.flag_console_log);

            ConsoleService("event", this.flag_console_log);
            ConsoleService(event, this.flag_console_log);

            //

        };

        /*
        * 
        * request / on error
        * 
        * stop
        * 
        */

        /*
        * 
        * request / on upgrade needed
        * 
        * start
        * 
        */

        request.onupgradeneeded = function (event) {

            ConsoleService("IndexedDbComponent / constructor / request / on upgrade needed", this.flag_console_log);

            ConsoleService("event", this.flag_console_log);
            ConsoleService(event, this.flag_console_log);

            ConsoleService("t.version", this.flag_console_log);
            ConsoleService(t.version, this.flag_console_log);

            ConsoleService("parseInt(t.version)", this.flag_console_log);
            ConsoleService(parseInt(t.version), this.flag_console_log);

            let db = event.target.result;

            /* DELIMITER */

            /*
             *
             * does the db need to be upgraded ?
             *
             * we might need to create the invoice store object
             * 
             */

            if (
                parseInt(t.version) >= 2
            ) {

                /*
                 * ok
                 * the db might need to be upgraded
                 * try & upgrade the db
                 */

                ConsoleService("parseInt(version) >= 2", this.flag_console_log);

                let objectStore_invoice = null;

                /*
                 * the invoice object store might be missing
                 * try & create the invoice object store
                 */

                try {

                    // ok

                    ConsoleService("creating the invoice object store ...", this.flag_console_log);

                    objectStore_invoice = db.createObjectStore("invoice_object_store", { keyPath: "invoice_object_store_id", autoIncrement: true });

                    //

                } catch (e) {

                    // error

                    ConsoleService("error while trying to create the invoice object store", this.flag_console_log);
                    ConsoleService(e, this.flag_console_log);

                    //

                }

                if (
                    objectStore_invoice !== null
                ) {

                    /*
                     * ok
                     * try & create the invoice object store indexes
                     * 
                     * start
                     * 
                     */

                    try {

                        /*
                        * ok
                        */

                        ConsoleService("creating the invoice object store indexes ...", this.flag_console_log);

                        // create a index for the PK
                        objectStore_invoice.createIndex("invoice_object_store_id", "invoice_object_store_id", { unique: true });

                        // the FK from "recipient_object_store"
                        objectStore_invoice.createIndex("recipient_object_store_id", "recipient_object_store_id", { unique: false });

                        /*
                        *
                        * other indexes
                        * 
                        * start
                        * 
                        */

                        objectStore_invoice.createIndex("recipient_external_account_id", "recipient_external_account_id", { unique: false });

                        objectStore_invoice.createIndex("invoice_type", "invoice_type", { unique: false });

                        objectStore_invoice.createIndex("invoice_serial", "invoice_serial", { unique: false });
                        objectStore_invoice.createIndex("invoice_number", "invoice_number", { unique: false });

                        objectStore_invoice.createIndex("invoice_date", "invoice_date", { unique: false });

                        objectStore_invoice.createIndex("flag_should_the_vat_be_taken_in_consideration", "flag_should_the_vat_be_taken_in_consideration", { unique: false });

                        objectStore_invoice.createIndex("provider_company_name", "provider_company_name", { unique: false });

                        /*
                        *
                        * other indexes
                        * 
                        * stop
                        * 
                        */

                    } catch (e) {

                        /*
                        *
                        * error
                        * 
                        */

                        ConsoleService("error while trying to create the invoice object store indexes", this.flag_console_log);
                        ConsoleService(e, this.flag_console_log);

                        //

                    }

                    /*
                     *
                     * try & create the invoice object store indexes
                     * 
                     * stop
                     * 
                     */

                } else {

                    /*
                     *
                     * ok ?
                     * 
                     * some errors have been encountered while trying to upgrade the db
                     * 
                     */

                }

                //

            }

            /* DELIMITER */

            /*
             *
             * does the db need to be upgraded ?
             * 
             * we might need to create the recipient store object
             * 
             */

            if (
                parseInt(t.version) >= 3
            ) {

                /*
                 * ok
                 * the db might need to be upgraded
                 * try & upgrade the db
                 */

                ConsoleService("parseInt(version) >= 3", this.flag_console_log);

                let objectStore_recipient = null;

                /*
                 *
                 * the recipient object store might be missing
                 * try & create the recipient object store
                 * 
                 * start
                 * 
                 */

                try {

                    /*
                    * ok
                    */

                    ConsoleService("creating the recipient object store ...", this.flag_console_log);

                    objectStore_recipient = db.createObjectStore("recipient_object_store", { keyPath: "recipient_object_store_id", autoIncrement: true });

                    //

                } catch (e) {

                    /*
                    * error
                    */

                    ConsoleService("error while trying to create the recipient object store", this.flag_console_log);
                    ConsoleService(e, this.flag_console_log);

                    //

                }

                /*
                 *
                 * the recipient object store might be missing
                 * try & create the recipient object store
                 * 
                 * stop
                 * 
                 */

                /*
                *
                * check if objectStore_recipient !== null
                *
                * start
                * 
                */

                if (
                    objectStore_recipient !== null
                ) {

                    /*
                     *
                     * ok
                     * try & create the recipient object store indexes
                     * 
                     * start
                     * 
                     */

                    try {

                        /*
                        * ok
                        */

                        ConsoleService("creating the recipient object store indexes ...", this.flag_console_log);

                        // create a index for the PK
                        objectStore_recipient.createIndex("recipient_object_store_id", "recipient_object_store_id", { unique: true });

                        /*
                        *
                        * other indexes
                        * 
                        * start
                        * 
                        */

                        objectStore_recipient.createIndex("recipient_external_account_id", "recipient_external_account_id", { unique: false });

                        objectStore_recipient.createIndex("recipient_company_name", "recipient_company_name", { unique: false });// ok 28.10.2019

                        /*
                        *
                        * other indexes
                        * 
                        * stop
                        * 
                        */

                    } catch (e) {

                        /*
                        * error
                        */

                        ConsoleService("error while trying to create the recipient object store indexes", this.flag_console_log);
                        ConsoleService(e, this.flag_console_log);

                        //

                    }

                    /*
                     *
                     * try & create the recipient object store indexes
                     * 
                     * stop
                     * 
                     */

                } else {

                    /*
                     * ok
                     * some errors have been encountered while trying to upgrade the db
                     */

                }

                /*
                *
                * check if objectStore_recipient !== null
                *
                * stop
                * 
                */

            }

            /* DELIMITER */

            /*
             *
             * does the db need to be upgraded ?
             * 
             * we might need to create the provider store object
             * 
             */

            if (
                parseInt(t.version) >= 4
            ) {

                /*
                 * ok
                 * the db might need to be upgraded
                 * try & upgrade the db
                 */

                ConsoleService("parseInt(version) >= 4", this.flag_console_log);

                let objectStore_provider = null;

                /*
                 *
                 * the provider object store might be missing
                 * try & create the provider object store
                 * 
                 * start
                 * 
                 */

                try {

                    /*
                    * ok
                    */

                    ConsoleService("creating the provider object store ...", this.flag_console_log);

                    objectStore_provider = db.createObjectStore("provider_object_store", { keyPath: "provider_object_store_id", autoIncrement: true });

                    //

                } catch (e) {

                    /*
                    * error
                    */

                    ConsoleService("error while trying to create the provider object store", this.flag_console_log);
                    ConsoleService(e, this.flag_console_log);

                    //

                }

                /*
                 *
                 * the provider object store might be missing
                 * try & create the provider object store
                 * 
                 * stop
                 * 
                 */

                /*
                *
                * check if objectStore_provider !== null
                *
                * start
                * 
                */

                if (
                    objectStore_provider !== null
                ) {

                    /*
                     *
                     * ok
                     * try & create the provider object store indexes
                     * 
                     * start
                     * 
                     */

                    try {

                        /*
                        * ok
                        */

                        ConsoleService("creating the provider object store indexes ...", this.flag_console_log);

                        // create a index for the PK
                        objectStore_provider.createIndex("provider_object_store_id", "provider_object_store_id", { unique: true });

                        /*
                        *
                        * other indexes
                        * 
                        * start
                        * 
                        */

                        objectStore_provider.createIndex("provider_external_account_id", "provider_external_account_id", { unique: false });

                        objectStore_provider.createIndex("provider_company_name", "provider_company_name", { unique: false });// ok 28.10.2019

                        /*
                        *
                        * other indexes
                        * 
                        * stop
                        * 
                        */

                    } catch (e) {

                        /*
                        * error
                        */

                        ConsoleService("error while trying to create the provider object store indexes", this.flag_console_log);
                        ConsoleService(e, this.flag_console_log);

                        //

                    }

                    /*
                     *
                     * try & create the provider object store indexes
                     * 
                     * stop
                     * 
                     */

                } else {

                    /*
                     * ok
                     * some errors have been encountered while trying to upgrade the db
                     */

                }

                /*
                *
                * check if provider !== null
                *
                * stop
                * 
                */

            }

            /* DELIMITER */

            /*
             *
             * does the db need to be upgraded ?
             * 
             * we might need to create the receipt store object
             * 
             */

            if (
                (parseInt(t.version) >= 5) && (1 === 2)
            ) {

                /*
                 * ok
                 * the db might need to be upgraded
                 * try & upgrade the db
                 */

                ConsoleService("parseInt(version) >= 5", this.flag_console_log);

                let objectStore_receipt = null;

                /*
                 *
                 * the receipt object store might be missing
                 * try & create the receipt object store
                 * 
                 * start
                 * 
                 */

                try {

                    /*
                    * ok
                    */

                    ConsoleService("creating the receipt object store ...", this.flag_console_log);

                    objectStore_receipt = db.createObjectStore("receipt_object_store", { keyPath: "receipt_object_store_id", autoIncrement: true });

                    //

                } catch (e) {

                    /*
                    * error
                    */

                    ConsoleService("error while trying to create the receipt object store", this.flag_console_log);
                    ConsoleService(e, this.flag_console_log);

                    //

                }

                /*
                 *
                 * the receipt object store might be missing
                 * try & create the receipt object store
                 * 
                 * stop
                 * 
                 */

                /*
                *
                * check if objectStore_receipt !== null
                *
                * start
                * 
                */

                if (
                    objectStore_receipt !== null
                ) {

                    /*
                     *
                     * ok
                     * try & create the receipt object store indexes
                     * 
                     * start
                     * 
                     */

                    try {

                        /*
                        * ok
                        */

                        ConsoleService("creating the receipt object store indexes ...", this.flag_console_log);

                        // create a index for the PK
                        objectStore_receipt.createIndex("receipt_object_store_id", "receipt_object_store_id", { unique: true });

                        /*
                        *
                        * other indexes
                        * 
                        * start
                        * 
                        */

                        objectStore_receipt.createIndex("invoice_object_store_id", "invoice_object_store_id", { unique: false });// ok 12.12.2019

                        objectStore_receipt.createIndex("receipt_provider_company_name", "receipt_provider_company_name", { unique: false });// ok 16.12.2019
                        objectStore_receipt.createIndex("receipt_recipient_company_name", "receipt_recipient_company_name", { unique: false });// ok 16.12.2019

                        /*
                        *
                        * other indexes
                        * 
                        * stop
                        * 
                        */

                    } catch (e) {

                        /*
                        * error
                        */

                        ConsoleService("error while trying to create the receipt object store indexes", this.flag_console_log);
                        ConsoleService(e, this.flag_console_log);

                        //

                    }

                    /*
                     *
                     * try & create the receipt object store indexes
                     * 
                     * stop
                     * 
                     */

                } else {

                    /*
                     * ok
                     * some errors have been encountered while trying to upgrade the db
                     */

                }

                /*
                *
                * check if receipt !== null
                *
                * stop
                * 
                */

            }

            /* DELIMITER */

            /*
             *
             * does the db need to be upgraded ?
             * 
             * we might need to create the settings store object
             * 
             */

            if (
                parseInt(t.version) >= 6
            ) {

                /*
                 * ok
                 * the db might need to be upgraded
                 * try & upgrade the db
                 */

                ConsoleService("parseInt(version) >= 6", this.flag_console_log);

                let objectStore_settings = null;

                /*
                 *
                 * the settings object store might be missing
                 * try & create the settings object store
                 * 
                 * start
                 * 
                 */

                try {

                    /*
                    * ok
                    */

                    ConsoleService("creating the settings object store ...", this.flag_console_log);

                    objectStore_settings = db.createObjectStore("settings_object_store", { keyPath: "settings_object_store_id", autoIncrement: true });

                    //

                } catch (e) {

                    /*
                    * error
                    */

                    ConsoleService("error while trying to create the settings object store", this.flag_console_log);
                    ConsoleService(e, this.flag_console_log);

                    //

                }

                /*
                 *
                 * the settings object store might be missing
                 * try & create the settings object store
                 * 
                 * stop
                 * 
                 */

                /*
                *
                * check if objectStore_settings !== null
                *
                * start
                * 
                */

                if (
                    objectStore_settings !== null
                ) {

                    /*
                     *
                     * ok
                     * try & create the settings object store indexes
                     * 
                     * start
                     * 
                     */

                    try {

                        /*
                        * ok
                        */

                        ConsoleService("creating the settings object store indexes ...", this.flag_console_log);

                        // create a index for the PK
                        objectStore_settings.createIndex("settings_object_store_id", "settings_object_store_id", { unique: true });

                        /*
                        *
                        * other indexes
                        * 
                        * start
                        * 
                        */

                        /*
                        
                        simple indexes / start
                        
                        */

                        objectStore_settings.createIndex("group", "group", { unique: false });// ok 06.01.2020
                        objectStore_settings.createIndex("key", "key", { unique: false });// ok 06.01.2020
                        objectStore_settings.createIndex("value", "value", { unique: false });// ok 06.01.2020

                        /*
                        
                        simple indexes / stop
                        
                        */

                        /*
                        
                        composed indexes / start
                        
                        */

                        objectStore_settings.createIndex('group, key', ['group', 'key']);// ok 07.01.2020

                        /*
                        
                        composed indexes / stop
                        
                        */

                        /*
                        *
                        * other indexes
                        * 
                        * stop
                        * 
                        */

                    } catch (e) {

                        /*
                        * error
                        */

                        ConsoleService("error while trying to create the settings object store indexes", this.flag_console_log);
                        ConsoleService(e, this.flag_console_log);

                        //

                    }

                    /*
                     *
                     * try & create the settings object store indexes
                     * 
                     * stop
                     * 
                     */

                } else {

                    /*
                     * ok
                     * some errors have been encountered while trying to upgrade the db
                     */

                }

                /*
                *
                * check if settings !== null
                *
                * stop
                * 
                */


            }

            /* DELIMITER */

            /*
             *
             * does the db need to be upgraded ?
             * 
             * we might need to create the receipt V2 store object
             * 
             * 22.01.2020
             * 
             */

            if (
                parseInt(t.version) >= 7
            ) {

                /*
                 * ok
                 * the db might need to be upgraded
                 * try & upgrade the db
                 */

                ConsoleService("parseInt(version) >= 7", this.flag_console_log);

                let objectStore_receipt = null;

                /*
                 *
                 * the receipt V2 object store might be missing
                 * try & create the receipt V2 object store
                 * 
                 * start
                 * 
                 */

                try {

                    /*
                    * ok
                    */

                    ConsoleService("creating the receipt V2 object store ...", this.flag_console_log);

                    objectStore_receipt = db.createObjectStore("receipt_v2_object_store", { keyPath: "receipt_object_store_id", autoIncrement: true });

                    //

                } catch (e) {

                    /*
                    * error
                    */

                    ConsoleService("error while trying to create the receipt V2 object store", this.flag_console_log);
                    ConsoleService(e, this.flag_console_log);

                    //

                }

                /*
                 *
                 * the receipt V2 object store might be missing
                 * try & create the receipt V2 object store
                 * 
                 * stop
                 * 
                 */

                /*
                *
                * check if objectStore_receipt !== null
                *
                * start
                * 
                */

                if (
                    objectStore_receipt !== null
                ) {

                    /*
                     *
                     * ok
                     * try & create the receipt V2 object store indexes
                     * 
                     * start
                     * 
                     */

                    try {

                        /*
                        * ok
                        */

                        ConsoleService("creating the receipt V2 object store indexes ...", this.flag_console_log);

                        // create a index for the PK
                        objectStore_receipt.createIndex("receipt_object_store_id", "receipt_object_store_id", { unique: true });// ok 22.01.2020

                        /*
                        *
                        * other indexes
                        * 
                        * start
                        * 
                        */

                        objectStore_receipt.createIndex("invoice_object_store_id", "invoice_object_store_id", { unique: false });// ok 22.01.2020

                        objectStore_receipt.createIndex("receipt_serial", "receipt_serial", { unique: false });// ok 22.01.2020
                        objectStore_receipt.createIndex("receipt_number", "receipt_number", { unique: false });// ok 22.01.2020

                        objectStore_receipt.createIndex("recipient_object_store_id", "recipient_object_store_id", { unique: false });// ok 22.01.2020
                        objectStore_receipt.createIndex("recipient_company_name", "recipient_company_name", { unique: false });// ok 22.01.2020

                        /*
                        *
                        * other indexes
                        * 
                        * stop
                        * 
                        */

                    } catch (e) {

                        /*
                        * error
                        */

                        ConsoleService("error while trying to create the receipt V2 object store indexes", this.flag_console_log);
                        ConsoleService(e, this.flag_console_log);

                        //

                    }

                    /*
                     *
                     * try & create the receipt V2 object store indexes
                     * 
                     * stop
                     * 
                     */

                } else {

                    /*
                     * ok
                     * some errors have been encountered while trying to upgrade the db
                     */

                }

                /*
                *
                * check if receipt V2 !== null
                *
                * stop
                * 
                */

            }

            /* DELIMITER */

            /*
             *
             * does the db need to be upgraded ?
             * 
             * we might need to create the event_log store object
             * 
             */

            if (
                parseInt(t.version) >= 8
            ) {

                /*
                 * ok
                 * the db might need to be upgraded
                 * try & upgrade the db
                 */

                ConsoleService("parseInt(version) >= 8", this.flag_console_log);

                let objectStore_event_log = null;

                /*
                 *
                 * the settings object store might be missing
                 * try & create the settings object store
                 * 
                 * start
                 * 
                 */

                try {

                    /*
                    * ok
                    */

                    ConsoleService("creating the event_log object store ...", this.flag_console_log);

                    objectStore_event_log = db.createObjectStore("event_log_object_store", { keyPath: "event_log_object_store_id", autoIncrement: true });

                    //

                } catch (e) {

                    /*
                    * error
                    */

                    ConsoleService("error while trying to create the event_log object store", this.flag_console_log);
                    ConsoleService(e, this.flag_console_log);

                    //

                }

                /*
                 *
                 * the event_log object store might be missing
                 * try & create the event_log object store
                 * 
                 * stop
                 * 
                 */

                /*
                *
                * check if objectStore_event_log !== null
                *
                * start
                * 
                */

                if (
                    objectStore_event_log !== null
                ) {

                    /*
                     *
                     * ok
                     * try & create the event_log object store indexes
                     * 
                     * start
                     * 
                     */

                    try {

                        /*
                        * ok
                        */

                        ConsoleService("creating the event_log object store indexes ...", this.flag_console_log);

                        // create a index for the PK
                        objectStore_event_log.createIndex("event_log_object_store_id", "event_log_object_store_id", { unique: true });

                        /*
                        *
                        * other indexes
                        * 
                        * start
                        * 
                        */

                        /*
                        
                        simple indexes / start
                        
                        */

                        objectStore_event_log.createIndex("object_type", "object_type", { unique: false });
                        objectStore_event_log.createIndex("object_id", "object_id", { unique: false });
                        objectStore_event_log.createIndex("action", "action", { unique: false });

                        /*
                        
                        simple indexes / stop
                        
                        */

                        /*
                        *
                        * other indexes
                        * 
                        * stop
                        * 
                        */

                    } catch (e) {

                        /*
                        * error
                        */

                        ConsoleService("error while trying to create the event_log object store indexes", this.flag_console_log);
                        ConsoleService(e, this.flag_console_log);

                        //

                    }

                    /*
                     *
                     * try & create the event_log object store indexes
                     * 
                     * stop
                     * 
                     */

                } else {

                    /*
                     * ok
                     * some errors have been encountered while trying to upgrade the db
                     */

                }

                /*
                *
                * check if event_log !== null
                *
                * stop
                * 
                */


            }

            /*

             end of callback method

             !!! DO NOT ADD ANYTHING ELSE AFTER THIS, WITHIN THIS CALLBACK METHOD !!!

            */

        };

        /*
        * 
        * request / on upgrade needed
        * 
        * stop
        * 
        */

        /*
        * 
        * request
        * 
        * stop
        * 
        */

    }

    /*
     *
     * constructor
     *
     * stop
     *
     */
}

export default IndexedDbComponent;

export { indexed_db_name, indexed_db_version };