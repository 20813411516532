/*
 *
 * This is the `IndexedDb LoadOne Component` component.
 *
 */

/*
 *
 * custom components
 *
 * start
 *
 */

import ConsoleService from './consoleServiceModules/ConsoleService';

import ConsoleServiceGeneral from "./consoleServiceModules/ConsoleServiceGeneral";

import { indexed_db_name, indexed_db_version } from '../IndexedDbComponent';

/*
 *
 * custom components
 *
 * stop
 *
 */

const execute = (payload) => {

    let flag_console_log = ConsoleServiceGeneral;

    ConsoleService('', flag_console_log);
    ConsoleService('IndexedDbLoadOneComponent', flag_console_log);

    ConsoleService('IndexedDbLoadOneComponent / payload', flag_console_log);
    ConsoleService(payload, flag_console_log);

    let idb_name = indexed_db_name;

    let version = indexed_db_version;

    /*
     *
     * trying to save the settings data
     * 
     * we still have to figure out if we are on the "create" or "update" scenario
     *
     * start
     * 
     */

    var record = null;

    return new Promise(
        (resolve, reject) => {

            ConsoleService('IndexedDbLoadOneComponent / new promise', flag_console_log);

            let indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;

            /*
             *
             * request
             *
             * start
             * 
             */

            let request = indexedDB.open(idb_name, version);

            /*
             *
             * request / on success / callback
             *
             * start
             * 
             */

            request.onsuccess = function (event) {

                ConsoleService('IndexedDbLoadOneComponent / request / on success', flag_console_log);

                ConsoleService('IndexedDbLoadOneComponent / request / on success / event', flag_console_log);
                ConsoleService(event, flag_console_log);

                let db = event.target.result;

                ConsoleService('IndexedDbLoadOneComponent / request / on success / db', flag_console_log);
                ConsoleService(db, flag_console_log);

                /*
                 *
                 * transaction
                 *
                 * start
                 * 
                 */

                /*
                 *
                 * transaction
                 * 
                 * open a read/write db transaction, ready for adding the data
                 * 
                 * https://developer.mozilla.org/en-US/docs/Web/API/IDBDatabase/transaction
                 * 
                 */

                let transaction = db.transaction([payload.object_store], 'readonly');

                ConsoleService('IndexedDbLoadOneComponent / request / on success / transaction', flag_console_log);
                ConsoleService(transaction, flag_console_log);

                /*
                 *
                 * transaction / on complete
                 * 
                 * report on the success of opening the transaction
                 * 
                 * start
                 * 
                 */

                transaction.oncomplete = function (event) {

                    ConsoleService('IndexedDbLoadOneComponent / request / on success / transaction / on complete', flag_console_log);

                    ConsoleService('IndexedDbLoadOneComponent / request / on success / transaction / on complete / event', flag_console_log);
                    ConsoleService(event, flag_console_log);

                    ConsoleService('IndexedDbLoadOneComponent / request / on success / transaction / on complete / event/ record', flag_console_log);
                    ConsoleService(record, flag_console_log);

                    if (
                        record !== null
                    ) {

                        /*

                        ok

                        */

                        resolve(record);

                        //

                    } else {

                        /*
                        
                        error

                        */

                        reject(record);

                        //

                    }

                    //

                }

                /*
                 *
                 * transaction / on complete
                 * 
                 * stop
                 * 
                 */

                /*
                 *
                 * transaction / on error
                 * 
                 * start
                 * 
                 */

                transaction.onerror = function (event) {

                    ConsoleService('IndexedDbLoadOneComponent / request / on success / transaction / on error', flag_console_log);

                    ConsoleService('IndexedDbLoadOneComponent / request / on success / transaction / on error / event', flag_console_log);
                    ConsoleService(event, flag_console_log);

                    reject(record);

                    //

                }

                /*
                 *
                 * transaction / on error
                 * 
                 * stop
                 * 
                 */

                /*
                 *
                 * transaction
                 *
                 * stop
                 * 
                 */

                let objectStore = transaction.objectStore(payload.object_store);

                ConsoleService('IndexedDbLoadOneComponent / request / on success / transaction / objectStore', flag_console_log);
                ConsoleService(objectStore, flag_console_log);

                /*
                 *
                 * req
                 *
                 * trying to load the settings object by using a index value
                 * 
                 * start
                 * 
                 */

                let index = objectStore.index(payload.index_name);// the index might be : simple or composed

                ConsoleService('IndexedDbLoadOneComponent / request / on success / transaction / objectStore / index', flag_console_log);
                ConsoleService(index, flag_console_log);

                /*
                 *
                 * req
                 *
                 * trying to load the settings object by using a index value
                 * 
                 * stop
                 * 
                 */

                /*
                 *
                 * req
                 *
                 * trying to load the settings object by using a index value
                 * 
                 * start
                 * 
                 */

                let req = index.get(payload.value);// depending on the index type : value or array

                ConsoleService('IndexedDbLoadOneComponent / request / on success / transaction / objectStore / index / req', flag_console_log);
                ConsoleService(req, flag_console_log);

                /*
                 *
                 * req / on success
                 *
                 * start
                 * 
                 */

                req.onsuccess = function (event) {

                    ConsoleService('IndexedDbLoadOneComponent / request / on success / transaction / objectStore / index / req / on success', flag_console_log);

                    ConsoleService('IndexedDbLoadOneComponent / request / on success / transaction / objectStore / index / req / on success / event', flag_console_log);
                    ConsoleService(event, flag_console_log);

                    let result = event.target.result;

                    ConsoleService('IndexedDbLoadOneComponent / request / on success / transaction / objectStore / index / req / on success / result', flag_console_log);
                    ConsoleService(result, flag_console_log);

                    if (
                        result !== undefined
                    ) {

                        /*

                        is this a error ?

                        the record exists ;)

                        */

                        ConsoleService('result !== undefined', flag_console_log);

                        //resolve(result);

                        record = result;

                        //

                    } else {

                        /*

                        success
                        
                        the record does not exist

                        */

                        ConsoleService('result === undefined', flag_console_log);

                        //reject(result);

                        //

                    }

                    //

                }

                /*
                 *
                 * req / on success
                 *
                 * stop
                 * 
                 */

                /*
                 *
                 * req / on error
                 *
                 * start
                 * 
                 */

                req.onerror = function (event) {

                    /*
                    *
                    * error
                    * 
                    */

                    ConsoleService('IndexedDbLoadOneComponent / request / on success / transaction / objectStore / index / req / on error');

                    ConsoleService('IndexedDbLoadOneComponent / request / on success / transaction / objectStore / index / req / on error / event', flag_console_log);
                    ConsoleService(event, flag_console_log);

                    reject(record);

                    //

                }

                /*
                 *
                 * req / on error
                 *
                 * stop
                 * 
                 */

                /*
                 *
                 * req
                 *
                 * stop
                 * 
                 */

            }

            /*
             * request / on success / callback
             *
             * stop
             * 
             */

            /*
             * request / on error / callback
             *
             * start
             * 
             */

            request.onerror = function (event) {

                /*
                *
                * error
                * 
                */

                ConsoleService('IndexedDbLoadOneComponent / request / on error', flag_console_log);

                ConsoleService('IndexedDbLoadOneComponent / request / on error / event', flag_console_log);
                ConsoleService(event, flag_console_log);

                reject(record);

                //

            }

            /*
             * request / on error / callback
             *
             * stop
             * 
             */

            /*
             * request
             *
             * stop
             * 
             */

        }
    );

    // !!! CAUTION !!! unreacheble code, because of return !!!

    /*
     *
     * trying to load the settings data
     *
     * stop
     * 
     */

}

export default execute;