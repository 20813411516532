const chars = 'qwertyuiopasdfghjklzxcvbnm1234567890QWERTYUIOPASDFGHJKLZXCVBNM';

const serviceGenerateRandomString = (length) => {

    let chars_length = chars.length;

    let result = '';

    for (let i = 0; i < length; i++) {

        result += chars.charAt(Math.floor(Math.random() * chars_length));

    }

    return result;

}

export default serviceGenerateRandomString;