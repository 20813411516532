import ConsoleService from '../consoleServiceModules/ConsoleService';

import component_compute_vat_type from './component_compute_vat_type';// ok 14.11.2019
import component_compute_total_without_vat from './component_compute_total_without_vat';// ok 14.11.2019
import component_compute_total_vat from './component_compute_total_vat';// ok 14.11.2019
import component_compute_total from './component_compute_total';// ok 14.11.2019

import component_form_errors_invoice_items_validation from './component_form_errors_invoice_items_validation';

const execute = (t, itemIndex) => {

    ConsoleService('', t.flag_console_log);
    ConsoleService('ModuleInvoice / component_item_remove / execute');

    ConsoleService('t', t.flag_console_log);
    ConsoleService(t, t.flag_console_log);

    ConsoleService('itemIndex', t.flag_console_log);
    ConsoleService(itemIndex, t.flag_console_log);

    //const invoice_items = [...t.state.form.attributes.invoice_items];

    const invoice_items = JSON.parse(JSON.stringify(t.state.form.attributes.invoice_items));

    // removing the item
    invoice_items.splice(itemIndex, 1);

    /*
    *
    * assign the new state
    *
    * start
    * 
    */

    let newState = Object.assign({}, t.state);

    newState.form.attributes.invoice_items = invoice_items;// ok 29.11.2019
    //newState.form.attributes.invoice_subtotal = computed_subtotal.subtotal;
    //newState.form.attributes.invoice_vat_value = computed_subtotal.vat_value;
    //newState.form.attributes.invoice_total = computed_subtotal.total;

    ConsoleService('newState.form.attributes.invoice_items', t.flag_console_log);
    ConsoleService(newState.form.attributes.invoice_items, t.flag_console_log);

    newState.form.attributes.invoice_vat_type = component_compute_vat_type(t, invoice_items);

    ConsoleService('newState.form.attributes.invoice_vat_type', t.flag_console_log);
    ConsoleService(newState.form.attributes.invoice_vat_type, t.flag_console_log);

    newState.form.attributes.invoice_total_without_vat = component_compute_total_without_vat(t, invoice_items);// ok 1.11.2019

    ConsoleService('newState.form.attributes.invoice_total_without_vat', t.flag_console_log);
    ConsoleService(newState.form.attributes.invoice_total_without_vat, t.flag_console_log);

    newState.form.attributes.invoice_total_vat = component_compute_total_vat(t, invoice_items);// ok 1.11.2019

    ConsoleService('newState.form.attributes.invoice_total_vat', t.flag_console_log);
    ConsoleService(newState.form.attributes.invoice_total_vat, t.flag_console_log);

    newState.form.attributes.invoice_total = component_compute_total(t, invoice_items);// ok 1.11.2019;

    ConsoleService('newState.form.attributes.invoice_total', t.flag_console_log);
    ConsoleService(newState.form.attributes.invoice_total, t.flag_console_log);

    /*
    *
    * validating the invoice items
    *
    * start
    * 
    */

    let obj_result = component_form_errors_invoice_items_validation(t.state.form);

    ConsoleService('obj_error', t.flag_console_log);
    ConsoleService(obj_result, t.flag_console_log);

    newState.form.errors.invoice_items = obj_result.flag_error;
    newState.form.touched.invoice_items = obj_result.flag_error;

    newState.form.errors.invoice_items_data = obj_result.arr_result;
    newState.form.touched.invoice_items_data = obj_result.arr_result;

    /*
    *
    * validating the invoice items
    *
    * stop
    * 
    */

    t.setState(newState);

    /*
    *
    * assign the new state
    *
    * stop
    * 
    */

}

export default execute;