import React from "react";

/*
 *
 * custom components
 *
 * start
 *
 */

import ConsoleService from "./components/consoleServiceModules/ConsoleService";

import ConsoleServiceModuleRecipient from "./components/consoleServiceModules/ConsoleServiceModuleRecipient";

//import translation from './TranslationComponent';

import arr_country from './components/dataProviders/const_arr_country_data';

/*
 *
 * custom components
 *
 * stop
 *
 */

const flag_console_log = ConsoleServiceModuleRecipient;

const ElementItemRecipient = (props) => {

    //let module_name = 'ElementItemRecipient';

    ConsoleService("", flag_console_log);
    ConsoleService("ElementItemRecipient / constructor", flag_console_log);

    ConsoleService("props elementItemRecipient", flag_console_log);
    ConsoleService(props, flag_console_log);

    //let recipient_account_type_select_options = translation[props.language]['general'].recipient_account_type_select_option;

    //ConsoleService('recipient_account_type_select_options', flag_console_log);
    //ConsoleService(recipient_account_type_select_options, flag_console_log);

    const editItem = (event, item) => {
        event.preventDefault();
        return props.edit(item);
    }

/*

    const set_recipient_account_type = (payload) => {

        ConsoleService('', flag_console_log);
        ConsoleService('ElementItemRecipient / set_recipient_account_type', flag_console_log);

        ConsoleService('payload', flag_console_log);
        ConsoleService(payload, flag_console_log);

        let option = {};

        Object.keys(translation[props.language].general.recipient_account_type_select_option).map(
            (index) => {

                ConsoleService('ElementItemRecipient / set_recipient_account_type / object.keys.map / index', flag_console_log);
                ConsoleService(index, flag_console_log);

                ConsoleService('ElementItemRecipient / set_recipient_account_type / object.keys.map / object', flag_console_log);
                ConsoleService(translation[props.language].general.recipient_account_type_select_option[index], flag_console_log);

                if (
                    translation[props.language].general.recipient_account_type_select_option[index].value === payload
                ) {
                    option = translation[props.language].general.recipient_account_type_select_option[index];
                }

                return true;

            }
        );

        return option;

    }

*/

    const set_recipient_country_key = (payload) => {

        ConsoleService('', flag_console_log);
        ConsoleService('ElementItemRecipient / set_recipient_country_key', flag_console_log);

        ConsoleService('payload', flag_console_log);
        ConsoleService(payload, flag_console_log);

        let option = {};

        Object.keys(arr_country).map(
            (index) => {

                ConsoleService('ElementItemRecipient / set_recipient_country_key / object.keys.map / index', flag_console_log);
                ConsoleService(index, flag_console_log);

                ConsoleService('ElementItemRecipient / set_recipient_country_key / object.keys.map / object', flag_console_log);
                ConsoleService(arr_country[index], flag_console_log);

                if (
                    arr_country[index].value === payload
                ) {
                    option = arr_country[index];
                }

                return true;

            }
        );

        return option;

    }

    //let recipient_account_type = set_recipient_account_type(props.item.recipient_account_type);

    let recipient_addresses_country_key = set_recipient_country_key(props.item.recipient_addresses_country_key);

    return (
        <React.Fragment>

            <td>
                {props.item.recipient_company_name}
            </td>

            <td>
                {props.item.recipient_vat_nr}
            </td>

            <td>
                {props.item.recipient_email}
            </td>

            <td>
                {props.item.recipient_phone_nr}
            </td>

            <td>
                {recipient_addresses_country_key.label}
            </td>

            <td>
                {props.item.recipient_addresses_city}
            </td>

            <td>
                {props.item.recipient_addresses_zip}
            </td>

            <td>
                <button className="btn btn-info btn-sm" onClick={(event) => editItem(event, props.item)}>
                    <i className="fa fa-pencil"></i>
                </button>
            </td>

        </React.Fragment>
    );
};

export default ElementItemRecipient;
