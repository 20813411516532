import React, { Component } from 'react';

import ReactSelect from 'react-select';

import DatePicker, { registerLocale } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import "./css/ModuleReceiptV2fromInvoice.css";

import ReactSelectCreatable from 'react-select/creatable';

import ConsoleService from './components/consoleServiceModules/ConsoleService';//ok 20.1.2020

import ConsoleServiceModuleReceipt from './components/consoleServiceModules/ConsoleServiceModuleReceipt';//ok 20.1.2020

import IndexedDbReturnRecipientsComponent from './components/IndexedDbReturnRecipientsComponent';

import IndexedDbLoadAllComponent from './components/IndexedDbLoadAllComponent';

import IndexedDbReceiptV2CreateOrUpdateComponent from './components/indexedDb/IndexedDbReceiptV2/IndexedDbReceiptV2CreateOrUpdateComponent';

import IndexedDbLoadOneComponent from './components/IndexedDbLoadOneComponent';

import IndexedDbSettingsValidateInvoiceSerialAndInvoiceNumberComponent from './components/IndexedDbSettingsValidateInvoiceSerialAndInvoiceNumberComponent';

import { store as notification } from 'react-notifications-component';// ok 21.01.2020

import FormValidation from './components/FormValidation';

import attribute_errors from './components/attribute_errors';

import attribute_to_word from './components/attribute_to_word';

import ro from "date-fns/locale/ro";// ok 20.1.2020

registerLocale("ro", ro);// ok 20.1.2020

class ModuleReceiptV2 extends Component {

    module_name = 'ModuleReceiptV2';

    flag_console_log = ConsoleServiceModuleReceipt;

    locale = 'ro';

    recipients = [];

    state = {

        // READONLY
        invoice_hash: 'TOXIC-!-POISON-!-DANGER-!-STAY-AWAY-!-DO-NOT-TOUCH-!',

        invoice_state: {},

        flag_permission_show_form_element_receipt_serial_input_or_react_select_creatable: 'react-select-creatable',

        form: {

            select: {

                receipt_serial: {},
                receipt_serial_select_options: [],

                recipient_filter: {},
                recipient_filter_select_options: {},

                invoice_filter: {},
                invoice_filter_select_options: [],

            },

            attributes: {

                /*
                
                receipt record attributes
                
                start
                
                */

                // the `receipt` PK
                receipt_object_store_id: 0,

                receipt_date: new Date(),

                receipt_serial: '',
                receipt_number: '',

                receipt_total: 0.0,
                receipt_currency: 'RON',

                receipt_total_details: '',
                receipt_extra_details: '',

                /*
                
                receipt record attributes
                
                stop
                
                */

                /* DELIMITER */

                /*
                
                recipient record attributes
                
                start
                
                */

                // the `recipient` PK
                recipient_object_store_id: 0,

                recipient_company_name: '',

                /*
                
                recipient record attributes
                
                stop
                
                */

                /* DELIMITER */

                /*
                
                invoice record attributes
                
                start
                
                */

                invoice_object_store_id: 0,// ok 22.01.2020

                invoice_serial_and_invoice_number: '',

                /*
                
                invoice record attributes
                
                stop
                
                */

                associate_the_invoice: 'nu',//ok 22.01.2020

            },

            errors: {

                receipt_data: true,

                receipt_serial: true,
                receipt_number: true,

                receipt_total: true,

                receipt_total_details: true,

            },

            touched: {

                receipt_data: false,

                receipt_serial: false,
                receipt_number: false,

                receipt_total: false,

                receipt_total_details: false,

            }

        },

        module_origin: 'fromInvoice'

    };

    form_validations = {

        /*
         
        receipt record attributes
         
        start
         
        */

        receipt_date: ['required'],
        receipt_serial: ['required'],
        receipt_number: ['required'],
        receipt_total: ['required'],
        receipt_total_details: ['required'],
        receipt_extra_details: ['safe'],

        /*
        
        receipt record attributes
        
        stop
        
        */

        /* DELIMITER */

        /*
        
        recipient record attributes
        
        start
        
        */

        recipient_object_store_id: ['required'],
        recipient_company_name: ['required'],

        /*
        
        recipient record attributes
        
        stop
        
        */

        /* DELIMITER */

        /*
        
        invoice record attributes
        
        start
        
        */

        invoice_object_store_id: ['require'],

        /*
        
        invoice record attributes
        
        stop
        
        */

    };

    constructor(props) {

        super(props);

        ConsoleService(this.module_name + ' / constructor()', this.flag_console_log);

        ConsoleService('props', this.flag_console_log);
        ConsoleService(props, this.flag_console_log);

        if (
            this.props.hasOwnProperty('module_origin')
        ) {

            this.state.module_origin = this.props.module_origin;

            ConsoleService('state', this.flag_console_log);
            ConsoleService(this.state, this.flag_console_log);

            //

        }

        //

    }

    componentDidMount() {

        ConsoleService(this.module_name + ' / componentDidMount()', this.flag_console_log);

        ConsoleService('props', this.flag_console_log);
        ConsoleService(this.props, this.flag_console_log);

        if (
            this.props.hasOwnProperty('module_origin')
        ) {

            let newState = Object.assign({}, this.state);

            newState.module_origin = this.props.module_origin;

            this.setState(newState);

            ConsoleService('newState', this.flag_console_log);
            ConsoleService(newState, this.flag_console_log);

            ConsoleService('state', this.flag_console_log);
            ConsoleService(this.state, this.flag_console_log);

            //

        }

        if (
            this.props.hasOwnProperty('invoice_state') ||
            this.props.hasOwnProperty('invoice_hash')
        ) {

            /*

            ok

            we have received `props` from the parent `invoice` component, on mount event

            we need to check the props and store the values

            */

            let newState = Object.assign({}, this.state);

            if (
                this.props.hasOwnProperty('invoice_state')
            ) {

                /*
    
                ok
    
                set data received from the parent `invoice` component, on mount event
    
                */

                newState.invoice_state = this.props.invoice_state;

                newState.form.attributes.recipient_object_store_id = this.props.invoice_state.form.attributes.recipient_object_store_id;

                newState.form.attributes.recipient_company_name = this.props.invoice_state.form.attributes.recipient_company_name;

                newState.form.attributes.invoice_object_store_id = this.props.invoice_state.form.attributes.invoice_object_store_id;

                newState.form.attributes.invoice_serial_and_invoice_number = this.props.invoice_state.form.attributes.invoice_serial + '-' + this.props.invoice_state.form.attributes.invoice_number;

                //

            }

            if (
                this.props.hasOwnProperty('invoice_hash')
            ) {

                /*
    
                ok
    
                we need to save the invoice hash
    
                */

                newState.invoice_hash = this.props.invoice_hash;

                //

            }

            this.setState(newState);

            ConsoleService('newState', this.flag_console_log);
            ConsoleService(newState, this.flag_console_log);

            //

        }

        if (
            this.props.hasOwnProperty('receipt') ||
            this.props.hasOwnProperty('receipt_hash')
        ) {

            /*

            ok

            we have received `props` from the parent `receipts` component, on mount event

            we need to check the props and store the values

            */

            let newState = Object.assign({}, this.state);

            if (
                this.props.hasOwnProperty('receipt') &&
                this.props.receipt.receipt_object_store_id > 0
            ) {

                /*
    
                ok
    
                set data received from the parent `invoice` component, on mount event
    
                */

                newState.form.attributes = this.props.receipt;

                // receipt / start

                newState.form.select.receipt_serial = { value: this.props.receipt_serial, label: this.props.receipt_serial };

                newState.form.select.receipt_serial_select_options.push({ value: this.props.receipt_serial, label: this.props.receipt_serial });

                // receipt / stop

                // recipient / start

                newState.form.select.recipient_filter = { value: this.props.receipt.recipient_object_store_id, label: this.props.receipt.recipient_company_name };

                // recipient / stop

                // invoice / start

                newState.form.select.invoice_filter = { value: this.props.receipt.invoice_object_store_id, invoice_filter_label: this.props.receipt.invoice_serial_and_invoice_number };

                // invoice / stop

                newState.flag_permission_show_form_element_receipt_serial_input_or_react_select_creatable = 'input';

                this.load_all_data_from_invoice_object_store();

                //

            }

            if (
                this.props.hasOwnProperty('receipt_hash')
            ) {

                /*
    
                ok
    
                we need to save the receipt hash
    
                */

                newState.receipt_hash = this.props.receipt_hash;

                //

            }

            this.setState(newState);

            ConsoleService('newState', this.flag_console_log);
            ConsoleService(newState, this.flag_console_log);

            //

        }

        this.load_all_recipients();

        /*
        
        load all data from IndexedDb "settings_object_store" object store

        start
        
        */

        IndexedDbLoadAllComponent('settings_object_store')
            .then(
                (data) => {

                    /*
                    *
                    * ok
                    * 
                    */

                    ConsoleService(this.module_name + ' / IndexedDbLoadAllComponent / new promise then', this.flag_console_log);

                    ConsoleService(this.module_name + ' / IndexedDbLoadAllComponent / new promise => then / data', this.flag_console_log);
                    ConsoleService(data, this.flag_console_log);

                    if (
                        data.length > 0
                    ) {

                        /*
    
                        ok
    
                        */

                        let newState = Object.assign({}, this.state);

                        for (let i = 0; i < data.length; i++) {

                            if (
                                data[i]['group'] === 'receipt_serial_and_receipt_number'
                            ) {

                                newState.form.select.receipt_serial_select_options.push(
                                    {
                                        value: data[i]['settings_object_store_id'],
                                        label: data[i]['key'],
                                        settings_object_store_id: data[i]['settings_object_store_id'],
                                        settings_value: data[i]['value']
                                    }
                                );

                                //

                            }

                            //

                        }

                        ConsoleService('newState', this.flag_console_log);
                        ConsoleService(newState, this.flag_console_log);

                        this.setState(newState);

                        //

                    }

                }
            )
            .catch(
                (data) => {

                    /*
                    *
                    * error
                    * 
                    */

                    ConsoleService('ModuleReceiptV2 / IndexedDbLoadAllComponent / new promise => catch', this.flag_console_log);

                    ConsoleService('ModuleReceiptV2 / IndexedDbLoadAllComponent / new promise => catch / data', this.flag_console_log);
                    ConsoleService(data, this.flag_console_log);

                }
            );

        /*
        
        load all data from IndexedDb "settings_object_store" object store

        stop
        
        */

        /*
        
        listeners / emit handlers

        start
        
        */

        window.emitService.on('save_receipt', () => {

            ConsoleService(this.module_name + ' / emitService / save_receipt');

            this.save();

        });

        /*
        
        listeners / emit handlers
    
        stop
        
        */

    }

    componentDidUpdate(prevProps, prevState) {

        ConsoleService(this.module_name + ' / componentDidUpdate()', this.flag_console_log);

        ConsoleService('prevProps', this.flag_console_log);
        ConsoleService(prevProps, this.flag_console_log);

        ConsoleService('this.props', this.flag_console_log);
        ConsoleService(this.props, this.flag_console_log);

        ConsoleService('prevState', this.flag_console_log);
        ConsoleService(prevState, this.flag_console_log);

        ConsoleService('this.state', this.flag_console_log);
        ConsoleService(this.state, this.flag_console_log);

        if (
            this.props.hasOwnProperty('invoice_hash')
        ) {

            /*
            
            ok
            
            */

            if (
                this.state.invoice_hash !== this.props.invoice_hash
            ) {

                /*
                
                ok
    
                we need to chech :
                
                the old invoice hash value from `state`
                VS
                the new invoice hash value from `props`
    
                */

                let newState = Object.assign({}, this.state);

                newState.invoice_hash = this.props.invoice_hash;

                if (
                    this.props.hasOwnProperty('invoice_state')
                ) {

                    /*
    
                    ok
    
                    */

                    newState.invoice_state = this.props.invoice_state;

                    newState.form.attributes.recipient_object_store_id = this.props.invoice_state.form.attributes.recipient_object_store_id;

                    newState.form.attributes.recipient_company_name = this.props.invoice_state.form.attributes.recipient_company_name;

                    newState.form.attributes.invoice_object_store_id = this.props.invoice_state.form.attributes.invoice_object_store_id;

                    newState.form.attributes.invoice_serial_and_invoice_number = this.props.invoice_state.form.attributes.invoice_serial + '-' + this.props.invoice_state.form.attributes.invoice_number;

                    //

                }

                this.setState(newState);

                ConsoleService('newState', this.flag_console_log);
                ConsoleService(newState, this.flag_console_log);

                //

            }

            //

        }

        //

    }

    componentWillUnmount() {

        window.emitService.removeListener('save_receipt', () => {
            //
        });

        //

    }

    /* DELIMITER */

    /*

     form element `receipt_date` on change dedicated event handler

     start

    */

    formElementDateChangedHandler = (event) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService(this.module_name + ' / formElementDateChangedHandler', this.flag_console_log);

        ConsoleService('event', this.flag_console_log);
        ConsoleService(event, this.flag_console_log);

        let newState = Object.assign({}, this.state);

        newState.form.errors.receipt_date = (event === null ? true : false);
        newState.form.touched.receipt_date = true;

        newState.form.attributes.receipt_date = event;

        this.setState(newState);

        //

    }

    /*

     form element `receipt_date` on change dedicated event handler

     stop

    */

    /* DELIMITER */

    // form element changed handler
    formElementChangedHandler = (event) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService(this.module_name + ' / formElementChangedHandler', this.flag_console_log);

        ConsoleService('event', this.flag_console_log);
        ConsoleService(event, this.flag_console_log);

        ConsoleService('event.target.name', this.flag_console_log);
        ConsoleService(event.target.name, this.flag_console_log);

        let name = event.target.name;

        ConsoleService('event.target.value', this.flag_console_log);
        ConsoleService(event.target.value, this.flag_console_log);

        let value = event.target.value;

        /*
        *
        * assign the new state
        *
        * start
        * 
        */

        let newState = Object.assign({}, this.state);

        newState.form.errors[name] = (value === '' ? true : false);

        newState.form.touched[name] = true;

        // ONLY "attributes"

        if (
            name === 'receipt_total'
        ) {

            /*

            ok

            convert the value to float

            store the value

            */

            newState.form.attributes[name] = parseFloat(value);

        } else {

            /*

            ok

            store the value

            */

            newState.form.attributes[name] = value;

        }

        this.setState(newState);

        /*
        *
        * assign the new state
        *
        * stop
        * 
        */

    }

    // form input element onBlur handler
    formInputElementOnBlurHandler = (event) => {

        /*
        * this handler is not required to handler only attribute or only labels
        * this handler works with the state form "errors" and "touched" arrays
        */


        ConsoleService('', this.flag_console_log);
        ConsoleService(this.module_name + ' / formInputElementOnBlurHandler', this.flag_console_log);

        ConsoleService('event', this.flag_console_log);
        ConsoleService(event, this.flag_console_log);

        ConsoleService('event.target.name', this.flag_console_log);
        ConsoleService(event.target.name, this.flag_console_log);

        let name = event.target.name;

        ConsoleService('event.target.value', this.flag_console_log);
        ConsoleService(event.target.value, this.flag_console_log);

        let value = event.target.value;

        /*
        *
        * assign the new state
        *
        * start
        * 
        */

        let newState = Object.assign({}, this.state);

        newState.form.errors[name] = (value === '' ? true : false);

        newState.form.touched[name] = true;

        this.setState(newState);

        /*
        *
        * assign the new state
        *
        * stop
        * 
        */

    }

    /* DELIMITER */

    /*
    
    `receipt serial` form attribute
    
    start
    
    */

    /*
    
    `receipt serial` onChangeHandler form attribute

    !!! this is where we might create a new receipt serial
    
    start
    
    */

    // form select element "receipt serial" changed handler
    formSelectElementReceiptSerialChangeHandler = (newValue, actionMeta) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService(this.module_name + ' / formSelectElementReceiptSerialChangeHandler', this.flag_console_log);

        ConsoleService('this.state.form.attributes.receipt_serial', this.flag_console_log);
        ConsoleService('*' + this.state.form.attributes.receipt_serial + '*', this.flag_console_log);

        ConsoleService('newValue', this.flag_console_log);
        ConsoleService('*' + newValue + '*', this.flag_console_log);
        ConsoleService(newValue, this.flag_console_log);

        ConsoleService('actionMeta', this.flag_console_log);
        ConsoleService(actionMeta, this.flag_console_log);

        let flag_permission = true;

        if (
            newValue !== this.state.form.attributes.receipt_serial
        ) {

            // ok

            ConsoleService('ok / new value != state value', this.flag_console_log);

            //

        } else {

            // error

            ConsoleService('error / new value == state value', this.flag_console_log);

            flag_permission = false;

            //

        }

        if (
            flag_permission
        ) {

            // ok

            ConsoleService('ok / the "receipt serial" state form attribute will be updated', this.flag_console_log);

            /*
            *
            * assign the new state
            *
            * start
            * 
            */

            let newState = Object.assign({}, this.state);

            newState.form.attributes.receipt_serial = newValue.label;// as string

            newState.form.select.receipt_serial = newValue;// as json

            newState.form.errors.receipt_serial = (newValue === '' ? true : false);// error ?

            newState.form.touched.receipt_serial = true;// touched ?

            if (
                actionMeta.action === 'create-option' ||
                actionMeta.action === 'select-option'
            ) {

                // ok

                /*
                * we need to switch the element, from `select` to `input`
                */

                newState.flag_permission_show_form_element_receipt_serial_input_or_react_select_creatable = 'input';

                if (
                    actionMeta.action === 'create-option'
                ) {

                    /*

                    ok

                    `create` scenario

                    */

                    newState.form.attributes.receipt_number = 1;// as int

                    //

                }

                if (
                    actionMeta.action === 'select-option'
                ) {

                    /*

                    ok

                    `select` scenario

                    */

                    newState.form.attributes.receipt_number = parseInt(newValue.settings_value) + 1;// as int

                    //

                }

                newState.form.errors.receipt_number = false;// error ?

                newState.form.touched.receipt_number = true;// touched ?

                //

            }

            this.setState(newState);

            /*
            *
            * assign the new state
            *
            * stop
            * 
            */

        }

        //

    }

    /*
    
    `receipt serial` onChangeHandler form attribute
    
    stop
    
    */

    /* DELIMITER */

    /*
    
    `receipt serial` onInputChangeHandler form attribute
    
    start
    
    */

    // form select element "receipt serial" input changed handler
    formSelectElementReceiptSerialInputChangeHandler = (inputValue, actionMeta) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService(this.module_name + ' / formSelectElementReceiptSerialInputChangeHandler', this.flag_console_log);

        ConsoleService('this.state.form.attributes', this.flag_console_log);
        ConsoleService(this.state.form.attributes, this.flag_console_log);

        ConsoleService('this.state.form.attributes.receipt_serial', this.flag_console_log);
        ConsoleService('*' + this.state.form.attributes.receipt_serial + '*', this.flag_console_log);

        ConsoleService('inputValue', this.flag_console_log);
        ConsoleService('*' + inputValue + '*', this.flag_console_log);
        ConsoleService(inputValue, this.flag_console_log);

        ConsoleService('actionMeta', this.flag_console_log);
        ConsoleService(actionMeta, this.flag_console_log);

        let flag_permission = true;

        if (
            inputValue !== this.state.form.attributes.receipt_serial
        ) {

            // ok

            ConsoleService('ok / input value != state value', this.flag_console_log);

        } else {

            // error

            ConsoleService('error / input value == state value', this.flag_console_log);

            flag_permission = false;

        }

        if (
            flag_permission
        ) {

            // ok

            if (
                actionMeta.action === 'input-blur' ||
                actionMeta.action === 'menu-close'
            ) {

                // error ?

                ConsoleService('error / actionMeta.action IN [ input-blur, menu-close ]', this.flag_console_log);

                if (
                    actionMeta.action === 'input-blur' &&
                    this.state.form.attributes.receipt_serial !== ''
                ) {

                    // ok

                    /*
                    *
                    * !!! change the value !!!
                    *
                    * !!! Darth Vader VS Luke Skywalker !!!
                    * 
                    */

                    let newState = Object.assign({}, this.state);

                    newState.flag_permission_show_form_element_receipt_serial_input_or_react_select_creatable = 'input';

                    this.setState(newState);

                    ConsoleService('Darth Vader, by using the dark force, has won this battle and has updated the state !!!', this.flag_console_log);

                    //

                }

                /*
                * !!! Enough battles for today !!!
                */

                flag_permission = false;

            } else {

                // ok

                ConsoleService('ok / actionMeta.action !IN [ input-blur, menu-close ]', this.flag_console_log);

            }

        }

        if (
            flag_permission
        ) {

            // ok

            if (
                actionMeta.action === 'input-change'
            ) {

                // error ? => NO

                /*
                *
                * !!! change the value as it gets written we must !!!
                *
                * !!! use the Jedi FORCE Anakin !!!
                * 
                */

                ConsoleService('error ? / actionMeta.action IN [ input-change ]', this.flag_console_log);

                ConsoleService('inputValue', this.flag_console_log);
                ConsoleService(inputValue, this.flag_console_log);

                let newState = Object.assign({}, this.state);

                newState.form.attributes.receipt_serial = inputValue;// as string

                this.setState(newState);

                ConsoleService('Anakin, a Jedi Master, has updated the state !!!', this.flag_console_log);

                /*
                * !!! Enough battles for today !!!
                */

                flag_permission = false;

            } else {

                // ok

                ConsoleService('ok / actionMeta.action !IN [ input-change ]', this.flag_console_log);

            }

        }

        if (
            flag_permission
        ) {

            // ok

            ConsoleService('ok / the "receipt serial" state form attribute will be updated', this.flag_console_log);

            /*
            *
            * assign the new state
            *
            * start
            * 
            */

            let newState = Object.assign({}, this.state);

            /*

            receipt serial

            start

            */

            newState.form.attributes.receipt_serial = inputValue.value;// as string

            newState.form.select.receipt_serial = inputValue;// as json

            newState.form.errors.receipt_serial = (inputValue === '' ? true : false);

            newState.form.touched.receipt_serial = true;

            /*

            receipt serial

            stop

            */

            /*

            receipt number

            start

            */

            newState.form.attributes.receipt_number = parseInt(inputValue.settings_value) + 1;// as int

            newState.form.errors.receipt_number = (inputValue === '' ? true : false);

            newState.form.touched.receipt_number = true;

            /*

            receipt number

            stop

            */

            this.setState(newState);

            /*
            *
            * assign the new state
            *
            * stop
            * 
            */

        }

        //

    }

    /*
    
    `receipt serial` onInputChangeHandler form attribute
    
    stop
    
    */

    /* DELIMITER */

    /*
    
    `receipt serial` form attribute
    
    stop
    
    */

    /* DELIMITER */

    async load_all_recipients() {

        ConsoleService('', this.flag_console_log);
        ConsoleService(this.module_name + ' / load_all_recipients()', this.flag_console_log);

        let result = await IndexedDbReturnRecipientsComponent()
            .then(
                (data) => {

                    /*
                    *
                    * ok
                    * 
                    */

                    ConsoleService(this.module_name + ' / load_all_recipients() / IndexedDbReturnRecipientsComponent / promise result => then', this.flag_console_log);

                    ConsoleService(this.module_name + ' / load_all_recipients() / IndexedDbReturnRecipientsComponent / promise result => then / data', this.flag_console_log);
                    ConsoleService(data, this.flag_console_log);

                    return data;

                }
            )
            .catch(
                (data) => {

                    /*
                    *
                    * error
                    * 
                    */

                    ConsoleService(this.module_name + ' / load_all_recipients() / IndexedDbReturnRecipientsComponent / promise result => catch', this.flag_console_log);

                    ConsoleService(this.module_name + ' / load_all_recipients() / IndexedDbReturnRecipientsComponent / promise result => catch / data', this.flag_console_log);
                    ConsoleService(data, this.flag_console_log);

                    return false;

                }
            );

        this.recipients = result;

        ConsoleService(this.module_name + ' this.recipients', this.flag_console_log);
        ConsoleService(this.recipients, this.flag_console_log);

        ConsoleService(this.module_name + ' / async-await / load_all_recipients() / result', this.flag_console_log);
        ConsoleService(result, this.flag_console_log);

        let recipient_company_name_select_options = [];

        if (
            result.length > 0
        ) {

            let index = 0;

            for (index in result) {

                ConsoleService('index', this.flag_console_log);
                ConsoleService(index, this.flag_console_log);

                ConsoleService('item', this.flag_console_log);
                ConsoleService(result[index], this.flag_console_log);

                let item = result[index];

                recipient_company_name_select_options.push(
                    {
                        value: item.recipient_object_store_id,
                        label: item.recipient_company_name
                    }
                );

            }

        }

        let newState = Object.assign({}, this.state);

        newState.form.select.recipient_filter_select_options = recipient_company_name_select_options;

        this.setState(newState);

    }

    /* DELIMITER */

    //form radio input element "flag invoice link" changed handler
    formRadioInputElementFlagInvoiceLinkChangedHandler = (event) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService(this.module_name + ' / form radio input element "flag invoice link" changed handler', this.flag_console_log);

        ConsoleService('event', this.flag_console_log);
        ConsoleService(event, this.flag_console_log);

        ConsoleService('event.currentTarget.value', this.flag_console_log);
        ConsoleService(event.currentTarget.value, this.flag_console_log);

        /*
        *
        * assign the new state
        *
        * start
        * 
        */

        let newState = Object.assign({}, this.state);

        newState.form.attributes.associate_the_invoice = event.currentTarget.value;

        ConsoleService('newState', this.flag_console_log);
        ConsoleService(newState, this.flag_console_log);

        this.setState(newState);

        /*
        *
        * assign the new state
        *
        * stop
        * 
        */

    }

    /* DELIMITER */

    load_all_data_from_invoice_object_store() {

        /*
        
        load all data from IndexedDb "settings_object_store" object store

        start
        
        */

        IndexedDbLoadAllComponent('invoice_object_store')
            .then(
                (data) => {

                    /*
                    *
                    * ok
                    * 
                    */

                    ConsoleService(this.module_name + ' / IndexedDbLoadAllComponent / new promise then', this.flag_console_log);

                    ConsoleService(this.module_name + ' / IndexedDbLoadAllComponent / new promise => then / data', this.flag_console_log);
                    ConsoleService(data, this.flag_console_log);

                    if (
                        data.length > 0
                    ) {

                        /*
     
                        ok
     
                        */

                        ConsoleService('data', this.flag_console_log);
                        ConsoleService(data, this.flag_console_log);

                        let newState = Object.assign({}, this.state);

                        for (let i = 0; i < data.length; i++) {

                            if (
                                parseInt(data[i]['recipient_object_store_id']) === parseInt(this.state.form.attributes.recipient_object_store_id)
                            ) {

                                let item = data[i];

                                item.invoice_filter_label = item.invoice_serial + '-' + item.invoice_number;

                                newState.form.select.invoice_filter_select_options.push(item);

                                //

                            }

                            //

                        }

                        ConsoleService('newState', this.flag_console_log);
                        ConsoleService(newState, this.flag_console_log);

                        this.setState(newState);

                        //

                    }

                }
            )
            .catch(
                (data) => {

                    /*
                    *
                    * error
                    * 
                    */

                    ConsoleService(this.module_name + ' / IndexedDbLoadAllComponent / new promise => catch', this.flag_console_log);

                    ConsoleService(this.module_name + ' / IndexedDbLoadAllComponent / new promise => catch / data', this.flag_console_log);
                    ConsoleService(data, this.flag_console_log);

                }
            );

        /*
        
        load all data from IndexedDb "settings_object_store" object store
     
        stop
        
        */

    }

    /* DELIMITER */

    /*

     form select element "recipient filter" changed handler

    */

    formSelectElementRecipientFilterChangeHandler = (newValue, actionMeta) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService(this.module_name + ' / formSelectElementRecipientFilterChangeHandler', this.flag_console_log);

        ConsoleService('this.state.form.select.recipient_filter', this.flag_console_log);
        ConsoleService('*' + this.state.form.select.recipient_filter + '*', this.flag_console_log);

        ConsoleService('newValue', this.flag_console_log);
        ConsoleService('*' + newValue + '*', this.flag_console_log);
        ConsoleService(newValue, this.flag_console_log);

        ConsoleService('actionMeta', this.flag_console_log);
        ConsoleService(actionMeta, this.flag_console_log);

        /*
        
        update the state
        
        start
        
        */

        let newState = Object.assign({}, this.state);

        newState.form.select.recipient_filter = newValue;

        newState.form.attributes.recipient_object_store_id = newValue.value;

        newState.form.attributes.recipient_company_name = newValue.label;

        this.setState(newState);

        /*
        
        update the state
        
        stop
        
        */

        this.load_all_data_from_invoice_object_store();

    }

    /* DELIMITER */

    /*

    form select element `invoice filter` changed event handler

    start

    */

    formSelectElementInvoiceFilterChangeHandler = (newValue, actionMeta) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService(this.module_name + ' / formSelectElementInvoiceFilterChangeHandler', this.flag_console_log);

        ConsoleService('this.state.form.select.invoice_filter', this.flag_console_log);
        ConsoleService('*' + this.state.form.select.invoice_filter + '*', this.flag_console_log);

        ConsoleService('newValue', this.flag_console_log);
        ConsoleService('*' + newValue + '*', this.flag_console_log);
        ConsoleService(newValue, this.flag_console_log);

        ConsoleService('actionMeta', this.flag_console_log);
        ConsoleService(actionMeta, this.flag_console_log);

        /*
        
        update the state
        
        start
        
        */

        let newState = Object.assign({}, this.state);

        newState.form.select.invoice_filter = newValue;

        newState.form.attributes.invoice_object_store_id = newValue.invoice_object_store_id;

        newState.form.attributes.invoice_serial_and_invoice_number = newValue.invoice_serial + '-' + newValue.invoice_number;

        this.setState(newState);

        /*
        
        update the state
        
        stop
        
        */

    }

    /*

    form select element `invoice filter` changed event handler

    stop

    */

    /* DELIMITER */

    /*
     *
     * general handler
     * 
     * form react select element on blur handler
     * 
     * start
     * 
     */

    formReactSelectElementOnBlurHandler = (event, attribute = '') => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleReceipts / formReactSelectElementOnBlurHandler', this.flag_console_log);

        ConsoleService('event', this.flag_console_log);
        ConsoleService(event, this.flag_console_log);

        ConsoleService('attribute', this.flag_console_log);
        ConsoleService(attribute, this.flag_console_log);

        //

    }

    /*
     *
     * general handler
     * 
     * form react select element on blur handler
     * 
     * stop
     * 
     */

    /* DELIMITER */

    async form_validation() {

        ConsoleService(this.module_name + ' / form_validation', this.flag_console_log);

        let flag_permission = true;

        let newState = Object.assign({}, this.state);

        let form_validation = FormValidation.checkValidity(this.form_validations, this.state.form.attributes, attribute_errors('ro'), attribute_to_word('ro'));

        if (
            form_validation.has_errors
        ) {

            /*

            error

            the `receipt` form data is invalid

            */

            ConsoleService(this.module_name + ' / form validation / invalid data', this.flag_console_log);

            flag_permission = false;

            for (let attribute in form_validation.errors) {

                newState.form.touched[attribute] = true;// set attribute as "touched"

                newState.form.errors[attribute] = true;// set attribute with "error"

                //newState.form.errors_as_text[attribute] = form_validation.errors[attribute];

                //

            }

            //

        } else {

            /*
            
            ok
            
            the `receipt` form data is valid
            
            */

            ConsoleService(this.module_name + ' / form validation / valid data', this.flag_console_log);

            //

        }

        this.setState(newState);

        /* DELIMITER */

        //console.log('t.state.form.attributes.invoice_serial');
        //console.log(t.state.form.attributes.invoice_serial);
        //console.log(typeof t.state.form.attributes.invoice_serial);

        let flag_check_if_receipt_serial_and_number_belong_to_the_current_receipt = false;

        if (
            flag_permission
        ) {

            /*
    
            ok
    
            the permission to continue is still granted
    
            */

            if (
                this.state.form.attributes.receipt_object_store_id > 0
            ) {

                /*
    
                ok
    
                we need to validate the receipt serial and number
    
                but, we need to know the original receipt serial and receipt number, because we might be in "edit" mode
    
                if the actual and original values are the same, we need to skip the receipt serial and number validation
    
                */

                let payload = {
                    object_store: 'receipt_v2_object_store',
                    index_name: 'receipt_object_store_id',
                    value: this.state.form.attributes.receipt_object_store_id
                }

                await IndexedDbLoadOneComponent(payload)
                    .then(
                        (data) => {

                            /*
                            *
                            * ok
                            * 
                            */

                            ConsoleService(this.module_name + ' / form_validation() / IndexedDbLoadOneComponent / new promise then', this.flag_console_log);

                            ConsoleService(this.module_name + ' / form_validation() / IndexedDbLoadOneComponent / new promise => then / data', this.flag_console_log);
                            ConsoleService(data, this.flag_console_log);

                            if (
                                this.state.form.attributes.receipt_serial === data.receipt_serial &&
                                this.state.form.attributes.receipt_number === data.receipt_number
                            ) {

                                /*
    
                                ok
    
                                the invoice serial and invoice number belong to this invoice
    
                                */

                                flag_check_if_receipt_serial_and_number_belong_to_the_current_receipt = true;

                            } else {

                                /*
    
                                error
    
                                the invoice serial and invoice number already exists
    
                                */

                            }

                            //

                        }
                    )
                    .catch(
                        (data) => {

                            /*
                            *
                            * error ?
                            * 
                            * the invoice record does not exist ?
                            * 
                            */

                            ConsoleService(this.module_name + ' / form_validation() / IndexedDbLoadOneComponent / new promise => catch', this.flag_console_log);

                            ConsoleService(this.module_name + ' / form_validation() / IndexedDbLoadOneComponent / new promise => catch / data', this.flag_console_log);
                            ConsoleService(data, this.flag_console_log);

                            //      

                        }
                    );

            }

            //

        }

        if (
            flag_permission &&
            flag_check_if_receipt_serial_and_number_belong_to_the_current_receipt === false
        ) {

            /*
    
            ok
    
            the permission to continue is still granted
    
            */

            let payload = {
                receipt_object_store_id: this.state.form.attributes.receipt_object_store_id,
                group: 'receipt_serial_and_receipt_number',
                key: this.state.form.attributes.receipt_serial,
                value: this.state.form.attributes.receipt_number
            };

            await IndexedDbSettingsValidateInvoiceSerialAndInvoiceNumberComponent(payload)
                .then(
                    (data) => {

                        /*
                        *
                        * ok
                        * 
                        */

                        ConsoleService(this.module_name + ' / form_validation() / IndexedDbSettingsValidateInvoiceSerialAndInvoiceNumberComponent / new promise then', this.flag_console_log);

                        ConsoleService(this.module_name + ' / form_validation() / IndexedDbSettingsValidateInvoiceSerialAndInvoiceNumberComponent / new promise => then / data', this.flag_console_log);
                        ConsoleService(data, this.flag_console_log);

                        //

                        if (
                            data.target.result !== undefined
                        ) {

                            /*
        
                            ok
        
                            the setting exists, and the payload data is valid
        
                            here is where we know the PK/id of the setting record
        
                            */

                        } else {

                            /*
        
                            ok
        
                            the setting !exists
        
                            nothing to do here, just continue ...
        
                            */

                        }

                        //

                    }
                ).catch(
                    (data) => {

                        /*
                        *
                        * error
                        * 
                        * the payload data is invalid
                        * 
                        */

                        ConsoleService(this.module_name + ' / form_validation() / IndexedDbSettingsValidateInvoiceSerialAndInvoiceNumberComponent / new promise => catch', this.flag_console_log);

                        ConsoleService(this.module_name + ' / form_validation() / IndexedDbSettingsValidateInvoiceSerialAndInvoiceNumberComponent / new promise => catch / data', this.flag_console_log);
                        ConsoleService(data, this.flag_console_log);

                        flag_permission = false;

                        //                

                    }
                );

            //

        }

        /* DELIMITER */

        //flag_permission = false;

        return flag_permission;

    }

    /* DELIMITER */

    async save() {

        ConsoleService(this.module_name + ' / componentDidMount / invoice_flag_save_receipt', this.flag_console_log);

        /*
 
        we will try and validate the receipt form data
 
        */

        if (
            await this.form_validation()
        ) {

            /*
            
            ok

            the receipt form data has been validated

            we will try and save the receipt form data
            
            */

            IndexedDbReceiptV2CreateOrUpdateComponent(this.state.form.attributes)
                .then(
                    (data) => {

                        /*
                        *
                        * ok
                        * 
                        */

                        ConsoleService(this.module_name + ' / IndexedDbReceiptV2CreateOrUpdateComponent / new promise result => then', this.flag_console_log);

                        ConsoleService(this.module_name + ' / IndexedDbReceiptV2CreateOrUpdateComponent / new promise result => then / data', this.flag_console_log);
                        ConsoleService(data, this.flag_console_log);

                        let newState = Object.assign({}, this.state);

                        if (
                            this.state.form.attributes.receipt_object_store_id > 0
                        ) {

                            /*

                            ok

                            update scenario

                            */

                        } else {

                            /*

                            ok

                            create scenario

                            */

                            // extract the PK allocated to the new receipt
                            let receipt_object_store_id = data.target.result;

                            /*
                            *
                            * assign the new state
                            *
                            * start
                            * 
                            */

                            newState.form.attributes.receipt_object_store_id = receipt_object_store_id;

                            /*
                            *
                            * assign the new state
                            *
                            * stop
                            * 
                            */

                        }

                        this.setState(newState);

                        notification.addNotification(
                            {
                                title: "Succes",
                                message: 'Datele pentru chitanta au fost salvate.',
                                type: "success",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animated", "fadeIn"],
                                animationOut: ["animated", "fadeOut"],
                                dismiss: {
                                    duration: 10000,
                                    onScreen: true
                                }
                            }
                        );

                        //

                    }
                ).catch(
                    (data) => {

                        /*
                        *
                        * error
                        * 
                        * the receipt form data could not been saved
                        * 
                        */

                        ConsoleService(this.module_name + ' / IndexedDbReceiptV2CreateOrUpdateComponent / new promise result => catch', this.flag_console_log);

                        ConsoleService(this.module_name + ' / IndexedDbReceiptV2CreateOrUpdateComponent / new promise result => catch / data', this.flag_console_log);
                        ConsoleService(data, this.flag_console_log);

                        notification.addNotification(
                            {
                                title: 'Eroare',
                                message: 'Datele pentru chitanta nu au fost salvate.',
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animated", "fadeIn"],
                                animationOut: ["animated", "fadeOut"],
                                dismiss: {
                                    duration: 10000,
                                    onScreen: true
                                }
                            }
                        );

                        //

                    }
                );

            //

        } else {

            /*
            
            error

            the receipt form data is invalid
            
            */

            notification.addNotification(
                {
                    title: 'Info',
                    message: 'Informatia pentru chitanta nu a fost validata. Va rugam verificati informatia.',
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 10000,
                        onScreen: true
                    }
                }
            );

            //

        }

        //

    }

    /* DELIMITER */

    render() {

        ConsoleService(this.module_name + ' / render()', this.flag_console_log);

        const shouldMarkError = (field) => {

            //ConsoleService('', t.flag_console_log);
            //ConsoleService('shouldMarkError', t.flag_console_log);

            //ConsoleService('field', t.flag_console_log);
            //ConsoleService(field, t.flag_console_log);

            const hasError = this.state.form.errors[field];

            //ConsoleService('hasError', t.flag_console_log);
            //ConsoleService(hasError, t.flag_console_log);

            const shouldShow = this.state.form.touched[field];

            //ConsoleService('shouldShow', t.flag_console_log);
            //ConsoleService(shouldShow, t.flag_console_log);

            return hasError ? shouldShow : false;

            //return true;// force error

            //

        }

        return (
            <div id="ModuleReceiptV2fromInvoice">

                {/* DELIMITER */}

                {/* row [ receipt data / receipt serial / receipt number ] / start */}
                <div className="m2 p2">

                    <div className="row">

                        <div className="col-2 bold">
                            Data:
                        </div>

                        <div className="col-10">

                            <table>

                                <tbody>

                                    <tr>

                                        {/* receipt date / start */}

                                        <td className="w20">

                                            <DatePicker
                                                id="receipt_date"
                                                name="receipt_date"
                                                className={shouldMarkError('receipt_date') ? "field_error" : ""}
                                                selected={this.state.form.attributes.receipt_date}
                                                value={this.state.form.attributes.receipt_date}
                                                dateFormat="d MMMM, yyyy"
                                                locale={this.locale}
                                                onChange={this.formElementDateChangedHandler} />

                                        </td>

                                        {/* receipt date / stop */}

                                        {/* DELIMITER */}

                                        {/* receipt serial & number / start */}

                                        {/* receipt serial / start */}

                                        <td className="text-right">
                                            Seria:
                                        </td>

                                        {/* receipt serial / type "input" / start */}

                                        {

                                            this.state.flag_permission_show_form_element_receipt_serial_input_or_react_select_creatable === 'input' ?

                                                <td className="w20">

                                                    <div id="container_receipt_serial_input" className="m2">

                                                        <input
                                                            type="text"
                                                            id="receipt_serial"
                                                            name="receipt_serial"
                                                            className={shouldMarkError('receipt_serial') ? "field_error" : ""}
                                                            value={this.state.form.attributes.receipt_serial}
                                                            onChange={this.formElementChangedHandler}
                                                            placeholder="serie"
                                                            onBlur={this.formInputElementOnBlurHandler} />

                                                    </div>

                                                </td> : null

                                        }

                                        {/* receipt serial / type "input" / stop */}

                                        {/* receipt serial / type "react-select-creatable" / start */}

                                        {

                                            this.state.flag_permission_show_form_element_receipt_serial_input_or_react_select_creatable === 'react-select-creatable' ?

                                                <td className="w20">

                                                    <div id="container_receipt_serial_react_select_creatable" className="m2">

                                                        <ReactSelectCreatable
                                                            placeholder="serie"
                                                            value={this.state.form.attributes.receipt_serial ? this.state.form.attributes.receipt_serial : null}
                                                            onChange={this.formSelectElementReceiptSerialChangeHandler}
                                                            onInputChange={this.formSelectElementReceiptSerialInputChangeHandler}
                                                            options={this.state.form.select.receipt_serial_select_options}
                                                            className={shouldMarkError('receipt_serial') ? "field_error" : ""} />

                                                    </div>

                                                </td> : null

                                        }

                                        {/* receipt serial / type "react-select-creatable" / stop */}

                                        {/* receipt serial / stop */}

                                        {/* receipt number / start */}

                                        <td className="text-right">
                                            Numarul:
                                        </td>

                                        <td className="w20">

                                            <div id="container_receipt_number" className="m2">

                                                <input
                                                    type="text"
                                                    id="receipt_number"
                                                    name="receipt_number"
                                                    className={shouldMarkError('receipt_number') ? "field_error" : ""}
                                                    value={this.state.form.attributes.receipt_number}
                                                    onChange={this.formElementChangedHandler}
                                                    placeholder="numarul"
                                                    onBlur={this.formInputElementOnBlurHandler} />

                                            </div>

                                        </td>
                                        {/* receipt number / stop */}

                                        {/* receipt serial & number / stop */}

                                        {/* DELIMITER */}

                                    </tr>

                                </tbody>

                            </table>

                        </div>

                    </div>

                </div>
                {/* row [ receipt data / receipt serial / receipt number ] / stop */}

                {/* DELIMITER */}

                {/* row [ associate_the_invoice ] / start */}
                {

                    this.state.module_origin === 'fromReceipts' ?

                        <div className="m2 p2">

                            <div className={shouldMarkError('associate_the_invoice') ? "field_error" : ""}>

                                <div className="row">

                                    <div className="col-2">
                                        Asociaza factura:
                                    </div>

                                    <div className="col-10">

                                        <div className="m2">

                                            <div className="recipient_input_radio">

                                                <input type="radio"
                                                    id="da"
                                                    name="associate_the_invoice"
                                                    value="da"
                                                    checked={this.state.form.attributes.associate_the_invoice === 'da'}
                                                    onChange={this.formRadioInputElementFlagInvoiceLinkChangedHandler} />

                                                <label htmlFor="da">Da</label>

                                                <input type="radio"
                                                    id="nu"
                                                    name="associate_the_invoice"
                                                    value="nu"
                                                    checked={this.state.form.attributes.associate_the_invoice === 'nu'}
                                                    onChange={this.formRadioInputElementFlagInvoiceLinkChangedHandler} />

                                                <label htmlFor="nu">Nu</label>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div> : null

                }
                {/* row [ associate_the_invoice ] / stop */}

                {/* DELIMITER */}

                {/* row [ factura ] / start */}
                {

                    this.state.module_origin === 'fromReceipts' ?

                        <div className="m2 p2">

                            <div className="row">

                                <div className="col-2">
                                    Client:
                                </div>

                                <div className="col-10">

                                    <ReactSelect
                                        id="recipient_filter"
                                        name="recipient_filter"
                                        value={this.state.form.select.recipient_filter}
                                        onChange={this.formSelectElementRecipientFilterChangeHandler}
                                        options={this.state.form.select.recipient_filter_select_options}
                                        placeholder="alegeti un client ..."
                                        onBlur={(e) => { this.formReactSelectElementOnBlurHandler(e, 'recipient_filter') }} />

                                </div>

                            </div>

                        </div> : null

                }
                {/* row [ factura ] / stop */}

                {/* DELIMITER */}

                {/* row [ factura ] / start */}
                {

                    (
                        (this.state.module_origin === 'fromReceipts') &&
                        (this.state.form.attributes.associate_the_invoice === 'da')
                    ) ?

                        <div className="m2 p2">

                            <div className="row">

                                <div className="col-2">
                                    Factura:
                                </div>

                                <div className="col-10">

                                    <ReactSelect
                                        id="invoice_filter"
                                        name="invoice_filter"
                                        getOptionLabel={option => option.invoice_filter_label}
                                        getOptionValue={option => option.invoice_object_store_id}
                                        value={this.state.form.select.invoice_filter}
                                        onChange={this.formSelectElementInvoiceFilterChangeHandler}
                                        options={this.state.form.select.invoice_filter_select_options}
                                        placeholder="alegeti o factura ..."
                                        onBlur={(e) => { this.formReactSelectElementOnBlurHandler(e, 'invoice_filter') }} />

                                </div>

                            </div>

                        </div> : null

                }
                {/* row [ factura ] / stop */}

                {/* DELIMITER */}

                {/* row [ receipt total ] / start */}
                <div className="m2 p2">

                    <div className="row">

                        <div className="col-2">
                            Suma:
                        </div>

                        <div className="col-2">

                            <input
                                type="text"
                                id="receipt_total"
                                name="receipt_total"
                                className={shouldMarkError('receipt_total') ? "field_error" : ""}
                                value={this.state.form.attributes.receipt_total}
                                onChange={this.formElementChangedHandler}
                                placeholder="numarul"
                                onBlur={this.formInputElementOnBlurHandler} />

                        </div>

                        <div className="col-2">
                            {this.state.form.attributes.receipt_currency}
                        </div>

                    </div>

                </div>
                {/* row [ receipt total ] / stop */}

                {/* DELIMITER */}

                {/* row [ receipt total details ] / start */}
                <div className="m2 p2">

                    <div className="row">

                        <div className="col-2">
                            Reprezentand:
                        </div>

                        <div className="col-10">

                            <input
                                type="text"
                                id="receipt_total_details"
                                name="receipt_total_details"
                                className={shouldMarkError('receipt_total_details') ? "field_error" : ""}
                                value={this.state.form.attributes.receipt_total_details}
                                onChange={this.formElementChangedHandler}
                                onBlur={this.formInputElementOnBlurHandler} />

                        </div>

                    </div>

                </div>
                {/* row [ receipt total details ] / stop */}

                {/* DELIMITER */}

                {/* row [ receipts extra details ] / start */}
                <div className="m2 p2">

                    <div className="row">

                        <div className="col-2">
                            Detalii:
                        </div>

                        <div className="col-10">

                            <textarea
                                name="receipt_extra_details"
                                id="receipt_extra_details"
                                value={this.state.form.attributes.receipt_extra_details}
                                onChange={this.formElementChangedHandler}
                                onBlur={this.formInputElementOnBlurHandler}
                                placeholder="detalii"></textarea>

                        </div>

                    </div>

                </div>
                {/* row [ receipt extra details ] / stop */}

                {/* DELIMITER */}

                {

                    this.state.module_origin === 'fromReceipts' ?

                        <div className="options-footer">

                            <button className="btn btn-primary btn-sm col-12" onClick={() => { this.save(); }}>
                                Salveaza
                            </button>

                        </div> : null
                }

            </div >
        )

    }

}

export default ModuleReceiptV2;