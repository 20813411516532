/*
 *
 * This is the `IndexedDb ModuleInvoices` component.
 *
 */

import { Component } from 'react';

/*
 *
 * custom components
 *
 * start
 *
 */

import ConsoleService from './components/consoleServiceModules/ConsoleService';

import ConsoleServiceIndexedDbModuleInvoicesComponent from "./components/consoleServiceModules/ConsoleServiceIndexedDbModuleInvoicesComponent";

import { indexed_db_name, indexed_db_version } from './IndexedDbComponent';

/* DELIMITER */

//// indexed db recipient components / start

////// create component
import IndexedDbInvoiceCreateComponent from "./components/IndexedDbInvoiceCreateComponent";
////// update component
import IndexedDbInvoiceUpdateComponent from "./components/IndexedDbInvoiceUpdateComponent";

//// indexed db recipient components / stop

/* DELIMITER */

//// indexed db recipient components / start

////// create component
import IndexedDbRecipientCreateComponent from "./components/IndexedDbRecipientCreateComponent";
////// update component
import IndexedDbRecipientUpdateComponent from "./components/IndexedDbRecipientUpdateComponent";

//// indexed db recipient components / stop

/* DELIMITER */

import IndexedDbProviderCreateOrUpdateComponent from './components/IndexedDbProviderCreateOrUpdateComponent';

import IndexedDbSettingsDetectRecordReturnRecordComponent from './components/IndexedDbSettingsDetectRecordReturnRecordComponent';

/*
 *
 * custom components
 *
 * stop
 *
 */

import get_country_name from './components/dataProviders/get_country_name_data';// ok 25.11.2019

import serviceAddEventLogElement from './serviceAddEventLogElement';

// the main class

class IndexedDbModuleInvoicesComponent extends Component {

    idb_name = indexed_db_name;

    version = indexed_db_version;

    flag_console_log = ConsoleServiceIndexedDbModuleInvoicesComponent;

    /*
     *
     * constructor
     *
     * start
     * 
     */

    constructor(props) {

        super(props);

        ConsoleService('', this.flag_console_log);
        ConsoleService('IndexedDbModuleInvoicesComponent / constructor', this.flag_console_log);

        ConsoleService('IndexedDbModuleInvoicesComponent / constructor / props', this.flag_console_log);
        ConsoleService(props, this.flag_console_log);

        ConsoleService('IndexedDbModuleInvoicesComponent / constructor / idb name', this.flag_console_log);
        ConsoleService(this.idb_name, this.flag_console_log);

        ConsoleService('IndexedDbModuleInvoicesComponent / constructor / version', this.flag_console_log);
        ConsoleService(this.version, this.flag_console_log);

    }

    /*
     *
     * constructor
     *
     * stop
     * 
     */

    /*
     *
     * trying to save the invoice data
     * 
     * we still have to figure out if we are on the "create" or "update" scenario
     *
     * start
     * 
     */

    async create_or_update(payload) {

        ConsoleService('', this.flag_console_log);
        ConsoleService('IndexedDbModuleInvoicesComponent / create_or_update()', this.flag_console_log);

        ConsoleService('IndexedDbModuleInvoicesComponent / create_or_update() / payload', this.flag_console_log);
        ConsoleService(payload, this.flag_console_log);

        /*
        *
        * we need to extract the recipient data, then ... create or update the recipient data
        * 
        * start
        *
        */

        let payload_ = {

            // we need this id, but we do not have it for now
            recipient_external_account_id: payload.attributes.recipient_external_account_id,// ok 6.11.2019

            /*
            * recipient attributes
            * start
            */

            recipient_company_name: payload.attributes.recipient_company_name,// ok 6.11.2019

            recipient_account_type: payload.attributes.recipient_account_type,// ok 6.11.2019
            recipient_account_type_name: payload.attributes.recipient_account_type_name,// ok 6.11.2019

            recipient_vat_nr: payload.attributes.recipient_vat_nr,// ok 6.11.2019
            recipient_email: payload.attributes.recipient_email,// ok 6.11.2019
            recipient_phone_nr: payload.attributes.recipient_phone_nr,// ok 6.11.2019

            recipient_addresses_country_key: payload.attributes.recipient_addresses_country_key,// ok 6.11.2019
            recipient_addresses_country_name: get_country_name(payload.attributes.recipient_addresses_country_key),

            recipient_addresses_city: payload.attributes.recipient_addresses_city,// ok 6.11.2019
            recipient_addresses_street_address: payload.attributes.recipient_addresses_street_address,// ok 6.11.2019
            recipient_addresses_zip: payload.attributes.recipient_addresses_zip,// ok 6.11.2019

            recipient_addresses_is_billing: payload.attributes.recipient_addresses_is_billing,// ok 6.11.2019
            recipient_addresses_is_delivery: payload.attributes.recipient_addresses_is_delivery,// ok 6.11.2019
            recipient_addresses_is_primary: payload.attributes.recipient_addresses_is_primary,// ok 6.11.2019
            recipient_addresses_is_site: payload.attributes.recipient_addresses_is_site,// ok 6.11.2019

            recipient_addresses_is_customer: payload.attributes.recipient_addresses_is_customer,// ok 6.11.2019
            recipient_addresses_is_supplier: payload.attributes.recipient_addresses_is_supplier,// ok 6.11.2019

            /*
            * recipient attributes
            * stop
            */

        };

        let t = this;

        if (
            payload.attributes.recipient_object_store_id != null
        ) {

            /*
            *
            * ok
            * 
            * this is the "update scenario"
            *
            * using the extracted data we will update a recipient
            * 
            * we need to craete a fix, because the PK is available only on the "update" scenario
            * 
            */

            // set the FK from "recipient_object_store"
            payload_.recipient_object_store_id = payload.attributes.recipient_object_store_id;

            await IndexedDbRecipientUpdateComponent(payload_).then(
                (data) => {

                    /*
                    *
                    * ok
                    * 
                    */

                    ConsoleService('IndexedDbModuleInvoicesComponent / IndexedDbRecipientUpdateComponent / then', t.flag_console_log);

                    ConsoleService('IndexedDbModuleInvoicesComponent / IndexedDbRecipientUpdateComponent / then / data', t.flag_console_log);
                    ConsoleService(data, t.flag_console_log);

                    return data;

                }
            ).catch(
                (data) => {

                    /*
                    *
                    * error
                    * 
                    */

                    ConsoleService('IndexedDbModuleInvoicesComponent / IndexedDbRecipientUpdateComponent / then', t.flag_console_log);

                    ConsoleService('IndexedDbModuleInvoicesComponent / IndexedDbRecipientUpdateComponent / then / data', t.flag_console_log);
                    ConsoleService(data, t.flag_console_log);

                    return data;

                }
            );

        } else {

            /*
            *
            * ok
            * 
            * this is the "create scenario"
            *  
            * using the extracted data we will create a new recipient
            * 
            * the PK is not available on the "create" scenario
            * 
            */

            let result = await IndexedDbRecipientCreateComponent(payload_).then(
                (data) => {

                    /*
                    *
                    * ok
                    * 
                    */

                    ConsoleService('IndexedDbModuleInvoicesComponent / IndexedDbRecipientCreateComponent / then', t.flag_console_log);

                    ConsoleService('IndexedDbModuleInvoicesComponent / IndexedDbRecipientCreateComponent / then / data', t.flag_console_log);
                    ConsoleService(data, t.flag_console_log);

                    return data;

                }
            ).catch(
                (data) => {

                    /*
                    *
                    * error
                    * 
                    */

                    ConsoleService('IndexedDbModuleInvoicesComponent / IndexedDbRecipientCreateComponent / then', t.flag_console_log);

                    ConsoleService('IndexedDbModuleInvoicesComponent / IndexedDbRecipientCreateComponent / then / data', t.flag_console_log);
                    ConsoleService(data, t.flag_console_log);

                    return data;

                }
            );

            ConsoleService('result', this.flag_console_log);
            ConsoleService(result, this.flag_console_log);

            payload.attributes.recipient_object_store_id = result.target.result;

        }

        /*
        *
        * we need to extract the recipient data, then ... create or update the recipient data
        * 
        * stop
        *
        */

        /*
        *
        * we need to save the provider data
        * 
        * start
        *
        */

        let result_indexed_db_provider_create_or_update_component = await IndexedDbProviderCreateOrUpdateComponent(payload).then(
            (data) => {

                /*
                *
                * ok
                * 
                */

                ConsoleService('IndexedDbModuleInvoicesComponent / IndexedDbProviderCreateOrUpdateComponent / then', t.flag_console_log);

                ConsoleService('IndexedDbModuleInvoicesComponent / IndexedDbProviderCreateOrUpdateComponent / then / data', t.flag_console_log);
                ConsoleService(data, t.flag_console_log);

                return data;

            }
        ).catch(
            (data) => {

                /*
                *
                * error
                * 
                */

                ConsoleService('IndexedDbModuleInvoicesComponent / IndexedDbProviderCreateOrUpdateComponent / then', t.flag_console_log);

                ConsoleService('IndexedDbModuleInvoicesComponent / IndexedDbProviderCreateOrUpdateComponent / then / data', t.flag_console_log);
                ConsoleService(data, t.flag_console_log);

                return data;

            }
        );

        ConsoleService('result_indexed_db_provider_create_or_update_component', this.flag_console_log);
        ConsoleService(result_indexed_db_provider_create_or_update_component, this.flag_console_log);

        /*
        *
        * we need to save the provider data
        * 
        * start
        *
        */

        return new Promise(
            (resolve, reject) => {

                ConsoleService('IndexedDbModuleInvoicesComponent / create_or_update() / new promise', t.flag_console_log);

                let indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;

                let flag_scenario_create_or_update = null;

                /*
                 *
                 * request
                 *
                 * start
                 * 
                 */

                let request = indexedDB.open(t.idb_name, t.version);

                /*
                 * request / on success / callback
                 *
                 * start
                 * 
                 */

                request.onsuccess = function (event) {

                    ConsoleService('IndexedDbModuleInvoicesComponent / create_or_update() / request / on success', t.flag_console_log);

                    ConsoleService('IndexedDbModuleInvoicesComponent / create_or_update() / request / on success / event', t.flag_console_log);
                    ConsoleService(event, t.flag_console_log);

                    ConsoleService('IndexedDbModuleInvoicesComponent / create_or_update() / request / on success / payload', t.flag_console_log);
                    ConsoleService(payload, t.flag_console_log);

                    let db = event.target.result;

                    ConsoleService('IndexedDbModuleInvoicesComponent / create_or_update() / request / on success / db', t.flag_console_log);
                    ConsoleService(db, t.flag_console_log);

                    /*
                     *
                     * transaction
                     *
                     * start
                     * 
                     */

                    /*
                     *
                     * transaction
                     * 
                     * open a read/write db transaction, ready for adding the data
                     * 
                     * https://developer.mozilla.org/en-US/docs/Web/API/IDBDatabase/transaction
                     * 
                     */

                    let transaction = db.transaction(['invoice_object_store'], 'readonly');

                    ConsoleService('IndexedDbModuleInvoicesComponent / create_or_update() / request / on success / transaction', t.flag_console_log);
                    ConsoleService(transaction, t.flag_console_log);

                    /*
                     *
                     * transaction / on complete
                     * 
                     * report on the success of opening the transaction
                     * 
                     * start
                     * 
                     */

                    transaction.oncomplete = function (event) {

                        ConsoleService('IndexedDbModuleInvoicesComponent / create_or_update() / request / on success / transaction / on complete', t.flag_console_log);

                        ConsoleService('IndexedDbModuleInvoicesComponent / create_or_update() / request / on success / transaction / on complete / event', t.flag_console_log);
                        ConsoleService(event, t.flag_console_log);

                        if (
                            flag_scenario_create_or_update === 'update'
                        ) {

                            /*
                            * ok
                            * 
                            * update scenario
                            * 
                            */

                            ConsoleService('IndexedDbModuleInvoicesComponent / create_or_update() / request / on success / transaction / on complete / update scenario', t.flag_console_log);

                            IndexedDbInvoiceUpdateComponent(payload).then(
                                (data) => {

                                    /*
                                    *
                                    * ok
                                    * 
                                    */

                                    ConsoleService('IndexedDbModuleInvoicesComponent / IndexedDbInvoiceUpdateComponent / then', t.flag_console_log);

                                    ConsoleService('IndexedDbModuleInvoicesComponent / IndexedDbInvoiceUpdateComponent / then / data', t.flag_console_log);
                                    ConsoleService(data, t.flag_console_log);

                                    let payload_ = {
                                        group: 'invoice_serial_and_invoice_number',
                                        key: payload.attributes.invoice_serial,
                                        value: payload.attributes.invoice_number
                                    };

                                    IndexedDbSettingsDetectRecordReturnRecordComponent(payload_);

                                    const timestamp = Math.floor(Date.now() / 1000);
                                    let payload_event_log = {
                                        object_type: 'invoice',
                                        object_id: payload.attributes.invoice_object_store_id,
                                        timestamp: timestamp,
                                        processed: 0,
                                        action: 'update'
                                    };

                                    serviceAddEventLogElement(payload_event_log);

                                    resolve(data);

                                }
                            ).catch(
                                (data) => {

                                    /*
                                    *
                                    * error
                                    * 
                                    */

                                    ConsoleService('IndexedDbModuleInvoicesComponent / IndexedDbInvoiceUpdateComponent / catch', t.flag_console_log);

                                    ConsoleService('IndexedDbModuleInvoicesComponent / IndexedDbInvoiceUpdateComponent / catch / data', t.flag_console_log);
                                    ConsoleService(data, t.flag_console_log);

                                    reject(data);

                                }
                            );

                            //


                        }

                        if (
                            flag_scenario_create_or_update === 'create'
                        ) {

                            /*
                            * ok
                            * 
                            * create scenario
                            * 
                            */

                            ConsoleService('IndexedDbModuleInvoicesComponent / create_or_update() / request / on success / transaction / on complete / create scenario', t.flag_console_log);

                            IndexedDbInvoiceCreateComponent(payload).then(
                                (data) => {

                                    /*
                                    *
                                    * ok
                                    * 
                                    */

                                    ConsoleService('IndexedDbModuleInvoicesComponent / IndexedDbInvoiceCreateComponent / then', t.flag_console_log);

                                    ConsoleService('IndexedDbModuleInvoicesComponent / IndexedDbInvoiceCreateComponent / then / data', t.flag_console_log);
                                    ConsoleService(data, t.flag_console_log);

                                    let payload_ = {
                                        group: 'invoice_serial_and_invoice_number',
                                        key: payload.attributes.invoice_serial,
                                        value: payload.attributes.invoice_number
                                    };

                                    IndexedDbSettingsDetectRecordReturnRecordComponent(payload_);

                                    const timestamp = Math.floor(Date.now() / 1000);
                                    let payload_event_log = {
                                        object_type: 'invoice',
                                        object_id: data.target.result,
                                        timestamp: timestamp,
                                        action: 'create'
                                    };

                                    serviceAddEventLogElement(payload_event_log);

                                    resolve(data);

                                }
                            ).catch(
                                (data) => {

                                    /*
                                    *
                                    * error
                                    * 
                                    */

                                    ConsoleService('IndexedDbModuleInvoicesComponent / IndexedDbInvoiceCreateComponent / catch', t.flag_console_log);

                                    ConsoleService('IndexedDbModuleInvoicesComponent / IndexedDbInvoiceCreateComponent / catch / data', t.flag_console_log);
                                    ConsoleService(data, t.flag_console_log);

                                    reject(data);

                                }
                            );

                            //

                        }

                    }

                    /*
                     *
                     * transaction / on complete
                     * 
                     * stop
                     * 
                     */

                    /*
                     *
                     * transaction / on error
                     * 
                     * start
                     * 
                     */

                    transaction.onerror = function (event) {

                        ConsoleService('IndexedDbModuleInvoicesComponent / create_or_update() / request / on success / transaction / on error', t.flag_console_log);

                        ConsoleService('IndexedDbModuleInvoicesComponent / create_or_update() / request / on success / transaction / on error / event', t.flag_console_log);
                        ConsoleService(event, t.flag_console_log);

                        reject(event);

                    }

                    /*
                     *
                     * transaction / on error
                     * 
                     * stop
                     * 
                     */

                    /*
                     *
                     * transaction
                     *
                     * stop
                     * 
                     */

                    let objectStore = transaction.objectStore('invoice_object_store');

                    ConsoleService('IndexedDbModuleInvoicesComponent / create_or_update() / request / on success / transaction / objectStore', t.flag_console_log);
                    ConsoleService(objectStore, t.flag_console_log);

                    let index = objectStore.index('invoice_object_store_id');

                    ConsoleService('IndexedDbModuleInvoicesComponent / create_or_update() / request / on success / transaction / objectStore / index', t.flag_console_log);
                    ConsoleService(index, t.flag_console_log);

                    /*
                     *
                     * req
                     *
                     * trying to load the invoice object by using the PK
                     * 
                     * start
                     * 
                     */

                    let req = index.get(payload.attributes.invoice_object_store_id);

                    ConsoleService('IndexedDbModuleInvoicesComponent / create_or_update() / request / on success / transaction / objectStore / index / req', t.flag_console_log);
                    ConsoleService(req, t.flag_console_log);

                    /*
                     *
                     * req / on success
                     *
                     * start
                     * 
                     */

                    req.onsuccess = function (event) {

                        ConsoleService('IndexedDbModuleInvoicesComponent / create_or_update() / request / on success / transaction / objectStore / index / req / on success', t.flag_console_log);

                        ConsoleService('IndexedDbModuleInvoicesComponent / create_or_update() / request / on success / transaction / objectStore / index / req / on success / payload', t.flag_console_log);
                        ConsoleService(payload, t.flag_console_log);

                        let result = event.target.result;

                        ConsoleService('IndexedDbModuleInvoicesComponent / create_or_update() / request / on success / transaction / objectStore / index / req / on success / result', t.flag_console_log);
                        ConsoleService(result, t.flag_console_log);

                        if (result) {

                            /*
                            * ok
                            * 
                            * update scenario
                            * 
                            */

                            ConsoleService('IndexedDbModuleInvoicesComponent / create_or_update() / request / on success / transaction / objectStore / index / req / on success / result / if => update scenario', t.flag_console_log);

                            flag_scenario_create_or_update = 'update';

                            //

                        } else {

                            /*
                            * ok
                            * 
                            * create scenario
                            * 
                            */

                            ConsoleService('IndexedDbModuleInvoicesComponent / create_or_update() / request / on success / transaction / objectStore / index / req / on success / result / else => create scenario', t.flag_console_log);

                            flag_scenario_create_or_update = 'create';

                            //

                        }

                    }

                    /*
                     *
                     * req / on success
                     *
                     * stop
                     * 
                     */

                    /*
                     *
                     * req / on error
                     *
                     * start
                     * 
                     */

                    req.onerror = function (event) {

                        /*
                        *
                        * error
                        * 
                        */

                        ConsoleService('IndexedDbModuleInvoicesComponent / create_or_update() / request / on success / transaction / objectStore / index / req / on error');

                        ConsoleService('IndexedDbModuleInvoicesComponent / create_or_update() / request / on success / transaction / objectStore / index / req / on error / event', t.flag_console_log);
                        ConsoleService(event, t.flag_console_log);

                        reject(event);

                    }

                    /*
                     *
                     * req / on error
                     *
                     * stop
                     * 
                     */

                    /*
                     *
                     * req
                     *
                     * stop
                     * 
                     */

                }

                /*
                 * request / on success / callback
                 *
                 * stop
                 * 
                 */

                /*
                 * request / on error / callback
                 *
                 * start
                 * 
                 */

                request.onerror = function (event) {

                    /*
                    *
                    * error
                    * 
                    */

                    ConsoleService('IndexedDbModuleInvoicesComponent / create_or_update() / request / on error', t.flag_console_log);

                    ConsoleService('IndexedDbModuleInvoicesComponent / create_or_update() / request / on error / event', t.flag_console_log);
                    ConsoleService(event, t.flag_console_log);

                    reject();

                }

                /*
                 * request / on error / callback
                 *
                 * stop
                 * 
                 */

                /*
                 * request
                 *
                 * stop
                 * 
                 */

            }
        ).then(
            (data) => {

                /*
                *
                * ok
                * 
                */

                ConsoleService('IndexedDbModuleInvoicesComponent / create_or_update() / new promise result => then', t.flag_console_log);

                ConsoleService('IndexedDbModuleInvoicesComponent / create_or_update() / new promise result => then / data', t.flag_console_log);
                ConsoleService(data, t.flag_console_log);

                return data;

            }
        ).catch(
            (data) => {

                /*
                *
                * error
                * 
                */

                ConsoleService('IndexedDbModuleInvoicesComponent / create_or_update() / new promise result => catch', t.flag_console_log);

                ConsoleService('IndexedDbModuleInvoicesComponent / create_or_update() / new promise result => catch / data', t.flag_console_log);
                ConsoleService(data, t.flag_console_log);

                return data;

            }
        );

        // !!! CAUTION !!! unreacheble code, because of return !!!

    }

    /*
     *
     * trying to save the invoice data
     *
     * stop
     * 
     */

    /*
     * update data
     *
     * stop
     * 
     */

    updateData = (payload) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('IndexedDbModuleInvoicesComponent / updateData()', this.flag_console_log);

        ConsoleService('IndexedDbModuleInvoicesComponent / updateData() / payload', this.flag_console_log);
        ConsoleService(payload, this.flag_console_log);

        let t = this;

        return new Promise(
            (resolve, reject) => {

                ConsoleService('IndexedDbModuleInvoicesComponent / load_all_store_objects() / new promise', t.flag_console_log);

                let indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;

                /*
                 *
                 * request
                 *
                 * start
                 * 
                 */

                let request = indexedDB.open(t.idb_name, t.version);

                // request / on success / callback
                request.onsuccess = function (event) {

                    ConsoleService('updateData / request / on success', t.flag_console_log);

                    ConsoleService('event', t.flag_console_log);
                    ConsoleService(event, t.flag_console_log);

                    let db = event.target.result;

                    /*
                     *
                     * transaction
                     *
                     * start
                     * 
                     */

                    /*
                     *
                     * transaction
                     * 
                     * open a read/write db transaction, ready for adding the data
                     * 
                     * https://developer.mozilla.org/en-US/docs/Web/API/IDBDatabase/transaction
                     * 
                     */

                    let transaction = db.transaction(['invoice_object_store'], 'readwrite');

                    ConsoleService('IndexedDbModuleInvoicesComponent / updateData() / request / on success / transaction', t.flag_console_log);
                    ConsoleService(transaction, t.flag_console_log);

                    /*
                     * report on the success of opening the transaction
                     */

                    transaction.oncomplete = function (event) {

                        ConsoleService('IndexedDbModuleInvoicesComponent / updateData() / request / on success / transaction / on complete', t.flag_console_log);

                        ConsoleService('IndexedDbModuleInvoicesComponent / updateData() / request / on success / transaction / on complete / event', t.flag_console_log);
                        ConsoleService(event, t.flag_console_log);

                        resolve(event);

                    }

                    transaction.onerror = function (event) {

                        ConsoleService('IndexedDbModuleInvoicesComponent / updateData() / request / on success / transaction / on error', t.flag_console_log);

                        ConsoleService('IndexedDbModuleInvoicesComponent / updateData() / request / on success / transaction / on error / event', t.flag_console_log);
                        ConsoleService(event, t.flag_console_log);

                        reject(event);

                    }

                    /*
                     *
                     * transaction
                     *
                     * stop
                     * 
                     */

                    let store = transaction.objectStore('invoice_object_store');

                    ConsoleService('store', t.flag_console_log);
                    ConsoleService(store, t.flag_console_log);

                    /*
                     *
                     * req
                     *
                     * start
                     * 
                     */

                    payload.invoice_object_store_item.recipient_akti_account_id = parseInt(payload.akti_account_id);

                    let req = store.put(payload.invoice_object_store_item);

                    /*
                     *
                     * req / on success
                     *
                     * start
                     * 
                     */

                    req.onsuccess = function (event) {

                        ConsoleService('IndexedDbModuleInvoicesComponent / updateData() / request / on success / req / on success', t.flag_console_log);

                        ConsoleService('IndexedDbModuleInvoicesComponent / updateData() / request / on success / req / on success / event', t.flag_console_log);
                        ConsoleService(event, t.flag_console_log);

                        //resolve(event);

                    }

                    /*
                     *
                     * req / on success
                     *
                     * stop
                     * 
                     */

                    /*
                     *
                     * req / on error
                     *
                     * start
                     * 
                     */

                    req.onerror = function (event) {

                        ConsoleService('IndexedDbModuleInvoicesComponent / updateData() / request / on success / req / on error', t.flag_console_log);

                        ConsoleService('IndexedDbModuleInvoicesComponent / updateData() / request / on success / req / on error / event', t.flag_console_log);
                        ConsoleService(event, t.flag_console_log);

                        reject(event);

                    }

                    /*
                     *
                     * req / on error
                     *
                     * stop
                     * 
                     */

                    /*
                     *
                     * req
                     *
                     * stop
                     * 
                     */

                }

                /*
                 * request / on error / callback
                 *
                 * start
                 * 
                 */

                request.onerror = function (event) {

                    ConsoleService('IndexedDbModuleInvoicesComponent / updateData() / request / on error', t.flag_console_log);

                    ConsoleService('IndexedDbModuleInvoicesComponent / updateData() / request / on error / event', t.flag_console_log);
                    ConsoleService(event, t.flag_console_log);

                    reject(event);

                }

                /*
                 * request / on error / callback
                 *
                 * stop
                 * 
                 */

            }).then(
                (data) => {

                    ConsoleService('IndexedDbModuleInvoicesComponent / updateData() / new promise => then', t.flag_console_log);

                    ConsoleService('IndexedDbModuleInvoicesComponent / updateData() / new promise => then / data', t.flag_console_log);
                    ConsoleService(data, t.flag_console_log);

                    return data;

                }
            ).catch(
                (data) => {

                    ConsoleService('IndexedDbModuleInvoicesComponent / updateData() / new promise => catch', t.flag_console_log);

                    ConsoleService('IndexedDbModuleInvoicesComponent / updateData() / new promise => catch / data', t.flag_console_log);
                    ConsoleService(data, t.flag_console_log);

                    return data;

                }
            );

        // !!! CAUTION !!! unreacheble code, because of return !!!

    }

    /*
     * update data
     *
     * start
     * 
     */

    /*
     *
     * load all store objects
     *
     * start
     * 
     */

    load_all_store_objects = () => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('IndexedDbModuleInvoicesComponent / load_all_store_objects()', this.flag_console_log);

        let t = this;

        return new Promise(
            (resolve, reject) => {

                ConsoleService('IndexedDbModuleInvoicesComponent / load_all_store_objects() / new promise', t.flag_console_log);

                let indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;

                /*
                 *
                 * request
                 *
                 * start
                 * 
                 */

                let request = indexedDB.open(t.idb_name, t.version);

                /*
                 * request / on success / callback
                 *
                 * start
                 * 
                 */

                request.onsuccess = function (event) {

                    ConsoleService('IndexedDbModuleInvoicesComponent / load_all_store_objects() / request / on success', t.flag_console_log);

                    ConsoleService('IndexedDbModuleInvoicesComponent / load_all_store_objects() / request / on success / event', t.flag_console_log);
                    ConsoleService(event, t.flag_console_log);

                    let db = event.target.result;

                    /*
                     *
                     * transaction
                     *
                     * start
                     * 
                     */

                    /*
                     *
                     * transaction
                     * 
                     * open a read/write db transaction, ready for adding the data
                     * 
                     * https://developer.mozilla.org/en-US/docs/Web/API/IDBDatabase/transaction
                     * 
                     */

                    let transaction = db.transaction(['invoice_object_store'], 'readonly');

                    ConsoleService('IndexedDbModuleInvoicesComponent / load_all_store_objects() / request / on success / transaction', t.flag_console_log);
                    ConsoleService(transaction, t.flag_console_log);

                    /*
                     * report on the success of opening the transaction
                     */

                    transaction.oncomplete = function (event) {

                        /*
                        *
                        * ok
                        * 
                        */

                        ConsoleService('IndexedDbModuleInvoicesComponent / load_all_store_objects() / request / on success / transaction / on complete', t.flag_console_log);

                        ConsoleService('IndexedDbModuleInvoicesComponent / load_all_store_objects() / request / on success / transaction / on complete / event', t.flag_console_log);
                        ConsoleService(event, t.flag_console_log);

                    }

                    transaction.onerror = function (event) {

                        /*
                        *
                        * error
                        * 
                        */

                        ConsoleService('IndexedDbModuleInvoicesComponent / load_all_store_objects() / request / on success / transaction / on error', t.flag_console_log);

                        ConsoleService('IndexedDbModuleInvoicesComponent / load_all_store_objects() / request / on success / transaction / on error / event', t.flag_console_log);
                        ConsoleService(event, t.flag_console_log);

                        reject(event);

                    }

                    /*
                     *
                     * transaction
                     *
                     * stop
                     * 
                     */

                    /*
                     *
                     * "bill" object store
                     *
                     * start
                     * 
                     */

                    let store = transaction.objectStore('invoice_object_store');

                    ConsoleService('IndexedDbModuleInvoicesComponent / load_all_store_objects() / request / on success / store', t.flag_console_log);
                    ConsoleService(store, t.flag_console_log);

                    /*
                     *
                     * "bill" object store
                     *                 
                     * stop
                     * 
                     */

                    /*
                     *
                     * req
                     *
                     * start
                     *
                     */

                    let req = store.openCursor();

                    ConsoleService('IndexedDbModuleInvoicesComponent / load_all_store_objects() / request / on success / req', t.flag_console_log);
                    ConsoleService(req, t.flag_console_log);

                    let items = [];

                    ConsoleService('IndexedDbModuleInvoicesComponent / load_all_store_objects() / request / on success / items', t.flag_console_log);
                    ConsoleService(items, t.flag_console_log);

                    req.onsuccess = function (event) {

                        /*
                        *
                        * ok
                        * 
                        */

                        ConsoleService('IndexedDbModuleInvoicesComponent / load_all_store_objects() / request / on success / req / on success', t.flag_console_log);

                        let cursor = event.target.result;

                        ConsoleService('IndexedDbModuleInvoicesComponent / load_all_store_objects() / request / on success / req / on success / cursor', t.flag_console_log);
                        ConsoleService(cursor, t.flag_console_log);

                        if (cursor != null) {

                            ConsoleService('IndexedDbModuleInvoicesComponent / load_all_store_objects() / request / on success / req / on success / cursor / if', t.flag_console_log);

                            ConsoleService('IndexedDbModuleInvoicesComponent / load_all_store_objects() / request / on success / req / on success / cursor / if / cursor.value', t.flag_console_log);
                            ConsoleService(cursor.value, t.flag_console_log);

                            items.push(cursor.value);

                            cursor.continue();

                        } else {

                            ConsoleService('IndexedDbModuleInvoicesComponent / load_all_store_objects() / request / on success / req / on success / cursor / else', t.flag_console_log);

                            resolve(items);

                        }

                    }

                    req.onerror = function (event) {

                        /*
                        *
                        * error
                        * 
                        */

                        ConsoleService('IndexedDbModuleInvoicesComponent / load_all_store_objects() / request / on success / req / on error', t.flag_console_log);

                        reject([]);

                    }

                    /*
                     *
                     * req
                     *
                     * stop
                     *
                     */

                }

                /*
                 *
                 * request / on success / callback
                 *              
                 * stop
                 * 
                 */

                /*
                 *
                 * request / on error / callback
                 *
                 * start
                 * 
                 */

                request.onerror = function (event) {

                    /*
                    *
                    * error
                    * 
                    */

                    ConsoleService('IndexedDbModuleInvoicesComponent / load_all_store_objects() / request / on error', t.flag_console_log);

                    reject([]);

                }

                /*
                 * request / on error / callback
                 *
                 * stop
                 * 
                 */

                /*
                 * request
                 *
                 * stop
                 * 
                 */

            }
        ).then(
            (data) => {

                /*
                *
                * ok
                * 
                */

                ConsoleService('IndexedDbModuleInvoicesComponent load_all_store_objects() promise then', t.flag_console_log);

                ConsoleService('data', t.flag_console_log);
                ConsoleService(data, t.flag_console_log);

                return data;

            }

        ).catch(
            (data) => {

                /*
                *
                * error
                * 
                */

                ConsoleService('IndexedDbModuleInvoicesComponent load_all_store_objects() promise catch', t.flag_console_log);

                ConsoleService('data', t.flag_console_log);
                ConsoleService(data, t.flag_console_log);

                return data;

            }
        );

        // !!! CAUTION !!! unreacheble code, because of return !!!

    }

    /*
     *
     * load all store objects
     *
     * stop
     * 
     */

}

export default IndexedDbModuleInvoicesComponent;