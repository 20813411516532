/*
 *
 * This is the `IndexedDb ModuleRecipient` component.
 *
 */

import { Component } from 'react';

/*
 *
 * custom components
 *
 * start
 *
 */

import ConsoleService from './components/consoleServiceModules/ConsoleService';

import ConsoleServiceIndexedDbModuleRecipientComponent from "./components/consoleServiceModules/ConsoleServiceIndexedDbModuleRecipientComponent";

import { indexed_db_name, indexed_db_version } from './IndexedDbComponent';

import IndexedDbRecipientUpdateComponent from "./components/IndexedDbRecipientUpdateComponent";


/*
 *
 * custom components
 *
 * stop
 *
 */

// the main class

class IndexedDbModuleRecipientComponent extends Component {

    idb_name = indexed_db_name;

    version = indexed_db_version;

    flag_console_log = ConsoleServiceIndexedDbModuleRecipientComponent;

    /*
     *
     * constructor
     *
     * start
     * 
     */

    constructor(props) {

        super(props);

        ConsoleService('', this.flag_console_log);
        ConsoleService('IndexedDbModuleRecipientComponent / constructor', this.flag_console_log);

        ConsoleService('IndexedDbModuleRecipientComponent / constructor / props', this.flag_console_log);
        ConsoleService(props, this.flag_console_log);

        ConsoleService('IndexedDbModuleInvoicesComponent / constructor / idb name', this.flag_console_log);
        ConsoleService(this.idb_name, this.flag_console_log);

        ConsoleService('IndexedDbModuleRecipientComponent / constructor / IndexedDb / version', this.flag_console_log);
        ConsoleService(this.version, this.flag_console_log);

    }

    /*
     *
     * constructor
     *
     * stop
     * 
     */

    /*
     *
     * add or update
     *
     * start
     * 
     */

    add_or_update = (payload) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('IndexedDbModuleRecipientComponent / add_or_update()', this.flag_console_log);

        ConsoleService('IndexedDbModuleRecipientComponent / add_or_update() / payload', this.flag_console_log);
        ConsoleService(payload, this.flag_console_log);

        let t = this;

        return new Promise(
            (resolve, reject) => {

                ConsoleService('IndexedDbModuleRecipientComponent / add_or_update() / new promise', t.flag_console_log);

                let indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;

                /*
                 *
                 * request
                 *
                 * start
                 * 
                 */

                let request = indexedDB.open(t.idb_name, t.version);

                // request / on success / callback
                request.onsuccess = function (event) {

                    ConsoleService('IndexedDbModuleRecipientComponent / add_or_update() / request / on success', t.flag_console_log);

                    ConsoleService('IndexedDbModuleRecipientComponent / add_or_update() / request / on success / event', t.flag_console_log);
                    ConsoleService(event, t.flag_console_log);

                    ConsoleService('IndexedDbModuleRecipientComponent / add_or_update() / request / on success / payload', t.flag_console_log);
                    ConsoleService(payload, t.flag_console_log);

                    let db = event.target.result;

                    /*
                     *
                     * transaction
                     *
                     * start
                     * 
                     */

                    /*
                     *
                     * transaction
                     * 
                     * open a read/write db transaction, ready for adding the data
                     * 
                     * https://developer.mozilla.org/en-US/docs/Web/API/IDBDatabase/transaction
                     * 
                     */

                    let transaction = db.transaction(['recipient_object_store'], 'readonly');

                    ConsoleService('IndexedDbModuleRecipientComponent / add_or_update() / request / on success / transaction', t.flag_console_log);
                    ConsoleService(transaction, t.flag_console_log);

                    /*
                     * report on the success of opening the transaction
                     */
                    transaction.oncomplete = function (event) {

                        ConsoleService('IndexedDbModuleRecipientComponent / add_or_update() / request / on success / transaction / on complete', t.flag_console_log);

                        ConsoleService('IndexedDbModuleRecipientComponent / add_or_update() / request / on success / transaction / on complete / event', t.flag_console_log);
                        ConsoleService(event, t.flag_console_log);

                    }

                    transaction.onerror = function (event) {

                        ConsoleService('IndexedDbModuleRecipientComponent / add_or_update() / request / on success / transaction / on error', t.flag_console_log);

                        ConsoleService('IndexedDbModuleRecipientComponent / add_or_update() / request / on success / transaction / on error / event', t.flag_console_log);
                        ConsoleService(event, t.flag_console_log);

                    }

                    /*
                     *
                     * transaction
                     *
                     * stop
                     * 
                     */

                    let store = transaction.objectStore('recipient_object_store');

                    ConsoleService('IndexedDbModuleRecipientComponent / add_or_update() / request / on success / store', t.flag_console_log);
                    ConsoleService(store, t.flag_console_log);

                    let index = store.index('recipient_object_store_id');

                    ConsoleService('IndexedDbModuleRecipientComponent / add_or_update() / request / on success / index', t.flag_console_log);
                    ConsoleService(index, t.flag_console_log);

                    /*
                     *
                     * req
                     *
                     * start
                     * 
                     */

                    let req = index.get(payload.attributes.recipient_object_store_id);

                    ConsoleService('IndexedDbModuleRecipientComponent / add_or_update() / request / on success / req', t.flag_console_log);
                    ConsoleService(req, t.flag_console_log);

                    /*
                     *
                     * req / on success
                     *
                     * start
                     * 
                     */

                    req.onsuccess = function (event) {

                        ConsoleService('IndexedDbModuleRecipientComponent / add_or_update() / request / on success / req / on success', t.flag_console_log);

                        ConsoleService('IndexedDbModuleRecipientComponent / add_or_update() / request / on success / req / on success / payload', t.flag_console_log);
                        ConsoleService(payload, t.flag_console_log);

                        let result = event.target.result;

                        ConsoleService('IndexedDbModuleRecipientComponent / add_or_update() / request / on success / req / on success / result', t.flag_console_log);
                        ConsoleService(result, t.flag_console_log);

                        if (result) {

                            /*
                             * update scenario
                             */

                            ConsoleService('IndexedDbModuleRecipientComponent / add_or_update() / request / on success / req / on success / result / if => update scenario', t.flag_console_log);

                            /*
                            *
                            * ok
                            *
                            * we need to update the recipient data
                            * 
                            */

                            let payload_ = {

                                //this is the PK
                                recipient_object_store_id: payload.attributes.recipient_object_store_id,

                                recipient_company_name: payload.attributes.recipient_company_name,

                                recipient_vat_nr: payload.attributes.recipient_vat_nr,

                                recipient_account_type: payload.attributes.recipient_account_type,

                                recipient_account_type_name: payload.attributes.recipient_account_type_name,

                                recipient_email: payload.attributes.recipient_email,

                                recipient_phone_nr: payload.attributes.recipient_phone_nr,

                                recipient_is_supplier: payload.attributes.recipient_is_supplier,

                                recipient_is_customer: payload.attributes.recipient_is_customer,

                                recipient_external_account_id: payload.attributes.recipient_external_account_id,

                                recipient_addresses_country_key: payload.attributes.recipient_addresses_country_key,
                                recipient_addresses_country_name: payload.attributes.recipient_addresses_country_name,
                                recipient_addresses_city: payload.attributes.recipient_addresses_city,
                                recipient_addresses_street_address: payload.attributes.recipient_addresses_street_address,
                                recipient_addresses_zip: payload.attributes.recipient_addresses_zip,

                                recipient_addresses_is_billing: payload.attributes.recipient_addresses_is_billing,
                                recipient_addresses_is_primary: payload.attributes.recipient_addresses_is_primary,
                                recipient_addresses_is_delivery: payload.attributes.recipient_addresses_is_delivery,
                                recipient_addresses_is_site: payload.attributes.recipient_addresses_is_site,

                            };

                            IndexedDbRecipientUpdateComponent(payload_).then(
                                (data) => {
                                    /*
                                    *
                                    * ok
                                    * 
                                    */

                                    ConsoleService('IndexedDbModuleRecipientComponent / add_or_update() / request / on success / req / on success / else => update scenario / promise result => then', t.flag_console_log);

                                    ConsoleService('IndexedDbModuleRecipientComponent / add_or_update() / request / on success / req / on success / else => update scenario / promise result => then / data', t.flag_console_log);
                                    ConsoleService(data, t.flag_console_log);

                                    resolve(data);
                                }
                            ).catch(
                                (data) => {
                                    /*
                                    *
                                    * error
                                    * 
                                    */

                                    ConsoleService('IndexedDbModuleRecipientComponent / add_or_update() / request / on success / req / on success / else => update scenario / promise result => catch', t.flag_console_log);

                                    ConsoleService('IndexedDbModuleRecipientComponent / add_or_update() / request / on success / req / on success / else => update scenario / promise result => catch / data', t.flag_console_log);
                                    ConsoleService(data, t.flag_console_log);

                                    reject(data);
                                }
                            );

                        } else {

                            /*
                             * add scenario
                             */

                            ConsoleService('IndexedDbModuleRecipientComponent / add_or_update() / request / on success / req / on success / result / else => add scenario', t.flag_console_log);

                            t.addData(payload).then(
                                (data) => {

                                    /*
                                    *
                                    * ok
                                    * 
                                    */

                                    ConsoleService('IndexedDbModuleRecipientComponent / add_or_update() / request / on success / req / on success / else => add scenario / t.addData() / promise result => then', t.flag_console_log);

                                    ConsoleService('IndexedDbModuleRecipientComponent / add_or_update() / request / on success / req / on success / else => add scenario / t.addData() / promise result => then / data', t.flag_console_log);
                                    ConsoleService(data, t.flag_console_log);

                                    resolve(data);

                                }
                            ).catch(
                                (data) => {

                                    /*
                                    *
                                    * error
                                    * 
                                    */

                                    ConsoleService('IndexedDbModuleRecipientComponent / add_or_update() / request / on success / req / on success / else => add scenario / t.addData() / promise result => catch', t.flag_console_log);

                                    ConsoleService('IndexedDbModuleRecipientComponent / add_or_update() / request / on success / req / on success / else => add scenario / t.addData() / promise result => catch / data', t.flag_console_log);
                                    ConsoleService(data, t.flag_console_log);

                                    reject(data);

                                }
                            );

                        }

                    }

                    req.onerror = function (event) {

                        ConsoleService('IndexedDbModuleRecipientComponent / add_or_update() / request / on success / req / on error', t.flag_console_log);

                        ConsoleService('IndexedDbModuleRecipientComponent / add_or_update() / request / on success / req / on error / event', t.flag_console_log);
                        ConsoleService(event, t.flag_console_log);

                        reject();

                    }

                }

                // request / on error / callback
                request.onerror = function (event) {

                    ConsoleService('IndexedDbModuleRecipientComponent / add_or_update() / request / on error', t.flag_console_log);

                    ConsoleService('IndexedDbModuleRecipientComponent / add_or_update() / request / on error / event', t.flag_console_log);
                    ConsoleService(event, t.flag_console_log);

                    reject();

                }

                /*
                 *
                 * request
                 * 
                 * stop
                 * 
                 */

            }
        ).then(
            (data) => {

                ConsoleService('IndexedDbModuleRecipientComponent / add_or_update() / promise result => then', t.flag_console_log);

                ConsoleService('IndexedDbModuleRecipientComponent / add_or_update() / promise result => then / data', t.flag_console_log);
                ConsoleService(data, t.flag_console_log);

                return data;

            }
        ).catch(
            (data) => {

                ConsoleService('IndexedDbModuleRecipientComponent / add_or_update() / promise result => catch', t.flag_console_log);

                ConsoleService('IndexedDbModuleRecipientComponent / add_or_update() / promise result => catch / data', t.flag_console_log);
                ConsoleService(data, t.flag_console_log);

                return data;

            }
        );

        // !!! CAUTION !!! unreacheble code, because of return !!!

    }

    /*
     *
     * add or update
     *
     * stop
     * 
     */

    /*
     *
     * add data
     *
     * start
     * 
     */

    addData = (payload) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('IndexedDbModuleRecipientComponent / addData()', this.flag_console_log);

        ConsoleService('IndexedDbModuleRecipientComponent / addData() / payload', this.flag_console_log);
        ConsoleService(payload, this.flag_console_log);

        let t = this;

        return new Promise(
            (resolve, reject) => {

                ConsoleService('IndexedDbModuleRecipientComponent / addData() / promise', t.flag_console_log);

                let indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;

                /*
                 *
                 * request
                 *
                 * start
                 * 
                 */

                let request = indexedDB.open(t.idb_name, t.version);

                /*
                 * request / on success / callback
                 *
                 * start
                 * 
                 */

                request.onsuccess = function (event) {

                    ConsoleService('IndexedDbModuleRecipientComponent / addData() / request / on success', t.flag_console_log);

                    ConsoleService('IndexedDbModuleRecipientComponent / addData() / request / on success / event', t.flag_console_log);
                    ConsoleService(event, t.flag_console_log);

                    let db = event.target.result;

                    /*
                     *
                     * transaction
                     *
                     * start
                     * 
                     */

                    /*
                     *
                     * transaction
                     * 
                     * open a read/write db transaction, ready for adding the data
                     * 
                     * https://developer.mozilla.org/en-US/docs/Web/API/IDBDatabase/transaction
                     * 
                     */

                    let transaction = db.transaction(['recipient_object_store'], 'readwrite');

                    /*
                     * report on the success of opening the transaction
                     */
                    transaction.oncomplete = function (event) {

                        ConsoleService('IndexedDbModuleRecipientComponent / addData() / transaction / on complete', t.flag_console_log);

                        ConsoleService('IndexedDbModuleRecipientComponent / addData() / transaction / on complete / event', t.flag_console_log);
                        ConsoleService(event, t.flag_console_log);

                        // !! SHOULD THE "RESOLVE" EVENT BE HERE ? !!

                        resolve(event);

                    }

                    transaction.onerror = function (event) {

                        ConsoleService('IndexedDbModuleRecipientComponent / addData() / transaction / on error', t.flag_console_log);

                        ConsoleService('IndexedDbModuleRecipientComponent / addData() / transaction / on error / event', t.flag_console_log);
                        ConsoleService(event, t.flag_console_log);

                        reject(event);

                    }

                    /*
                     *
                     * transaction
                     *
                     * stop
                     * 
                     */

                    /*
                     *
                     * object store
                     *
                     * start
                     * 
                     */

                    let objectStore = transaction.objectStore('recipient_object_store');

                    ConsoleService('IndexedDbModuleRecipientComponent / addData() / objectStore', t.flag_console_log);
                    ConsoleService(objectStore, t.flag_console_log);

                    ConsoleService('IndexedDbModuleRecipientComponent / addData() / objectStore.indexNames', t.flag_console_log);
                    ConsoleService(objectStore.indexNames, t.flag_console_log);

                    ConsoleService('IndexedDbModuleRecipientComponent / addData() / objectStore.keyPath', t.flag_console_log);
                    ConsoleService(objectStore.keyPath, t.flag_console_log);

                    ConsoleService('IndexedDbModuleRecipientComponent / addData() / objectStore.name', t.flag_console_log);
                    ConsoleService(objectStore.name, t.flag_console_log);

                    ConsoleService('IndexedDbModuleRecipientComponent / addData() / objectStore.transaction', t.flag_console_log);
                    ConsoleService(objectStore.transaction, t.flag_console_log);

                    ConsoleService('IndexedDbModuleRecipientComponent / addData() / objectStore.autoIncrement', t.flag_console_log);
                    ConsoleService(objectStore.autoIncrement, t.flag_console_log);

                    /*
                    *
                    * ok
                    *
                    * we need to add the recipient data
                    * 
                    */

                    var newItem = [
                        {

                            /*
                            * the indexedDb engine should set the PK !!!
                            *
                            * leave this task to the engine
                            * 
                            */

                            //recipient_object_store_id: payload.form_recipient_unique_id,

                            recipient_company_name: payload.attributes.recipient_company_name,

                            recipient_vat_nr: payload.attributes.recipient_vat_nr,

                            //recipient_reference_nr: payload.form_recipient_reference_nr,

                            recipient_account_type: payload.attributes.recipient_account_type,

                            recipient_account_type_name: payload.attributes.recipient_account_type_name,

                            //recipient_language_key: payload.form_recipient_language_key,

                            recipient_email: payload.attributes.recipient_email,

                            recipient_phone_nr: payload.attributes.recipient_phone_nr,

                            //recipient_fax_nr: payload.form_recipient_fax_nr,

                            //recipient_is_supplier: payload.form_recipient_is_supplier,
                            recipient_is_supplier: 0,

                            //recipient_is_customer: payload.form_recipient_is_customer,
                            recipient_is_customer: 1,

                            //recipient_json_data: payload,

                            /*
                            * !!! fail safe !!!
                            */

                            recipient_external_account_id: 0,

                            /*
                            *
                            * addresses info
                            *
                            * start
                            *
                            */

                            recipient_addresses_country_key: payload.attributes.recipient_addresses_country_key,
                            recipient_addresses_country_name: payload.attributes.recipient_addresses_country_name,
                            recipient_addresses_city: payload.attributes.recipient_addresses_city,
                            recipient_addresses_street_address: payload.attributes.recipient_addresses_street_address,
                            recipient_addresses_zip: payload.attributes.recipient_addresses_zip,


                            //recipient_addresses_is_billing: payload.form_recipient_addresses_is_billing,
                            recipient_addresses_is_billing: 1,

                            //recipient_addresses_is_primary: payload.form_recipient_addresses_is_primary,
                            recipient_addresses_is_primary: 1,

                            //recipient_addresses_is_delivery: payload.form_recipient_addresses_is_delivery,
                            recipient_addresses_is_delivery: 1,

                            //recipient_addresses_is_site: payload.form_recipient_addresses_is_site,
                            recipient_addresses_is_site: 1,

                            /*
                            *
                            * addresses info
                            *
                            * stop
                            *
                            */

                        }
                    ];

                    ConsoleService('IndexedDbModuleRecipientComponent / addData() / newItem', t.flag_console_log);
                    ConsoleService(newItem, t.flag_console_log);

                    let objectStoreRequest = objectStore.add(newItem[0]);

                    objectStoreRequest.onsuccess = function (event) {

                        ConsoleService('IndexedDbModuleRecipientComponent / addData() / object store request / on success', t.flag_console_log);

                        ConsoleService('IndexedDbModuleRecipientComponent / addData() / object store request / on success / event', t.flag_console_log);
                        ConsoleService(event, t.flag_console_log);

                        // !! SHOULD THE "RESOLVE" EVENT BE HERE ? !!

                        //resolve(event);

                    }

                    objectStoreRequest.onerror = function (event) {

                        ConsoleService('IndexedDbModuleRecipientComponent / addData() / object store request / on error', t.flag_console_log);

                        ConsoleService('IndexedDbModuleRecipientComponent / addData() / object store request / on error / event', t.flag_console_log);
                        ConsoleService(event, t.flag_console_log);

                        reject(event);

                    }

                    /*
                     *
                     * object store
                     *
                     * stop
                     * 
                     */

                };

                /*
                 * request / on success / callback
                 *
                 * stop
                 * 
                 */

                /*
                 * request / on error / callback
                 *
                 * start
                 * 
                 */

                request.onerror = function (event) {

                    ConsoleService('IndexedDbModuleRecipientComponent / addData() / request / on error', t.flag_console_log);

                    ConsoleService('IndexedDbModuleRecipientComponent / addData() / request / on error / event', t.flag_console_log);
                    ConsoleService(event, t.flag_console_log);

                    reject(event);

                };

                /*
                 * request / on error / callback
                 *
                 * stop
                 * 
                 */

                /*
                 *
                 * request
                 *
                 * start
                 * 
                 */

            }
        ).then(
            (data) => {

                ConsoleService('IndexedDbModuleRecipientComponent / addData() / promise then', t.flag_console_log);

                ConsoleService('IndexedDbModuleRecipientComponent / addData() / promise then / data', t.flag_console_log);
                ConsoleService(data, t.flag_console_log);

                return data;

            }
        ).catch(
            (data) => {

                ConsoleService('IndexedDbModuleRecipientComponent / addData() / promise catch', t.flag_console_log);

                ConsoleService('IndexedDbModuleRecipientComponent / addData() / promise catch / data', t.flag_console_log);
                ConsoleService(data, t.flag_console_log);

                return data;

            }
        );

        // !!! CAUTION !!! unreacheble code, because of return !!!

    }

    /*
     *
     * add data
     *
     * stop
     * 
     */

    /*
     *
     * update data
     * 
     * stop
     * 
     */

    updateData = (payload) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('IndexedDbModuleRecipientComponent / updateData()', this.flag_console_log);

        ConsoleService('IndexedDbModuleRecipientComponent / updateData() / payload', this.flag_console_log);
        ConsoleService(payload, this.flag_console_log);

        let t = this;

        return new Promise(
            (resolve, reject) => {

                ConsoleService('IndexedDbModuleRecipientComponent / load_all_store_objects() / new promise', t.flag_console_log);

                let indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;

                /*
                 *
                 * request
                 *
                 * start
                 * 
                 */

                let request = indexedDB.open(t.idb_name, t.version);

                // request / on success / callback
                request.onsuccess = function (event) {

                    ConsoleService('updateData / request / on success', t.flag_console_log);

                    ConsoleService('event', t.flag_console_log);
                    ConsoleService(event, t.flag_console_log);

                    let db = event.target.result;

                    /*
                     *
                     * transaction
                     *
                     * start
                     * 
                     */

                    /*
                     *
                     * transaction
                     * 
                     * open a read/write db transaction, ready for adding the data
                     * 
                     * https://developer.mozilla.org/en-US/docs/Web/API/IDBDatabase/transaction
                     * 
                     */

                    let transaction = db.transaction(['recipient_object_store'], 'readwrite');

                    ConsoleService('IndexedDbModuleRecipientComponent / updateData() / request / on success / transaction', t.flag_console_log);
                    ConsoleService(transaction, t.flag_console_log);

                    /*
                     * report on the success of opening the transaction
                     */

                    transaction.oncomplete = function (event) {

                        ConsoleService('IndexedDbModuleRecipientComponent / updateData() / request / on success / transaction / on complete', t.flag_console_log);

                        ConsoleService('IndexedDbModuleRecipientComponent / updateData() / request / on success / transaction / on complete / event', t.flag_console_log);
                        ConsoleService(event, t.flag_console_log);

                        resolve(event);

                    }

                    transaction.onerror = function (event) {

                        ConsoleService('IndexedDbModuleRecipientComponent / updateData() / request / on success / transaction / on error', t.flag_console_log);

                        ConsoleService('IndexedDbModuleRecipientComponent / updateData() / request / on success / transaction / on error / event', t.flag_console_log);
                        ConsoleService(event, t.flag_console_log);

                        reject(event);

                    }

                    /*
                     *
                     * transaction
                     *
                     * stop
                     * 
                     */

                    let store = transaction.objectStore('recipient_object_store');

                    ConsoleService('store', t.flag_console_log);
                    ConsoleService(store, t.flag_console_log);

                    /*
                     *
                     * req
                     *
                     * start
                     * 
                     */

                    let req = store.put(payload);

                    /*
                     *
                     * req / on success
                     *
                     * start
                     * 
                     */

                    req.onsuccess = function (event) {

                        ConsoleService('IndexedDbModuleRecipientComponent / updateData() / request / on success / req / on success', t.flag_console_log);

                        ConsoleService('IndexedDbModuleRecipientComponent / updateData() / request / on success / req / on success / event', t.flag_console_log);
                        ConsoleService(event, t.flag_console_log);

                        //resolve(event);

                    }

                    /*
                     *
                     * req / on success
                     *
                     * stop
                     * 
                     */

                    /*
                     *
                     * req / on error
                     *
                     * start
                     * 
                     */

                    req.onerror = function (event) {

                        ConsoleService('IndexedDbModuleRecipientComponent / updateData() / request / on success / req / on error', t.flag_console_log);

                        ConsoleService('IndexedDbModuleRecipientComponent / updateData() / request / on success / req / on error / event', t.flag_console_log);
                        ConsoleService(event, t.flag_console_log);

                        reject(event);

                    }

                    /*
                     *
                     * req / on error
                     *
                     * stop
                     * 
                     */

                    /*
                     *
                     * req
                     *
                     * stop
                     * 
                     */

                }

                /*
                 * request / on error / callback
                 *
                 * start
                 * 
                 */

                request.onerror = function (event) {

                    ConsoleService('IndexedDbModuleRecipientComponent / updateData() / request / on error', t.flag_console_log);

                    ConsoleService('IndexedDbModuleRecipientComponent / updateData() / request / on error / event', t.flag_console_log);
                    ConsoleService(event, t.flag_console_log);

                    reject(event);

                }

                /*
                 * request / on error / callback
                 *
                 * stop
                 * 
                 */

            }).then(
                (data) => {

                    ConsoleService('IndexedDbModuleRecipientComponent / updateData() / new promise => then', t.flag_console_log);

                    ConsoleService('IndexedDbModuleRecipientComponent / updateData() / new promise => then / data', t.flag_console_log);
                    ConsoleService(data, t.flag_console_log);

                    return data;

                }
            ).catch(
                (data) => {

                    ConsoleService('IndexedDbModuleRecipientComponent / updateData() / new promise => catch', t.flag_console_log);

                    ConsoleService('IndexedDbModuleRecipientComponent / updateData() / new promise => catch / data', t.flag_console_log);
                    ConsoleService(data, t.flag_console_log);

                    return data;

                }
            );

        // !!! CAUTION !!! unreacheble code, because of return !!!

    }

    /*
     *
     * update data
     *
     * stop
     * 
     */

    /*
     *
     * update data
     * 
     * update the external id
     *
     * stop
     * 
     */

    updateExternalIdData = (payload) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('IndexedDbModuleRecipientComponent / updateExternalIdData()', this.flag_console_log);

        ConsoleService('IndexedDbModuleRecipientComponent / updateExternalIdData() / payload', this.flag_console_log);
        ConsoleService(payload, this.flag_console_log);

        let t = this;

        return new Promise(
            (resolve, reject) => {

                ConsoleService('IndexedDbModuleRecipientComponent / load_all_store_objects() / new promise', t.flag_console_log);

                let indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;

                /*
                 *
                 * request
                 *
                 * start
                 * 
                 */

                let request = indexedDB.open(t.idb_name, t.version);

                // request / on success / callback
                request.onsuccess = function (event) {

                    ConsoleService('updateExternalIdData / request / on success', t.flag_console_log);

                    ConsoleService('event', t.flag_console_log);
                    ConsoleService(event, t.flag_console_log);

                    let db = event.target.result;

                    /*
                     *
                     * transaction
                     *
                     * start
                     * 
                     */

                    /*
                     *
                     * transaction
                     * 
                     * open a read/write db transaction, ready for adding the data
                     * 
                     * https://developer.mozilla.org/en-US/docs/Web/API/IDBDatabase/transaction
                     * 
                     */

                    let transaction = db.transaction(['recipient_object_store'], 'readwrite');

                    ConsoleService('IndexedDbModuleRecipientComponent / updateExternalIdData() / request / on success / transaction', t.flag_console_log);
                    ConsoleService(transaction, t.flag_console_log);

                    /*
                     * report on the success of opening the transaction
                     */

                    transaction.oncomplete = function (event) {

                        ConsoleService('IndexedDbModuleRecipientComponent / updateExternalIdData() / request / on success / transaction / on complete', t.flag_console_log);

                        ConsoleService('IndexedDbModuleRecipientComponent / updateExternalIdData() / request / on success / transaction / on complete / event', t.flag_console_log);
                        ConsoleService(event, t.flag_console_log);

                        resolve(event);

                    }

                    transaction.onerror = function (event) {

                        ConsoleService('IndexedDbModuleRecipientComponent / updateExternalIdData() / request / on success / transaction / on error', t.flag_console_log);

                        ConsoleService('IndexedDbModuleRecipientComponent / updateExternalIdData() / request / on success / transaction / on error / event', t.flag_console_log);
                        ConsoleService(event, t.flag_console_log);

                        reject(event);

                    }

                    /*
                     *
                     * transaction
                     *
                     * stop
                     * 
                     */

                    let store = transaction.objectStore('recipient_object_store');

                    ConsoleService('store', t.flag_console_log);
                    ConsoleService(store, t.flag_console_log);

                    /*
                     *
                     * req
                     *
                     * start
                     * 
                     */

                    payload.recipient_object_store_item.recipient_external_account_id = parseInt(payload.external_account_id);

                    let req = store.put(payload.recipient_object_store_item);

                    /*
                     *
                     * req / on success
                     *
                     * start
                     * 
                     */

                    req.onsuccess = function (event) {

                        ConsoleService('IndexedDbModuleRecipientComponent / updateExternalIdData() / request / on success / req / on success', t.flag_console_log);

                        ConsoleService('IndexedDbModuleRecipientComponent / updateExternalIdData() / request / on success / req / on success / event', t.flag_console_log);
                        ConsoleService(event, t.flag_console_log);

                        //resolve(event);

                    }

                    /*
                     *
                     * req / on success
                     *
                     * stop
                     * 
                     */

                    /*
                     *
                     * req / on error
                     *
                     * start
                     * 
                     */

                    req.onerror = function (event) {

                        ConsoleService('IndexedDbModuleRecipientComponent / updateExternalIdData() / request / on success / req / on error', t.flag_console_log);

                        ConsoleService('IndexedDbModuleRecipientComponent / updateExternalIdData() / request / on success / req / on error / event', t.flag_console_log);
                        ConsoleService(event, t.flag_console_log);

                        reject(event);

                    }

                    /*
                     *
                     * req / on error
                     *
                     * stop
                     * 
                     */

                    /*
                     *
                     * req
                     *
                     * stop
                     * 
                     */

                }

                /*
                 * request / on error / callback
                 *
                 * start
                 * 
                 */

                request.onerror = function (event) {

                    ConsoleService('IndexedDbModuleRecipientComponent / updateExternalIdData() / request / on error', t.flag_console_log);

                    ConsoleService('IndexedDbModuleRecipientComponent / updateExternalIdData() / request / on error / event', t.flag_console_log);
                    ConsoleService(event, t.flag_console_log);

                    reject(event);

                }

                /*
                 * request / on error / callback
                 *
                 * stop
                 * 
                 */

            }).then(
                (data) => {

                    ConsoleService('IndexedDbModuleRecipientComponent / updateExternalIdData() / new promise => then', t.flag_console_log);

                    ConsoleService('IndexedDbModuleRecipientComponent / updateExternalIdData() / new promise => then / data', t.flag_console_log);
                    ConsoleService(data, t.flag_console_log);

                    return data;

                }
            ).catch(
                (data) => {

                    ConsoleService('IndexedDbModuleRecipientComponent / updateExternalIdData() / new promise => catch', t.flag_console_log);

                    ConsoleService('IndexedDbModuleRecipientComponent / updateExternalIdData() / new promise => catch / data', t.flag_console_log);
                    ConsoleService(data, t.flag_console_log);

                    return data;

                }
            );

        // !!! CAUTION !!! unreacheble code, because of return !!!

    }

    /*
     *
     * update data
     * 
     * update the external id
     *
     * stop
     * 
     */

    /*
     *
     * load all store objects
     *
     * start
     * 
     */

    load_all_store_objects = () => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('IndexedDbModuleRecipientComponent / load_all_store_objects()', this.flag_console_log);

        let t = this;

        return new Promise(
            (resolve, reject) => {

                ConsoleService('IndexedDbModuleRecipientComponent / load_all_store_objects() / new promise', t.flag_console_log);

                let indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;

                /*
                 *
                 * request
                 *
                 * start
                 * 
                 */

                let request = indexedDB.open(t.idb_name, t.version);

                /*
                 * request / on success / callback
                 *
                 * start
                 * 
                 */

                request.onsuccess = function (event) {

                    ConsoleService('IndexedDbModuleRecipientComponent / load_all_store_objects() / request / on success', t.flag_console_log);

                    ConsoleService('IndexedDbModuleRecipientComponent / load_all_store_objects() / request / on success / event', t.flag_console_log);
                    ConsoleService(event, t.flag_console_log);

                    let db = event.target.result;

                    /*
                     *
                     * transaction
                     *
                     * start
                     * 
                     */

                    /*
                     *
                     * transaction
                     * 
                     * open a read/write db transaction, ready for adding the data
                     * 
                     * https://developer.mozilla.org/en-US/docs/Web/API/IDBDatabase/transaction
                     * 
                     */

                    let transaction = db.transaction(['recipient_object_store'], 'readonly');

                    ConsoleService('IndexedDbModuleRecipientComponent / load_all_store_objects() / request / on success / transaction', t.flag_console_log);
                    ConsoleService(transaction, t.flag_console_log);

                    /*
                     * report on the success of opening the transaction
                     */

                    transaction.oncomplete = function (event) {

                        ConsoleService('IndexedDbModuleRecipientComponent / load_all_store_objects() / request / on success / transaction / on complete', t.flag_console_log);

                        ConsoleService('IndexedDbModuleRecipientComponent / load_all_store_objects() / request / on success / transaction / on complete / event', t.flag_console_log);
                        ConsoleService(event, t.flag_console_log);

                    }

                    transaction.onerror = function (event) {

                        ConsoleService('IndexedDbModuleRecipientComponent / load_all_store_objects() / request / on success / transaction / on error', t.flag_console_log);

                        ConsoleService('IndexedDbModuleRecipientComponent / load_all_store_objects() / request / on success / transaction / on error / event', t.flag_console_log);
                        ConsoleService(event, t.flag_console_log);

                        reject(event);

                    }

                    /*
                     *
                     * transaction
                     *
                     * stop
                     * 
                     */

                    /*
                     *
                     * "recipient" object store
                     *
                     * start
                     * 
                     */

                    let store = transaction.objectStore('recipient_object_store');

                    ConsoleService('IndexedDbModuleRecipientComponent / load_all_store_objects() / request / on success / store', t.flag_console_log);
                    ConsoleService(store, t.flag_console_log);

                    /*
                     *
                     * "recipient" object store
                     *
                     * stop
                     * 
                     */

                    /*
                     *
                     * req
                     *
                     * start
                     *
                     */

                    let req = store.openCursor();

                    ConsoleService('IndexedDbModuleRecipientComponent / load_all_store_objects() / request / on success / req', t.flag_console_log);
                    ConsoleService(req, t.flag_console_log);

                    let items = [];

                    ConsoleService('IndexedDbModuleRecipientComponent / load_all_store_objects() / request / on success / items', t.flag_console_log);
                    ConsoleService(items, t.flag_console_log);

                    req.onsuccess = function (event) {

                        ConsoleService('IndexedDbModuleRecipientComponent / load_all_store_objects() / request / on success / req / on success', t.flag_console_log);

                        let cursor = event.target.result;

                        ConsoleService('IndexedDbModuleRecipientComponent / load_all_store_objects() / request / on success / req / on success / cursor', t.flag_console_log);
                        ConsoleService(cursor, t.flag_console_log);

                        if (cursor != null) {

                            ConsoleService('IndexedDbModuleRecipientComponent / load_all_store_objects() / request / on success / req / on success / cursor / if', t.flag_console_log);

                            ConsoleService('IndexedDbModuleRecipientComponent / load_all_store_objects() / request / on success / req / on success / cursor / if / cursor.value', t.flag_console_log);
                            ConsoleService(cursor.value, t.flag_console_log);

                            items.push(cursor.value);

                            cursor.continue();

                        } else {

                            ConsoleService('IndexedDbModuleRecipientComponent / load_all_store_objects() / request / on success / req / on success / cursor / else', t.flag_console_log);

                            resolve(items);

                        }

                    }

                    req.onerror = function (event) {

                        ConsoleService('IndexedDbModuleRecipientComponent / load_all_store_objects() / request / on success / req / on error', t.flag_console_log);

                        reject([]);

                    }

                    /*
                     *
                     * req
                     *
                     * stop
                     *
                     */

                }

                /*
                 * request / on success / callback
                 *
                 * stop
                 * 
                 */

                /*
                 * request / on error / callback
                 *
                 * start
                 * 
                 */

                request.onerror = function (event) {

                    ConsoleService('IndexedDbModuleRecipientComponent / load_all_store_objects() / request / on error', t.flag_console_log);

                    reject([]);

                }

                /*
                 * request / on error / callback
                 *
                 * stop
                 * 
                 */

                /*
                 * request
                 *
                 * stop
                 * 
                 */

            }
        ).then(
            (data) => {

                ConsoleService('IndexedDbModuleRecipientComponent / load_all_store_objects() / new promise => then', t.flag_console_log);

                ConsoleService('IndexedDbModuleRecipientComponent / load_all_store_objects() / new promise => then / data', t.flag_console_log);
                ConsoleService(data, t.flag_console_log);

                return data;

            }
        ).catch(
            (data) => {

                ConsoleService('IndexedDbModuleRecipientComponent / load_all_store_objects() / new promise => catch', t.flag_console_log);

                ConsoleService('IndexedDbModuleRecipientComponent / load_all_store_objects() / new promise => catch / data', t.flag_console_log);
                ConsoleService(data, t.flag_console_log);

                return data;

            }
        );

        // !!! CAUTION !!! unreacheble code, because of return !!!

    }

    /*
     *
     * load all store objects
     *
     * stop
     * 
     */

}

export default IndexedDbModuleRecipientComponent;