import React, { Component } from 'react';

import "./css/ModuleSettings.css";

import ConsoleService from './components/consoleServiceModules/ConsoleService';//ok 12.12.2019

import ConsoleServiceModuleSettings from './components/consoleServiceModules/ConsoleServiceModuleSettings';//ok 12.12.2019

import VerticalTabsComponent from './components/moduleSettings/VerticalTabsComponent';

import translation from './TranslationComponent';

class ModuleSettings extends Component {

    module_name = 'ModuleSettings';

    flag_console_log = ConsoleServiceModuleSettings;

    state = {};

    constructor(props) {

        super(props);

        ConsoleService('ModuleSettings / constructor', this.flag_console_log);

        ConsoleService('props', this.flag_console_log);
        ConsoleService(props, this.flag_console_log);

        this.state = {

            language: props.language

        }

    }

    /* DELIMITER */

    componentDidMount() {

        ConsoleService('ModuleSettings / componentDidMount');

        ConsoleService('this.state');
        ConsoleService(this.state);

        //

    }

    /* DELIMITER */

    componentDidUpdate(prevProps, prevState) {

        ConsoleService('ModuleSettings / component did update', this.flag_console_log);

        ConsoleService('prevState', this.flag_console_log);
        ConsoleService(prevState, this.flag_console_log);

        ConsoleService('this.state', this.flag_console_log);
        ConsoleService(this.state, this.flag_console_log);

        ConsoleService('prevProps', this.flag_console_log);
        ConsoleService(prevProps, this.flag_console_log);

        ConsoleService('this.props', this.flag_console_log);
        ConsoleService(this.props, this.flag_console_log);

        if (
            this.props.hasOwnProperty('language')
        ) {

            // ok

            /*
            *
            * within the props, we have also received the "language" prop
            * 
            */

            ConsoleService('ModuleSettings / component did update / props.language exists', this.flag_console_log);

            ConsoleService('props.language', this.flag_console_log);
            ConsoleService(this.props.language, this.flag_console_log);

            if (
                this.state.language !== this.props.language
            ) {

                // ok

                /*
                *
                * we need to update the translations
                * 
                */

                ConsoleService('ModuleSettings / component did update / the translations will be updated', this.flag_console_log);

                let newState = Object.assign({}, this.state);

                newState.language = this.props.language;

                this.setState(newState);

                //

            }

            //

        }

        //

    }

    /* DELIMITER */

    render() {

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleSettings render', this.flag_console_log);

        ConsoleService('this.state');
        ConsoleService(this.state);

        return (
            <div id="ModuleSettings" >
                {/* module settings / start */}

                <div className="row" >

                    <div className="col-12">

                        <div className="m5 p5">

                            <h1>{translation[this.state.language]['settings']['settings']}</h1>

                        </div>

                    </div>

                    <div className="col-12">

                        <VerticalTabsComponent
                            language={this.state.language} />

                    </div>

                </div >

                {/* module settings / stop */}
            </div >
        )

    }

}

export default ModuleSettings;