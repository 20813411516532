import React, { Component } from 'react';

import "./../../css/ModuleSettings.css";

import { arr_languages } from '../../LanguageComponent';

import ConsoleService from '../consoleServiceModules/ConsoleService';//ok 12.12.2019

import localStorage_getData from '../LocalStorageGetComponent';

import localStorage_setData from '../LocalStorageSetComponent';

import ToggleButton from 'react-toggle-button';

import translation from './../../TranslationComponent';

/*
*
* "react notification component" docs available at :
*
* https://www.npmjs.com/package/react-notifications-component
*
*/

import { store as notification } from 'react-notifications-component';// ok 14.01.2019

class ModuleSettings_Languages_Component extends Component {

    module_name = 'ModuleSettings_LanguagesComponent';

    flag_console_log = true;

    state = {};

    constructor(props) {

        super(props);

        ConsoleService('ModuleSettings_LanguagesComponent / constructor', this.flag_console_log);

        ConsoleService('props', this.flag_console_log);
        ConsoleService(props, this.flag_console_log);

        this.state = {

            language: props.language,

            arr_languages: arr_languages,

            local_storage: {},

            arr_invoice_serial_and_number: [],

            flag_available_option_vat: 'active',

            variables_vat: 0,

        };

    }

    /* DELIMITER */

    componentDidMount() {

        //

        ConsoleService('ModuleSettings_LanguagesComponent / componentDidMount', this.flag_console_log);

        //console.log('this.state');
        //console.log(this.state);

        let newState = Object.assign({}, this.state);

        newState.local_storage = localStorage_getData();

        ConsoleService('local storage', this.flag_console_log);
        ConsoleService(newState.local_storage, this.flag_console_log);

        this.setState(newState);

        //

    }

    /* DELIMITER */

    componentDidUpdate(prevProps, prevState) {

        ConsoleService('ModuleSettings_LanguagesComponent / component did update', this.flag_console_log);

        ConsoleService('prevState', this.flag_console_log);
        ConsoleService(prevState, this.flag_console_log);

        ConsoleService('this.state', this.flag_console_log);
        ConsoleService(this.state, this.flag_console_log);

        ConsoleService('prevProps', this.flag_console_log);
        ConsoleService(prevProps, this.flag_console_log);

        ConsoleService('this.props', this.flag_console_log);
        ConsoleService(this.props, this.flag_console_log);

        if (
            this.props.hasOwnProperty('language')
        ) {

            // ok

            /*
            *
            * within the props, we have also received the "language" prop
            * 
            */

            ConsoleService('ModuleSettings_LanguagesComponent / component did update / props.language exists', this.flag_console_log);

            ConsoleService('props.language', this.flag_console_log);
            ConsoleService(this.props.language, this.flag_console_log);

            if (
                this.state.language !== this.props.language
            ) {

                // ok

                /*
                *
                * we need to update the translations
                * 
                */

                ConsoleService('ModuleSettings_LanguagesComponent / component did update / the translations will be updated', this.flag_console_log);

                let newState = Object.assign({}, this.state);

                newState.language = this.props.language;

                this.setState(newState);

                //

            }

            //

        }

        //

    }

    languageOnToggle = (language) => {

        //

        ConsoleService('langauge', this.flag_console_log);
        ConsoleService(language, this.flag_console_log);

        let newState = Object.assign({}, this.state);

        newState.local_storage['language'][language] = (this.state.local_storage['language'][language] === 1 ? 0 : 1);

        this.setState(newState);

        localStorage_setData(JSON.stringify(newState.local_storage));

        notification.addNotification(
            {
                title: translation[this.state.language]['general']['notification_success'],
                message: translation[this.state.language]['settings']['notification_success_update_language'],
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            }
        );

        //

    }

    /* DELIMITER */

    render() {

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleSettings_LanguagesComponent render', this.flag_console_log);

        ConsoleService('this.state');
        ConsoleService(this.state);

        ConsoleService('this.state.arr_languages', this.flag_console_log);
        ConsoleService(this.state.arr_languages, this.flag_console_log);

        ConsoleService('local_storge', this.flag_console_log);
        ConsoleService(this.state.local_storage, this.flag_console_log);

        const languages = Object.keys(this.state.arr_languages).map(
            (language, key) => {
                return (
                    <React.Fragment key={key}>

                        {

                            this.state.local_storage.hasOwnProperty('language') &&
                                this.state.local_storage['language'].hasOwnProperty(language) ?

                                <tr>

                                    <td>
                                        <img src={require('./../../pictures/flag_' + language + '.png')} alt="Info" height="20" />
                                    </td>

                                    <td>
                                        {this.state.arr_languages[language].name}
                                    </td>

                                    <td>
                                        <ToggleButton
                                            value={this.state.local_storage['language'][language] || false}
                                            onToggle={() => { this.languageOnToggle(language); }} />
                                    </td>

                                </tr>

                                : null

                        }

                    </React.Fragment>
                );
            }
        );

        return (
            <div id="ModuleSettings_LanguagesComponentLanguageComponent" >
                {/* module settings - languages / start */}

                <div className="row" >

                    {/* DELIMITER */}

                    {/* languages / start */}
                    <div className="col-12" >

                        <div className="m5 p5">

                            <div className="m5 p5">

                                <h2>
                                    {translation[this.state.language]['settings']['languages']}:
                                </h2>

                                <div className="container-alert">

                                    <div className="alert alert-success" role="alert">

                                        <h4 className="alert-heading">
                                            {translation[this.state.language]['general']['alert_header_info']}
                                        </h4>

                                        <p>
                                            {translation[this.state.language]['settings']['languages_info_content']}
                                        </p>

                                    </div>

                                </div>

                            </div>

                            <div className="m5 p5">

                                <table className="table">

                                    <tbody>

                                        {languages}

                                    </tbody>

                                </table>

                            </div>

                        </div>

                    </div >
                    {/* languages / stop */}

                </div >

                {/* module settings - langauges / stop */}
            </div >
        )

    }

}

export default ModuleSettings_Languages_Component;