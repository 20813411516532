import React, { Component } from 'react';

import ReactSelect from 'react-select';

import { store as notification } from 'react-notifications-component';

import ReactPaginate from 'react-paginate';

/*
*
* custom css files
*
* start
*
*/

import 'bootstrap/dist/css/bootstrap.min.css';

import './css/ModuleRecipients.css';

/*
*
* custom css files
*
* stop
*
*/

import Form from 'react-bootstrap/Form';

/*
*
* custom components
*
* start
*
*/

import ConsoleService from './components/consoleServiceModules/ConsoleService';

import ConsoleServiceModuleRecipient from "./components/consoleServiceModules/ConsoleServiceModuleRecipient";

import IndexedDbComponent from './IndexedDbComponent';

import DexieDbComponent from './DexieDbComponent';

import IndexedDbModuleRecipientComponent from './IndexedDbModuleRecipientComponent';

//import serviceGenerateRandomString from './serviceGenerateRandomString';

// element item component
import ElementItemRecipient from './ElementItemRecipient';

import default_language from './LanguageComponent';

import translation from './TranslationComponent';

import arr_country from './components/dataProviders/const_arr_country_data';// ok 14.11.2019

import arr_items_perPage from './components/dataProviders/const_arr_items_per_page_data';

import component_official_recipient_attributes from './components/moduleInvoice/component_official_recipient_attributes';

import attribute_errors from './components/attribute_errors';

import attribute_to_word from './components/attribute_to_word';

import FormValidation from './components/FormValidation';

/*
*
* custom components
*
* stop
*
*/

// the main class
class ModuleRecipients extends Component {

    /*
    *
    * IndexedDB property
    * 
    */

    idb = null;

    /*
    *
    * When the "flag console log" is set as "true", it's used to log messages.
    * 
    */

    flag_console_log = ConsoleServiceModuleRecipient;

    module_name = 'ModuleRecipients';

    language = default_language;

    official_recipient_attributes = component_official_recipient_attributes;

    form_validations = {

        recipient_object_store_id: ['safe'],
        recipient_external_id: ['safe'],


        recipient_account_type: ['required'],
        recipient_account_type_name: ['required'],

        recipient_company_name: ['required'],
        recipient_vat_nr: ['required'],
        recipient_email: ['required', 'email'],
        recipient_phone_nr: ['required'],

        recipient_addresses_country_key: ['required'],
        recipient_addresses_country_name: ['required'],
        recipient_addresses_city: ['required'],
        recipient_addresses_street_address: ['required'],
        recipient_addresses_zip: ['required']

    }

    /*
    *
    * react built-in "constructor" method
    * 
    * start
    *
    */

    constructor(props) {

        super(props);

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleRecipients constructor', this.flag_console_log);

        ConsoleService('props', this.flag_console_log);
        ConsoleService(props, this.flag_console_log);

        //let translation_component = new TranslationComponent();

        this.language = props.language;

        this.state = {

            /*
            *
            * form recipient
            *
            * start
            * 
            */

            /* DELIMITER */

            /*
            *
            * form attributes
            *
            * start
            * 
            */

            form: {

                select: {

                    /*
                    *
                    * recipient account type
                    *
                    * start
                    * 
                    */

                    recipient_account_type: null,

                    recipient_account_type_select_options: translation[props.language][this.module_name].recipient_account_type_select_option,

                    /*
                    *
                    * recipient account type
                    *
                    * stop
                    * 
                    */

                    /*
                    *
                    * recipient country key
                    *
                    * start
                    * 
                    */

                    recipient_addresses_country_key: null,

                    recipient_addresses_country_key_select_options: arr_country,

                    /*
                    *
                    * recipient country key
                    *
                    * stop
                    * 
                    */

                    /*
                    *
                    * recipients list perPage
                    *
                    * start
                    * 
                    */

                    recipients_list_perPage: this.set_recipients_list_per_page(15),

                    recipients_list_perPage_select_options: arr_items_perPage,

                    /*
                    *
                    * recipients list perPage
                    *
                    * stop
                    * 
                    */

                },

                labels: {

                    recipient_company_name_label: translation[props.language][this.module_name].recipient_company_name_label,
                    recipient_vat_nr_label: translation[props.language][this.module_name].recipient_vat_nr_label,
                    recipient_account_type_label: translation[props.language][this.module_name].recipient_account_type_label,
                    recipient_account_type_input_radio_label: translation[props.language][this.module_name].recipient_account_type_select_option,
                    recipient_email_label: translation[props.language][this.module_name].recipient_email_label,
                    recipient_phone_nr_label: translation[props.language][this.module_name].recipient_phone_nr_label,

                    recipient_addresses_label: translation[props.language][this.module_name].recipient_addresses_label,
                    recipient_addresses_country_key_label: translation[props.language][this.module_name].recipient_addresses_country_key_label,
                    recipient_addresses_city_label: translation[props.language][this.module_name].recipient_addresses_city_label,
                    recipient_addresses_street_address_label: translation[props.language][this.module_name].recipient_addresses_street_address_label,
                    recipient_addresses_zip_label: translation[props.language][this.module_name].recipient_addresses_zip_label,

                },

                attributes: {

                    //this is the PK
                    recipient_object_store_id: 0,

                    recipient_external_id: 0,

                    recipient_company_name: '',

                    recipient_vat_nr: '',

                    recipient_account_type: '',

                    recipient_account_type_name: '',

                    recipient_email: '',

                    recipient_phone_nr: '',

                    /*
                    *
                    * address info
                    *
                    * start
                    * 
                    */

                    recipient_addresses_country_key: '',

                    recipient_addresses_country_name: '',

                    recipient_addresses_city: '',

                    recipient_addresses_street_address: '',

                    recipient_addresses_zip: '',

                    /*
                    *
                    * address info
                    *
                    * stop
                    * 
                    */

                },

                errors: {

                    recipient_account_type: true,
                    recipient_account_type_name: true,

                    recipient_company_name: true,
                    recipient_vat_nr: true,
                    recipient_email: true,
                    recipient_phone_nr: true,

                    recipient_addresses_country_key: true,
                    recipient_addresses_country_name: true,
                    recipient_addresses_city: true,
                    recipient_addresses_street_address: true,
                    recipient_addresses_zip: true

                },

                errors_as_text: {

                    recipient_account_type: '',
                    recipient_account_type_name: '',

                    recipient_company_name: '',
                    recipient_vat_nr: '',
                    recipient_email: '',
                    recipient_phone_nr: '',

                    recipient_addresses_country_key: '',
                    recipient_addresses_country_name: '',
                    recipient_addresses_city: '',
                    recipient_addresses_street_address: '',
                    recipient_addresses_zip: ''

                },

                touched: {
                    recipient_account_type: false,
                    recipient_account_type_name: false,

                    recipient_company_name: false,
                    recipient_vat_nr: false,
                    recipient_email: false,
                    recipient_phone_nr: false,

                    recipient_addresses_country_key: false,
                    recipient_addresses_country_name: false,
                    recipient_addresses_city: false,
                    recipient_addresses_street_address: false,
                    recipient_addresses_zip: false
                },

            },

            /*
            *
            * form attributes
            *
            * stop
            * 
            */

            /* DELIMITER */

            /*
            *
            * form recipient
            *
            * stop
            * 
            */

            //arr_translation: translation_component.arr_translation,

            button_add_recipient_label: translation[props.language][this.module_name].button_add_recipient_label,
            button_show_recipients_label: translation[props.language][this.module_name].button_show_recipients_label,
            button_save_recipient_label: translation[props.language][this.module_name].button_save_recipient_label,

            recipients_label: translation[props.language][this.module_name].recipients_label,
            no_recipients_label: translation[props.language][this.module_name].no_recipients_label,

            flag_form_or_list: 'list',// ok 28.10.2019

            language: props.language,// ok 28.10.2019

            recipients_list_pageCount: 0,
            recipients_list_offset: 0,
            recipients_list_currentPage: 0,

            click_on_recipients_counter: 0,

            recipient_items: [],

            recipient_items_count: 0,

        }

        ConsoleService('this.state', this.flag_console_log);
        ConsoleService(this.state, this.flag_console_log);

    }

    /*
    *
    * react built-in "constructor" method
    * 
    * stop
    *
    */

    /* DELIMITER */

    /*
    *
    * react built-in "component did mount"" method
    * 
    * start
    * 
    */

    componentDidMount() {

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleRecipients component did mount', this.flag_console_log);

        ConsoleService('this.state', this.flag_console_log);
        ConsoleService(this.state, this.flag_console_log);

        this.idb = null;

        /*
        *
        * We check if the IndexedDB component is available for this browser.
        * 
        */

        if (
            !('indexedDB' in window)
        ) {

            /*
            *
            * error
            * 
            * This browser does support the IndexedDB component.
            * 
            */

            ConsoleService('This browser doesn\'t support IndexedDB', this.flag_console_log);

        } else {

            /*
            *
            * ok
            *
            * This browser supports the IndexedDB component.
            * 
            */

            ConsoleService('This browser supports IndexedDB', this.flag_console_log);

            /*
            *
            * We need to make sure that the db exists.
            * We check all the data and we take the needed actions.
            * 
            */

            new IndexedDbComponent();

            new DexieDbComponent();

            /*
            *
            * We need to connect this module with the dedicated IndexedDB component.
            * ModuleRecipients => IndexedDbModuleRecipientComponent
            * 
            */

            this.idb = new IndexedDbModuleRecipientComponent();

            ConsoleService('this.idb new IndexedDbModuleRecipientComponent()', this.flag_console_log);
            ConsoleService(this.idb, this.flag_console_log);

            this.load_all_the_store_objects();

        }

    }

    /*
    *
    * react built-in "component did mount"" method
    * 
    * stop
    * 
    */

    /* DELIMITER */

    /*
    *
    * react built-in "component did update"" method
    * 
    * start
    * 
    */

    componentDidUpdate() {

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleRecipients component did update', this.flag_console_log);

        ConsoleService('this.state', this.flag_console_log);
        ConsoleService(this.state, this.flag_console_log);

        if (
            this.props.hasOwnProperty('language')
        ) {

            // ok

            /*
            *
            * within the props, we have also received the "language" prop
            * 
            */

            ConsoleService('ModuleRecipients / component did update / props.language exists', this.flag_console_log);

            ConsoleService('props.language', this.flag_console_log);
            ConsoleService(this.props.language, this.flag_console_log);

            if (
                this.state.language !== this.props.language
            ) {

                // ok

                /*
                *
                * we need to update the translations
                * 
                */

                ConsoleService('ModuleRecipients / component did update / the translations will be updated', this.flag_console_log);

                //this.default_language = this.props.language;

                let newState = Object.assign({}, this.state);

                newState.language = this.props.language;

                newState.button_add_recipient_label = translation[this.props.language][this.module_name].button_add_recipient_label;
                newState.button_show_recipients_label = translation[this.props.language][this.module_name].button_show_recipients_label;
                newState.button_save_recipient_label = translation[this.props.language][this.module_name].button_save_recipient_label;

                newState.form.labels.recipient_account_type_input_radio_label = translation[this.props.language][this.module_name].recipient_account_type_select_option;

                this.setState(newState);

                //

            }

            //

        }

        if (
            this.props.hasOwnProperty('click_on_recipients_counter')
        ) {

            // ok, the prop exists

            if (
                parseInt(this.props.click_on_recipients_counter) !== parseInt(this.state.click_on_recipients_counter)
            ) {

                // ok

                // !!! PAY ATTENTION FOR ENDLESS LOOP !!!

                if (
                    parseInt(this.props.click_on_recipients_counter) > parseInt(this.state.click_on_recipients_counter)
                ) {

                    // ok

                    let newState = Object.assign({}, this.state);

                    newState.click_on_recipients_counter = parseInt(this.props.click_on_recipients_counter);

                    newState.flag_form_or_list = 'list';

                    this.setState(newState);

                }

            }

        }

        //        

    }

    /*
    *
    * react built-in "component did update"" method
    * 
    * stop
    * 
    */

    /* DELIMITER */

    /*
    *
    * "load all the store objects" method
    * 
    * start
    * 
    */

    load_all_the_store_objects = () => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleRecipients load_all_the_store_objects()', this.flag_console_log);

        if (
            this.idb !== null
        ) {

            /*
            *
            * ok
            * 
            * the db connection is successfully
            * 
            */

            this.idb.load_all_store_objects().then(
                (data) => {

                    /*
                    *
                    * ok
                    * 
                    */

                    ConsoleService('ModuleRecipients load_all_the_store_objects() this.idb.load_all_store_objects() promise then', this.flag_console_log);

                    ConsoleService('ModuleRecipients load_all_the_store_objects() this.idb.load_all_store_objects() promise then => data', this.flag_console_log);
                    ConsoleService(data, this.flag_console_log);


                    let perPage = this.state.form.select.recipients_list_perPage.value;

                    let newState = Object.assign({}, this.state);

                    newState.recipient_items = data;
                    newState.recipient_items_count = data.length;
                    newState.recipients_list_pageCount = Math.ceil(data.length / perPage);

                    this.setState(newState);

                    //this.props.parent_callback_function(data);

                }
            ).catch(
                (data) => {

                    /*
                    *
                    * error
                    * 
                    */

                    ConsoleService('ModuleRecipients / load_all_the_store_objects() / this.idb.load_all_store_objects() / promise => catch', this.flag_console_log);

                    ConsoleService('ModuleRecipients / load_all_the_store_objects() / this.idb.load_all_store_objects() / promise => catch / data', this.flag_console_log);
                    ConsoleService(data, this.flag_console_log);

                }
            );

        } else {

            /*
            *
            * error
            * 
            * for some reason/s, the db connection failed
            * 
            */

        }

    }

    /*
    *
    * "load all the store objects" method
    * 
    * stop
    * 
    */

    /* DELIMITER */

    handlePageClick = data => {

        let selected = data.selected;
        let offset = Math.ceil(selected * this.state.form.select.recipients_list_perPage.value);

        this.setState({
            recipients_list_offset: offset,
            recipients_list_currentPage: selected
        });

    };

    /* DELIMITER */

    /*
    *
    * form recipient
    *
    * start
    *
    */

    /*
    * form element changed handler / start
    */

    formElementChangedHandler = (event) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleRecipients formElementChangedHandler', this.flag_console_log);

        ConsoleService('event.target.name', this.flag_console_log);
        ConsoleService(event.target.name, this.flag_console_log);

        ConsoleService('event.target.value', this.flag_console_log);
        ConsoleService(event.target.value, this.flag_console_log);

        /*
        *
        * assign the new state
        *
        * start
        * 
        */

        let newState = Object.assign({}, this.state);

        newState.form.errors[event.target.name] = (event.target.value === '' ? true : false);

        newState.form.touched[event.target.name] = true;

        newState.form.attributes[event.target.name] = event.target.value;

        this.setState(newState);

        /*
        *
        * assign the new state
        *
        * stop
        * 
        */

    }

    /*
    * form element changed handler / stop
    */

    // form select element "recipient account type" changed handler
    formSelectElementRecipientAccountTypeChangedHandler = (event) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleRecipients / formSelectElementRecipientAccountTypeChangedHandler', this.flag_console_log);

        ConsoleService('event', this.flag_console_log);
        ConsoleService(event, this.flag_console_log);

        /*
        *
        * assign the new state
        *
        * start
        * 
        */

        let newState = Object.assign({}, this.state);

        newState.form.attributes.recipient_account_type = event.value;

        newState.form.attributes.recipient_account_type_name = event.label;

        newState.form.select.recipient_account_type = event;

        this.setState(newState);

        /*
        *
        * assign the new state
        *
        * stop
        * 
        */

    }

    //form radio input element "recipient account type" changed handler
    formRadioInputElementRecipientAccountTypeChangedHandler = (event) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleRecipients / form radio input element recipient account type changed handler', this.flag_console_log);

        ConsoleService('event', this.flag_console_log);
        ConsoleService(event, this.flag_console_log);

        ConsoleService('event.currentTarget.value', this.flag_console_log);
        ConsoleService(event.currentTarget.value, this.flag_console_log);

        /*
        *
        * assign the new state
        *
        * start
        * 
        */

        let newState = Object.assign({}, this.state);

        newState.form.attributes.recipient_account_type = event.currentTarget.value;

        newState.form.attributes.recipient_account_type_name = this.set_recipient_account_type_name(event.currentTarget.value);

        newState.form.errors.recipient_account_type = (event.currentTarget.value === '' ? true : false);

        newState.form.touched.recipient_account_type = true;

        ConsoleService('newState', this.flag_console_log);
        ConsoleService(newState, this.flag_console_log);

        this.setState(newState);

        /*
        *
        * assign the new state
        *
        * stop
        * 
        */

    }

    // form select element "recipient addresses country key" changed handler
    formSelectElementRecipientAddressesCountryKeyChangedHandler = (event) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleRecipients formSelectElementRecipientAddressesCountryKeyChangedHandler', this.flag_console_log);

        ConsoleService('event', this.flag_console_log);
        ConsoleService(event, this.flag_console_log);

        /*
        *
        * assign the new state
        *
        * start
        * 
        */

        let newState = Object.assign({}, this.state);

        newState.form.attributes.recipient_addresses_country_key = event.value;

        newState.form.attributes.recipient_addresses_country_name = event.label;

        newState.form.select.recipient_addresses_country_key = event;

        newState.form.errors.recipient_addresses_country_key = (event.value === '' ? true : false);

        newState.form.touched.recipient_addresses_country_key = true;

        this.setState(newState);

        /*
        *
        * assign the new state
        *
        * stop
        * 
        */

    }

    formSelectRecipientsListPerPageChangedHandler = (event) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleRecipients formSelectRecipientsListPerPageChangedHandler', this.flag_console_log);

        ConsoleService('event', this.flag_console_log);
        ConsoleService(event, this.flag_console_log);

        let newState = Object.assign({}, this.state);

        newState.form.select.recipients_list_perPage = this.set_recipients_list_per_page(event.value);

        this.setState(newState);

        this.load_all_the_store_objects();

    }

    /*
    *
    * submit recipient form validation
    *
    * start
    * 
    */

    recipientFormSubmitValidation = () => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleRecipients recipientFormSubmitValidation', this.flag_console_log);

        ConsoleService('this.state', this.flag_console_log);
        ConsoleService(this.state, this.flag_console_log);

        let flag_permission = true;

        let newState = Object.assign({}, this.state);

        /*
        *
        * validating the attributes
        *
        * start
        * 
        */

        Object.keys(this.official_recipient_attributes).map(
            (attribute) => {

                ConsoleService('attribute', this.flag_console_log);
                ConsoleService(attribute, this.flag_console_log);

                if (
                    this.state.form.attributes[attribute] === ''
                ) {

                    // error

                    ConsoleService('validation failed at : ' + attribute, this.flag_console_log);

                    newState.form.touched[attribute] = true;// set attribute as "touched"

                    newState.form.errors[attribute] = true;// set attribute with "error"

                    if (flag_permission) {

                        flag_permission = false;

                        //

                    }

                    //

                }

                return true;

                //
            }
        );

        let form_validation = FormValidation.checkValidity(this.form_validations, this.state.form.attributes, attribute_errors(this.state.language), attribute_to_word(this.state.language));

        if (
            form_validation.has_errors
        ) {

            for (let attribute in form_validation.errors) {

                newState.form.touched[attribute] = true;// set attribute as "touched"

                newState.form.errors[attribute] = true;// set attribute with "error"

                newState.form.errors_as_text[attribute] = form_validation.errors[attribute];

                //

            }

            //

        }

        if (!flag_permission) {

            /*
            * Info notification recipient account type
            */

            let message = translation[this.state.language].general.invalid_form;

            notification.addNotification(
                {
                    title: "Info",
                    message: message,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                }
            );

        }

        /*
        *
        * validating the attributes
        *
        * stop
        * 
        */

        this.setState(newState);

        return flag_permission;

    }

    /*
    *
    * submit recipient form validation
    * 
    * start
    * 
    */

    /*
    * submit / start
    */

    recipientFormSubmit = (event) => {

        event.preventDefault();

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleRecipients recipientFormSubmit', this.flag_console_log);

        /*
         * form attributes
         * start
         */

        ConsoleService('this.state.form.attributes', this.flag_console_log);
        ConsoleService(this.state.form.attributes, this.flag_console_log);

        /*
        * form attributes
        * stop
        */

        /*
        * form labels
        * start
        */

        ConsoleService('this.state.form.labels', this.flag_console_log);
        ConsoleService(this.state.form.labels, this.flag_console_log);

        /*
        * form labels
        * stop
        */

        /*
        * TODO
        * continue to list attributes
        */

        if (
            this.recipientFormSubmitValidation()
        ) {

            /*
            * ok
            */

            ConsoleService('recipient form submit validation => validated', this.flag_console_log);

            /*
            * save the submitted data
            */

            let t = this;

            let json_data = {
                labels: t.state.form.labels,
                attributes: t.state.form.attributes
            };

            ConsoleService('ModuleRecipients recipientFormSubmit() json_data', this.flag_console_log);
            ConsoleService(json_data, this.flag_console_log);

            this.idb.add_or_update(json_data)
                .then(
                    (data) => {

                        ConsoleService('ModuleRecipients recipientFormSubmit() this.idb.add_or_update() data then', this.flag_console_log);

                        ConsoleService('data', this.flag_console_log);
                        ConsoleService(data, this.flag_console_log);

                        /*
                        * the `parent_callback_function` method belongs to the "ModuleBill" component
                        * this is how we transfer data from the `child` `ModuleRecipients` component to the `parent` `ModuleBill` component
                        */

                        //t.props.parent_callback_function(t.state);

                        t.load_all_the_store_objects();

                        /*
                        * reset the form data
                        */

                        let newState = Object.assign({}, this.state);

                        newState.form.select.recipient_account_type = null;
                        newState.form.select.recipient_addresses_country_key = null;

                        newState.form.attributes.recipient_account_type = false; //reset radio input for recipient account type

                        newState.form.attributes.recipient_company_name = '';
                        newState.form.attributes.recipient_vat_nr = '';
                        newState.form.attributes.recipient_email = '';
                        newState.form.attributes.recipient_phone_nr = '';
                        newState.form.attributes.recipient_addresses_street_address = '';
                        newState.form.attributes.recipient_addresses_city = '';
                        newState.form.attributes.recipient_addresses_zip = '';
                        newState.form.attributes.recipient_addresses_country_key = 0;

                        newState.form.errors.recipient_account_type = true;
                        newState.form.errors.recipient_account_type_name = true;
                        newState.form.errors.recipient_company_name = true;
                        newState.form.errors.recipient_vat_nr = true;
                        newState.form.errors.recipient_email = true;
                        newState.form.errors.recipient_phone_nr = true;
                        newState.form.errors.recipient_addresses_country_key = true;
                        newState.form.errors.recipient_addresses_country_name = true;
                        newState.form.errors.recipient_addresses_city = true;
                        newState.form.errors.recipient_addresses_street_address = true;
                        newState.form.errors.recipient_addresses_zip = true;

                        newState.form.touched.recipient_account_type = false;
                        newState.form.touched.recipient_account_type_name = false;

                        newState.form.touched.recipient_company_name = false;
                        newState.form.touched.recipient_vat_nr = false;
                        newState.form.touched.recipient_email = false;
                        newState.form.touched.recipient_phone_nr = false;

                        newState.form.touched.recipient_addresses_country_key = false;
                        newState.form.touched.recipient_addresses_country_name = false;
                        newState.form.touched.recipient_addresses_city = false;
                        newState.form.touched.recipient_addresses_street_address = false;
                        newState.form.touched.recipient_addresses_zip = false;

                        if (json_data.attributes.recipient_object_store_id > 0) {

                            newState.flag_form_or_list = 'list';

                        }

                        this.setState(newState);

                        /*
                        * Success notification recipient saved
                        */

                        let message = translation[this.state.language][this.module_name].notification_recipient_saved;

                        notification.addNotification(
                            {
                                title: "Success",
                                message: message,
                                type: "success",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animated", "fadeIn"],
                                animationOut: ["animated", "fadeOut"],
                                dismiss: {
                                    duration: 1000,
                                    onScreen: true
                                }
                            }
                        );

                        return true;

                    }
                )
                .catch(
                    (data) => {

                        ConsoleService('ModuleRecipients recipientFormSubmit()  this.idb.add_or_update() data catch', this.flag_console_log);

                        ConsoleService('data', this.flag_console_log);
                        ConsoleService(data, this.flag_console_log);

                        return false;

                    }
                );

        } else {

            /*
            * error
            */

            ConsoleService('recipient form submit validation => !validated', this.flag_console_log);

        }

    }

    /*
    * submit / stop
    */

    /*
    *
    * form recipient
    *
    * stop
    *
    */

    /* DELIMITER */

    form_or_list_handler = (payload = {}) => {

        ConsoleService("ModuleRecipients / callback_function() payload", this.flag_console_log);
        ConsoleService(payload, this.flag_console_log);

        let newState = Object.assign({}, this.state);

        if (payload.hasOwnProperty('recipient_object_store_id')) {

            if (payload.recipient_object_store_id > 0) {

                /*
                * update recipient
                */

                ConsoleService("ModuleRecipients / update recipient", this.flag_console_log);

                Object.keys(payload).map(
                    (attribute) => {

                        ConsoleService('attribute', this.flag_console_log);
                        ConsoleService(attribute, this.flag_console_log);

                        newState.form.attributes[attribute] = payload[attribute];

                        if (attribute === 'recipient_account_type') {

                            newState.form.select.recipient_account_type = this.set_recipient_account_type(payload[attribute]);
                        }

                        if (attribute === 'recipient_addresses_country_key') {

                            newState.form.select.recipient_addresses_country_key = this.set_recipient_country_key(payload[attribute]);
                        }

                        return newState.form;

                    }
                );

            }

        } else {

            /*
            * add recipient
            */

            ConsoleService("ModuleRecipients / add recipient", this.flag_console_log);

            newState.form.attributes.recipient_object_store_id = 0;

            newState.form.select.recipient_account_type = null;
            newState.form.select.recipient_addresses_country_key = null;

            newState.form.attributes.recipient_company_name = '';
            newState.form.attributes.recipient_vat_nr = '';
            newState.form.attributes.recipient_email = '';
            newState.form.attributes.recipient_phone_nr = '';
            newState.form.attributes.recipient_addresses_street_address = '';
            newState.form.attributes.recipient_addresses_city = '';
            newState.form.attributes.recipient_addresses_zip = '';

        }

        let flag_form_or_list = this.state.flag_form_or_list === 'list' ? 'form' : 'list';

        newState.flag_form_or_list = flag_form_or_list;

        this.setState(newState);

    }

    /* DELIMITER */

    callback_function = child_data => {

        ConsoleService("ModuleRecipients / callback_function()", this.flag_console_log);

        ConsoleService("ModuleRecipients / callback_function() / child data", this.flag_console_log);
        ConsoleService(child_data, this.flag_console_log);

        this.props.parent_callback_function_return_recipient_data(child_data);

    };

    /* DELIMITER */

    set_recipient_account_type = (payload) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleRecipients / set_recipient_account_type', this.flag_console_log);

        ConsoleService('payload', this.flag_console_log);
        ConsoleService(payload, this.flag_console_log);

        let option = {};

        Object.keys(translation[this.state.language].general.recipient_account_type_select_option).map(
            (index) => {

                ConsoleService('ModuleRecipients / set_recipient_account_type / object.keys.map / index', this.flag_console_log);
                ConsoleService(index, this.flag_console_log);

                ConsoleService('ModuleRecipients / set_recipient_account_type / object.keys.map / object', this.flag_console_log);
                ConsoleService(translation[this.state.language].general.recipient_account_type_select_option[index], this.flag_console_log);

                if (
                    translation[this.state.language].general.recipient_account_type_select_option[index].value === payload
                ) {
                    option = translation[this.state.language].general.recipient_account_type_select_option[index];
                }

                return true;

            }
        );

        return option;

    }

    /* DELIMITER */

    set_recipient_country_key = (payload) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleRecipients / set_recipient_country_key', this.flag_console_log);

        ConsoleService('payload', this.flag_console_log);
        ConsoleService(payload, this.flag_console_log);

        let option = {};

        Object.keys(arr_country).map(
            (index) => {

                ConsoleService('ModuleRecipients / set_recipient_country_key / object.keys.map / index', this.flag_console_log);
                ConsoleService(index, this.flag_console_log);

                ConsoleService('ModuleRecipients / set_recipient_country_key / object.keys.map / object', this.flag_console_log);
                ConsoleService(arr_country[index], this.flag_console_log);

                if (
                    arr_country[index].value === payload
                ) {
                    option = arr_country[index];
                }

                return true;

            }
        );

        return option;

    }

    /* DELIMITER */

    set_recipients_list_per_page = (payload) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleRecipients / set_recipients_list_per_page', this.flag_console_log);

        ConsoleService('payload', this.flag_console_log);
        ConsoleService(payload, this.flag_console_log);

        let option = {};

        Object.keys(arr_items_perPage).map(
            (index) => {

                ConsoleService('ModuleRecipients / set_recipients_list_per_page / object.keys.map / index', this.flag_console_log);
                ConsoleService(index, this.flag_console_log);

                ConsoleService('ModuleRecipients / set_recipients_list_per_page / object.keys.map / object', this.flag_console_log);
                ConsoleService(arr_items_perPage[index], this.flag_console_log);

                if (
                    arr_items_perPage[index].value === payload
                ) {
                    option = arr_items_perPage[index];
                }

                return true;

            }
        );

        return option;

    }

    /* DELIMITER */

    set_recipient_account_type_name = (payload) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleRecipients / set_recipient_account_type_name()', this.flag_console_log);

        ConsoleService('ModuleRecipients / set_recipient_account_type_name() / payload', this.flag_console_log);
        ConsoleService(payload, this.flag_console_log);

        let option = {};

        Object.keys(translation[this.state.language].general.recipient_account_type_select_option).map(
            (index) => {

                ConsoleService('ModuleRecipients / set_recipient_account_type_name() / object.keys.map / index', this.flag_console_log);
                ConsoleService(index, this.flag_console_log);

                ConsoleService('ModuleRecipients / set_recipient_account_type_name() / object.keys.map / object', this.flag_console_log);
                ConsoleService(translation[this.state.language].general.recipient_account_type_select_option[index], this.flag_console_log);

                if (
                    translation[this.state.language].general.recipient_account_type_select_option[index].value === payload// individual === individual
                ) {
                    option = translation[this.state.language].general.recipient_account_type_select_option[index].label;// Individual
                }

                return true;

            }
        );

        return option;

    }

    /* DELIMITER */

    render() {

        const shouldMarkError = (field) => {

            //ConsoleService('', this.flag_console_log);
            //ConsoleService('shouldMarkError', this.flag_console_log);

            //ConsoleService('field', this.flag_console_log);
            //ConsoleService(field, this.flag_console_log);

            const hasError = this.state.form.errors[field];

            //ConsoleService('hasError', this.flag_console_log);
            //ConsoleService(hasError, this.flag_console_log);

            const shouldShow = this.state.form.touched[field];

            //ConsoleService('shouldShow', this.flag_console_log);
            //ConsoleService(shouldShow, this.flag_console_log);

            return hasError ? shouldShow : false;

            //return true;// force error

            //

        }

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleRecipients render', this.flag_console_log);

        ConsoleService('this.state');
        ConsoleService(this.state);

        var w = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;

        ConsoleService('window width', this.flag_console_log);
        ConsoleService(w, this.flag_console_log);

        var h = window.innerHeight
            || document.documentElement.clientHeight
            || document.body.clientHeight;

        ConsoleService('window height', this.flag_console_log);
        ConsoleService(h, this.flag_console_log);

        let recipient_items = null;

        let placeholders = {

            // recipient account type
            recipient_account_type: translation[this.state.language][this.module_name].placeholder_recipient_account_type,

            // recipient addresses country key
            recipient_addresses_country_key: translation[this.state.language][this.module_name].placeholder_recipient_addresses_country_key,

            // recipient company name
            recipient_company_name: translation[this.state.language][this.module_name].placeholder_recipient_company_name,

            // recipient vat nr
            recipient_vat_nr: translation[this.state.language][this.module_name].placeholder_recipient_vat_nr,

            // recipient email
            recipient_email: translation[this.state.language][this.module_name].placeholder_recipient_email,

            // recipient phone nr
            recipient_phone_nr: translation[this.state.language][this.module_name].placeholder_recipient_phone_nr,

            // recipient addresses city
            recipient_addresses_city: translation[this.state.language][this.module_name].placeholder_recipient_addresses_city,

            // recipient addresses street address
            recipient_addresses_street_address: translation[this.state.language][this.module_name].placeholder_recipient_addresses_street_address,

            // recipient addresses zip
            recipient_addresses_zip: translation[this.state.language][this.module_name].placeholder_recipient_addresses_zip,

        };

        if (
            this.state.recipient_items.length
        ) {

            ConsoleService('this.state.recipient_items', this.flag_console_log);
            ConsoleService(this.state.recipient_items, this.flag_console_log);

            let recipient_items_length = this.state.recipient_items.length;

            let parsed_index = 0;

            let current_recipients = this.state.recipient_items.slice(this.state.recipients_list_offset, this.state.recipients_list_offset + this.state.form.select.recipients_list_perPage.value);

            ConsoleService('render () current_recipients', this.flag_console_log);
            ConsoleService(current_recipients, this.flag_console_log);

            recipient_items = (
                <React.Fragment>

                    {/* recipients list a.k.a. "items" / stop */}

                    <table className="table table-bordered table-striped table-hower" id="recipient_items">

                        <thead>

                            <tr>

                                <th>{translation[this.state.language][this.module_name].label_company_name}</th>

                                <th>{translation[this.state.language][this.module_name].label_vat_nr}</th>

                                <th>{translation[this.state.language][this.module_name].label_email}</th>

                                <th>{translation[this.state.language][this.module_name].label_phone_nr}</th>

                                <th>{translation[this.state.language][this.module_name].label_addresses_country_key}</th>

                                <th>{translation[this.state.language][this.module_name].label_addresses_city}</th>

                                <th>{translation[this.state.language][this.module_name].label_addresses_zip}</th>

                                <th>{translation[this.state.language]['general'].actions}</th>

                            </tr>

                        </thead>

                        <tbody>

                            {

                                current_recipients.map(
                                    (item, index) => {

                                        ConsoleService('item ModuleRecipients', this.flag_console_log);
                                        ConsoleService(item, this.flag_console_log);

                                        ConsoleService('index ModuleRecipients', this.flag_console_log);
                                        ConsoleService(index, this.flag_console_log);

                                        parsed_index = parsed_index + 1;

                                        return (
                                            <tr key={index}>
                                                <ElementItemRecipient
                                                    key={index}
                                                    item={item}
                                                    recipient_items_length={recipient_items_length}
                                                    parsed_index={parsed_index}
                                                    parent_callback_function={this.callback_function}
                                                    language={this.state.language}
                                                    edit={this.form_or_list_handler} />
                                            </tr>
                                        )
                                    }

                                )

                            }

                        </tbody>

                    </table >

                    {/* recipients list a.k.a. "items" / stop */}

                </React.Fragment>
            )

        } else {
            recipient_items = (
                <div>
                    <span>{translation[this.state.language][this.module_name].no_recipients_label}</span>
                </div>
            )
        }

        return (
            <div id="ModuleRecipients">
                {/* ModuleRecipients */}

                {

                    /* list / start */

                    this.state.flag_form_or_list === 'list' ?

                        <div className="row">

                            <div className="col-6">

                                <div className="m5 p5">

                                    <h1>{translation[this.state.language][this.module_name].recipients_label} ( {this.state.recipient_items_count} )</h1>

                                </div>

                            </div>

                            <div className="col-6">

                                <div className="m5 p5">

                                    <button className="btn btn-primary btn-recipient" onClick={this.form_or_list_handler}>
                                        {this.state.flag_form_or_list === 'list' ? '+ ' + this.state.button_add_recipient_label : this.state.button_show_recipients_label}
                                    </button>

                                </div>

                            </div>

                            {/* DELIMITER */}

                            <div className="col-12">

                                <div className="container-alert">

                                    <div className="alert alert-success" role="alert">
                                        <h4 className="alert-heading">Info ;)</h4>
                                        <p>text goes here</p>
                                    </div>

                                </div>

                            </div>

                            {/* DELIMITER */}

                            {/* recipients list container / start */}

                            <div className="col-12">

                                {this.state.recipient_items.length ?

                                    <div className="m5 p5">

                                        <ReactSelect
                                            id="recipients_list_perPage"
                                            name="recipients_list_perPage"
                                            className=""
                                            value={this.state.form.select.recipients_list_perPage}
                                            onChange={this.formSelectRecipientsListPerPageChangedHandler}
                                            options={this.state.form.select.recipients_list_perPage_select_options} />

                                    </div>

                                    : null}

                                <div id="recipients-container">

                                    {recipient_items}

                                </div>

                                {

                                    this.state.recipients_list_pageCount > 1 ?

                                        /* ReactPaginate / start */

                                        <div className="m5 p5">

                                            <ReactPaginate
                                                previousLabel={translation[this.state.language].general.previous}
                                                nextLabel={translation[this.state.language].general.next}
                                                breakLabel={'...'}
                                                breakClassName={'break-me'}
                                                breakLinkClassName={'page-link'}
                                                pageCount={this.state.recipients_list_pageCount}
                                                forcePage={this.state.recipients_list_currentPage}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={this.handlePageClick}
                                                containerClassName={'pagination page-' + this.state.recipients_list_pageCount}
                                                subContainerClassName={'pages pagination'}
                                                activeClassName={'active'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link'}
                                                previousLinkClassName={'page-link'}
                                                nextLinkClassName={'page-link'}
                                            />

                                        </div>

                                        /* ReactPaginate / stop */

                                        : null

                                }

                            </div>

                            {/* recipients list container / stop */}

                        </div>

                        : null

                    /* list / stop */

                }

                {

                    /* recipient form container / start */

                    this.state.flag_form_or_list === 'form' ?

                        <div>

                            <div className="m5 p5">

                                <h1>{translation[this.state.language][this.module_name].recipients_label}</h1>

                            </div>

                            <div className="">

                                <div className="container-alert">

                                    <div className="alert alert-success" role="alert">
                                        <h4 className="alert-heading">Info ;)</h4>
                                        <p>text goes here</p>
                                    </div>

                                </div>

                            </div>

                            <div className="align-center">

                                <div className="recipientForm" id="outerRecipientFormContainer">

                                    <div id="recipientFormContainer">

                                        <div className="m5 p5">
                                            <h2>{translation[this.state.language].general.form_company_info_label}</h2>
                                        </div>

                                        <div id="innerRecipientFormContainer">

                                            {/* form recipient / start */}
                                            <Form id="recipientForm" onSubmit={this.recipientFormSubmit}>

                                                <div className="m5 p5">

                                                    <table id="recipient" className="left">

                                                        <tbody>

                                                            {/* row form recipient account type / start */}
                                                            <tr>

                                                                <td>

                                                                    {/* recipient account type / start */}
                                                                    <div className="m5">

                                                                        {/**!!DO NOT DELETE!! code may be used in the future */}
                                                                        {/*
                                                                            <ReactSelect
                                                                                id="recipient_account_type"
                                                                                name="recipient_account_type"
                                                                                value={this.state.form.select.recipient_account_type}
                                                                                onChange={this.formSelectElementRecipientAccountTypeChangedHandler}
                                                                                options={this.state.form.select.recipient_account_type_select_options}
                                                                                placeholder={placeholders.recipient_account_type}
                                                                            />
                                                                            */}

                                                                        <div className={(shouldMarkError('recipient_account_type') ? "field_error " : "")}>

                                                                            <div>{translation[this.state.language][this.module_name].recipient_account_type_label}:</div>

                                                                            <div className="recipient_input_radio">

                                                                                <input type="radio"
                                                                                    id="individual"
                                                                                    name="recipient_account_type"
                                                                                    value={'individual'}
                                                                                    checked={this.state.form.attributes.recipient_account_type === 'individual'}
                                                                                    onChange={this.formRadioInputElementRecipientAccountTypeChangedHandler} />

                                                                                <label htmlFor="individual">
                                                                                    {translation[this.state.language][this.module_name].recipient_account_type_select_option[0].label}
                                                                                </label>

                                                                                <input type="radio"
                                                                                    id="company"
                                                                                    name="recipient_account_type"
                                                                                    value={'company'}
                                                                                    checked={this.state.form.attributes.recipient_account_type === 'company'}
                                                                                    onChange={this.formRadioInputElementRecipientAccountTypeChangedHandler} />

                                                                                <label htmlFor="company">
                                                                                    {translation[this.state.language][this.module_name].recipient_account_type_select_option[1].label}
                                                                                </label>

                                                                            </div>

                                                                        </div>

                                                                    </div>
                                                                    {/* recipient account type / stop */}

                                                                </td>
                                                            </tr>
                                                            {/* row form recipient account type / stop */}

                                                            {/* row form recipient company name / start */}
                                                            <tr>

                                                                <td>

                                                                    <Form.Group controlId="container_recipient_company_name_input">

                                                                        <Form.Control
                                                                            type="text"
                                                                            name="recipient_company_name"
                                                                            className={(shouldMarkError('recipient_company_name') ? "field_error " : "")}
                                                                            autoComplete="off"
                                                                            value={this.state.form.attributes.recipient_company_name}
                                                                            onChange={this.formElementChangedHandler}
                                                                            placeholder={placeholders.recipient_company_name} />

                                                                        {

                                                                            this.state.form.errors.recipient_company_name === true &&
                                                                                this.state.form.touched.recipient_company_name === true ?

                                                                                <Form.Text className="text-danger">
                                                                                    {this.state.form.errors_as_text['recipient_company_name']}
                                                                                </Form.Text>

                                                                                : null

                                                                        }

                                                                    </Form.Group>

                                                                </td>

                                                            </tr>
                                                            {/* row form recipient company name / stop */}

                                                            {/* row form recipient vat nr / start */}
                                                            <tr>

                                                                <td>

                                                                    <Form.Group controlId="container_recipient_vat_nr_input">

                                                                        <Form.Control
                                                                            type="text"
                                                                            name="recipient_vat_nr"
                                                                            className={(shouldMarkError('recipient_vat_nr') ? "field_error " : "")}
                                                                            autoComplete="off"
                                                                            value={this.state.form.attributes.recipient_vat_nr}
                                                                            onChange={this.formElementChangedHandler}
                                                                            placeholder={placeholders.recipient_vat_nr} />

                                                                        {

                                                                            this.state.form.errors.recipient_vat_nr === true &&
                                                                                this.state.form.touched.recipient_vat_nr === true ?

                                                                                <Form.Text className="text-danger">
                                                                                    {this.state.form.errors_as_text['recipient_vat_nr']}
                                                                                </Form.Text>

                                                                                : null

                                                                        }

                                                                    </Form.Group>

                                                                </td>

                                                            </tr>
                                                            {/* row form recipient vat nr / stop */}

                                                            {/* row form recipient email / start */}
                                                            <tr>

                                                                <td>

                                                                    <Form.Group controlId="container_recipient_email_input">

                                                                        <Form.Control
                                                                            type="text"
                                                                            name="recipient_email"
                                                                            className={(shouldMarkError('recipient_email') ? "field_error " : "")}
                                                                            autoComplete="off"
                                                                            value={this.state.form.attributes.recipient_email}
                                                                            onChange={this.formElementChangedHandler}
                                                                            placeholder={placeholders.recipient_email} />

                                                                        {

                                                                            this.state.form.errors.recipient_email === true &&
                                                                                this.state.form.touched.recipient_email === true ?

                                                                                <Form.Text className="text-danger">
                                                                                    {this.state.form.errors_as_text['recipient_email']}
                                                                                </Form.Text>

                                                                                : null

                                                                        }

                                                                    </Form.Group>

                                                                </td>

                                                            </tr>
                                                            {/* row form recipient email / stop */}

                                                            {/* row form recipient phone nr / start */}
                                                            <tr>

                                                                <td>

                                                                    <div className="m5">

                                                                        <input
                                                                            type="text"
                                                                            id="recipient_phone_nr"
                                                                            name="recipient_phone_nr"
                                                                            className={(shouldMarkError('recipient_phone_nr') ? "field_error " : "")}
                                                                            autoComplete="off"
                                                                            value={this.state.form.attributes.recipient_phone_nr}
                                                                            onChange={this.formElementChangedHandler}
                                                                            placeholder={placeholders.recipient_phone_nr} />

                                                                    </div>

                                                                </td>
                                                            </tr>
                                                            {/* row form recipient phone nr / stop */}


                                                            {/* row form recipient addresses / country key / start */}
                                                            <tr>

                                                                <td>

                                                                    <div className="m5">

                                                                        <ReactSelect
                                                                            id="recipient_addresses_country_key"
                                                                            name="recipient_addresses_country_key"
                                                                            value={this.state.form.select.recipient_addresses_country_key}
                                                                            className={(shouldMarkError('recipient_addresses_country_key') ? "field_error " : "")}
                                                                            onChange={this.formSelectElementRecipientAddressesCountryKeyChangedHandler}
                                                                            options={this.state.form.select.recipient_addresses_country_key_select_options}
                                                                            placeholder={placeholders.recipient_addresses_country_key} />

                                                                    </div>

                                                                </td>

                                                            </tr>
                                                            {/* row form recipient addresses / country key / stop */}

                                                            {/* row form recipient addresses / city / start */}
                                                            <tr>

                                                                <td>

                                                                    <div className="m5">

                                                                        <input
                                                                            type="text"
                                                                            id="recipient_addresses_city"
                                                                            name="recipient_addresses_city"
                                                                            className={(shouldMarkError('recipient_addresses_city') ? "field_error " : "")}
                                                                            autoComplete="off"
                                                                            value={this.state.form.attributes.recipient_addresses_city}
                                                                            onChange={this.formElementChangedHandler}
                                                                            placeholder={placeholders.recipient_addresses_city} />

                                                                    </div>

                                                                </td>

                                                            </tr>
                                                            {/* row form recipient addresses / city / stop */}

                                                            {/* row form recipient addresses / street address / start */}
                                                            <tr>

                                                                <td>

                                                                    <div className="m5">

                                                                        <input
                                                                            type="text"
                                                                            id="recipient_addresses_street_address"
                                                                            name="recipient_addresses_street_address"
                                                                            className={(shouldMarkError('recipient_addresses_street_address') ? "field_error " : "")}
                                                                            autoComplete="off"
                                                                            value={this.state.form.attributes.recipient_addresses_street_address}
                                                                            onChange={this.formElementChangedHandler}
                                                                            placeholder={placeholders.recipient_addresses_street_address} />

                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            {/* row form recipient addresses / street address / stop */}

                                                            {/* row form recipient addresses / zip / start */}
                                                            <tr>

                                                                <td>

                                                                    <div className="m5">

                                                                        <input
                                                                            type="text"
                                                                            id="recipient_addresses_zip"
                                                                            name="recipient_addresses_zip"
                                                                            className={(shouldMarkError('recipient_addresses_zip') ? "field_error " : "")}
                                                                            autoComplete="off"
                                                                            value={this.state.form.attributes.recipient_addresses_zip}
                                                                            onChange={this.formElementChangedHandler}
                                                                            placeholder={placeholders.recipient_addresses_zip} />

                                                                    </div>

                                                                </td>

                                                            </tr>
                                                            {/* row form recipient addresses / zip / stop */}

                                                            <tr>

                                                                <td>

                                                                    <div className="m5">

                                                                        <button className="btn btn-primary btn-sm col-12" onClick={this.recipientFormSubmit}>
                                                                            {this.state.button_save_recipient_label}
                                                                        </button>

                                                                    </div>

                                                                </td>

                                                            </tr>

                                                        </tbody>

                                                    </table>

                                                </div>

                                            </Form>
                                            {/* form recipient / stop */}

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                        : null

                    /* recipient form container / stop */

                }

                {/* /ModuleRecipients */}
            </div >
        );

    }

}

export default ModuleRecipients;