const form_attributes = {

    /*
    *
    * provider attributes
    * 
    * start
    * 
    */

    provider_company_name: '',// ok 20.11.2019
    provider_vat_nr: '',// ok 20.11.2019
    provider_phone_nr: '',// ok 20.11.2019
    provider_bank_account: '',// ok 20.11.2019
    provider_bank_name: '',// ok 20.11.2019

    provider_address_country_key: '',// ok 20.11.2019
    provider_address_county: '',// ok 20.11.2019
    provider_address_city: '',// ok 20.11.2019
    provider_address_street_address: '',// ok 20.11.2019
    provider_address_zip: '',// ok 20.11.2019

    /*
    *
    * provider attributes
    * 
    * stop
    * 
    */

};

export default form_attributes;