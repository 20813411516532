/*
 *
 * custom components
 *
 * start
 *
 */

import ConsoleService from './consoleServiceModules/ConsoleService';

import ConsoleServiceIndexedDbInvoiceUpdateComponent from "./consoleServiceModules/ConsoleServiceIndexedDbInvoiceUpdateComponent";

import { indexed_db_name, indexed_db_version } from '../IndexedDbComponent';

import get_country_name from './dataProviders/get_country_name_data';// ok 25.11.2019

/*
 *
 * custom components
 *
 * stop
 *
 */

const execute = (payload) => {

    let flag_console_log = ConsoleServiceIndexedDbInvoiceUpdateComponent;

    ConsoleService('', flag_console_log);
    ConsoleService('IndexedDbInvoiceUpdateComponent', flag_console_log);

    ConsoleService('IndexedDbInvoiceUpdateComponent / payload', flag_console_log);
    ConsoleService(payload, flag_console_log);

    let idb_name = indexed_db_name;

    ConsoleService('IndexedDbInvoiceUpdateComponent / IndexedDb / idb name', flag_console_log);
    ConsoleService(idb_name, flag_console_log);

    let version = indexed_db_version;

    ConsoleService('IndexedDbInvoiceUpdateComponent / IndexedDb / version', flag_console_log);
    ConsoleService(version, flag_console_log);

    return new Promise(
        (resolve, reject) => {

            ConsoleService('IndexedDbInvoiceUpdateComponent / new promise', flag_console_log);

            let indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;

            /*
             *
             * request
             *
             * start
             * 
             */

            let request = indexedDB.open(idb_name, version);

            /*
             * request / on success / callback
             *
             * start
             * 
             */

            request.onsuccess = function (event) {

                ConsoleService('IndexedDbInvoiceUpdateComponent / request / on success', flag_console_log);

                ConsoleService('IndexedDbInvoiceUpdateComponent / request / on success / event', flag_console_log);
                ConsoleService(event, flag_console_log);

                let db = event.target.result;

                /*
                 *
                 * transaction
                 *
                 * start
                 * 
                 */

                /*
                 *
                 * transaction
                 * 
                 * open a read/write db transaction, ready for adding the data
                 * 
                 * https://developer.mozilla.org/en-US/docs/Web/API/IDBDatabase/transaction
                 * 
                 */

                let transaction = db.transaction(['invoice_object_store'], 'readwrite');

                ConsoleService('IndexedDbInvoiceUpdateComponent / request / on success / transaction', flag_console_log);
                ConsoleService(transaction, flag_console_log);

                /*
                 * report on the success of opening the transaction
                 */

                transaction.oncomplete = function (event) {

                    ConsoleService('IndexedDbInvoiceUpdateComponent / request / on success / transaction / on complete', flag_console_log);

                    ConsoleService('IndexedDbInvoiceUpdateComponent / request / on success / transaction / on complete / event', flag_console_log);
                    ConsoleService(event, flag_console_log);

                    // !! SHOULD THE "RESOLVE" EVENT BE HERE ? !!

                    resolve(event);

                }

                transaction.onerror = function (event) {

                    ConsoleService('IndexedDbInvoiceUpdateComponent / request / on success / transaction / on error', flag_console_log);

                    ConsoleService('IndexedDbInvoiceUpdateComponent / request / on success / transaction / on error / event', flag_console_log);
                    ConsoleService(event, flag_console_log);

                    reject(event);

                }

                /*
                 *
                 * transaction
                 *
                 * stop
                 * 
                 */

                /*
                 *
                 * object store
                 *
                 * start
                 * 
                 */

                let objectStore = transaction.objectStore('invoice_object_store');

                ConsoleService('store', flag_console_log);
                ConsoleService(objectStore, flag_console_log);

                ConsoleService('IndexedDbInvoiceUpdateComponent / objectStore.indexNames', flag_console_log);
                ConsoleService(objectStore.indexNames, flag_console_log);

                ConsoleService('IndexedDbInvoiceUpdateComponent / objectStore.keyPath', flag_console_log);
                ConsoleService(objectStore.keyPath, flag_console_log);

                ConsoleService('IndexedDbInvoiceUpdateComponent / objectStore.name', flag_console_log);
                ConsoleService(objectStore.name, flag_console_log);

                ConsoleService('IndexedDbInvoiceUpdateComponent / objectStore.transaction', flag_console_log);
                ConsoleService(objectStore.transaction, flag_console_log);

                ConsoleService('IndexedDbInvoiceUpdateComponent / objectStore.autoIncrement', flag_console_log);
                ConsoleService(objectStore.autoIncrement, flag_console_log);

                /*
                 *
                 * object store request "objectStoreRequest"
                 *
                 * start
                 * 
                 */

                var item = {

                    // because we are on the "update scenario", we must set the PK
                    invoice_object_store_id: payload.attributes.invoice_object_store_id,

                    // set the FK from "recipient_object_store"
                    recipient_object_store_id: payload.attributes.recipient_object_store_id,

                    // we need this id, but we do not have it for now
                    recipient_external_account_id: 0,

                    // set the invoice serial attribute
                    invoice_serial: payload.attributes.invoice_serial,

                    // set the invoice number attribute
                    invoice_number: payload.attributes.invoice_number,

                    invoice_type: payload.attributes.invoice_type,

                    invoice_date: payload.attributes.invoice_date,

                    // this attribute should be READ ONLY
                    //flag_should_the_vat_be_taken_in_consideration: payload.attributes.flag_should_the_vat_be_taken_in_consideration,

                    /*
                    *
                    * provider attributes
                    * 
                    * start
                    *
                    */

                    provider_object_store_id: 1,// this attribute value should always be equal to 1

                    provider_external_id: payload.attributes.provider_external_id,

                    provider_company_name: payload.attributes.provider_company_name,
                    provider_vat_nr: payload.attributes.provider_vat_nr,
                    provider_phone_nr: payload.attributes.provider_phone_nr,
                    provider_bank_account: payload.attributes.provider_bank_account,
                    provider_bank_name: payload.attributes.provider_bank_name,

                    provider_address_country_key: payload.attributes.provider_address_country_key,
                    provider_address_country_name: get_country_name(payload.attributes.provider_address_country_key),

                    provider_address_county: payload.attributes.provider_address_county,
                    provider_address_city: payload.attributes.provider_address_city,
                    provider_address_street_address: payload.attributes.provider_address_street_address,
                    provider_address_zip: payload.attributes.provider_address_zip,

                    /*
                    *
                    * provider attributes
                    * 
                    * stop
                    *
                    */

                    //invoice_recipient_info: payload.invoice_recipient_info,// TOGO 29.10.2019

                    /*
                    * recipient attributes
                    * start
                    */

                    recipient_company_name: payload.attributes.recipient_company_name,

                    recipient_account_type: payload.attributes.recipient_account_type,
                    recipient_account_type_name: payload.attributes.recipient_account_type_name,

                    recipient_vat_nr: payload.attributes.recipient_vat_nr,
                    recipient_email: payload.attributes.recipient_email,
                    recipient_phone_nr: payload.attributes.recipient_phone_nr,

                    recipient_addresses_country_key: payload.attributes.recipient_addresses_country_key,
                    recipient_addresses_country_name: get_country_name(payload.attributes.recipient_addresses_country_key),

                    recipient_addresses_city: payload.attributes.recipient_addresses_city,
                    recipient_addresses_street_address: payload.attributes.recipient_addresses_street_address,
                    recipient_addresses_zip: payload.attributes.recipient_addresses_zip,

                    recipient_account_type_label: payload.labels.recipient_account_type_label,

                    /*
                    * recipient attributes
                    * stop
                    */

                    invoice_items: payload.attributes.invoice_items,

                    invoice_vat_type: payload.attributes.invoice_vat_type,

                    //invoice_subtotal: payload.attributes.invoice_subtotal,

                    //invoice_vat: payload.attributes.invoice_vat,
                    //invoice_vat_value: payload.attributes.invoice_vat_value,

                    invoice_total_without_vat: payload.attributes.invoice_total_without_vat,

                    invoice_total_vat: payload.attributes.invoice_total_vat,

                    invoice_total: payload.attributes.invoice_total,

                    //invoice_json_data: payload// TOGO 29.10.2019

                    /*
                    *
                    * setting the attributes labels
                    * 
                    * start
                    * 
                    */

                    invoice_number_label: payload.labels.invoice_number_label,

                    invoice_date_label: payload.labels.invoice_date_label,

                    item_description_label: payload.labels.item_description_label,

                    item_quantity_label: payload.labels.item_quantity_label,

                    item_price_label: payload.labels.item_price_label,

                    item_vat_label: payload.labels.item_vat_label,

                    //invoice_subtotal_label: payload.labels.invoice_subtotal_label,

                    //invoice_vat_label: payload.labels.invoice_vat_label,

                    invoice_subtotal_without_vat_for_vat_x: payload.labels.invoice_subtotal_without_vat_for_vat_x,
                    invoice_total_vat_for_vat_x: payload.labels.invoice_total_vat_for_vat_x,

                    invoice_total_without_vat_label: payload.labels.invoice_total_without_vat_label,

                    invoice_total_vat_label: payload.labels.invoice_total_vat_label,

                    invoice_total_label: payload.labels.invoice_total_label,

                    invoice_currency: payload.attributes.invoice_currency,

                    invoice_currency_symbol: payload.attributes.invoice_currency_symbol,

                    invoice_language: payload.attributes.invoice_language

                    /*
                    *
                    * setting the attributes labels
                    * 
                    * stop
                    * 
                    */

                };

                ConsoleService('IndexedDbInvoiceUpdateComponent / request / on success / item', flag_console_log);
                ConsoleService(item, flag_console_log);

                /*
                * we need to save the data
                * the invoice data will be updated
                */

                let objectStoreRequest = objectStore.put(item);

                /*
                 *
                 * objectStoreRequest / on success
                 *
                 * start
                 * 
                 */

                objectStoreRequest.onsuccess = function (event) {

                    ConsoleService('IndexedDbInvoiceUpdateComponent / request / on success / objectStoreRequest / on success', flag_console_log);

                    ConsoleService('IndexedDbInvoiceUpdateComponent / request / on success / objectStoreRequest / on success / event', flag_console_log);
                    ConsoleService(event, flag_console_log);

                    // !! SHOULD THE "RESOLVE" EVENT BE HERE ? !!

                    //resolve(event);

                }

                /*
                 *
                 * objectStoreRequest / on success
                 *
                 * stop
                 * 
                 */

                /*
                 *
                 * objectStoreRequest / on error
                 *
                 * start
                 * 
                 */

                objectStoreRequest.onerror = function (event) {

                    ConsoleService('IndexedDbInvoiceUpdateComponent / request / on success / objectStoreRequest / on error', flag_console_log);

                    ConsoleService('IndexedDbInvoiceUpdateComponent / request / on success / objectStoreRequest / on error / event', flag_console_log);
                    ConsoleService(event, flag_console_log);

                    reject(event);

                }

                /*
                 *
                 * objectStoreRequest / on error
                 *
                 * stop
                 * 
                 */

                /*
                 *
                 * object store request "objectStoreRequest"
                 *
                 * stop
                 * 
                 */

            }

            /*
             * request / on success / callback
             *
             * stop
             * 
             */

            /*
             * request / on error / callback
             *
             * start
             * 
             */

            request.onerror = function (event) {

                ConsoleService('IndexedDbInvoiceUpdateComponent / request / on error', flag_console_log);

                ConsoleService('IndexedDbInvoiceUpdateComponent / request / on error / event', flag_console_log);
                ConsoleService(event, flag_console_log);

                reject(event);

            }

            /*
             * request / on error / callback
             *
             * stop
             * 
             */

        }
    ).then(
        (data) => {

            ConsoleService('IndexedDbInvoiceUpdateComponent / new promise => then', flag_console_log);

            ConsoleService('IndexedDbInvoiceUpdateComponent / new promise => then / data', flag_console_log);
            ConsoleService(data, flag_console_log);

            return data;

        }
    ).catch(
        (data) => {

            ConsoleService('IndexedDbInvoiceUpdateComponent / new promise => catch', flag_console_log);

            ConsoleService('IndexedDbInvoiceUpdateComponent / new promise => catch / data', flag_console_log);
            ConsoleService(data, flag_console_log);

            return data;

        }
    );

    // !!! CAUTION !!! unreacheble code, because of return !!!

}

export default execute;