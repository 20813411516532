/*
*
* unofficial recipient attributes
* 
* !!! DO NOT TOUCH !!!
*
* we need access to this list when we load a recipient data from the "recipient module"
* 
* start
* 
*/

const unofficial_recipient_attributes = {

    recipient_object_store_id: 0,

    recipient_external_account_id: 0,

    recipient_addresses_is_billing: 1,
    recipient_addresses_is_delivery: 1,
    recipient_addresses_is_primary: 1,
    recipient_addresses_is_site: 1,

    recipient_is_customer: 1,
    recipient_is_supplier: 0

};

/*
*
* unofficial recipient attributes
* 
* stop
* 
*/

export default unofficial_recipient_attributes;