export default {

    general: {

        recipient_account_type_select_option: [
            {
                value: 'individual', label: 'Individual'
            },
            {
                value: 'company', label: 'Company'
            }
        ],

        actions: 'actions',
        previous: 'previous',
        next: 'next',

        invoice: {

            invoice_number: 'Invoice #',

            invoice_date: 'Date',

            invoice_company_name: 'Company',

            invoice_company_info: 'Company info',

            invoice_total_without_vat: 'Amount',

            invoice_total: 'Amount + VAT',

            invoice_subtotal_without_vat_for_vat_x: 'Subtotal for VAT',
            invoice_total_vat_for_vat_x: 'VAT for',

        },

        item_description: 'item description',

        months: [

            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'

        ],

        my_details: 'My details',
        button_save_label: 'Save',

        form_company_info_label: 'Company info',

        invalid_form: 'Invalid form. Please fill in data.',

        apply: 'Apply',

        no_data_available: 'No data available.',

        alert_header_info: 'Info',

        notification_info: 'Info',
        notification_success: 'Success',
        notification_error: 'Error',

        login: 'Login',
        close: 'Close',

    },

    ModuleBill: {

        button_providers_label: 'My details',
        button_invoices_label: 'Invoices',
        button_recipients_label: 'Recipients',

        notification_choose_akti: 'Choose a professional invoicing web service. Try akti',
        button_new_invoice_label: 'New invoice',

        button_login_label: 'Login',
        button_login_google_label: 'Login with google',
        button_login_facebook_label: 'Login with facebook',
        button_logout_label: 'Logout',

    },

    ModuleRecipients: {

        //add form
        recipient_company_name_label: 'Company name',
        recipient_vat_nr_label: 'VAT Nr',
        //form_recipient_reference_nr_label: 'Reference Nr',
        recipient_account_type_label: 'Recipient Account Type',
        //form_recipient_language_key_label: 'Language Key',
        recipient_email_label: 'Email',
        recipient_phone_nr_label: 'Phone Nr',
        //form_recipient_fax_nr_label: 'Fax Nr',
        //form_recipient_is_supplier_label: 'Is Supplier',
        //form_recipient_is_customer_label: 'Is Customer',

        recipient_addresses_label: 'Address',
        //form_recipient_company_info_label: 'Company info',
        //form_recipient_info_label: 'Recipient info',
        //form_recipient_addresses_is_billing_label: 'is billing',
        //form_recipient_addresses_is_primary_label: 'is primary',
        //form_recipient_addresses_is_delivery_label: 'is delivery',
        //form_recipient_addresses_is_site_label: 'is site',
        recipient_addresses_country_key_label: 'Country',
        //form_recipient_addresses_state_label: 'State',
        recipient_addresses_street_address_label: 'Street address',
        recipient_addresses_city_label: 'City',
        recipient_addresses_zip_label: 'Zip',

        recipients_label: 'Recipients',
        no_recipients_label: 'No recipients',
        button_add_recipient_label: 'add recipient',
        button_show_recipients_label: 'show the recipients',
        button_save_recipient_label: 'Save',
        select_option_company_use: 'Company',
        select_option_individual_use: 'Individual',
        select_option_yes: 'Yes',
        select_option_no: 'No',

        //notification messages
        notification_vat_nr: 'Please fill in vat nr ...',
        notification_email: 'Please fill in email ...',
        notification_phone_nr: 'Please fill in phone number ...',
        notification_addresses_city: 'Please fill in city ...',
        notification_addresses_street_address: 'Please fill in street address ...',
        notification_addresses_zip: 'Please fill in zip ...',
        notification_reference_nr: 'Please fill in reference nr ...',
        notification_recipient_saved: 'Recipient saved',
        notification_company_name: 'Please fill in company name ...',
        notification_account_type: 'Please select the account type ...',
        notification_addresses_country_key: 'Please select country ...',

        label_company_name: 'Company name',
        label_vat_nr: 'Vat nr.',
        label_account_type: 'Recipient type',
        label_email: 'Email',
        label_phone_nr: 'Phone nr.',
        label_addresses_country_key: 'Country',
        label_addresses_street_address: 'Street address',
        label_addresses_city: 'City',
        label_addresses_zip: 'Zip',

        recipient_account_type_select_option: [
            {
                value: 'individual', label: 'Individual'
            },
            {
                value: 'company', label: 'Company'
            }
        ],

        placeholder_recipient_account_type: 'select the account type',
        placeholder_recipient_addresses_country_key: 'select the country',
        placeholder_recipient_company_name: 'company name',
        placeholder_recipient_vat_nr: 'vat nr.',
        placeholder_recipient_email: 'email',
        placeholder_recipient_phone_nr: 'phone nr.',
        placeholder_recipient_addresses_city: 'city',
        placeholder_recipient_addresses_street_address: 'street address',
        placeholder_recipient_addresses_zip: 'zip',

    },

    ModuleInvoices: {
        invoices_label: 'Invoices',
        no_invoices_label: 'No invoices',
        recipient_company_name: 'Recipient company',
    },

    ModuleInvoice: {

        invoice_type: 'Invoice Type',

        invoice_number_label: 'Invoice #',
        invoice_date_label: 'Date',

        item_description_label: 'Item & Description',
        item_quantity_label: 'Quantity',
        item_price_label: 'Price',
        item_vat_label: 'VAT',

        invoice_subtotal_label: 'Subtotal',
        invoice_vat_label: 'VAT',

        invoice_total_without_vat_label: 'TOTAL without VAT',
        invoice_total_vat_label: 'TOTAL VAT',
        invoice_total_label: 'TOTAL',

        invoice_first_item_description: 'first item',

        invoice_item_description: 'undefined item',

        button_invoices_label: 'Invoices',
        button_recipients_label: 'Recipients',

        button_save_invoice_label: 'SAVE',
        //button_new_bill_label: 'New invoice',
        button_new_invoice_form_label: 'New invoice',
        button_try_external_tool_label: 'sync your invoices now',
        button_download_pdf_label: 'Download the invoice as PDF',

        recipient_account_type_select_option: [
            {
                value: 'individual', label: 'Individual'
            },
            {
                value: 'company', label: 'Company'
            }
        ],

        recipient_account_type_label: 'Recipient Account Type',

        invoice_subtotal_without_vat_for_vat_x: 'Subtotal for VAT',
        invoice_total_vat_for_vat_x: 'VAT for',

        /*
        *
        * provider placeholders
        *
        * start
        *
        */

        placeholder_provider_company_name: 'your "company name" goes here ...',
        placeholder_provider_vat_nr: 'provider vat nr',
        placeholder_provider_phone_nr: 'provider phone nr',
        placeholder_provider_bank_account: 'provider bank account',
        placeholder_provider_bank_name: 'provider bank_name',

        placeholder_provider_address_country_key: 'provider address country',
        placeholder_provider_address_county: 'provider address county',
        placeholder_provider_address_city: 'provider address city',
        placeholder_provider_address_street_address: 'provider address street address',
        placeholder_provider_address_zip: 'provider address zip',

        /*
        *
        * provider placeholders
        *
        * stop
        *
        */

        /*
        *
        * notification messages
        *
        * start
        * 
        */

        notification_invoice_data_loaded: 'Invoice data loaded',
        notification_choose_recipient: 'Please choose a recipient ...',

        /* DELIMITER */

        /*
        *
        * invoice notification messages
        *
        * start
        * 
        */

        notification_invoice_type: 'Please fill in the invoice type.',// ok 20.10.2019

        notification_invoice_company_name: 'Please fill in the company name.',// ok 20.10.2019
        notification_invoice_company_info: 'Please fill in the company info.',// ok 20.10.2019

        /*
        *
        * provider notifications
        * 
        * start
        * 
        */

        notification_provider_company_name: 'Please fill in the provider company name.',// ok 20.11.2019
        notification_provider_vat_nr: 'Please fill in the provider vat nr.',// ok 20.11.2019
        notification_provider_phone_nr: 'Please fill in the provider phone nr.',// ok 20.11.2019
        notification_provider_bank_account: 'Please fill in the provider bank account.',// ok 20.11.2019
        notification_provider_bank_name: 'Please fill in the provider bank name.',// ok 20.11.2019

        notification_provider_address_country_key: 'Please fill in the provider country.',// ok 20.11.2019
        notification_provider_address_county: 'Please fill in the provider county.',// ok 20.11.2019
        notification_provider_address_city: 'Please fill in the provider city.',// ok 20.11.2019
        notification_provider_address_street_address: 'Please fill in the provider street address.',// ok 20.11.2019
        notification_provider_address_zip: 'Please fill in the provider zip.',// ok 20.11.2019

        /*
        *
        * provider notifications
        * 
        * stop
        * 
        */

        notification_recipient_account_type: 'Please fill in the recipient account type.',// ok 20.10.2019
        notification_recipient_company_name: 'Please fill in the recipient company name.',// ok 20.10.2019
        notification_recipient_vat_nr: 'Please fill in the recipient vat nr.',// ok 20.10.2019
        notification_recipient_email: 'Please fill in the recipient email.',// ok 20.10.2019
        notification_recipient_phone_nr: 'Please fill in the recipient phone number.',// ok 20.10.2019
        notification_recipient_addresses_country_key: 'Please choose a country',// ok 20.10.2019
        notification_recipient_addresses_city: 'Please fill in the city.',// ok 20.10.2019
        notification_recipient_addresses_street_address: 'Please fill in the street address.',// ok 20.10.2019
        notification_recipient_addresses_zip: 'Please fill in the zip.',// ok 20.10.2019

        notification_invoice_number: 'Please fill in the invoice number.',// ok 20.10.2019
        notification_invoice_date: 'Please fill in the invoice date.',// ok 20.10.2019

        notification_invoice_vat: 'Please fill in the invoice vat.',// ok 20.10.2019

        /*
        *
        * invoice notification messages
        *
        * stop
        * 
        */

        /* DELIMITER */

        notification_bill_saved: 'Bill saved',
        notification_recipient_data_loaded: 'Recipient data loaded',

        /*
        *
        * notification messages
        *
        * stop
        * 
        */

        /*
        *
        * placeholders
        * 
        * start
        * 
        */

        /*
        *
        * placeholders for invoice
        *
        * start
        * 
        */

        placeholder_invoice_type: 'Please fill in the invoice type',// ok 30.10.2019
        placeholder_invoice_company_name: 'Your "company name" goes here ...',// ok 30.10.2019
        placeholder_invoice_company_info: 'Your "company info" goes here ...',// ok 30.10.2019

        placeholder_invoice_number: 'eg : inv 123',
        placeholder_invoice_number_label: 'Invoice #',

        placeholder_invoice_date: 'eg : 31 November 2019',
        placeholder_invoice_date_label: 'Date',

        /*
        *
        * placeholders for invoice
        *
        * stop
        * 
        */

        /*
        *
        * placeholders for recipient
        *
        * start
        * 
        */

        placeholder_recipient_account_type: 'select the account type',
        placeholder_recipient_company_name: 'select the company name',
        placeholder_recipient_vat_nr: 'vat nr.',
        placeholder_recipient_email: 'email',
        placeholder_recipient_phone_nr: 'phone nr.',
        placeholder_recipient_addresses_country_key: 'select the country',
        placeholder_recipient_addresses_city: 'city',
        placeholder_recipient_addresses_street_address: 'street address',
        placeholder_recipient_addresses_zip: 'zip',

        /*
        *
        * placeholders for recipient
        *
        * stop
        * 
        */

        placeholder_invoice_subtotal: 'Subtotal',
        placeholder_invoice_vat: 'VAT',

        placeholder_invoice_total_without_vat: 'TOTAL without VAT',
        placeholder_invoice_total_vat: 'TOTAL VAT',
        placeholder_invoice_total: 'Total',

        /*
        *
        * placeholders for invoice item labels
        *
        * start
        *
        */

        placeholder_item_description_label: 'Item & Description',
        placeholder_item_quantity_label: 'Quantity',
        placeholder_item_price_label: 'Price',
        placeholder_item_vat_label: 'VAT',

        /*
        *
        * placeholders for invoice item labels
        *
        * stop
        *
        */

        /*
        *
        * placeholders
        * 
        * stop
        * 
        */

    },

    ElementItemRecipient: {
        label_recipient_info: 'Recipient info',
        label_addresses: 'Address',
        label_button_load_data: 'load the above recipient data',
        label_button_edit_recipient: 'edit'
    },

    ElementItemInvoice: {

        company_info_label: 'company info label ...',
        company_name_label: 'company name label ...',
        invoice_date_label: 'invoice date label ...',
        invoice_number_label: 'invoice number label ...',
        invoice_type_label: 'invoice type label ...',
        recipient_info_label: 'recipient info label ...',
        subtotal_label: 'subtotal label ...',
        total_label: 'total label ...',
        vat_label: 'vat label ...',
        vat_value_label: 'vat value label ...',

        invoice_details_label: 'Invoice details',
        button_load_data: 'load the above invoice data',
        label_button_show_invoice: 'show',
        label_button_edit_invoice: 'edit',
        label_button_download_pdf_invoice: 'download PDF',
        label_button_receipts: 'receipts'

    },

    ModuleProvider: {

        /*
        *
        * provider placeholders
        *
        * start
        *
        */

        placeholder_provider_company_name: 'your "company name" goes here ...',
        placeholder_provider_vat_nr: 'provider vat nr',
        placeholder_provider_phone_nr: 'provider phone nr',
        placeholder_provider_bank_account: 'provider bank account',
        placeholder_provider_bank_name: 'provider bank_name',

        placeholder_provider_address_country_key: 'provider address country',
        placeholder_provider_address_county: 'provider address county',
        placeholder_provider_address_city: 'provider address city',
        placeholder_provider_address_street_address: 'provider address street address',
        placeholder_provider_address_zip: 'provider address zip',

        /*
        *
        * provider placeholders
        *
        * stop
        *
        */

        /*
        *
        * provider notifications
        * 
        * start
        * 
        */

        notification_provider_company_name: 'Please fill in the provider company name.',// ok 20.11.2019
        notification_provider_vat_nr: 'Please fill in the provider vat nr.',// ok 20.11.2019
        notification_provider_phone_nr: 'Please fill in the provider phone nr.',// ok 20.11.2019
        notification_provider_bank_account: 'Please fill in the provider bank account.',// ok 20.11.2019
        notification_provider_bank_name: 'Please fill in the provider bank name.',// ok 20.11.2019

        notification_provider_address_country_key: 'Please fill in the provider country.',// ok 20.11.2019
        notification_provider_address_county: 'Please fill in the provider county.',// ok 20.11.2019
        notification_provider_address_city: 'Please fill in the provider city.',// ok 20.11.2019
        notification_provider_address_street_address: 'Please fill in the provider street address.',// ok 20.11.2019
        notification_provider_address_zip: 'Please fill in the provider zip.',// ok 20.11.2019

        /*
        *
        * provider notifications
        * 
        * stop
        * 
        */

        notification_provider_saved: 'The provider data has been saved.',

    },

    settings: {

        settings: 'Settings',

        /* general settings / start */

        general_settings: 'General settings',// tab/h element

        general_settings_info_content: 'general settings info content',// alert content

        vat_status: 'VAT status',// option
        default_vat_value: 'The default VAT value',// option

        notification_success_update_vat_status: 'The VAT status has been updated.',// notification text
        notification_error_update_vat_status: 'Due to some errors, the VAT status could not be updated.',// notification text

        notification_success_update_variables_vat: 'The default VAT value has been updated.',// notification text
        notification_error_update_variables_vat: 'Due to some errors, the default VAT value could not be updated.',// notification text

        /* general settings / stop */

        /* langauges / start */

        languages: 'Languages',// tab/h element

        languages_info_content: 'languages info content',// alert content

        notification_success_update_language: 'The languages setting has been updated.',// notification text

        /* langauges / stop */

        /* invoices / start */

        invoices: 'Invoices',// tab element

        invoices_page_title: 'Invoices serials and numbers details',// h element

        invoices_info_content: 'settings invoices info content',

        invoices_serial: 'Serial',
        invoices_number: 'Number',

        notification_success_update_invoice_serial_and_number: 'The invoice serial number setting has been updated.',// notification text
        notification_error_update_invoice_serial_and_number: 'Due to some errors, the invoice serial number setting could not be updated.'// notification text

        /* invoices / stop */

    }

};