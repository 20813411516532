export default {

    checkValidity(rules, state, attribute_to_errors, attribute_to_word) {

        console.log('checkValidity');

        console.log('rules');
        console.log(rules);

        console.log('state');
        console.log(state);

        console.log('attribute_to_errors');
        console.log(attribute_to_errors);

        let responseObj = { 'has_errors': false, 'errors': {} };

        for (let x in rules) {

            for (let y in rules[x]) {

                console.log('attribute_to_errors');
                console.log(attribute_to_errors);

                let error_message = this.errorMessage(rules[x][y], x, state, attribute_to_errors, attribute_to_word);

                if (error_message) {

                    console.log('error message');
                    console.log(error_message);

                    if (!responseObj.has_errors) {

                        console.log('error');

                        responseObj.has_errors = true;

                        //

                    }

                    if (!responseObj.errors[x]) {

                        responseObj.errors[x] = [];

                        //

                    }

                    responseObj.errors[x].push(error_message);

                    break;
                }

            }

        }

        return responseObj;

    },

    errorMessage(type, attribute, state, attribute_to_errors, attribute_to_word) {

        console.log('type');
        console.log(type);

        console.log('attribute');
        console.log(attribute);

        console.log('state');
        console.log(state);

        console.log('attribute_to_errors');
        console.log(attribute_to_errors);

        var msg = "";

        switch (type) {

            case 'required':

                console.log('case required');

                if (
                    !state.hasOwnProperty(attribute) ||
                    state[attribute] === ''
                ) {

                    //msg = "The " + attribute + " field is required";

                    console.log('if');

                    msg = (attribute_to_errors[type]).replace('[attribute]', attribute_to_word[attribute]);

                    //
                }

                //
                break;

            case 'email':

                console.log('case email');

                //let EMAIL_REGEXP = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{2,}|[\d\.]+))$/;

                let EMAIL_REGEXP = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

                if (!EMAIL_REGEXP.test(state[attribute])) {

                    //msg = "The " + attribute + " field is not a valid email";

                    console.log('if');

                    msg = (attribute_to_errors[type]).replace('[attribute]', attribute_to_word[attribute]);

                    //

                }

                break;

            default:
                break;

        }

        return msg;

    }

}
