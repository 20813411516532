import { Component } from "react";
import Dexie from "dexie";

class DexieDbComponent extends Component {

    constructor(props) {
        super(props);
        var db = new Dexie('react_billable');
        db.version(10.6).stores({
            invoice_object_store: '++invoice_object_store_id,flag_should_the_vat_be_taken_in_consideration,invoice_date,invoice_number,&invoice_object_store_id,invoice_serial,invoice_type,provider_company_name,recipient_external_account_id,recipient_object_store_id',
            provider_object_store: '++provider_object_store_id,provider_company_name,provider_external_account_id,&provider_object_store_id',
            receipt_v2_object_store: '++receipt_object_store_id,invoice_object_store_id,receipt_number,&receipt_object_store_id,receipt_serial,recipient_company_name,recipient_object_store_id',
            recipient_object_store: '++recipient_object_store_id,recipient_company_name,recipient_external_account_id,&recipient_object_store_id',
            settings_object_store: '++settings_object_store_id,group,[group+key],key,&settings_object_store_id,value',
            event_log_object_store: '++event_log_object_store_id, object_type, object_id, timestamp, processed, action'
        });
    }
}

export default DexieDbComponent;