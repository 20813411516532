import ConsoleService from './consoleServiceModules/ConsoleService';

import IndexedDbLoadAllComponent from './IndexedDbLoadAllComponent';

import IndexedDbSettingsCreateComponent from './IndexedDbSettingsCreateComponent';

async function execute() {

    let flag_console_log = false;

    var settings_default = [
        {
            group: 'flag_available_option',
            key: 'vat',// tva
            value: 'active',
        },
        {
            group: 'variables',
            key: 'vat',// tva
            value: 0
        },
        {
            group: 'variables',
            key: 'invoice_type',
            value: 'Invoice Type'
        }
    ];

    var settings_available = [];

    if (
        settings_default.length
    ) {

        /*
    
        ok
    
        */

        await IndexedDbLoadAllComponent('settings_object_store')
            .then(
                (data) => {

                    /*
                    *
                    * ok
                    * 
                    */

                    ConsoleService('ModuleInvoice / IndexedDbLoadAllComponent / new promise then', flag_console_log);

                    ConsoleService('ModuleInvoice / IndexedDbLoadAllComponent / new promise => then / data', flag_console_log);
                    ConsoleService(data, flag_console_log);

                    if (
                        data.length > 0
                    ) {

                        /*
        
                        ok
        
                        */

                        for (let i = 0; i < data.length; i++) {

                            settings_available.push(data[i]);

                        }

                        //

                    }

                }
            )
            .catch(
                (data) => {

                    /*
                    *
                    * error
                    * 
                    */

                    ConsoleService('ModuleSettings / IndexedDbLoadAllComponent / new promise => catch', flag_console_log);

                    ConsoleService('ModuleSettings / IndexedDbLoadAllComponent / new promise => catch / data', flag_console_log);
                    ConsoleService(data, flag_console_log);

                }
            );

        //

    }

    if (settings_default.length) {

        /*

        we need to check each default setting

        */

        for (let i = 0; i < settings_default.length; i++) {

            let flag_found = false;

            if (
                settings_available.length
            ) {

                /*
                
                ok
                
                */

                for (let j = 0; j < settings_available.length; j++) {

                    /*

                    we need to check each available setting VS each default setting

                    */

                    if (
                        settings_default[i]['group'] === settings_available[j]['group'] &&
                        settings_default[i]['key'] === settings_available[j]['key']
                    ) {

                        /*
                        
                        ok

                        the setting is available
                        
                        */

                        flag_found = true;

                        //

                    }

                    //

                }

                //

            }

            if (
                flag_found === false
            ) {

                /*
                
                ok

                the setting is not available, we need to store the setting
                
                */

                IndexedDbSettingsCreateComponent(settings_default[i]);

                //

            }

            //

        }

        //

    }

    //

}

export default execute;