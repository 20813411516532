import attribute_errors_en from './attribute_errors_en';
import attribute_errors_fr from './attribute_errors_fr';
import attribute_errors_ro from './attribute_errors_ro';

const execute = (language) => {

    let obj = {
        'en': attribute_errors_en,
        'fr': attribute_errors_fr,
        'ro': attribute_errors_ro,
    };

    return obj[language];

}

export default execute;