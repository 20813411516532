import React, { Component } from 'react';

import "./../../css/ModuleSettings.css";

import ConsoleService from './../consoleServiceModules/ConsoleService';//ok 12.12.2019

import IndexedDbLoadAllComponent from './../IndexedDbLoadAllComponent';

import IndexedDbSettingsCreateOrUpdateComponent from './../IndexedDbSettingsCreateOrUpdateComponent';

import translation from './../../TranslationComponent';

/*
*
* "react notification component" docs available at :
*
* https://www.npmjs.com/package/react-notifications-component
*
*/

import { store as notification } from 'react-notifications-component';// ok 14.01.2019

class ModuleSettings_InvoiceSettings_Component extends Component {

    module_name = 'ModuleSettings_InvoiceSettings';

    flag_console_log = true;

    state = {};

    constructor(props) {

        super(props);

        ConsoleService('ModuleSettings_InvoiceSettings / constructor', this.flag_console_log);

        ConsoleService('props', this.flag_console_log);
        ConsoleService(props, this.flag_console_log);

        this.state = {

            language: props.language,

            arr_invoice_serial_and_number: []

        };

    }

    /* DELIMITER */

    componentDidMount() {

        //

        //console.log('ModuleSettings_InvoiceSettings / componentDidMount');

        //console.log('this.state');
        //console.log(this.state);

        this.loadAll();

        //

    }

    /* DELIMITER */

    componentDidUpdate(prevProps, prevState) {

        ConsoleService('ModuleSettings_InvoiceSettings / component did update', this.flag_console_log);

        ConsoleService('prevState', this.flag_console_log);
        ConsoleService(prevState, this.flag_console_log);

        ConsoleService('this.state', this.flag_console_log);
        ConsoleService(this.state, this.flag_console_log);

        ConsoleService('prevProps', this.flag_console_log);
        ConsoleService(prevProps, this.flag_console_log);

        ConsoleService('this.props', this.flag_console_log);
        ConsoleService(this.props, this.flag_console_log);

        if (
            this.props.hasOwnProperty('language')
        ) {

            // ok

            /*
            *
            * within the props, we have also received the "language" prop
            * 
            */

            ConsoleService('ModuleSettings_InvoiceSettings / component did update / props.language exists', this.flag_console_log);

            ConsoleService('props.language', this.flag_console_log);
            ConsoleService(this.props.language, this.flag_console_log);

            if (
                this.state.language !== this.props.language
            ) {

                // ok

                /*
                *
                * we need to update the translations
                * 
                */

                ConsoleService('ModuleSettings_InvoiceSettings / component did update / the translations will be updated', this.flag_console_log);

                let newState = Object.assign({}, this.state);

                newState.language = this.props.language;

                this.setState(newState);

                //

            }

            //

        }

        //

    }

    loadAll() {

        IndexedDbLoadAllComponent('settings_object_store')
            .then(
                (data) => {

                    /*
                    *
                    * ok
                    * 
                    */

                    ConsoleService('ModuleSettings_InvoiceSettings / IndexedDbLoadAllComponent / new promise then', this.flag_console_log);

                    ConsoleService('ModuleSettings_InvoiceSettings / IndexedDbLoadAllComponent / new promise => then / data', this.flag_console_log);
                    ConsoleService(data, this.flag_console_log);

                    if (
                        data.length > 0
                    ) {

                        /*
    
                        ok
    
                        */

                        let newState = Object.assign({}, this.state);

                        newState.arr_invoice_serial_and_number = [];

                        for (let i = 0; i < data.length; i++) {

                            //

                            if (
                                data[i]['group'] === 'invoice_serial_and_invoice_number'
                            ) {

                                //

                                newState.arr_invoice_serial_and_number.push(data[i]);

                                //

                            }

                            if (
                                data[i]['group'] === 'flag_available_option'
                            ) {

                                //

                                if (
                                    data[i]['key'] === 'vat'
                                ) {

                                    //

                                    newState.flag_available_option_vat = data[i]['value'];

                                    //

                                }

                                //

                            }

                            if (
                                data[i]['group'] === 'variables'
                            ) {

                                //

                                if (
                                    data[i]['key'] === 'vat'
                                ) {

                                    //

                                    newState.variables_vat = data[i]['value'];

                                    //

                                }

                                //

                            }

                            //

                        }

                        ConsoleService('newState', this.flag_console_log);
                        ConsoleService(newState, this.flag_console_log);

                        this.setState(newState);

                        //

                    }

                }
            )
            .catch(
                (data) => {

                    /*
                    *
                    * error
                    * 
                    */

                    ConsoleService('ModuleSettings_InvoiceSettings / IndexedDbLoadAllComponent / new promise => catch', this.flag_console_log);

                    ConsoleService('ModuleSettings_InvoiceSettings / IndexedDbLoadAllComponent / new promise => catch / data', this.flag_console_log);
                    ConsoleService(data, this.flag_console_log);

                }
            );

    }

    /* DELIMITER */

    itemOnChangeHandler = (event, itemIndex) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleSettings_InvoiceSettings / itemOnChangeHandler', this.flag_console_log);

        ConsoleService('event', this.flag_console_log);
        ConsoleService(event, this.flag_console_log);

        ConsoleService('event.target', this.flag_console_log);
        ConsoleService(event.target, this.flag_console_log);

        ConsoleService('event.target.name', this.flag_console_log);
        ConsoleService(event.target.name, this.flag_console_log);

        let name = event.target.name;

        ConsoleService('event.target.value', this.flag_console_log);
        ConsoleService(event.target.value, this.flag_console_log);

        let value = event.target.value;

        ConsoleService('itemIndex', this.flag_console_log);
        ConsoleService(itemIndex, this.flag_console_log);

        let newState = Object.assign({}, this.state);

        if (
            name === 'invoice_serial'
        ) {

            /*
            
            ok
            
            set the 'key' attribute value
            
            */

            newState.arr_invoice_serial_and_number[itemIndex].key = value;

            //

        }

        if (
            name === 'invoice_number'
        ) {

            /*
            
            ok
            
            set the 'value' attribute value
            
            */

            newState.arr_invoice_serial_and_number[itemIndex].value = value;

            //

        }

        this.setState(newState);

        //

    }

    /* DELIMITER */

    update_invoice_serial_and_number = (event, itemIndex) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleSettings_InvoiceSettings / update_invoice_serial_and_number', this.flag_console_log);

        ConsoleService('event', this.flag_console_log);
        ConsoleService(event, this.flag_console_log);

        ConsoleService('event.target', this.flag_console_log);
        ConsoleService(event.target, this.flag_console_log);

        ConsoleService('itemIndex', this.flag_console_log);
        ConsoleService(itemIndex, this.flag_console_log);

        /*
        
        update the setting record
        
        start
        
        */

        let payload = this.state.arr_invoice_serial_and_number[itemIndex];

        IndexedDbSettingsCreateOrUpdateComponent(payload)
            .then(
                (data) => {

                    /*
                    *
                    * ok
                    * 
                    */

                    ConsoleService('ModuleInvoice / IndexedDbSettingsCreateOrUpdateComponent / new promise then', this.flag_console_log);

                    ConsoleService('ModuleInvoice / IndexedDbSettingsCreateOrUpdateComponent / new promise => then / data', this.flag_console_log);
                    ConsoleService(data, this.flag_console_log);

                    notification.addNotification(
                        {
                            title: translation[this.state.language]['general']['notification_success'],
                            message: translation[this.state.language]['settings']['notification_success_update_invoice_serial_and_number'],
                            type: "success",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 4000,
                                onScreen: true
                            }
                        }
                    );

                    this.loadAll();

                    //

                }
            ).catch(
                (data) => {

                    /*
                    *
                    * error
                    * 
                    */

                    ConsoleService('ModuleInvoice / IndexedDbSettingsCreateOrUpdateComponent / new promise => catch', this.flag_console_log);

                    ConsoleService('ModuleInvoice / IndexedDbSettingsCreateOrUpdateComponent / new promise => catch / data', this.flag_console_log);
                    ConsoleService(data, this.flag_console_log);

                    notification.addNotification(
                        {
                            title: translation[this.state.language]['general']['notification_error'],
                            message: translation[this.state.language]['settings']['notification_error_update_invoice_serial_and_number'],
                            type: "success",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 4000,
                                onScreen: true
                            }
                        }
                    );

                    //

                }
            );

        /*
        
        update the setting record
        
        stop
        
        */

        //

    }

    /* DELIMITER */

    render() {

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleSettings_InvoiceSettings render', this.flag_console_log);

        ConsoleService('this.state');
        ConsoleService(this.state);

        let invoices_serials_and_numbers = null;

        if (
            this.state.arr_invoice_serial_and_number.length
        ) {

            /*
     
            ok
     
            */

            invoices_serials_and_numbers = (
                <tbody>

                    {
                        this.state.arr_invoice_serial_and_number.map(
                            (item, index) => {

                                ConsoleService('invoice item', this.flag_console_log);
                                ConsoleService(item, this.flag_console_log);

                                ConsoleService('index', this.flag_console_log);
                                ConsoleService(index, this.flag_console_log);

                                return (

                                    <React.Fragment key={index}>

                                        <tr>

                                            <td>
                                                {
                                                    /** */

                                                    <input
                                                        type="text"
                                                        name="invoice_serial"
                                                        className="filter_error"
                                                        value={item.key}
                                                        onChange={(event) => { this.itemOnChangeHandler(event, index) }}
                                                        placeholder="p key" />

                                                    /** */
                                                }
                                                {/*item.key*/}
                                            </td>

                                            <td>
                                                <input
                                                    type="text"
                                                    name="invoice_number"
                                                    value={item.value}
                                                    onChange={(event) => { this.itemOnChangeHandler(event, index) }}
                                                    placeholder="p value" />
                                            </td>

                                            <td>

                                                <button variant="secondary" onClick={(event) => { this.update_invoice_serial_and_number(event, index) }}>
                                                    {translation[this.state.language]['general']['apply']}
                                                </button>

                                            </td>

                                        </tr>

                                    </React.Fragment>

                                )

                            }
                        )
                    }

                </tbody>
            )

            //

        }

        return (
            <div id="ModuleSettings_InvoiceSettings" >
                {/* module settings - invoices details / start */}

                <div className="row" >

                    {/* invoice serials and numbers / start */}
                    <div className="col-12">

                        <div className="m5 p5">

                            <div className="m5 p5">

                                <h2>
                                    {translation[this.state.language]['settings']['invoices']}
                                </h2>

                                <div className="container-alert">

                                    <div className="alert alert-success" role="alert">

                                        <h4 className="alert-heading">
                                            {translation[this.state.language]['general']['alert_header_info']}
                                        </h4>

                                        <p>
                                            {translation[this.state.language]['settings']['invoices_info_content']}
                                        </p>

                                    </div>

                                </div>

                            </div>

                            <div className="m5 p5">

                                {

                                    invoices_serials_and_numbers ?

                                        <table className="table">

                                            <thead>

                                                <tr>

                                                    <th>
                                                        <b>{translation[this.state.language]['settings']['invoices_serial']}</b>
                                                    </th>

                                                    <th>
                                                        <b>{translation[this.state.language]['settings']['invoices_number']}</b>
                                                    </th>

                                                    <th>
                                                        <b>{translation[this.state.language]['general']['actions']}</b>
                                                    </th>

                                                </tr>

                                            </thead>

                                            {invoices_serials_and_numbers}

                                        </table>

                                        : <div>{translation[this.state.language]['general']['no_data_available']}</div>

                                }

                            </div>

                        </div>

                    </div>
                    {/* invoice serials and numbers / stop */}

                </div >

                {/* module settings - invoices details / stop */}
            </div >
        )

    }

}

export default ModuleSettings_InvoiceSettings_Component;