import React from "react";

/*
 *
 * custom components
 *
 * start
 *
 */

import ConsoleService from "../consoleServiceModules/ConsoleService";

import ConsoleServiceModuleBill from "../consoleServiceModules/ConsoleServiceModuleBill";

/*
 *
 * custom components
 *
 * stop
 *
 */

const flag_console_log = ConsoleServiceModuleBill;

const ElementItemBill = (props) => {

    ConsoleService("ElementItemBill / constructor", flag_console_log);

    ConsoleService("props", flag_console_log);
    ConsoleService(props, flag_console_log);

    return (
        <tr>

            <td className="action"></td>

            <td className="description">
                {props.item.description ? props.item.description : ''}
            </td>

            <td>
                {props.item.quantity}
            </td>

            <td>
                {props.item.price}
            </td>

            <td>
                {props.item.vat}
            </td>

            <td className="action"></td>

        </tr>
    );
};

export default ElementItemBill;
