/*

language : EN

*/

export default {

    recipient_account_type: 'attribute recipient account type en',

    recipient_company_name: 'attribute recipient company name en',

    recipient_vat_nr: 'attribute recipient vat number en',

    recipient_email: 'attribute recipient email en',

    recipient_phone_nr: 'attribute recipient phone nr en',

    recipient_addresses_country_key: 'attribute recipient country en',

    recipient_addresses_city: 'attribute recipient city en',

    recipient_addresses_street_address: 'attribute recipient street address en',

    recipient_addresses_zip: 'attribute recipient zip en',

}