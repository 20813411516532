/*
 *
 * custom components
 *
 * start
 *
 */

import ConsoleService from './consoleServiceModules/ConsoleService';

import ConsoleServiceIndexedDbModuleRecipientComponent from "./consoleServiceModules/ConsoleServiceIndexedDbModuleRecipientComponent";

import { indexed_db_name, indexed_db_version } from './../IndexedDbComponent';

/*
 *
 * custom components
 *
 * stop
 *
 */

const execute = () => {

    let flag_console_log = ConsoleServiceIndexedDbModuleRecipientComponent;

    ConsoleService('', flag_console_log);
    ConsoleService('IndexedDbReturnRecipientsComponent', flag_console_log);

    let idb_name = indexed_db_name;

    ConsoleService('IndexedDbModuleInvoicesComponent / idb name', flag_console_log);
    ConsoleService(idb_name, flag_console_log);

    let version = indexed_db_version;

    ConsoleService('IndexedDbReturnRecipientsComponent / IndexedDb / version', flag_console_log);
    ConsoleService(version, flag_console_log);

    return new Promise(
        (resolve, reject) => {

            ConsoleService('IndexedDbReturnRecipientsComponent / load_all_store_objects() / new promise', flag_console_log);

            let indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;

            /*
             *
             * request
             *
             * start
             * 
             */

            let request = indexedDB.open(idb_name, version);

            /*
             * request / on success / callback
             *
             * start
             * 
             */

            request.onsuccess = function (event) {

                ConsoleService('IndexedDbReturnRecipientsComponent / load_all_store_objects() / request / on success', flag_console_log);

                ConsoleService('IndexedDbReturnRecipientsComponent / load_all_store_objects() / request / on success / event', flag_console_log);
                ConsoleService(event, flag_console_log);

                let db = event.target.result;

                /*
                 *
                 * transaction
                 *
                 * start
                 * 
                 */

                /*
                 *
                 * transaction
                 * 
                 * open a read/write db transaction, ready for adding the data
                 * 
                 * https://developer.mozilla.org/en-US/docs/Web/API/IDBDatabase/transaction
                 * 
                 */

                let transaction = db.transaction(['recipient_object_store'], 'readonly');

                ConsoleService('IndexedDbReturnRecipientsComponent / load_all_store_objects() / request / on success / transaction', flag_console_log);
                ConsoleService(transaction, flag_console_log);

                /*
                 * report on the success of opening the transaction
                 */

                transaction.oncomplete = function (event) {

                    ConsoleService('IndexedDbReturnRecipientsComponent / load_all_store_objects() / request / on success / transaction / on complete', flag_console_log);

                    ConsoleService('IndexedDbReturnRecipientsComponent / load_all_store_objects() / request / on success / transaction / on complete / event', flag_console_log);
                    ConsoleService(event, flag_console_log);

                }

                transaction.onerror = function (event) {

                    ConsoleService('IndexedDbReturnRecipientsComponent / load_all_store_objects() / request / on success / transaction / on error', flag_console_log);

                    ConsoleService('IndexedDbReturnRecipientsComponent / load_all_store_objects() / request / on success / transaction / on error / event', flag_console_log);
                    ConsoleService(event, flag_console_log);

                    reject(event);

                }

                /*
                 *
                 * transaction
                 *
                 * stop
                 * 
                 */

                /*
                 *
                 * "recipient" object store
                 *
                 * start
                 * 
                 */

                let store = transaction.objectStore('recipient_object_store');

                ConsoleService('IndexedDbReturnRecipientsComponent / load_all_store_objects() / request / on success / store', flag_console_log);
                ConsoleService(store, flag_console_log);

                /*
                 *
                 * "recipient" object store
                 *
                 * stop
                 * 
                 */

                /*
                 *
                 * req
                 *
                 * start
                 *
                 */

                let req = store.openCursor();

                ConsoleService('IndexedDbReturnRecipientsComponent / load_all_store_objects() / request / on success / req', flag_console_log);
                ConsoleService(req, flag_console_log);

                let items = [];

                ConsoleService('IndexedDbReturnRecipientsComponent / load_all_store_objects() / request / on success / items', flag_console_log);
                ConsoleService(items, flag_console_log);

                req.onsuccess = function (event) {

                    ConsoleService('IndexedDbReturnRecipientsComponent / load_all_store_objects() / request / on success / req / on success', flag_console_log);

                    let cursor = event.target.result;

                    ConsoleService('IndexedDbReturnRecipientsComponent / load_all_store_objects() / request / on success / req / on success / cursor', flag_console_log);
                    ConsoleService(cursor, flag_console_log);

                    if (cursor != null) {

                        ConsoleService('IndexedDbReturnRecipientsComponent / load_all_store_objects() / request / on success / req / on success / cursor / if', flag_console_log);

                        ConsoleService('IndexedDbReturnRecipientsComponent / load_all_store_objects() / request / on success / req / on success / cursor / if / cursor.value', flag_console_log);
                        ConsoleService(cursor.value, flag_console_log);

                        items.push(cursor.value);

                        cursor.continue();

                    } else {

                        ConsoleService('IndexedDbReturnRecipientsComponent / load_all_store_objects() / request / on success / req / on success / cursor / else', flag_console_log);

                        resolve(items);

                    }

                }

                req.onerror = function (event) {

                    ConsoleService('IndexedDbReturnRecipientsComponent / load_all_store_objects() / request / on success / req / on error', flag_console_log);

                    reject([]);

                }

                /*
                 *
                 * req
                 *
                 * stop
                 *
                 */

            }

            /*
             * request / on success / callback
             *
             * stop
             * 
             */

            /*
             * request / on error / callback
             *
             * start
             * 
             */

            request.onerror = function (event) {

                ConsoleService('IndexedDbReturnRecipientsComponent / load_all_store_objects() / request / on error', flag_console_log);

                reject([]);

            }

            /*
             * request / on error / callback
             *
             * stop
             * 
             */

            /*
             * request
             *
             * stop
             * 
             */

        }
    ).then(
        (data) => {

            ConsoleService('IndexedDbReturnRecipientsComponent / load_all_store_objects() / new promise => then', flag_console_log);

            ConsoleService('IndexedDbReturnRecipientsComponent / load_all_store_objects() / new promise => then / data', flag_console_log);
            ConsoleService(data, flag_console_log);

            return data;

        }
    ).catch(
        (data) => {

            ConsoleService('IndexedDbReturnRecipientsComponent / load_all_store_objects() / new promise => catch', flag_console_log);

            ConsoleService('IndexedDbReturnRecipientsComponent / load_all_store_objects() / new promise => catch / data', flag_console_log);
            ConsoleService(data, flag_console_log);

            return data;

        }
    );

    //

}

export default execute;