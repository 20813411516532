const arr = [

    {
        value: 5, label: '5'
    },
    {
        value: 10, label: '10'
    },
    {
        value: 15, label: '15'
    },
    {
        value: 25, label: '25'
    },
    {
        value: 50, label: '50'
    },
    {
        value: 100, label: '100'
    },

];

export default arr;