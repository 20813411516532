/*
*
* official recipient attributes
*
* !!! DO NOT TOUCH !!!
*
* we need access to this list when we load a recipient data from the "recipient module"
*
* start
* 
*/

const official_recipient_attributes = {

    recipient_account_type: '',
    recipient_account_type_name: '',

    recipient_company_name: '',
    recipient_vat_nr: '',
    recipient_email: '',
    recipient_phone_nr: '',

    recipient_addresses_country_key: '',
    recipient_addresses_country_name: '',
    recipient_addresses_city: '',
    recipient_addresses_street_address: '',
    recipient_addresses_zip: ''

};

/*
*
* official recipient attributes
* 
* stop
* 
*/

export default official_recipient_attributes;