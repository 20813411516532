const execute = (payload) => {

    let flag_error = false;

    let arr_result = [];

    arr_result = Object.keys(payload.attributes.invoice_items).map(
        (index) => {

            //ConsoleService('index', t.flag_console_log);
            //ConsoleService(index, t.flag_console_log);

            let obj = {
                description: false,
                quantity: false,
                price: false,
                vat: false
            };

            if (
                payload.attributes.invoice_items[index].description === ''
            ) {

                obj.description = true;

                flag_error = true;

            }

            if (
                payload.attributes.invoice_items[index].quantity === ''
            ) {

                obj.quantity = true;

                flag_error = true;

            }

            if (
                payload.attributes.invoice_items[index].price === ''
            ) {

                obj.price = true;

                flag_error = true;

            }

            if (
                payload.attributes.invoice_items[index].vat === ''
            ) {

                obj.vat = true;

                flag_error = true;

            }

            return obj;

        }
    );

    //ConsoleService('flag error', t.flag_console_log);
    //ConsoleService(flag_error, t.flag_console_log);

    //ConsoleService('arr_result', t.flag_console_log);
    //ConsoleService(arr_result, t.flag_console_log);

    return {
        flag_error: flag_error,
        arr_result: arr_result,
    };

    //

}

export default execute;