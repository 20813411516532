import React, { Component } from 'react';// ok 14.11.2019

import { store as notification } from 'react-notifications-component';// ok 14.11.2019

import './css/ModuleInvoice.css';

/*
*
* custom components
*
* start
*
*/

import ModuleInvoiceComponentRenderJsx from './components/moduleInvoice/ModuleInvoiceComponentRenderJsx';

import ConsoleService from './components/consoleServiceModules/ConsoleService';//ok 14.11.2019

import ConsoleServiceModuleInvoice from "./components/consoleServiceModules/ConsoleServiceModuleInvoice";// ok 14.11.2019

import component_form_errors_invoice_items_validation from './components/moduleInvoice/component_form_errors_invoice_items_validation';

import serviceGenerateRandomString from './serviceGenerateRandomString';

/*
*
* Indexed DB custom components
*
* start
*
*/

import IndexedDbComponent from './IndexedDbComponent';// ok 14.11.2019

import DexieDbComponent from './DexieDbComponent';

import IndexedDbModuleInvoicesComponent from './IndexedDbModuleInvoicesComponent';// ok 14.11.2019

import IndexedDbModuleInvoicesReturnStoreCountComponent from './IndexedDbModuleInvoicesReturnStoreCountComponent';// ok 14.11.2019

import IndexedDbReturnRecipientsComponent from './components/IndexedDbReturnRecipientsComponent';

import IndexedDbProviderReturnProviderComponent from './components/IndexedDbProviderReturnProviderComponent';

import IndexedDbLoadAllComponent from './components/IndexedDbLoadAllComponent';

import IndexedDbLoadOneComponent from './components/IndexedDbLoadOneComponent';

import IndexedDbSettingsCreateOrUpdateComponent from './components/IndexedDbSettingsCreateOrUpdateComponent';

/*
*
* Indexed DB custom components
*
* stop
*
*/

import default_language, { arr_languages } from './LanguageComponent';// ok 14.11.2019

import translation from './TranslationComponent';// ok 14.11.2019

import component_form_submit_validation from './components/moduleInvoice/component_form_submit_validation';// ok 14.11.2019

//import convertInvoiceToPDF from './components/convertInvoiceToPDF';// ok 14.11.2019
import convertInvoiceToPdfRo from './components/convertInvoiceToPdfRo';// ok 14.11.2019

import component_compute_vat_type from './components/moduleInvoice/component_compute_vat_type';// ok 14.11.2019
import component_compute_total_without_vat from './components/moduleInvoice/component_compute_total_without_vat';// ok 14.11.2019
import component_compute_total_vat from './components/moduleInvoice/component_compute_total_vat';// ok 14.11.2019
import component_compute_total from './components/moduleInvoice/component_compute_total';// ok 14.11.2019

import component_compute_select_option from './components/component_compute_select_option';// ok 14.11.2019

/*
*
* importing recipient attributes
*
* start
* 
*/

import component_official_recipient_attributes from './components/moduleInvoice/component_official_recipient_attributes';// ok 14.11.2019

import component_unofficial_recipient_attributes from './components/moduleInvoice/component_unofficial_recipient_attributes';// ok 14.11.2019

/*
*
* importing recipient attributes
*
* stop
* 
*/

import component_item_remove from './components/moduleInvoice/component_item_remove';// ok 14.11.2019

/*
*
* importing form components
*
* start
* 
*/

import component_form_attributes_to_be_validated from './components/moduleInvoice/component_form_attributes_to_be_validated';// ok 14.11.2019

import component_form_labels_to_be_validated from './components/moduleInvoice/component_form_labels_to_be_validated';// ok 14.11.2019

/*
*
* importing form components
*
* stop
* 
*/

import arr_country from './components/dataProviders/const_arr_country_data';// ok 14.11.2019

/*
*
* custom components
*
* stop
*
*/

import ModuleReceiptV2 from './ModuleReceiptV2';

//let d = new Date();// ok 14.11.2019

/* DELIMITER */

// the main class
class ModuleInvoice extends Component {

    module_name = 'ModuleInvoice';// ok 14.11.2019

    idb_for_module_invoices = null;// ok 14.11.2019

    container_width = 914;// ok 14.11.2019

    indexed_db_invoice_object_store_name = 'invoice_object_store';// ok 14.11.2019

    flag_console_log = ConsoleServiceModuleInvoice;// ok 14.11.2019

    default_language = default_language;// ok 14.11.2019

    recipients = [];// ok 14.11.2019

    provider = null;

    /*
    *
    * recipient attributes
    *
    * start
    * 
    */

    official_recipient_attributes = component_official_recipient_attributes;// ok 14.11.2019

    unofficial_recipient_attributes = component_unofficial_recipient_attributes;// ok 14.11.2019

    /*
    *
    * recipient attributes
    *
    * stop
    * 
    */

    /*
    *
    * form components
    *
    * start
    * 
    */

    //component_form_labels = component_form_labels;

    //component_form_placeholders = component_form_placeholders;

    component_form_attributes_to_be_validated = component_form_attributes_to_be_validated;// ok 14.11.2019

    component_form_labels_to_be_validated = component_form_labels_to_be_validated;// ok 14.11.2019

    /*
    *
    * form components
    *
    * stop
    * 
    */

    state = {};// ok 14.11.2019

    /* DELIMITER */

    getInitialState() {

        //

        const initialState = {

            language: this.default_language,

            currency: { value: 'EUR', symbol: '€', label: '€ ( EUR )' },

            /* DELIMITER */

            /*
            *
            * form attributes
            *
            * start
            * 
            */

            form: {

                select: {

                    /* DELIMITER */

                    /*
                    *
                    * provider attributes
                    * 
                    * start
                    * 
                    */

                    /*
                    *
                    * provider address country key
                    *
                    * start
                    * 
                    */

                    provider_address_country_key: null,

                    provider_address_country_key_select_options: arr_country,

                    /*
                    *
                    * provider address country key
                    *
                    * stop
                    * 
                    */

                    /*
                    *
                    * provider attributes
                    * 
                    * stop
                    * 
                    */

                    /* DELIMITER */

                    /*
                    *
                    * recipient attributes
                    * 
                    * start
                    * 
                    */

                    /*
                    *
                    * recipient account type
                    *
                    * start
                    * 
                    */

                    recipient_account_type: null,

                    recipient_account_type_select_options: translation[this.default_language][this.module_name].recipient_account_type_select_option,

                    /*
                    *
                    * recipient account type
                    *
                    * stop
                    * 
                    */

                    /* DELIMITER */

                    /*
                    *
                    * recipient company name
                    *
                    * start
                    * 
                    */

                    recipient_company_name: null,

                    // we need the recipients list
                    recipient_company_name_select_options: [],

                    /*
                    *
                    * recipient company name
                    *
                    * stop
                    * 
                    */

                    /* DELIMITER */

                    /*
                    *
                    * recipient country key
                    *
                    * start
                    * 
                    */

                    recipient_addresses_country_key: null,// as json

                    recipient_addresses_country_key_select_options: arr_country,

                    /*
                    *
                    * recipient country key
                    *
                    * stop
                    * 
                    */

                    /*
                    *
                    * recipient attributes
                    * 
                    * stop
                    * 
                    */

                    invoice_serial: null,// as json

                    invoice_serial_select_options: []

                    /* DELIMITER */

                },

                labels: {

                    // invoice number
                    invoice_number_label: translation[this.default_language][this.module_name].invoice_number_label,

                    // invoice date
                    invoice_date_label: translation[this.default_language][this.module_name].invoice_date_label,

                    /*
                    *
                    * item labels
                    * 
                    * start
                    *
                    */

                    // item description
                    item_description_label: translation[this.default_language][this.module_name].item_description_label,

                    // item quantity
                    item_quantity_label: translation[this.default_language][this.module_name].item_quantity_label,

                    // item price
                    item_price_label: translation[this.default_language][this.module_name].item_price_label,

                    // item vat
                    item_vat_label: translation[this.default_language][this.module_name].item_vat_label,

                    /*
                    *
                    * item labels
                    * 
                    * stop
                    *
                    */

                    /*
                    *
                    * subtotal, vat, total
                    * 
                    * start
                    *
                    */

                    // invoice "subtotal" label
                    invoice_subtotal_label: translation[this.default_language][this.module_name].invoice_subtotal_label,

                    // invoice "total without vat" label
                    invoice_total_without_vat_label: translation[this.default_language][this.module_name].invoice_total_without_vat_label,

                    invoice_subtotal_without_vat_for_vat_x: translation[this.default_language].general.invoice['invoice_subtotal_without_vat_for_vat_x'],
                    invoice_total_vat_for_vat_x: translation[this.default_language].general.invoice['invoice_total_vat_for_vat_x'],

                    // invoice "total vat" label
                    invoice_total_vat_label: translation[this.default_language][this.module_name].invoice_total_vat_label,

                    // invoice "total" label
                    invoice_total_label: translation[this.default_language][this.module_name].invoice_total_label,

                    /*
                    *
                    * subtotal, vat, total
                    * 
                    * stop
                    *
                    */

                    recipient_account_type_label: translation[this.default_language][this.module_name].recipient_account_type_label,

                },

                placeholders: {

                    /*                
                    *
                    * invoice placeholders
                    *
                    * start
                    * 
                    */

                    // invoice serial
                    invoice_serial: 'INV',//translation[this.default_language][this.module_name].placeholder_invoice_serial,

                    // invoice number
                    invoice_number: '123',//translation[this.default_language][this.module_name].placeholder_invoice_number,

                    // invoice number label
                    invoice_number_label: translation[this.default_language][this.module_name].placeholder_invoice_number_label,

                    // invoice date
                    invoice_date: translation[this.default_language][this.module_name].placeholder_invoice_date,

                    // invoice date label
                    invoice_date_label: translation[this.default_language][this.module_name].placeholder_invoice_date_label,

                    // invoice type
                    invoice_type: translation[this.default_language][this.module_name].placeholder_invoice_type,

                    /*                
                    *
                    * invoice placeholders
                    *
                    * stop
                    * 
                    */

                    /*
                    *
                    * provider placeholders
                    * 
                    * start
                    * 
                    */

                    provider_company_name: translation[this.default_language][this.module_name].placeholder_provider_company_name,// ok 20.11.2019 / this is a mandatory field
                    provider_vat_nr: translation[this.default_language][this.module_name].placeholder_provider_vat_nr,// ok 20.11.2019 / this is a mandatory field
                    provider_phone_nr: translation[this.default_language][this.module_name].placeholder_provider_phone_nr,// ok 20.11.2019 / this is a mandatory field
                    provider_bank_account: translation[this.default_language][this.module_name].placeholder_provider_bank_account,// ok 20.11.2019 / this is a mandatory field
                    provider_bank_name: translation[this.default_language][this.module_name].placeholder_provider_bank_name,// ok 20.11.2019 / this is a mandatory field

                    provider_address_country_key: translation[this.default_language][this.module_name].placeholder_provider_address_country_key,// ok 20.11.2019 / this is a mandatory field
                    provider_address_county: translation[this.default_language][this.module_name].placeholder_provider_address_county,// ok 20.11.2019
                    provider_address_city: translation[this.default_language][this.module_name].placeholder_provider_address_city,// ok 20.11.2019 / this is a mandatory field
                    provider_address_street_address: translation[this.default_language][this.module_name].placeholder_provider_address_street_address,// ok 20.11.2019 / this is a mandatory field
                    provider_address_zip: translation[this.default_language][this.module_name].placeholder_provider_address_zip,// ok 20.11.2019 / this is a mandatory field

                    /*
                    *
                    * provider placeholders
                    * 
                    * stop
                    * 
                    */

                    /*
                    *
                    * recipient placeholders
                    *
                    * start
                    * 
                    */

                    // recipient company name
                    recipient_company_name: translation[this.default_language][this.module_name].placeholder_recipient_company_name,

                    // recipient account type
                    recipient_account_type: translation[this.default_language][this.module_name].placeholder_recipient_account_type,

                    // recipient vat nr
                    recipient_vat_nr: translation[this.default_language][this.module_name].placeholder_recipient_vat_nr,

                    // recipient email
                    recipient_email: translation[this.default_language][this.module_name].placeholder_recipient_email,

                    // recipient phone nr
                    recipient_phone_nr: translation[this.default_language][this.module_name].placeholder_recipient_phone_nr,

                    // recipient addresses country key
                    recipient_addresses_country_key: translation[this.default_language][this.module_name].placeholder_recipient_addresses_country_key,

                    // recipient addresses city
                    recipient_addresses_city: translation[this.default_language][this.module_name].placeholder_recipient_addresses_city,

                    // recipient addresses street address
                    recipient_addresses_street_address: translation[this.default_language][this.module_name].placeholder_recipient_addresses_street_address,

                    // recipient addresses zip
                    recipient_addresses_zip: translation[this.default_language][this.module_name].placeholder_recipient_addresses_zip,

                    /*
                    *
                    * recipient placeholders
                    *
                    * stop
                    * 
                    */

                    /*
                    *
                    * invoice item labels
                    *
                    * start
                    *
                    */

                    // item description label
                    item_description_label: translation[this.default_language][this.module_name].placeholder_item_description_label,

                    // item quantity label
                    item_quantity_label: translation[this.default_language][this.module_name].placeholder_item_quantity_label,

                    // item price label
                    item_price_label: translation[this.default_language][this.module_name].placeholder_item_price_label,

                    // item vat label
                    item_vat_label: translation[this.default_language][this.module_name].placeholder_item_vat_label,

                    /*
                    *
                    * invoice item labels
                    *
                    * stop
                    *
                    */

                    // invoice subtotal
                    invoice_subtotal_label: translation[this.default_language][this.module_name].placeholder_invoice_subtotal,

                    // invoice total without vat
                    invoice_total_without_vat_label: translation[this.default_language][this.module_name].placeholder_invoice_total_without_vat,

                    // invoice total vat
                    invoice_total_vat_label: translation[this.default_language][this.module_name].placeholder_invoice_total_vat,

                    // invoice total
                    invoice_total_label: translation[this.default_language][this.module_name].placeholder_invoice_total,

                },

                attributes: {

                    // set the PK
                    invoice_object_store_id: 0,

                    // invoice type
                    invoice_type: translation[this.default_language][this.module_name].invoice_type,

                    flag_should_the_vat_be_taken_in_consideration: 1,// default value is 1

                    // company name
                    //invoice_company_name: '',

                    // company info
                    //invoice_company_info: '',// TOGO 20.11.2019

                    /*
                    *
                    * official provider attributes
                    *
                    * start
                    * 
                    */

                    // the FK from "provider_object_store"
                    provider_object_store_id: 1,// this attribute value should always be equal to 1

                    provider_external_id: 0,

                    provider_company_name: '',
                    provider_vat_nr: '',
                    provider_phone_nr: '',
                    provider_bank_account: '',
                    provider_bank_name: '',

                    provider_address_country_key: '',
                    provider_address_country_name: '',

                    provider_address_county: '',
                    provider_address_city: '',
                    provider_address_street_address: '',
                    provider_address_zip: '',

                    /*
                    *
                    * official provider attributes
                    *
                    * stop
                    * 
                    */

                    /*
                    *
                    * official recipient attributes
                    * 
                    * !!! DO NOT TOUCH !!!
                    *
                    * start
                    * 
                    */

                    recipient_account_type: '',
                    recipient_account_type_name: '',

                    recipient_company_name: '',
                    recipient_vat_nr: '',
                    recipient_email: '',
                    recipient_phone_nr: '',

                    recipient_addresses_country_key: '',
                    recipient_addresses_country_name: '',

                    recipient_addresses_city: '',
                    recipient_addresses_street_address: '',
                    recipient_addresses_zip: '',

                    /*
                    *
                    * official recipient attributes
                    * 
                    * stop
                    * 
                    */

                    /*
                    *
                    * unofficial recipient attributes
                    * 
                    * the following recipient attributes are hardcoded
                    * the following recipient attributes might be served by the recipients module
                    * we do not use these attributes on the screen
                    * 
                    * !!! DO NOT TOUCH !!!
                    * 
                    * start
                    * 
                    */

                    // the FK from "recipient_object_store"
                    recipient_object_store_id: null,

                    recipient_external_account_id: 0,

                    recipient_addresses_is_billing: 1,
                    recipient_addresses_is_delivery: 1,
                    recipient_addresses_is_primary: 1,
                    recipient_addresses_is_site: 1,

                    recipient_addresses_is_customer: 1,
                    recipient_addresses_is_supplier: 0,

                    /*
                    *
                    * unofficial recipient attributes
                    * 
                    * stop
                    * 
                    */

                    invoice_serial: '',
                    invoice_number: '',

                    //invoice_date: d.getDate() + ' ' + translation[this.default_language].general.months[d.getMonth()] + ', ' + d.getFullYear(),

                    invoice_date: new Date(),

                    // items
                    invoice_items: [
                        {
                            description: '',// translation[this.default_language].general.item_description,
                            quantity: 0.00,
                            price: 0.00,
                            vat: 0.00
                        }
                    ],

                    invoice_vat_type: [],

                    // subtotal
                    invoice_subtotal: 0.00,

                    // vat
                    invoice_vat_value: 0.00,

                    // invoice total without vat
                    invoice_total_without_vat: 0.00,

                    // invoice total vat
                    invoice_total_vat: 0.00,

                    // total
                    invoice_total: 0.00,

                    // the currency
                    invoice_currency: 'EUR',

                    // the currency symbol
                    invoice_currency_symbol: '€',

                    //the invoice language
                    invoice_language: this.default_language

                },

                errors: {

                    invoice_type: true,

                    /* DELIMITER */

                    /*
                    *
                    * official recipient attributes
                    *
                    * start
                    * 
                    */

                    recipient_account_type: true,
                    recipient_company_name: true,
                    recipient_vat_nr: true,
                    recipient_email: true,
                    recipient_phone_nr: true,

                    recipient_addresses_country_key: true,
                    recipient_addresses_city: true,
                    recipient_addresses_street_address: true,
                    recipient_addresses_zip: true,

                    /*
                    *
                    * official recipient attributes
                    * 
                    * stop
                    * 
                    */

                    /* DELIMITER */

                    /*
                    *
                    * invoice number and date
                    * 
                    * start
                    * 
                    */

                    // invoice number
                    invoice_number_label: true,
                    invoice_serial: true,
                    invoice_number: true,

                    // invoice date
                    invoice_date_label: true,
                    invoice_date: true,

                    /*
                    *
                    * invoice number and date
                    * 
                    * stop
                    * 
                    */

                    /* DELIMITER */

                    /*
                    *
                    * official provider attributes
                    *
                    * start
                    * 
                    */

                    provider_company_name: true,

                    provider_vat_nr: true,
                    provider_phone_nr: true,
                    provider_bank_account: true,
                    provider_bank_name: true,

                    provider_address_country_key: true,

                    provider_address_county: true,
                    provider_address_city: true,
                    provider_address_street_address: true,
                    provider_address_zip: true,

                    /*
                    *
                    * official provider attributes
                    *
                    * stop
                    * 
                    */

                    invoice_items: false,
                    invoice_items_data: [
                        {
                            description: false,
                            quantity: false,
                            price: false,
                            vat: false
                        }
                    ],

                },

                /* DELIMITER */

                /*
                *
                * the form attributes that can be "touched"
                *
                * start
                * 
                */

                touched: {

                    // invoice type
                    invoice_type: false,

                    /*
                    *
                    * official recipient attributes
                    *
                    * start
                    * 
                    */

                    recipient_account_type: false,

                    recipient_company_name: false,

                    recipient_vat_nr: false,
                    recipient_email: false,
                    recipient_phone_nr: false,

                    recipient_addresses_country_key: false,

                    recipient_addresses_city: false,
                    recipient_addresses_street_address: false,
                    recipient_addresses_zip: false,

                    /*
                    *
                    * official recipient attributes
                    * 
                    * stop
                    * 
                    */

                    /* DELIMITER */

                    /*
                    *
                    * invoice number and date
                    * 
                    * start
                    * 
                    */

                    invoice_number_label: false,
                    invoice_serial: false,
                    invoice_number: false,

                    invoice_date_label: false,
                    invoice_date: false,

                    /*
                    *
                    * invoice number and date
                    * 
                    * stop
                    * 
                    */

                    /* DELIMITER */

                    /*
                    *
                    * official provider attributes
                    *
                    * start
                    * 
                    */

                    provider_company_name: false,

                    provider_vat_nr: false,
                    provider_phone_nr: false,
                    provider_bank_account: false,
                    provider_bank_name: false,

                    provider_address_country_key: false,

                    provider_address_county: false,
                    provider_address_city: false,
                    provider_address_street_address: false,
                    provider_address_zip: false,

                    /*
                    *
                    * official provider attributes
                    *
                    * stop
                    * 
                    */

                    invoice_items: false,
                    invoice_items_data: [
                        {
                            description: false,
                            quantity: false,
                            price: false,
                            vat: false
                        }
                    ],

                },

                /*
                *
                * the form attributes that can be "touched"
                *
                * stopF
                * 
                */

            },

            /*
            *
            * form attributes
            *
            * stop
            * 
            */

            container_scenario: 'left-middle-right',

            flag_permission_loading_element_animation: true,// ?

            flag_permission_show_form_element_recipient_company_name_input_or_react_select_creatable: "react-select-creatable",// ok 14.11.2019

            flag_permission_show_form_element_invoice_serial_input_or_react_select_creatable: "react-select-creatable",// ok 09.01.2020

            /*
            * default value should be set to false
            */

            loading_element: false,// ok 14.11.2019

            arr_languages: arr_languages,// ok 14.11.2019

            button_new_invoice_form_label: translation[this.default_language][this.module_name].button_new_invoice_form_label,// ok 28.11.2019
            button_download_pdf_label: translation[this.default_language][this.module_name].button_download_pdf_label,// ok 14.11.2019

            /*
            
            we need to use a standard 'VAT' value
            
            we should always store and use the last VAT value, if available ( default or from storage )
            
            */

            standard_vat_value: 0.0,// ok 10.01.2020

            flag_add_receipt: false,// ok 17.01.2020

            flag_should_focus_on_last_invoice_item: false,// ok 20.01.2020

        };

        return initialState;

        //

    }

    /* DELIMITER */

    constructor(props) {

        super(props);

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleInvoice / construct', this.flag_console_log);

        ConsoleService('ModuleInvoice / props', this.flag_console_log);
        ConsoleService(props, this.flag_console_log);

        this.default_language = props.language;

        let state = this.getInitialState();

        ConsoleService('ModuleInvoice / initial state', this.flag_console_log);
        ConsoleService(state, this.flag_console_log);

        state.language = props.language;

        state.locale = props.locale;

        this.state = state;

        //

    }

    /* DELIMITER */

    componentDidMount() {

        ConsoleService('ModuleInvoice / component did mount', this.flag_console_log);

        ConsoleService('this.state', this.flag_console_log);
        ConsoleService(this.state, this.flag_console_log);

        this.idb_for_module_invoices = null;

        if (
            !('indexedDB' in window)
        ) {

            ConsoleService('This browser doesn\'t support IndexedDB', this.flag_console_log);

        } else {

            ConsoleService('This browser supports IndexedDB', this.flag_console_log);

            /*
            * !! DO NOT DELETE !! THE DB SHOULD BE CREATED FOR ANY NEW USER !!
            */

            new IndexedDbComponent();

            new DexieDbComponent();

            this.idb_for_module_invoices = new IndexedDbModuleInvoicesComponent();

        }

        this.query_selector_all_textarea_elements();

        this.detect_prop_invoice_update_state({}, this.props);

        if (
            this.props.invoice !== null
        ) {

            // ok

            /*
            *
            * the "invoice" prop exists and has a value != null
            * this is the "update" scenario
            * 
            */

            ConsoleService('ModuleInvoice / componentDidMount / props.invoice !== null', this.flag_console_log);

        } else {

            // ok

            /*
            *
            * the "invoice" prop exists, but the value is null
            * this is the "create" scenario
            * 
            */

            ConsoleService('ModuleInvoice / componentDidMount / props.invoice === null => load_all_recipients() will execute', this.flag_console_log);

            this.load_all_recipients();

        }

        //this.load_all_recipients();

        this.load_provider();

        if (this.state.form.attributes.invoice_object_store_id === 0) {

            /*

            ok

            this is a new invoice

            we need to know if the "VAT" should be applied or not

            for a existing invoice, we already know if the "VAT" is applied or not

            */

            let payload = {
                object_store: 'settings_object_store',
                index_name: 'group, key',
                value: [
                    'flag_available_option',
                    'vat'
                ]
            }

            IndexedDbLoadOneComponent(payload)
                .then(
                    (data) => {

                        /*
                        *
                        * ok
                        * 
                        */

                        ConsoleService('ModuleInvoice / IndexedDbLoadOneComponent / new promise then', this.flag_console_log);

                        ConsoleService('ModuleInvoice / IndexedDbLoadOneComponent / new promise => then / data', this.flag_console_log);
                        ConsoleService(data, this.flag_console_log);

                        let newState = Object.assign({}, this.state);

                        if (
                            data.value === 'active'
                        ) {

                            /*

                            ok

                            the "VAT" value should be applied

                            */

                            newState.form.attributes.flag_should_the_vat_be_taken_in_consideration = 1;// active

                            //

                        } else {

                            /*

                            ok
                            
                            the "VAT" should NOT be applied
                            
                            */

                            newState.form.attributes.flag_should_the_vat_be_taken_in_consideration = 0;// inactive

                            //

                        }

                        this.setState(newState);

                    }
                )
                .catch(
                    (data) => {

                        /*
                        *
                        * error
                        * 
                        */

                        ConsoleService('ModuleInvoice / IndexedDbLoadOneComponent / new promise => catch', this.flag_console_log);

                        ConsoleService('ModuleInvoice / IndexedDbLoadOneComponent / new promise => catch / data', this.flag_console_log);
                        ConsoleService(data, this.flag_console_log);

                    }
                );

        }

        if (
            this.state.form.attributes.invoice_object_store_id === 0
        ) {

            /*

            ok

            loading the last know 'invoice_type_' value

            */

            let payload = {
                object_store: 'settings_object_store',
                index_name: 'group, key',
                value: [
                    'variables',
                    'invoice_type'
                ]
            }

            IndexedDbLoadOneComponent(payload)
                .then(
                    (data) => {

                        /*
                        *
                        * ok
                        * 
                        */

                        ConsoleService('ModuleInvoice / IndexedDbLoadOneComponent / new promise then', this.flag_console_log);

                        ConsoleService('ModuleInvoice / IndexedDbLoadOneComponent / new promise => then / data', this.flag_console_log);
                        ConsoleService(data, this.flag_console_log);

                        if (
                            data.value !== ''
                        ) {

                            /*
    
                            ok
    
                            the "VAT" value should be applied
    
                            */

                            let newState = Object.assign({}, this.state);

                            newState.form.attributes.invoice_type = data.value;

                            this.setState(newState);

                            //

                        }

                        //

                    }
                )
                .catch(
                    (data) => {

                        /*
                        *
                        * error
                        * 
                        */

                        ConsoleService('ModuleInvoice / IndexedDbLoadOneComponent / new promise => catch', this.flag_console_log);

                        ConsoleService('ModuleInvoice / IndexedDbLoadOneComponent / new promise => catch / data', this.flag_console_log);
                        ConsoleService(data, this.flag_console_log);

                        //

                    }
                );

            //

        }

        if (true) {

            /*

            ok

            loading the last known VAT value

            */

            let payload = {
                object_store: 'settings_object_store',
                index_name: 'group, key',
                value: [
                    'variables',
                    'vat'
                ]
            }

            IndexedDbLoadOneComponent(payload)
                .then(
                    (data) => {

                        /*
                        *
                        * ok
                        * 
                        */

                        ConsoleService('ModuleInvoice / IndexedDbLoadOneComponent / new promise then', this.flag_console_log);

                        ConsoleService('ModuleInvoice / IndexedDbLoadOneComponent / new promise => then / data', this.flag_console_log);
                        ConsoleService(data, this.flag_console_log);

                        if (
                            data.value !== 0
                        ) {

                            /*

                            ok

                            the "VAT" value should be applied

                            */

                            let newState = Object.assign({}, this.state);

                            newState.standard_vat_value = data.value;

                            if (
                                this.state.form.attributes.invoice_object_store_id === 0
                            ) {

                                newState.form.attributes.invoice_items[0]['vat'] = data.value;

                            }

                            this.setState(newState);

                            //

                        }

                        //

                    }
                )
                .catch(
                    (data) => {

                        /*
                        *
                        * error
                        * 
                        */

                        ConsoleService('ModuleInvoice / IndexedDbLoadOneComponent / new promise => catch', this.flag_console_log);

                        ConsoleService('ModuleInvoice / IndexedDbLoadOneComponent / new promise => catch / data', this.flag_console_log);
                        ConsoleService(data, this.flag_console_log);

                        //

                    }
                );

        }

        IndexedDbLoadAllComponent('settings_object_store')
            .then(
                (data) => {

                    /*
                    *
                    * ok
                    * 
                    */

                    ConsoleService('ModuleInvoice / IndexedDbLoadAllComponent / new promise then', this.flag_console_log);

                    ConsoleService('ModuleInvoice / IndexedDbLoadAllComponent / new promise => then / data', this.flag_console_log);
                    ConsoleService(data, this.flag_console_log);

                    if (
                        data.length > 0
                    ) {

                        /*
    
                        ok
    
                        */

                        let newState = Object.assign({}, this.state);

                        for (let i = 0; i < data.length; i++) {

                            if (
                                data[i]['group'] === 'invoice_serial_and_invoice_number'
                            ) {

                                newState.form.select.invoice_serial_select_options.push(
                                    {
                                        value: data[i]['settings_object_store_id'],
                                        label: data[i]['key'],
                                        settings_object_store_id: data[i]['settings_object_store_id'],
                                        settings_value: data[i]['value']
                                    }
                                );

                                //

                            }

                            //

                        }

                        ConsoleService('newState', this.flag_console_log);
                        ConsoleService(newState, this.flag_console_log);

                        this.setState(newState);

                        //

                    }

                }
            )
            .catch(
                (data) => {

                    /*
                    *
                    * error
                    * 
                    */

                    ConsoleService('ModuleInvoice / IndexedDbLoadAllComponent / new promise => catch', this.flag_console_log);

                    ConsoleService('ModuleInvoice / IndexedDbLoadAllComponent / new promise => catch / data', this.flag_console_log);
                    ConsoleService(data, this.flag_console_log);

                }
            );

    }

    load_provider = () => {

        let t = this;

        // the PK is hardcoded => 1 because there should always be only one provider
        IndexedDbProviderReturnProviderComponent(1).then(
            (data) => {

                /*
                *
                * ok
                * 
                */

                ConsoleService('ModuleInvoice / getInitialState() / IndexedDbProviderReturnProviderComponent / new promise then', t.flag_console_log);

                ConsoleService('ModuleInvoice / getInitialState() / IndexedDbProviderReturnProviderComponent / new promise => then / data', t.flag_console_log);
                ConsoleService(data, t.flag_console_log);

                if (
                    data.target.result !== undefined
                ) {

                    // ok

                    let newState = Object.assign({}, this.state);

                    let data_provider = data.target.result;

                    newState.form.attributes.provider_company_name = data_provider.provider_company_name;

                    newState.form.attributes.provider_vat_nr = data_provider.provider_vat_nr;
                    newState.form.attributes.provider_phone_nr = data_provider.provider_phone_nr;
                    newState.form.attributes.provider_bank_account = data_provider.provider_bank_account;
                    newState.form.attributes.provider_bank_name = data_provider.provider_bank_name;

                    // set the value as a json for the select element
                    newState.form.select.provider_address_country_key = this.set_provider_country_key(data_provider.provider_address_country_key);
                    // set the value as "fr" for the attribute
                    newState.form.attributes.provider_address_country_key = data_provider.provider_address_country_key;
                    // set the value as "France" for the attribute
                    newState.form.attributes.provider_address_country_name = data_provider.provider_address_country_name;

                    newState.form.attributes.provider_address_county = data_provider.provider_address_county;
                    newState.form.attributes.provider_address_city = data_provider.provider_address_city;
                    newState.form.attributes.provider_address_street_address = data_provider.provider_address_street_address;
                    newState.form.attributes.provider_address_zip = data_provider.provider_address_zip;

                    this.setState(newState);

                }

                return true;

                //

            }
        ).catch(
            (data) => {

                /*
                *
                * error
                * 
                */

                ConsoleService('ModuleInvoice / getInitialState() / IndexedDbProviderReturnProviderComponent / new promise => catch', t.flag_console_log);

                ConsoleService('ModuleInvoice / getInitialState() / IndexedDbProviderReturnProviderComponent / new promise => catch / data', t.flag_console_log);
                ConsoleService(data, t.flag_console_log);

                return false;

                //

            }
        );

        //

    }

    /* DELIMITER */

    componentDidUpdate(prevProps, prevState) {

        ConsoleService('ModuleInvoice / component did update', this.flag_console_log);

        ConsoleService('prevState', this.flag_console_log);
        ConsoleService(prevState, this.flag_console_log);

        ConsoleService('this.state', this.flag_console_log);
        ConsoleService(this.state, this.flag_console_log);

        ConsoleService('prevProps', this.flag_console_log);
        ConsoleService(prevProps, this.flag_console_log);

        ConsoleService('this.props', this.flag_console_log);
        ConsoleService(this.props, this.flag_console_log);

        this.query_selector_all_textarea_elements();

        if (
            this.props.hasOwnProperty('language')
        ) {

            // ok

            /*
            *
            * within the props, we have also received the "language" prop
            * 
            */

            ConsoleService('ModuleInvoice / component did update / props.language exists', this.flag_console_log);

            ConsoleService('props.language', this.flag_console_log);
            ConsoleService(this.props.language, this.flag_console_log);

            if (
                //prevProps.language !== this.props.language
                this.state.language !== this.props.language
            ) {

                // ok

                /*
                *
                * we need to update the translations
                * 
                */

                ConsoleService('ModuleInvoice / component did update / the translations will be updated', this.flag_console_log);

                //this.default_language = this.props.language;

                let newState = Object.assign({}, this.state);

                newState.language = this.props.language;

                newState.form.attributes.invoice_language = this.props.language;

                newState.form.attributes.invoice_type = translation[this.props.language][this.module_name].invoice_type;

                /*
                *
                * assign the placeholders
                * 
                * start
                * 
                */

                Object.keys(this.state.form.placeholders).map(
                    (attribute) => {

                        //ConsoleService('attribute', this.flag_console_log);
                        //ConsoleService(attribute, this.flag_console_log);

                        newState.form.placeholders[attribute] = translation[this.props.language][this.module_name]['placeholder_' + attribute];

                        return newState.form.placeholders[attribute];

                    }
                );

                /*
                *
                * assign the placeholders
                * 
                * stop
                * 
                */

                /*
                *
                * assign the labels
                * 
                * start
                *  
                */

                Object.keys(this.state.form.labels).map(
                    (attribute) => {

                        //ConsoleService('attribute', this.flag_console_log);
                        //ConsoleService(attribute, this.flag_console_log);

                        newState.form.labels[attribute] = translation[this.props.language][this.module_name][attribute];

                        return newState.form.labels[attribute];

                    }
                );

                /*
                *
                * assign the labels
                * 
                * stop
                *  
                */

                this.setState(newState);

                //

            }

            //

        }

        if (
            this.props.hasOwnProperty('currency')
        ) {

            // ok

            /*
            *
            * within the props, we have also received the "currency" prop
            * 
            */

            ConsoleService('ModuleInvoice / component did update / props.currency exists', this.flag_console_log);

            ConsoleService('props.currency', this.flag_console_log);
            ConsoleService(this.props.currency, this.flag_console_log);

            if (
                prevProps.currency !== this.props.currency
            ) {

                // ok

                /*
                *
                * we need to update the currency
                * 
                */

                ConsoleService('ModuleInvoice / component did update / the currency will be updated', this.flag_console_log);

                let newState = Object.assign({}, this.state);

                newState.currency = this.props.currency;

                // USD
                newState.form.attributes.invoice_currency = this.props.currency.value;

                // $
                newState.form.attributes.invoice_currency_symbol = this.props.currency.symbol;

                this.setState(newState);

            }

            //

        }


        if (
            this.props.hasOwnProperty('locale')
        ) {

            // ok

            /*
            *
            * within the props, we have also received the "locale" prop
            * 
            */

            ConsoleService('ModuleInvoice / component did update / props.locale exists', this.flag_console_log);

            ConsoleService('props.locale', this.flag_console_log);
            ConsoleService(this.props.locale, this.flag_console_log);

            if (
                prevProps.locale !== this.props.locale
            ) {

                // ok

                /*
                *
                * we need to update the locale
                * 
                */

                ConsoleService('ModuleInvoice / component did update / the locale will be updated', this.flag_console_log);

                let newState = Object.assign({}, this.state);

                newState.locale = this.props.locale;

                this.setState(newState);

            }

            //

        }

        //

        this.detect_prop_invoice_update_state(prevProps, this.props);

        this.focusOnItemDescriptionElement();

        //

    }

    /* DELIMITER */

    set_provider_country_key = (payload) => {


        ConsoleService('', this.flag_console_log);
        ConsoleService('Moduleinvoice / set_provider_country_key', this.flag_console_log);

        ConsoleService('payload', this.flag_console_log);
        ConsoleService(payload, this.flag_console_log);

        let option = {};

        Object.keys(arr_country).map(
            (index) => {

                ConsoleService('ModuleInvoice / set_provider_country_key / object.keys.map / index', this.flag_console_log);
                ConsoleService(index, this.flag_console_log);

                ConsoleService('ModuleInvoice / set_provider_country_key / object.keys.map / object', this.flag_console_log);
                ConsoleService(arr_country[index], this.flag_console_log);

                if (
                    arr_country[index].value === payload
                ) {
                    option = arr_country[index];
                }

                return true;

            }
        );

        ConsoleService('option', this.flag_console_log);
        ConsoleService(option, this.flag_console_log);

        return option;

    }

    /* DELIMITER */

    set_recipient_account_type = (payload) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('Moduleinvoice / set_recipient_account_type', this.flag_console_log);

        ConsoleService('payload', this.flag_console_log);
        ConsoleService(payload, this.flag_console_log);

        let option = {};

        Object.keys(translation[this.state.language].general.recipient_account_type_select_option).map(
            (index) => {

                ConsoleService('ModuleInvoice / set_recipient_account_type / object.keys.map / index', this.flag_console_log);
                ConsoleService(index, this.flag_console_log);

                ConsoleService('ModuleInvoice / set_recipient_account_type / object.keys.map / object', this.flag_console_log);
                ConsoleService(translation[this.state.language].general.recipient_account_type_select_option[index], this.flag_console_log);

                if (
                    translation[this.state.language].general.recipient_account_type_select_option[index].value === payload
                ) {
                    option = translation[this.state.language].general.recipient_account_type_select_option[index];
                }

                return true;

            }
        );

        return option;

    }

    /* DELIMITER */

    set_recipient_account_type_name = (payload) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('Moduleinvoice / set_recipient_account_type_name()', this.flag_console_log);

        ConsoleService('Moduleinvoice / set_recipient_account_type_name() / payload', this.flag_console_log);
        ConsoleService(payload, this.flag_console_log);

        ConsoleService('Moduleinvoice / set_recipient_account_type_name() / this.state', this.flag_console_log);
        ConsoleService(this.state, this.flag_console_log);

        let option = {};

        Object.keys(translation[this.state.language].general.recipient_account_type_select_option).map(
            (index) => {

                ConsoleService('ModuleInvoice / set_recipient_account_type_name() / object.keys.map / index', this.flag_console_log);
                ConsoleService(index, this.flag_console_log);

                ConsoleService('ModuleInvoice / set_recipient_account_type_name() / object.keys.map / object', this.flag_console_log);
                ConsoleService(translation[this.state.language].general.recipient_account_type_select_option[index], this.flag_console_log);

                if (
                    translation[this.state.language].general.recipient_account_type_select_option[index].value === payload// individual === individual
                ) {
                    option = translation[this.state.language].general.recipient_account_type_select_option[index].label;// Individual
                }

                return true;

            }
        );

        return option;

    }

    /* DELIMITER */

    set_recipient_country_key = (payload) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('Moduleinvoice / set_recipient_country_key', this.flag_console_log);

        ConsoleService('payload', this.flag_console_log);
        ConsoleService(payload, this.flag_console_log);

        let option = {};

        Object.keys(arr_country).map(
            (index) => {

                ConsoleService('ModuleInvoice / set_recipient_country_key / object.keys.map / index', this.flag_console_log);
                ConsoleService(index, this.flag_console_log);

                ConsoleService('ModuleInvoice / set_recipient_country_key / object.keys.map / object', this.flag_console_log);
                ConsoleService(arr_country[index], this.flag_console_log);

                if (
                    arr_country[index].value === payload
                ) {
                    option = arr_country[index];
                }

                return true;

            }
        );

        return option;

    }

    /* DELIMITER */

    detect_prop_invoice_update_state = (prevProps, props) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('Moduleinvoice / detect_prop_invoice_update_state', this.flag_console_log);

        let flag_permission = true;

        if (
            props.hasOwnProperty('invoice')
        ) {

            // ok

            /*
            * within the props, we have also received the "invoice" prop
            */

            ConsoleService('ModuleInvoice / detect_prop_invoice_update_state / props.invoice exists', this.flag_console_log);

            ConsoleService('ModuleInvoice / detect_prop_invoice_update_state / props.invoice', this.flag_console_log);
            ConsoleService(props.invoice, this.flag_console_log);

            //

        } else {

            // error

            flag_permission = false;

        }

        if (
            flag_permission
        ) {

            // ok

            if (
                props.invoice !== null
            ) {

                // ok

                /*
                *
                * the "invoice" prop exists and has a value != null
                * this is the "update" scenario
                * 
                */

                ConsoleService('ModuleInvoice / detect_prop_invoice_update_state / props.invoice !== null', this.flag_console_log);

            } else {

                // ok

                /*
                *
                * the "invoice" prop exists, but the value is null
                * this is the "create" scenario
                * 
                */

                ConsoleService('ModuleInvoice / detect_prop_invoice_update_state / props.invoice === null => permission denied', this.flag_console_log);

                flag_permission = false;

            }

        }

        if (
            flag_permission
        ) {

            // ok

            if (
                prevProps.hasOwnProperty('invoice_hash') &&
                props.hasOwnProperty('invoice_hash')
            ) {

                // ok

                ConsoleService('ModuleInvoice / detect_prop_invoice_update_state / prevProps.invoice_hash exists', this.flag_console_log);

                ConsoleService('ModuleInvoice / detect_prop_invoice_update_state / prevProps.invoice_hash', this.flag_console_log);
                ConsoleService(prevProps.invoice_hash, this.flag_console_log);

                ConsoleService('ModuleInvoice / detect_prop_invoice_update_state / props.invoice_hash exists', this.flag_console_log);

                ConsoleService('ModuleInvoice / detect_prop_invoice_update_state / props.invoice_hash', this.flag_console_log);
                ConsoleService(props.invoice_hash, this.flag_console_log);

                if (
                    prevProps.invoice_hash === props.invoice_hash
                ) {

                    // ok

                    // do not update the state !!

                    ConsoleService('ModuleInvoice / detect_prop_invoice_update_state / prevProps.invoice_hash === props.invoice_hash', this.flag_console_log);

                    flag_permission = false;

                } else {

                    // ok

                    // update the state

                    ConsoleService('ModuleInvoice / detect_prop_invoice_update_state / prevProps.invoice_hash !== props.invoice_hash', this.flag_console_log);

                }

            } else {

                // ok

                ConsoleService('ModuleInvoice / detect_prop_invoice_update_state / prevProps.invoice_hash !exists', this.flag_console_log);

            }

        }

        if (
            flag_permission
        ) {

            // ok

            ConsoleService('ModuleInvoice / detect_prop_invoice_update_state / the invoice data will be loaded within the state', this.flag_console_log);

            /*
            *
            * update the state
            * 
            * start
            * 
            */

            let newState = Object.assign({}, this.state);

            newState.flag_permission_show_form_element_recipient_company_name_input_or_react_select_creatable = 'input';

            newState.flag_permission_show_form_element_invoice_serial_input_or_react_select_creatable = 'input';

            // PK
            newState.form.attributes.invoice_object_store_id = props.invoice.invoice_object_store_id;

            // currency coin and currency symbol
            newState.form.attributes.invoice_currency = props.invoice.invoice_currency;
            newState.form.attributes.invoice_currency_symbol = props.invoice.invoice_currency_symbol;

            newState.form.attributes.invoice_type = props.invoice.invoice_type;

            /*
            
            should we take the VAT in consideration ?
            
            */

            newState.form.attributes.flag_should_the_vat_be_taken_in_consideration = props.invoice.flag_should_the_vat_be_taken_in_consideration;

            // newState.form.attributes.invoice_company_name = props.invoice.invoice_company_name;
            // newState.form.attributes.invoice_company_name = 'abc';// for testing purposes

            /*
            *
            * provider attributes
            * 
            * start
            * 
            */

            newState.form.attributes.provider_object_store_id = props.invoice.provider_object_store_id;// this attribute value should always be equal to 1

            newState.form.attributes.provider_external_id = props.invoice.provider_external_id;

            newState.form.attributes.provider_company_name = props.invoice.provider_company_name;
            newState.form.attributes.provider_vat_nr = props.invoice.provider_vat_nr;
            newState.form.attributes.provider_phone_nr = props.invoice.provider_phone_nr;
            newState.form.attributes.provider_bank_account = props.invoice.provider_bank_account;
            newState.form.attributes.provider_bank_name = props.invoice.provider_bank_name;

            // set the value as a json for the select element
            newState.form.select.provider_address_country_key = this.set_provider_country_key(props.invoice.provider_address_country_key);
            // set the value as "fr" for the attribute
            newState.form.attributes.provider_address_country_key = props.invoice.provider_address_country_key;
            // set the value as "France" for the attribute
            newState.form.attributes.provider_address_country_name = props.invoice.provider_address_country_name;

            newState.form.attributes.provider_address_county = props.invoice.provider_address_county;
            newState.form.attributes.provider_address_city = props.invoice.provider_address_city;
            newState.form.attributes.provider_address_street_address = props.invoice.provider_address_street_address;
            newState.form.attributes.provider_address_zip = props.invoice.provider_address_zip;

            /*
            *
            * provider attributes
            * 
            * stop
            * 
            */

            /*
            *
            * recipient data
            *
            * start
            * 
            */

            // PK
            newState.form.attributes.recipient_object_store_id = props.invoice.recipient_object_store_id;

            // external id
            newState.form.attributes.recipient_external_account_id = props.invoice.recipient_external_account_id;

            newState.form.attributes.recipient_company_name = props.invoice.recipient_company_name;

            // set the value as a json for the select element
            newState.form.select.recipient_account_type = this.set_recipient_account_type(props.invoice.recipient_account_type);
            // set the value as "individual" for the attribute
            newState.form.attributes.recipient_account_type = props.invoice.recipient_account_type;
            // set the value as "Individual" for the attribute
            newState.form.attributes.recipient_account_type_name = props.invoice.recipient_account_type_name;

            newState.form.attributes.recipient_addresses_city = props.invoice.recipient_addresses_city;

            // set the value as a json for the select element
            newState.form.select.recipient_addresses_country_key = this.set_recipient_country_key(props.invoice.recipient_addresses_country_key);
            // set the value as "fr" for the attribute
            newState.form.attributes.recipient_addresses_country_key = props.invoice.recipient_addresses_country_key;
            // set the value as "France" for the attribute
            newState.form.attributes.recipient_addresses_country_name = props.invoice.recipient_addresses_country_name;

            newState.form.attributes.recipient_addresses_street_address = props.invoice.recipient_addresses_street_address;
            newState.form.attributes.recipient_addresses_zip = props.invoice.recipient_addresses_zip;

            newState.form.attributes.recipient_vat_nr = props.invoice.recipient_vat_nr;

            newState.form.attributes.recipient_email = props.invoice.recipient_email;

            newState.form.attributes.recipient_phone_nr = props.invoice.recipient_phone_nr;

            /*
            *
            * recipient data
            *
            * stop
            * 
            */

            // invoice number
            newState.form.labels.invoice_number_label = props.invoice.invoice_number_label;
            newState.form.attributes.invoice_serial = props.invoice.invoice_serial;
            newState.form.attributes.invoice_number = props.invoice.invoice_number;

            // invoice date
            newState.form.labels.invoice_date_label = props.invoice.invoice_date_label;
            newState.form.attributes.invoice_date = props.invoice.invoice_date;

            // invoice items labels
            newState.form.labels.item_description_label = props.invoice.item_description_label;
            newState.form.labels.item_price_label = props.invoice.item_price_label;
            newState.form.labels.item_quantity_label = props.invoice.item_quantity_label;
            newState.form.labels.item_vat_label = props.invoice.item_vat_label;

            // the invoice items
            newState.form.attributes.invoice_items = props.invoice.invoice_items;

            // custom partial text
            newState.form.labels.invoice_subtotal_without_vat_for_vat_x = props.invoice.invoice_subtotal_without_vat_for_vat_x;
            newState.form.labels.invoice_total_vat_for_vat_x = props.invoice.invoice_total_vat_for_vat_x;

            // total without vat
            newState.form.labels.invoice_total_without_vat_label = props.invoice.invoice_total_without_vat_label;// label
            newState.form.attributes.invoice_total_without_vat = props.invoice.invoice_total_without_vat;// value

            // total vat
            newState.form.labels.invoice_total_vat_label = props.invoice.invoice_total_vat_label;// label
            newState.form.attributes.invoice_total_vat = props.invoice.invoice_total_vat;// value

            // vat type
            newState.form.attributes.invoice_vat_type = props.invoice.invoice_vat_type;// value

            // total
            newState.form.labels.invoice_total_label = props.invoice.invoice_total_label;// label
            newState.form.attributes.invoice_total = props.invoice.invoice_total;// value

            newState.form.attributes.invoice_language = props.invoice.invoice_language;// value

            ConsoleService('newState', this.flag_console_log);
            ConsoleService(newState, this.flag_console_log);

            this.setState(newState);

            /*
            *
            * update the state
            * 
            * stop
            * 
            */

        }

        //

    }

    /* DELIMITER */

    /*
    *
    * "textarea" element event listeners ( hover )
    * 
    * start
    * 
    */

    query_selector_all_textarea_elements = () => {

        /*
        * mousover/mouseout events for textatea elements
        * start
        */

        document.querySelectorAll('textarea').forEach(input => input.addEventListener('mouseover', () => { input.style.background = "rgb(249,245,198)"; }));

        document.querySelectorAll('textarea').forEach(input => input.addEventListener('mouseout', () => { input.style.background = "rgb(250,251,251)"; }));

        /*
        * mousover/mouseout events for textatea elements
        * stop
        */

        /*
        * mousover/mouseout events for input elements
        * start
        */

        document.querySelectorAll('input').forEach(input => input.addEventListener('mouseover', () => { input.style.background = "rgb(249,245,198)"; }));

        document.querySelectorAll('input').forEach(input => input.addEventListener('mouseout', () => { input.style.background = "rgb(250,251,251)"; }));

        /*
        * mousover/mouseout events for input elements
        * stop
        */

    }

    /*
    *
    * "textarea" element event listeners ( hover )
    * 
    * stop
    * 
    */

    /* DELIMITER */

    async load_all_recipients() {

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleInvoice / load_all_recipients()', this.flag_console_log);

        /*
        *
        * show the loading element
        * 
        * this element might appear and dissapear very fast :)
        *
        * start
        * 
        */

        let newState = Object.assign({}, this.state);

        newState.loading_element = true;

        this.setState(newState);

        /*
        *
        * show the loading element
        *
        * stop
        * 
        */

        let result = await IndexedDbReturnRecipientsComponent().then(
            (data) => {

                /*
                *
                * ok
                * 
                */

                ConsoleService('ModuleInvoice / load_all_recipients() / promise result => then', this.flag_console_log);

                ConsoleService('ModuleInvoice / load_all_recipients() / promise result => then / data', this.flag_console_log);
                ConsoleService(data, this.flag_console_log);

                return data;

            }
        ).catch(
            (data) => {

                /*
                *
                * error
                * 
                */

                ConsoleService('ModuleInvoice / load_all_recipients() / promise result => catch', this.flag_console_log);

                ConsoleService('ModuleInvoice / load_all_recipients() / promise result => catch / data', this.flag_console_log);
                ConsoleService(data, this.flag_console_log);

                return false;

            }
        );

        this.recipients = result;

        ConsoleService('ModuleInvoice this.recipients', this.flag_console_log);
        ConsoleService(this.recipients, this.flag_console_log);

        ConsoleService('ModuleInvoice / async-await / load_all_recipients() / result', this.flag_console_log);
        ConsoleService(result, this.flag_console_log);

        let recipient_company_name_select_options = [];

        if (
            result.length > 0
        ) {

            let index = 0;

            for (index in result) {

                ConsoleService('index', this.flag_console_log);
                ConsoleService(index, this.flag_console_log);

                ConsoleService('item', this.flag_console_log);
                ConsoleService(result[index], this.flag_console_log);

                let item = result[index];

                recipient_company_name_select_options.push(
                    {
                        value: item.recipient_object_store_id,
                        label: item.recipient_company_name
                    }
                );

            }

        }

        let newState2 = Object.assign({}, this.state);

        newState2.loading_element = false;

        newState2.flag_permission_loading_element_animation = false;

        newState2.form.select.recipient_company_name_select_options = recipient_company_name_select_options;

        this.setState(newState2);

    }

    /* DELIMITER */

    /*
    *
    * event handlers
    * 
    * start 
    * 
    */

    /*
    * form element => label changed handler / start
    */

    formElementLabelChangedHandler = (event) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleInvoice / formElementLabelChangedHandler', this.flag_console_log);

        ConsoleService('event.target.name', this.flag_console_log);
        ConsoleService(event.target.name, this.flag_console_log);

        ConsoleService('event.target.value', this.flag_console_log);
        ConsoleService(event.target.value, this.flag_console_log);

        /*
        *
        * assign the new state
        *
        * start
        * 
        */

        let newState = Object.assign({}, this.state);

        newState.form.errors[event.target.name] = (event.target.value === '' ? true : false);

        newState.form.touched[event.target.name] = true;

        // ONLY "labels"
        newState.form.labels[event.target.name] = event.target.value;

        this.setState(newState);

        /*
        *
        * assign the new state
        *
        * stop
        * 
        */

    }

    /*
    * form element => label changed handler / stop
    */

    // form element changed handler
    formElementChangedHandler = (event) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleInvoice / formElementChangedHandler', this.flag_console_log);

        ConsoleService('event', this.flag_console_log);
        ConsoleService(event, this.flag_console_log);

        ConsoleService('event.target.name', this.flag_console_log);
        ConsoleService(event.target.name, this.flag_console_log);

        let name = event.target.name;

        ConsoleService('event.target.value', this.flag_console_log);
        ConsoleService(event.target.value, this.flag_console_log);

        let value = event.target.value;

        /*
        *
        * assign the new state
        *
        * start
        * 
        */

        let newState = Object.assign({}, this.state);

        newState.form.errors[name] = (value === '' ? true : false);

        newState.form.touched[name] = true;

        // ONLY "attributes"
        newState.form.attributes[name] = value;

        this.setState(newState);

        /*
        *
        * assign the new state
        *
        * stop
        * 
        */

        if (
            name === 'invoice_type'
        ) {

            /*

            ok

            store the last 'invoice_type_ value

            */

            /*
            
            loading the setting record
            
            start
            
            */

            let payload = {
                object_store: 'settings_object_store',
                index_name: 'group, key',
                value: [
                    'variables',
                    'invoice_type'
                ]
            }

            IndexedDbLoadOneComponent(payload)
                .then(
                    (data) => {

                        /*
                        *
                        * ok
                        * 
                        */

                        ConsoleService('ModuleInvoice / IndexedDbLoadOneComponent / new promise then', this.flag_console_log);

                        ConsoleService('ModuleInvoice / IndexedDbLoadOneComponent / new promise => then / data', this.flag_console_log);
                        ConsoleService(data, this.flag_console_log);

                        data.value = value;

                        /*
                        
                        update the setting record
                        
                        start
                        
                        */

                        IndexedDbSettingsCreateOrUpdateComponent(data).then(
                            (data) => {

                                /*
                                *
                                * ok
                                * 
                                */

                                ConsoleService('ModuleInvoice / IndexedDbSettingsCreateOrUpdateComponent / new promise then', this.flag_console_log);

                                ConsoleService('ModuleInvoice / IndexedDbSettingsCreateOrUpdateComponent / new promise => then / data', this.flag_console_log);
                                ConsoleService(data, this.flag_console_log);

                                //

                            }
                        ).catch(
                            (data) => {

                                /*
                                *
                                * error
                                * 
                                */

                                ConsoleService('ModuleInvoice / IndexedDbSettingsCreateOrUpdateComponent / new promise => catch', this.flag_console_log);

                                ConsoleService('ModuleInvoice / IndexedDbSettingsCreateOrUpdateComponent / new promise => catch / data', this.flag_console_log);
                                ConsoleService(data, this.flag_console_log);

                                //

                            }
                        );

                        /*
                        
                        update the setting record
                        
                        stop
                        
                        */

                    }
                )
                .catch(
                    (data) => {

                        /*
                        *
                        * error
                        * 
                        */

                        ConsoleService('ModuleInvoice / IndexedDbLoadOneComponent / new promise => catch', this.flag_console_log);

                        ConsoleService('ModuleInvoice / IndexedDbLoadOneComponent / new promise => catch / data', this.flag_console_log);
                        ConsoleService(data, this.flag_console_log);

                        //

                    }
                );

            /*
            
            loading the setting record
            
            stop
            
            */

            //

        }

        //

    }

    /* DELIMITER */

    /* DELIMITER */

    // form element date changed handler
    formElementDateChangedHandler = (event) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleInvoice / formElementDateChangedHandler', this.flag_console_log);

        ConsoleService('event', this.flag_console_log);
        ConsoleService(event, this.flag_console_log);

        let newState = Object.assign({}, this.state);

        newState.form.errors.invoice_date = (event === null ? true : false);
        newState.form.touched.invoice_date = true;

        newState.form.attributes.invoice_date = event;

        this.setState(newState);

    }

    // form input element onBlur handler
    formInputElementOnBlurHandler = (event) => {

        /*
        * this handler is not required to handler only attribute or only labels
        * this handler works with the state form "errors" and "touched" arrays
        */


        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleInvoice / formInputElementOnBlurHandler', this.flag_console_log);

        ConsoleService('event', this.flag_console_log);
        ConsoleService(event, this.flag_console_log);

        ConsoleService('event.target.name', this.flag_console_log);
        ConsoleService(event.target.name, this.flag_console_log);

        ConsoleService('event.target.value', this.flag_console_log);
        ConsoleService(event.target.value, this.flag_console_log);

        /*
        *
        * assign the new state
        *
        * start
        * 
        */

        let newState = Object.assign({}, this.state);

        newState.form.errors[event.target.name] = (event.target.value === '' ? true : false);

        newState.form.touched[event.target.name] = true;

        this.setState(newState);

        /*
        *
        * assign the new state
        *
        * stop
        * 
        */

    }

    // form react select element "recipient account type" changed handler
    formReactSelectElementRecipientAccountTypeChangedHandler = (event) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleInvoice / form react select element recipient account type changed handler', this.flag_console_log);

        ConsoleService('event', this.flag_console_log);
        ConsoleService(event, this.flag_console_log);

        /*
        *
        * assign the new state
        *
        * start
        * 
        */

        let newState = Object.assign({}, this.state);

        newState.form.attributes.recipient_account_type = event.value;

        newState.form.select.recipient_account_type = event;

        newState.form.errors.recipient_account_type = false;

        newState.form.touched.recipient_account_type = true;

        newState.form.attributes.recipient_account_type_name = this.set_recipient_account_type_name(event.value);

        ConsoleService('newState', this.flag_console_log);
        ConsoleService(newState, this.flag_console_log);

        this.setState(newState);

        /*
        *
        * assign the new state
        *
        * stop
        * 
        */

    }

    //form radio input element "recipient account type" changed handler
    formRadioInputElementRecipientAccountTypeChangedHandler = (event) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleInvoice / form radio input element recipient account type changed handler', this.flag_console_log);

        ConsoleService('event', this.flag_console_log);
        ConsoleService(event, this.flag_console_log);

        ConsoleService('event.currentTarget.value', this.flag_console_log);
        ConsoleService(event.currentTarget.value, this.flag_console_log);

        /*
        *
        * assign the new state
        *
        * start
        * 
        */

        let newState = Object.assign({}, this.state);

        newState.form.attributes.recipient_account_type = event.currentTarget.value;

        newState.form.attributes.recipient_account_type_name = this.set_recipient_account_type_name(event.currentTarget.value);

        newState.form.errors[event.currentTarget.name] = (event.currentTarget.value === '' ? true : false);

        newState.form.touched[event.currentTarget.name] = true;

        ConsoleService('newState', this.flag_console_log);
        ConsoleService(newState, this.flag_console_log);

        this.setState(newState);

        /*
        *
        * assign the new state
        *
        * stop
        * 
        */

    }

    // form select element "invoice serial" changed handler
    formSelectElementInvoiceSerialChangeHandler = (newValue, actionMeta) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleInvoice / formSelectElementInvoiceSerialChangeHandler', this.flag_console_log);

        ConsoleService('this.state.form.attributes.invoice_serial', this.flag_console_log);
        ConsoleService('*' + this.state.form.attributes.invoice_serial + '*', this.flag_console_log);

        ConsoleService('newValue', this.flag_console_log);
        ConsoleService('*' + newValue + '*', this.flag_console_log);
        ConsoleService(newValue, this.flag_console_log);

        ConsoleService('actionMeta', this.flag_console_log);
        ConsoleService(actionMeta, this.flag_console_log);

        let flag_permission = true;

        if (
            newValue !== this.state.form.attributes.invoice_serial
        ) {

            // ok

            ConsoleService('ok / new value != state value', this.flag_console_log);

            //

        } else {

            // error

            ConsoleService('error / new value == state value', this.flag_console_log);

            flag_permission = false;

            //

        }

        if (
            flag_permission
        ) {

            // ok

            ConsoleService('ok / the "invoice serial" state form attribute will be updated', this.flag_console_log);

            /*
            *
            * assign the new state
            *
            * start
            * 
            */

            let newState = Object.assign({}, this.state);

            newState.form.attributes.invoice_serial = newValue.label;// as string

            newState.form.select.invoice_serial = newValue;// as json

            newState.form.errors.invoice_serial = (newValue === '' ? true : false);

            newState.form.touched.invoice_serial = true;

            if (
                actionMeta.action === 'create-option' ||
                actionMeta.action === 'select-option'
            ) {

                // ok

                /*
                * we need to switch the element, from `select` to `input`
                */

                newState.flag_permission_show_form_element_invoice_serial_input_or_react_select_creatable = 'input';

                if (
                    actionMeta.action === 'create-option'
                ) {

                    // ok

                    newState.form.attributes.invoice_number = 1;// as int

                    //

                }

                if (
                    actionMeta.action === 'select-option'
                ) {

                    // ok

                    newState.form.attributes.invoice_number = parseInt(newValue.settings_value) + 1;// as int

                    //

                }

                newState.form.errors.invoice_number = false;

                newState.form.touched.invoice_number = true;

                //

            }

            this.setState(newState);

            /*
            *
            * assign the new state
            *
            * stop
            * 
            */

        } else {

            // error

            ConsoleService('ok / the "recipient company name" state form attribute will NOT be updated', this.flag_console_log);

        }

        //

    }

    // form select element "invoice serial" input changed handler
    formSelectElementInvoiceSerialInputChangeHandler = (inputValue, actionMeta) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleInvoice formSelectElementInvoiceSerialInputChangeHandler', this.flag_console_log);

        ConsoleService('this.state.form.attributes', this.flag_console_log);
        ConsoleService(this.state.form.attributes, this.flag_console_log);

        ConsoleService('this.state.form.attributes.invoice_serial', this.flag_console_log);
        ConsoleService('*' + this.state.form.attributes.invoice_serial + '*', this.flag_console_log);

        ConsoleService('inputValue', this.flag_console_log);
        ConsoleService('*' + inputValue + '*', this.flag_console_log);
        ConsoleService(inputValue, this.flag_console_log);

        ConsoleService('actionMeta', this.flag_console_log);
        ConsoleService(actionMeta, this.flag_console_log);

        let flag_permission = true;

        if (
            inputValue !== this.state.form.attributes.invoice_serial
        ) {

            // ok

            ConsoleService('ok / input value != state value', this.flag_console_log);

        } else {

            // error

            ConsoleService('error / input value == state value', this.flag_console_log);

            flag_permission = false;

        }

        if (
            flag_permission
        ) {

            // ok

            if (
                actionMeta.action === 'input-blur' ||
                actionMeta.action === 'menu-close'
            ) {

                // error ?

                ConsoleService('error / actionMeta.action IN [ input-blur, menu-close ]', this.flag_console_log);

                if (
                    actionMeta.action === 'input-blur' &&
                    this.state.form.attributes.invoice_serial !== ''
                ) {

                    // ok

                    /*
                    *
                    * !!! change the value !!!
                    *
                    * !!! Darth Vader VS Luke Skywalker !!!
                    * 
                    */

                    let newState = Object.assign({}, this.state);

                    newState.flag_permission_show_form_element_invoice_serial_input_or_react_select_creatable = 'input';

                    this.setState(newState);

                    ConsoleService('Darth Vader, by using the dark force, has won this battle and has updated the state !!!', this.flag_console_log);

                    //

                }

                /*
                * !!! Enough battles for today !!!
                */

                flag_permission = false;

            } else {

                // ok

                ConsoleService('ok / actionMeta.action !IN [ input-blur, menu-close ]', this.flag_console_log);

            }

        }

        if (
            flag_permission
        ) {

            // ok

            if (
                actionMeta.action === 'input-change'
            ) {

                // error ? => NO

                /*
                *
                * !!! change the value as it gets written we must !!!
                *
                * !!! use the Jedi FORCE Anakin !!!
                * 
                */

                ConsoleService('error ? / actionMeta.action IN [ input-change ]', this.flag_console_log);

                ConsoleService('inputValue', this.flag_console_log);
                ConsoleService(inputValue, this.flag_console_log);

                let newState = Object.assign({}, this.state);

                newState.form.attributes.invoice_serial = inputValue;// as string

                this.setState(newState);

                ConsoleService('Anakin, a Jedi Master, has updated the state !!!', this.flag_console_log);

                /*
                * !!! Enough battles for today !!!
                */

                flag_permission = false;

            } else {

                // ok

                ConsoleService('ok / actionMeta.action !IN [ input-change ]', this.flag_console_log);

            }

        }

        if (
            flag_permission
        ) {

            // ok

            ConsoleService('ok / the "invoice serial" state form attribute will be updated', this.flag_console_log);

            /*
            *
            * assign the new state
            *
            * start
            * 
            */

            let newState = Object.assign({}, this.state);

            /*

            invoice serial

            start

            */

            newState.form.attributes.invoice_serial = inputValue.value;// as string

            newState.form.select.invoice_serial = inputValue;// as json

            newState.form.errors.invoice_serial = (inputValue === '' ? true : false);

            newState.form.touched.invoice_serial = true;

            /*

            invoice serial

            stop

            */

            /*

            invoice number

            start

            */

            newState.form.attributes.invoice_number = parseInt(inputValue.settings_value) + 1;// as int

            newState.form.errors.invoice_number = (inputValue === '' ? true : false);

            newState.form.touched.invoice_number = true;

            /*

            invoice number

            stop

            */

            this.setState(newState);

            /*
            *
            * assign the new state
            *
            * stop
            * 
            */

        } else {

            // error

            ConsoleService('error / the "recipient company name" state form attribute will NOT be updated', this.flag_console_log);

        }

    }

    // form select element "recipient company name" changed handler
    formSelectElementRecipientCompanyNameChangeHandler = (newValue, actionMeta) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleInvoice / formSelectElementRecipientCompanyNameChangeHandler', this.flag_console_log);

        ConsoleService('this.state.form.attributes.recipient_company_name', this.flag_console_log);
        ConsoleService('*' + this.state.form.attributes.recipient_company_name + '*', this.flag_console_log);

        ConsoleService('newValue', this.flag_console_log);
        ConsoleService('*' + newValue + '*', this.flag_console_log);
        ConsoleService(newValue, this.flag_console_log);

        ConsoleService('actionMeta', this.flag_console_log);
        ConsoleService(actionMeta, this.flag_console_log);

        let flag_permission = true;

        if (
            newValue !== this.state.form.attributes.recipient_company_name
        ) {

            // ok

            ConsoleService('ok / new value != state value', this.flag_console_log);

            //

        } else {

            // error

            ConsoleService('error / new value == state value', this.flag_console_log);

            flag_permission = false;

            //

        }

        if (
            flag_permission
        ) {

            // ok

            if (
                actionMeta.action === 'create-option'
            ) {

                // ok

            }

            //

        }

        if (
            flag_permission
        ) {

            // ok

            ConsoleService('ok / the "recipient company name" state form attribute will be updated', this.flag_console_log);

            /*
            *
            * assign the new state
            *
            * start
            * 
            */

            let newState = Object.assign({}, this.state);

            newState.form.attributes.recipient_company_name = newValue.label;

            newState.form.select.recipient_company_name = newValue;

            if (
                actionMeta.action === 'create-option' ||
                actionMeta.action === 'select-option'
            ) {

                // ok

                /*
                * we need to switch the element, from `select` to `input`
                */

                newState.flag_permission_show_form_element_recipient_company_name_input_or_react_select_creatable = 'input';

                if (
                    actionMeta.action === 'create-option'
                ) {

                    // ok

                    /*
                    *
                    * this is a new "recipient company name" value
                    * this new value does not have a ID
                    * 
                    */

                    newState.form.attributes.recipient_object_store_id = null;

                    //

                }

                if (
                    actionMeta.action === 'select-option'
                ) {

                    // ok

                    /*
                    *
                    * this is a old "recipient company name" value
                    * this old value has a ID
                    * 
                    */

                    newState.form.attributes.recipient_object_store_id = newValue.value;

                    /*
                    * we need to find the chosen recipient data within the recipients list
                    */

                    let recipient = {};

                    let flag_found = false;

                    if (
                        this.recipients.length > 0
                    ) {

                        // ok

                        /*
                        *
                        * the recipients list is not empty
                        *
                        * we will parse the recipients list
                        * 
                        */

                        let index = 0;

                        for (index in this.recipients) {

                            // load the recipient item
                            let item = this.recipients[index];

                            // is this the recipient that we are searching for ?
                            if (
                                parseInt(item.recipient_object_store_id) === parseInt(newValue.value)
                            ) {

                                // ok

                                /*
                                * we have found the recipient data
                                */

                                recipient = item;

                                flag_found = true;

                                break;

                                //

                            }

                            //

                        }

                        //

                    }

                    if (
                        flag_found
                    ) {

                        // ok

                        Object.keys(this.official_recipient_attributes).map(
                            (attribute) => {

                                ConsoleService('attribute', this.flag_console_log);
                                ConsoleService(attribute, this.flag_console_log);
                                ConsoleService(recipient[attribute], this.flag_console_log);

                                /*
                                *
                                * we should always set the attribute value
                                * 
                                */

                                // setting the attribute value
                                newState.form.attributes[attribute] = recipient[attribute];

                                // setting the attribute error property
                                newState.form.errors[attribute] = (recipient[attribute].length ? false : true);

                                // setting the attribute touched property
                                newState.form.touched[attribute] = true;

                                if (
                                    this.state.form.select.hasOwnProperty(attribute)
                                ) {

                                    // ok

                                    /*
                                    *
                                    * the element type is a "select/dropdown"
                                    * 
                                    * we need to set the json for the select/dropdown element
                                    * 
                                    */

                                    newState.form.select[attribute] = component_compute_select_option(this.state.form.select[attribute + '_select_options'], recipient[attribute]);

                                    //

                                } else {

                                    // ok

                                    /*
                                    *
                                    * the element type is a "input"
                                    *
                                    * nothing to do here
                                    * 
                                    */

                                    //

                                }

                                return true;

                            }
                        );

                    }

                    //

                }

                //

            }

            this.setState(newState);

            /*
            *
            * assign the new state
            *
            * stop
            * 
            */

        } else {

            // error

            ConsoleService('ok / the "recipient company name" state form attribute will NOT be updated', this.flag_console_log);

        }

        //

    }

    // form select element "recipient company name" input changed handler
    formSelectElementRecipientCompanyNameInputChangeHandler = (inputValue, actionMeta) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleInvoice formSelectElementRecipientCompanyNameInputChangeHandler', this.flag_console_log);

        ConsoleService('this.state.form.attributes', this.flag_console_log);
        ConsoleService(this.state.form.attributes, this.flag_console_log);

        ConsoleService('this.state.form.attributes.recipient_company_name', this.flag_console_log);
        ConsoleService('*' + this.state.form.attributes.recipient_company_name + '*', this.flag_console_log);

        ConsoleService('inputValue', this.flag_console_log);
        ConsoleService('*' + inputValue + '*', this.flag_console_log);
        ConsoleService(inputValue, this.flag_console_log);

        ConsoleService('actionMeta', this.flag_console_log);
        ConsoleService(actionMeta, this.flag_console_log);

        let flag_permission = true;

        if (
            inputValue !== this.state.form.attributes.recipient_company_name
        ) {

            // ok

            ConsoleService('ok / input value != state value', this.flag_console_log);

        } else {

            // error

            ConsoleService('error / input value == state value', this.flag_console_log);

            flag_permission = false;

        }

        if (
            flag_permission
        ) {

            // ok

            if (
                actionMeta.action === 'input-blur' ||
                actionMeta.action === 'menu-close'
            ) {

                // error ?

                ConsoleService('error / actionMeta.action IN [ input-blur, menu-close ]', this.flag_console_log);

                if (
                    actionMeta.action === 'input-blur' &&
                    this.state.form.attributes.recipient_company_name !== ''
                ) {

                    // ok

                    /*
                    *
                    * !!! change the value !!!
                    *
                    * !!! Darth Vader VS Luke Skywalker !!!
                    * 
                    */

                    let newState = Object.assign({}, this.state);

                    newState.flag_permission_show_form_element_recipient_company_name_input_or_react_select_creatable = 'input';

                    this.setState(newState);

                    ConsoleService('Darth Vader, by using the dark force, has won this battle and has updated the state !!!', this.flag_console_log);

                    //

                }

                /*
                * !!! Enough battles for today !!!
                */

                flag_permission = false;

            } else {

                // ok

                ConsoleService('ok / actionMeta.action !IN [ input-blur, menu-close ]', this.flag_console_log);

            }

        }

        if (
            flag_permission
        ) {

            // ok

            if (
                actionMeta.action === 'input-change'
            ) {

                // error ? => NO

                /*
                *
                * !!! change the value as it gets written we must !!!
                *
                * !!! use the Jedi FORCE Anakin !!!
                * 
                */

                ConsoleService('error ? / actionMeta.action IN [ input-change ]', this.flag_console_log);

                ConsoleService('inputValue', this.flag_console_log);
                ConsoleService(inputValue, this.flag_console_log);

                let newState = Object.assign({}, this.state);

                newState.form.attributes.recipient_company_name = inputValue;

                this.setState(newState);

                ConsoleService('Anakin, a Jedi Master, has updated the state !!!', this.flag_console_log);

                /*
                * !!! Enough battles for today !!!
                */

                flag_permission = false;

            } else {

                // ok

                ConsoleService('ok / actionMeta.action !IN [ input-change ]', this.flag_console_log);

            }

        }

        if (
            flag_permission
        ) {

            // ok

            ConsoleService('ok / the "recipient company name" state form attribute will be updated', this.flag_console_log);

            /*
            *
            * assign the new state
            *
            * start
            * 
            */

            let newState = Object.assign({}, this.state);

            newState.form.attributes.recipient_company_name = inputValue.value;

            newState.form.select.recipient_company_name = inputValue;

            this.setState(newState);

            /*
            *
            * assign the new state
            *
            * stop
            * 
            */

        } else {

            // error

            ConsoleService('error / the "recipient company name" state form attribute will NOT be updated', this.flag_console_log);

        }

    }

    // form select element "recipient addresses country key" changed handler
    formSelectElementRecipientAddressesCountryKeyChangedHandler = (event) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleInvoice / formSelectElementRecipientAddressesCountryKeyChangedHandler', this.flag_console_log);

        ConsoleService('event', this.flag_console_log);
        ConsoleService(event, this.flag_console_log);

        /*
        *
        * assign the new state
        *
        * start
        * 
        */

        let newState = Object.assign({}, this.state);

        newState.form.attributes.recipient_addresses_country_key = event.value;

        newState.form.attributes.recipient_addresses_country_name = event.label;

        newState.form.select.recipient_addresses_country_key = event;

        newState.form.errors.recipient_addresses_country_key = false;

        newState.form.touched.recipient_addresses_country_key = true;

        this.setState(newState);

        /*
        *
        * assign the new state
        *
        * stop
        * 
        */

    }

    // form react select element "provider addresses country key" changed handler
    formReactSelectElementProviderAddressCountryKeyChangedHandler = (event) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleInvoice / formReactSelectElementProviderAddressCountryKeyChangedHandler', this.flag_console_log);

        ConsoleService('event', this.flag_console_log);
        ConsoleService(event, this.flag_console_log);

        /*
        *
        * assign the new state
        *
        * start
        * 
        */

        let newState = Object.assign({}, this.state);

        newState.form.attributes.provider_address_country_key = event.value;

        newState.form.attributes.provider_address_country_name = event.label;

        newState.form.select.provider_address_country_key = event;

        newState.form.errors.provider_address_country_key = false;

        newState.form.touched.provider_address_country_key = true;

        this.setState(newState);

        /*
        *
        * assign the new state
        *
        * stop
        * 
        */

    }

    /*
     *
     * general handler
     * 
     * form react select element on blur handler
     * 
     * start
     * 
     */

    formReactSelectElementOnBlurHandler = (event, attribute = '') => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleInvoice / formReactSelectElementOnBlurHandler', this.flag_console_log);

        ConsoleService('event', this.flag_console_log);
        ConsoleService(event, this.flag_console_log);

        ConsoleService('attribute', this.flag_console_log);
        ConsoleService(attribute, this.flag_console_log);

        let flag_permission = true;

        if (
            attribute.length
        ) {

            // ok

        } else {

            // error

            flag_permission = false;

        }

        if (
            flag_permission
        ) {

            // ok

            if (
                this.state.form.touched.hasOwnProperty(attribute)
            ) {

                // ok

            } else {

                // error

                flag_permission = false;

            }

        }

        if (
            flag_permission
        ) {

            // ok

            /*
            *
            * assign the new state
            *
            * start
            * 
            */

            let newState = Object.assign({}, this.state);

            newState.form.touched[attribute] = true;

            ConsoleService('newState', this.flag_console_log);
            ConsoleService(newState, this.flag_console_log);

            this.setState(newState);

            /*
            *
            * assign the new state
            *
            * stop
            * 
            */

        }

        //

    }

    /*
     *
     * general handler
     * 
     * form react select element on blur handler
     * 
     * stop
     * 
     */

    addItemHandler = (event) => {

        event.preventDefault();

        ConsoleService('ModuleInvoice / add item handler', this.flag_console_log);

        let invoice_items = [...this.state.form.attributes.invoice_items];

        let item = {
            description: '',// translation[this.default_language].general.item_description,
            quantity: 0.00,
            price: 0.00,
            vat: this.state.standard_vat_value
        };

        invoice_items.push(item);

        /*
        *
        * assign the new state
        *
        * start
        * 
        */

        let newState = Object.assign({}, this.state);

        newState.form.attributes.invoice_items = invoice_items;

        newState.flag_should_focus_on_last_invoice_item = true;

        if (
            this.state.form.attributes.flag_should_the_vat_be_taken_in_consideration
        ) {

            /*

            ok

            take the VAT in consideration

            */

            newState.form.attributes.invoice_vat_type = component_compute_vat_type(this, invoice_items);

            newState.form.attributes.invoice_total_without_vat = component_compute_total_without_vat(this, invoice_items);// ok 1.11.2019

            newState.form.attributes.invoice_total_vat = component_compute_total_vat(this, invoice_items);// ok 1.11.2019

            //

        }

        newState.form.attributes.invoice_total = component_compute_total(this, invoice_items);// ok 1.11.2019;

        /*
        *
        * validating the invoice items
        *
        * start
        * 
        */

        let obj_result = component_form_errors_invoice_items_validation(this.state.form);

        ConsoleService('obj_error', this.flag_console_log);
        ConsoleService(obj_result, this.flag_console_log);

        newState.form.errors.invoice_items = obj_result.flag_error;
        newState.form.touched.invoice_items = obj_result.flag_error;

        newState.form.errors.invoice_items_data = obj_result.arr_result;
        newState.form.touched.invoice_items_data = obj_result.arr_result;

        /*
        *
        * validating the invoice items
        *
        * stop
        * 
        */

        this.setState(newState);

        //this.state.form.attributes.invoice_items[this.state.form.attributes.invoice_items.length - 1].description.focus();

        /*
        *
        * assign the new state
        *
        * stop
        * 
        */

    }

    focusOnItemDescriptionElement() {

        if (
            this.state.flag_should_focus_on_last_invoice_item
        ) {

            /*
            
            ok
            
            */

            let items_count = this.state.form.attributes.invoice_items.length;

            document.getElementById('invoiceItemDescription_' + (items_count - 1)).focus();

            let newState = Object.assign({}, this.state);

            newState.flag_should_focus_on_last_invoice_item = false;

            this.setState(newState);

            //

        }

        //

    }

    itemDescriptionChangeHandler = (event, itemIndex) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleInvoice / itemDescriptionChangeHandler', this.flag_console_log);

        ConsoleService('event', this.flag_console_log);
        ConsoleService(event, this.flag_console_log);

        ConsoleService('event.target', this.flag_console_log);
        ConsoleService(event.target, this.flag_console_log);

        ConsoleService('event.target.name', this.flag_console_log);
        ConsoleService(event.target.name, this.flag_console_log);

        ConsoleService('event.target.value', this.flag_console_log);
        ConsoleService(event.target.value, this.flag_console_log);

        ConsoleService('itemIndex', this.flag_console_log);
        ConsoleService(itemIndex, this.flag_console_log);

        let invoice_items = [...this.state.form.attributes.invoice_items];

        invoice_items[itemIndex]['description'] = event.target.value;

        /*
        *
        * assign the new state
        *
        * start
        * 
        */

        let newState = Object.assign({}, this.state);

        newState.form.attributes.invoice_items = invoice_items;

        /*
        *
        * validating the invoice items
        *
        * start
        * 
        */

        let obj_result = component_form_errors_invoice_items_validation(this.state.form);

        ConsoleService('obj_error', this.flag_console_log);
        ConsoleService(obj_result, this.flag_console_log);

        newState.form.errors.invoice_items = obj_result.flag_error;
        newState.form.touched.invoice_items = obj_result.flag_error;

        newState.form.errors.invoice_items_data = obj_result.arr_result;
        newState.form.touched.invoice_items_data = obj_result.arr_result;

        /*
        *
        * validating the invoice items
        *
        * stop
        * 
        */

        this.setState(newState);

        /*
        *
        * assign the new state
        *
        * stop
        * 
        */

    }

    /* DELIMITER */

    /*
    * item "type" [quantity/price] onBlur event handler
    */

    itemOnBlurHandler = (event, type, itemIndex) => {

        ConsoleService('itemOnBlurHandler => item type="' + type + '" onBlur handler', this.flag_console_log);

        ConsoleService('event.target.value', this.flag_console_log);
        ConsoleService(event.target.value, this.flag_console_log);

        ConsoleService('type', this.flag_console_log);
        ConsoleService(type, this.flag_console_log);

        ConsoleService('itemIndex', this.flag_console_log);
        ConsoleService(itemIndex, this.flag_console_log);

        let value = event.target.value;

        ConsoleService('value', this.flag_console_log);
        ConsoleService(value, this.flag_console_log);

        if (
            parseFloat(value)
        ) {

            // ok

            //console.log('if parse float value => if');

            ConsoleService('value', this.flag_console_log);
            ConsoleService(value, this.flag_console_log);

            value = parseFloat(value).toFixed(2);

            ConsoleService('value', this.flag_console_log);
            ConsoleService(value, this.flag_console_log);

            //

        } else {

            //

            value = 0.00;

            //console.log('if parse float value => else');

            //

        }

        ConsoleService('value', this.flag_console_log);
        ConsoleService(value, this.flag_console_log);

        ConsoleService('typeof value', this.flag_console_log);
        ConsoleService(typeof value, this.flag_console_log);

        let invoice_items = JSON.parse(JSON.stringify(this.state.form.attributes.invoice_items));

        if (
            invoice_items[itemIndex][type] !== value || true
        ) {

            // ok
            // we should update the item

            ConsoleService('invoice_items state will be updated', this.flag_console_log);

            invoice_items[itemIndex][type] = value;

            ConsoleService('invoice_items[itemIndex][type]', this.flag_console_log);
            ConsoleService(invoice_items[itemIndex][type], this.flag_console_log);

            /*
            *
            * assign the new state
            *
            * start
            * 
            */

            let newState = Object.assign({}, this.state);

            newState.form.attributes.invoice_items = invoice_items;

            /*
            *
            * validating the invoice items
            *
            * start
            * 
            */

            let obj_result = component_form_errors_invoice_items_validation(this.state.form);

            ConsoleService('obj_error', this.flag_console_log);
            ConsoleService(obj_result, this.flag_console_log);

            newState.form.errors.invoice_items = obj_result.flag_error;
            newState.form.touched.invoice_items = obj_result.flag_error;

            newState.form.errors.invoice_items_data = obj_result.arr_result;
            newState.form.touched.invoice_items_data = obj_result.arr_result;

            /*
            *
            * validating the invoice items
            *
            * stop
            * 
            */

            if (
                this.state.form.attributes.flag_should_the_vat_be_taken_in_consideration
            ) {

                /*
    
                ok
    
                take the VAT in consideration
    
                */

                newState.form.attributes.invoice_vat_type = component_compute_vat_type(this, invoice_items);

                newState.form.attributes.invoice_total_without_vat = component_compute_total_without_vat(this, invoice_items);// ok 1.11.2019

                newState.form.attributes.invoice_total_vat = component_compute_total_vat(this, invoice_items);// ok 1.11.2019

                //

            }

            newState.form.attributes.invoice_total = component_compute_total(this, invoice_items);// ok 1.11.2019;

            ConsoleService('newState', this.flag_console_log);
            ConsoleService(newState, this.flag_console_log);

            this.setState(newState);

            /*
            *
            * assign the new state
            *
            * stop
            * 
            */

        } else {

            // ok
            // nothing to do here

            ConsoleService('invoice_items state will not be updated', this.flag_console_log);

        }

    }

    /*
    * item "type" [quantity/price] onChange event handler
    */

    itemChangeHandler = (event, type, itemIndex) => {

        ConsoleService('itemChangeHandler => item type="' + type + '" onChange handler', this.flag_console_log);

        ConsoleService('event.target.value', this.flag_console_log);
        ConsoleService(event.target.value, this.flag_console_log);

        ConsoleService('type', this.flag_console_log);
        ConsoleService(type, this.flag_console_log);

        ConsoleService('itemIndex', this.flag_console_log);
        ConsoleService(itemIndex, this.flag_console_log);

        let value = event.target.value;

        ConsoleService('parseFloat(value)', this.flag_console_log);
        ConsoleService(parseFloat(value), this.flag_console_log);

        let flag_permission = true;

        if (
            flag_permission
        ) {

            // ok
            // at this stage, we accept almost everything [ empty string, int, float, etc ... ]

            if (
                value === ''
            ) {

                /*
                *
                * ok
                * 
                * the value is a empty string
                * 
                */

                ConsoleService('value === empty => ok', this.flag_console_log);

            } else {

                /*
                *
                * ok
                * 
                * only numbers
                * 
                * +x or -x
                * +x.y or -x.y
                * +x.yz or - x.yz
                * 
                * 0
                * 0.0
                * 0.00
                * 
                */

                ConsoleService('value !== empty => ok', this.flag_console_log);

                //value = parseFloat(value);

                if (
                    isNaN(value)
                ) {

                    // the value is not a number

                    value = 0.00;

                } else {

                    // the value is a number

                }

            }

            //

        }

        ConsoleService('value', this.flag_console_log);
        ConsoleService(value, this.flag_console_log);

        let invoice_items = JSON.parse(JSON.stringify(this.state.form.attributes.invoice_items));

        let invoice_items_copy = JSON.parse(JSON.stringify(this.state.form.attributes.invoice_items));

        if (
            invoice_items[itemIndex][type] !== parseFloat(value) || true
        ) {

            // ok
            // we should update the item

            //console.log('typeof value');
            //console.log(typeof value);

            //value = parseFloat(value);

            //console.log(typeof value);

            //console.log('parseFloat("")');
            //console.log(parseFloat(''));

            ConsoleService('invoice_items[itemIndex][type] !== parseFloat(value) || true', this.flag_console_log);

            invoice_items[itemIndex][type] = (value === '' ? '' : value);// this has effect on the memmory

            ConsoleService('invoice_items[itemIndex][type]', this.flag_console_log);
            ConsoleService(invoice_items[itemIndex][type], this.flag_console_log);

            ConsoleService('invoice_items', this.flag_console_log);
            ConsoleService(invoice_items, this.flag_console_log);

            invoice_items_copy[itemIndex][type] = (value === '' ? 0 : value);// this has effect on the interface

            ConsoleService('invoice_items_copy[itemIndex][type]', this.flag_console_log);
            ConsoleService(invoice_items_copy[itemIndex][type], this.flag_console_log);

            ConsoleService('invoice_items_copy', this.flag_console_log);
            ConsoleService(invoice_items_copy, this.flag_console_log);

            /*
            *
            * assign the new state
            *
            * start
            * 
            */

            let newState = Object.assign({}, this.state);

            newState.form.attributes.invoice_items = invoice_items;

            ConsoleService('newState.form.attributes.invoice_items', this.flag_console_log);
            ConsoleService(newState.form.attributes.invoice_items, this.flag_console_log);

            if (
                this.state.form.attributes.flag_should_the_vat_be_taken_in_consideration
            ) {

                /*

                ok

                take the VAT in consideration

                */

                if (
                    type === 'vat'
                ) {

                    //

                    newState.standard_vat_value = value;

                    /*
                    
                    loading the setting record
                    
                    start
                    
                    */

                    let payload = {
                        object_store: 'settings_object_store',
                        index_name: 'group, key',
                        value: [
                            'variables',
                            'vat'
                        ]
                    }

                    IndexedDbLoadOneComponent(payload)
                        .then(
                            (data) => {

                                /*
                                *
                                * ok
                                * 
                                */

                                ConsoleService('ModuleInvoice / IndexedDbLoadOneComponent / new promise then', this.flag_console_log);

                                ConsoleService('ModuleInvoice / IndexedDbLoadOneComponent / new promise => then / data', this.flag_console_log);
                                ConsoleService(data, this.flag_console_log);

                                data.value = this.state.standard_vat_value;

                                /*
                                
                                update the setting record
                                
                                start
                                
                                */

                                IndexedDbSettingsCreateOrUpdateComponent(data).then(
                                    (data) => {

                                        /*
                                        *
                                        * ok
                                        * 
                                        */

                                        ConsoleService('ModuleInvoice / IndexedDbSettingsCreateOrUpdateComponent / new promise then', this.flag_console_log);

                                        ConsoleService('ModuleInvoice / IndexedDbSettingsCreateOrUpdateComponent / new promise => then / data', this.flag_console_log);
                                        ConsoleService(data, this.flag_console_log);

                                        //

                                    }
                                ).catch(
                                    (data) => {

                                        /*
                                        *
                                        * error
                                        * 
                                        */

                                        ConsoleService('ModuleInvoice / IndexedDbSettingsCreateOrUpdateComponent / new promise => catch', this.flag_console_log);

                                        ConsoleService('ModuleInvoice / IndexedDbSettingsCreateOrUpdateComponent / new promise => catch / data', this.flag_console_log);
                                        ConsoleService(data, this.flag_console_log);

                                        //

                                    }
                                );

                                /*
                                
                                update the setting record
                                
                                stop
                                
                                */

                            }
                        )
                        .catch(
                            (data) => {

                                /*
                                *
                                * error
                                * 
                                */

                                ConsoleService('ModuleInvoice / IndexedDbLoadOneComponent / new promise => catch', this.flag_console_log);

                                ConsoleService('ModuleInvoice / IndexedDbLoadOneComponent / new promise => catch / data', this.flag_console_log);
                                ConsoleService(data, this.flag_console_log);

                                //

                            }
                        );

                    /*
                    
                    loading the setting record
                    
                    stop
                    
                    */

                    //

                }

                newState.form.attributes.invoice_vat_type = component_compute_vat_type(this, invoice_items_copy);

                ConsoleService('newState.form.attributes.invoice_vat_type', this.flag_console_log);
                ConsoleService(newState.form.attributes.invoice_vat_type, this.flag_console_log);

                //

                newState.form.attributes.invoice_total_without_vat = component_compute_total_without_vat(this, invoice_items_copy);// ok 1.11.2019

                ConsoleService('newState.form.attributes.invoice_total_without_vat', this.flag_console_log);
                ConsoleService(newState.form.attributes.invoice_total_without_vat, this.flag_console_log);

                //

                newState.form.attributes.invoice_total_vat = component_compute_total_vat(this, invoice_items_copy);// ok 1.11.2019

                ConsoleService('newState.form.attributes.invoice_total_vat', this.flag_console_log);
                ConsoleService(newState.form.attributes.invoice_total_vat, this.flag_console_log);

                //

            }

            newState.form.attributes.invoice_total = component_compute_total(this, invoice_items_copy);// ok 1.11.2019;

            ConsoleService('newState.form.attributes.invoice_total', this.flag_console_log);
            ConsoleService(newState.form.attributes.invoice_total, this.flag_console_log);

            /*
            *
            * validating the invoice items
            *
            * start
            * 
            */

            let obj_result = component_form_errors_invoice_items_validation(this.state.form);

            ConsoleService('obj_error', this.flag_console_log);
            ConsoleService(obj_result, this.flag_console_log);

            newState.form.errors.invoice_items = obj_result.flag_error;
            newState.form.touched.invoice_items = obj_result.flag_error;

            newState.form.errors.invoice_items_data = obj_result.arr_result;
            newState.form.touched.invoice_items_data = obj_result.arr_result;

            /*
            *
            * validating the invoice items
            *
            * stop
            * 
            */

            this.setState(newState);

            /*
            *
            * assign the new state
            *
            * stop
            * 
            */

        } else {

            // ok
            // nothing to do here

            ConsoleService('invoice_items state will not be updated', this.flag_console_log);

        }

        //

    }

    /* DELIMITER */

    // item remove handler
    itemRemoveHandler = (itemIndex) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleInvoice / itemRemoveHandlert', this.flag_console_log);

        component_item_remove(this, itemIndex);

    }

    /* DELIMITER */

    //edit recipient company handler
    editRecipientCompanyHandler = (event, recipient_object_store_id, recipient_company_name) => {

        event.preventDefault();

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleInvoice editRecipientCompanyHandler', this.flag_console_log);

        this.load_all_recipients();

        ConsoleService('ModuleInvoice editRecipientCompanyHandler recipient_object_store_id', this.flag_console_log);
        ConsoleService(recipient_object_store_id, this.flag_console_log);

        ConsoleService('ModuleInvoice editRecipientCompanyHandler recipient_company_name', this.flag_console_log);
        ConsoleService(recipient_company_name, this.flag_console_log);

        ConsoleService('ModuleInvoice editRecipientCompanyHandler this.state.form.select.recipient_company_name_select_options', this.flag_console_log);
        ConsoleService(this.state.form.select.recipient_company_name_select_options, this.flag_console_log);

        let recipient_company_name_select_option = { label: recipient_company_name, value: recipient_object_store_id };

        let newState = Object.assign({}, this.state);

        newState.flag_permission_show_form_element_recipient_company_name_input_or_react_select_creatable = 'react-select-creatable';

        newState.form.attributes.recipient_company_name = recipient_company_name;
        newState.form.select.recipient_company_name = recipient_company_name_select_option;

        ConsoleService('ModuleInvoice editRecipientCompanyHandler newState', this.flag_console_log);
        ConsoleService(newState, this.flag_console_log);

        this.setState(newState);

    }

    /*
    *
    * event handlers
    * 
    * stop
    * 
    */

    /* DELIMITER */

    /*
    *
    * subtotal label event handlers
    * 
    * start
    * 
    */

    invoiceSubtotalLabelChangedHandler = (event) => {

        ConsoleService('subtotal label changed handler');

        ConsoleService('event.target.value');
        ConsoleService(event.target.value);

        let invoice_subtotal_label = event.target.value;

        /*
        *
        * assign the new state
        *
        * start
        * 
        */

        let newState = Object.assign({}, this.state);

        newState.form.attributes.invoice_subtotal_label = invoice_subtotal_label;

        this.setState(newState);

        /*
        *
        * assign the new state
        *
        * stop
        * 
        */

    }

    /*
    *
    * subtotal label event handlers
    * 
    * stop
    * 
    */

    /* DELIMITER */

    /*
    *
    * save bill on click event handler
    *
    * start
    * 
    */

    async saveBillOnCLickHandler(event) {

        event.preventDefault();

        ConsoleService('ModuleInvoice / saveBillOnCLickHandler', this.flag_console_log);

        let flag_permission = true;

        if (
            flag_permission
        ) {

            /*
            * ok
            */

            if (
                parseInt(this.state.form.attributes.invoice_object_store_id) === parseInt(0)
            ) {

                /*
                *
                * ok
                *
                * a new invoice will be created
                * 
                */

            } else {

                /*
                *
                * error
                * 
                * a old invoice will be edited
                * 
                */

            }

        }

        if (
            flag_permission && 1 === 2
        ) {

            // ok

            // we presume that the form is valid

            // we reset the errors and touched

            let newState = Object.assign({}, this.state);

            let flag_empty = false;

            // validating the attributes
            Object.keys(this.component_form_attributes_to_be_validated).map(
                (attribute) => {

                    ConsoleService('attribute', this.flag_console_log);
                    ConsoleService(attribute, this.flag_console_log);

                    if (
                        this.state.form.attributes[attribute] === ''
                    ) {

                        // error

                        // don't do anything for now

                        /*
                        
                                                ConsoleService('validation failed at : ' + attribute, this.flag_console_log);
                        
                                                flag_empty = true;
                        
                                                newState.form.touched[attribute] = true;// set attribute as "touched"
                        
                                                newState.form.errors[attribute] = true;// set attribute as with "error"
                        
                                                if (flag_permission) {
                        
                                                    flag_permission = false;
                        
                                                    //
                        
                                                }
                        
                        */

                        //

                    } else {

                        // ok

                        newState.form.errors[attribute] = false;

                        //

                    }

                    return true;

                    //
                }
            );

            // validating the labels
            Object.keys(this.component_form_labels_to_be_validated).map(
                (attribute) => {

                    ConsoleService('attribute', this.flag_console_log);
                    ConsoleService(attribute, this.flag_console_log);

                    if (
                        this.state.form.labels[attribute] === ''
                    ) {

                        // error

                        // don't do anything for now

                        /*
                        
                                                ConsoleService('validation failed at : ' + attribute, this.flag_console_log);
                        
                                                flag_empty = true;
                        
                                                newState.form.touched[attribute] = true;// set attribute as "touched"
                        
                                                newState.form.errors[attribute] = true;// set attribute as with "error"
                        
                        */

                        //

                    } else {

                        // ok

                        newState.form.errors[attribute] = false;

                        //

                    }

                    return true;

                    //
                }
            );

            if (
                flag_empty
            ) {

                // error
                // the attribute value is empty

                this.setState(newState);

                //

            }

        }

        if (
            flag_permission
        ) {

            /*
            * ok
            */

            if (
                !('indexedDB' in window)
            ) {

                /*
                * error ?
                *
                * indexedDB is not available
                *
                */

                ConsoleService('This browser doesn\'t support IndexedDB', this.flag_console_log);

                flag_permission = false;

            } else {

                /*
                * ok
                */

                ConsoleService('This browser supports IndexedDB', this.flag_console_log);

            }

        }

        if (
            flag_permission
        ) {

            /*
            * ok
            */

            if (
                await component_form_submit_validation(this)
            ) {

                /*
                * ok
                */

                ConsoleService('invoice form submit validation => validated', this.flag_console_log);

                //

            } else {

                /*
                * error ?
                */

                ConsoleService('invoice form submit validation => !validated', this.flag_console_log);

                flag_permission = false;

                //

            }

            //

        }

        if (
            flag_permission
        ) {

            /*
            *
            * ok
            * 
            * in future, we might need to know how many invoices a user has stored
            * 
            * for now, any user can use this tool as he wants, without any restrictions
            *
            */

            let t = this;

            let invoices_store_count = new IndexedDbModuleInvoicesReturnStoreCountComponent();

            invoices_store_count.execute().then(
                (data) => {

                    /*
                    * ok
                    */

                    ConsoleService('then data', t.flag_console_log);
                    ConsoleService(data, t.flag_console_log);

                    if (
                        data.permission
                    ) {

                        /*
                        * ok
                        * 
                        * user has less than X invoices saved
                        */

                    } else {

                        /*
                        * ok
                        * 
                        * user must be "convinced" to buy
                        */

                        /*
                        t.setState(
     
                            {
                                flag_permission_akti: true,
                                loading_element: true
                            }
     
                        );
                        */

                        /*
                        * Success notification
                        */

                        let message = translation[this.state.language]['ModuleBill'].notification_choose_akti;

                        notification.addNotification(
                            {
                                title: "Info",
                                message: message,
                                type: "success",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animated", "fadeIn"],
                                animationOut: ["animated", "fadeOut"],
                                dismiss: {
                                    duration: 10000,
                                    onScreen: true
                                }
                            }
                        );

                        //

                    }

                    //

                }
            ).catch(
                (data) => {

                    /*
                    * error
                    */

                    ConsoleService('catch data', t.flag_console_log);
                    ConsoleService(data, t.flag_console_log);

                    //

                }
            );

            //

        }

        if (
            flag_permission
        ) {

            /*
            * ok
            */

            if (
                this.idb_for_module_invoices !== null
            ) {

                /*
                * ok
                */

                let json_data = {
                    labels: this.state.form.labels,
                    attributes: this.state.form.attributes
                };

                ConsoleService('ModuleInvoice / saveBillOnCLickHandler() json_data', this.flag_console_log);
                ConsoleService(json_data, this.flag_console_log);

                this.idb_for_module_invoices.create_or_update(json_data).then(
                    (data) => {

                        /*
                        * ok
                        */

                        ConsoleService('ModuleInvoice / saveBillOnCLickHandler() / ... / promise result => then / data', this.flag_console_log);
                        ConsoleService(data, this.flag_console_log);

                        let newState = Object.assign({}, this.state);

                        if (
                            this.state.form.attributes.invoice_object_store_id > 0
                        ) {

                            // ok

                            // update scenario

                            // should we do something here ?

                        } else {

                            // ok

                            // create scenario

                            /*
                            * reset the form data
                            */

                            // extract the PK allocated to the new invoice
                            let invoice_object_store_id = data.target.result;

                            ConsoleService('invoice_object_store_id', this.flag_console_log);
                            ConsoleService(invoice_object_store_id, this.flag_console_log);

                            /*
                            *
                            * assign the new state
                            *
                            * start
                            * 
                            */

                            newState.form.attributes.invoice_object_store_id = invoice_object_store_id;
                            newState.loading_element = false;

                            /*
                            *
                            * assign the new state
                            *
                            * stop
                            * 
                            */

                        }

                        this.setState(newState);

                        /*
                        
                        is the receipt module available and active ?

                        should we save the receipt data ?
                        
                        */

                        if (
                            this.state.flag_add_receipt === true
                        ) {

                            /*
    
                            ok
    
                            we should save the receipt
    
                            emit the signal, the `receipt` component is listening
    
                            */

                            window.emitService.emit('save_receipt');

                            //

                        }

                        /*
                        * Success notification bill saved
                        */

                        let message = translation[this.state.language][this.module_name].notification_bill_saved;

                        notification.addNotification(
                            {
                                title: "Succes",
                                message: message,
                                type: "success",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animated", "fadeIn"],
                                animationOut: ["animated", "fadeOut"],
                                dismiss: {
                                    duration: 1000,
                                    onScreen: true
                                }
                            }
                        );

                        //

                    }
                ).catch(
                    (data) => {

                        /*
                        * error
                        */

                        ConsoleService('ModuleInvoice / saveBillOnCLickHandler() / ... / promise result => catch / data', this.flag_console_log);
                        ConsoleService(data, this.flag_console_log);

                        /*
                        *
                        * assign the new state
                        *
                        * start
                        * 
                        */

                        let newState = Object.assign({}, this.state);

                        newState.loading_element = false;

                        this.setState(newState);

                        /*
                        *
                        * assign the new state
                        *
                        * stop
                        * 
                        */

                    }
                );

            } else {

                /*
                
                 error ? this browser does not support indexedDb
                
                 assign the new state
    
                 start
    
                */

                let newState = Object.assign({}, this.state);

                newState.loading_element = false;

                this.setState(newState);

                /*
                
                 assign the new state
                
                 stop
     
                */

            }

            //

        } else {

            /*
            
             permission is denied
            
             assign the new state

             start

            */

            let newState = Object.assign({}, this.state);

            newState.loading_element = false;

            this.setState(newState);

            /*
            
             assign the new state
            
             stop

            */

        }

        //

    }

    /*
    *
    * save bill on click event handler
    *
    * stop
    * 
    */

    /* DELIMITER */

    resetStateHandler = (event) => {

        event.preventDefault();

        ConsoleService('ModuleInvoice / resetStateHandler', this.flag_console_log);

        ConsoleService('ModuleInvoice / original state', this.flag_console_log);
        ConsoleService(this.getInitialState(), this.flag_console_log);

        ConsoleService('ModuleInvoice / current state', this.flag_console_log);
        ConsoleService(this.state, this.flag_console_log);

        if (
            this.state.form.attributes.invoice_object_store_id === 0
        ) {

            /*
            *
            * fail safe
            * 
            * do not allow to create a new invoice from a old invoice
            * 
            */

            this.load_all_recipients();

            this.setState(this.getInitialState());

            this.load_provider();

        }

        //

    }

    /* DELIMITER */

    /*
    *
    * convert the invoice data to a PDF file handler
    *
    * start
    * 
    */

    convertInvoiceToPDFOnCLickHandler = (event) => {

        event.preventDefault();

        ConsoleService('ModuleInvoice / convertInvoiceToPDFOnCLickHandler', this.flag_console_log);

        let payload = {

            invoice_object_store_id: this.state.form.attributes.invoice_object_store_id,// ok 8.11.2019

            invoice_currency_symbol: this.state.form.attributes.invoice_currency_symbol,// ok 11.11.2019

            invoice_type: this.state.form.attributes.invoice_type,// ok 8.11.2019

            /*
            
            shoud we take the 'VAT' in consideration ?
            
            */

            flag_should_the_vat_be_taken_in_consideration: this.state.form.attributes.flag_should_the_vat_be_taken_in_consideration,// ok 10.01.2020

            //invoice_company_name: this.state.form.attributes.invoice_company_name,// ok 8.11.2019
            //invoice_company_info: this.state.form.attributes.invoice_company_info,// ok 8.11.2019

            /*
            *
            * provider attributes
            * 
            * start
            * 
            */

            provider_company_name: this.state.form.attributes.provider_company_name,

            provider_vat_nr: this.state.form.attributes.provider_vat_nr,
            provider_phone_nr: this.state.form.attributes.provider_phone_nr,
            provider_bank_account: this.state.form.attributes.provider_bank_account,
            provider_bank_name: this.state.form.attributes.provider_bank_name,

            //provider_address_country_key: this.state.form.attributes.provider_address_country_key,// this attribute value is not needed here
            provider_address_country_name: this.state.form.attributes.provider_address_country_name,

            provider_address_county: this.state.form.attributes.provider_address_county,
            provider_address_city: this.state.form.attributes.provider_address_city,
            provider_address_street_address: this.state.form.attributes.provider_address_street_address,
            provider_address_zip: this.state.form.attributes.provider_address_zip,

            /*
            *
            * provider attributes
            * 
            * stop
            * 
            */

            /*
            *
            * recipient attributes
            * 
            * start
            * 
            */

            recipient_company_name: this.state.form.attributes.recipient_company_name,

            //recipient_account_type: this.state.form.attributes.recipient_account_type,// this attribute value is not needed here
            recipient_account_type_name: this.state.form.attributes.recipient_account_type_name,

            recipient_vat_nr: this.state.form.attributes.recipient_vat_nr,
            recipient_email: this.state.form.attributes.recipient_email,
            recipient_phone_nr: this.state.form.attributes.recipient_phone_nr,

            //recipient_addresses_country_key: this.state.form.attributes.recipient_addresses_country_key,// this attribute value is not needed here
            recipient_addresses_country_name: this.state.form.attributes.recipient_addresses_country_name,

            recipient_addresses_city: this.state.form.attributes.recipient_addresses_city,
            recipient_addresses_street_address: this.state.form.attributes.recipient_addresses_street_address,
            recipient_addresses_zip: this.state.form.attributes.recipient_addresses_zip,

            /*
            *
            * recipient attributes
            * 
            * start
            * 
            */

            invoice_number_label: this.state.form.labels.invoice_number_label,// ok 8.11.2019
            invoice_serial: this.state.form.attributes.invoice_serial,// ok 06.01.2020
            invoice_number: this.state.form.attributes.invoice_number,// ok 8.11.2019

            invoice_date_label: this.state.form.labels.invoice_date_label,// ok 8.11.2019
            invoice_date: this.state.form.attributes.invoice_date,// ok 8.11.2019

            invoice_items: this.state.form.attributes.invoice_items,// ok 8.11.2019

            item_description_label: this.state.form.labels.item_description_label,// ok 8.11.2019
            item_quantity_label: this.state.form.labels.item_quantity_label,// ok 8.11.2019
            item_price_label: this.state.form.labels.item_price_label,// ok 8.11.2019
            item_vat_label: this.state.form.labels.item_vat_label,// ok 8.11.2019

            invoice_total_without_vat_label: this.state.form.labels.invoice_total_without_vat_label,// ok 8.11.2019
            invoice_total_without_vat: this.state.form.attributes.invoice_total_without_vat,// ok 11.11.2019

            invoice_total_vat_label: this.state.form.labels.invoice_total_vat_label,// ok 8.11.2019
            invoice_total_vat: this.state.form.attributes.invoice_total_vat,// ok 11.11.2019

            invoice_vat_type: this.state.form.attributes.invoice_vat_type,// ok 8.11.2019

            invoice_subtotal_without_vat_for_vat_x: this.state.form.labels.invoice_subtotal_without_vat_for_vat_x,// ok 8.11.2019
            invoice_total_vat_for_vat_x: this.state.form.labels.invoice_total_vat_for_vat_x,// ok 8.11.2019

            invoice_total_label: this.state.form.labels.invoice_total_label,// ok 8.11.2019

            invoice_total: this.state.form.attributes.invoice_total,// ok 8.11.2019

            invoice_language: this.state.form.attributes.invoice_language

        };

        ConsoleService('ModuleInvoice / convertInvoiceToPDFOnCLickHandler payload', this.flag_console_log);
        ConsoleService(payload, this.flag_console_log);

        //convertInvoiceToPDF(payload);
        convertInvoiceToPdfRo(payload);

    }

    /* DELIMITER */

    recipientsOnClickHandler = () => {

        ConsoleService('recipientsOnClickHandler', this.flag_console_log);

        this.setState(

            {
                flag_permission_recipients: true,
                flag_permission_invoices: false,
                flag_permission_akti: false,
                loading_element: true
            }

        );

    }

    formCheckboxElementFlagAddReceiptChangedHandler = (event) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleInvoice / form checkbox element flag add receipt changed handler', this.flag_console_log);

        ConsoleService('event', this.flag_console_log);
        ConsoleService(event, this.flag_console_log);

        /*
        *
        * assign the new state
        *
        * start
        * 
        */

        let newState = Object.assign({}, this.state);

        newState.flag_add_receipt = (newState.flag_add_receipt === true ? false : true);

        ConsoleService('newState', this.flag_console_log);
        ConsoleService(newState, this.flag_console_log);

        this.setState(newState);

        /*
        *
        * assign the new state
        *
        * stop
        * 
        */

    }

    /* DELIMITER */

    render() {

        ConsoleService('moduleInvoice / render() / this.state', this.flag_console_log);
        ConsoleService(this.state, this.flag_console_log);

        let container_bill_styles = { margin: '0 auto' };

        return (
            <div className="ModuleInvoice">
                {/* ModuleInvoice */}

                {/* container-invoice */}
                <div id="container-invoice">

                    {/* container bill */}
                    <div id="container-bill" style={container_bill_styles}>

                        <div className="content">

                            <div className="options">

                                {this.state.form.attributes.invoice_object_store_id === 0 ? " " : null}

                                {

                                    this.state.form.attributes.invoice_object_store_id === 0 ?

                                        <button className="btn btn-info btn-sm" onClick={this.resetStateHandler}>
                                            {translation[this.state.language][this.module_name].button_new_invoice_form_label}
                                        </button>

                                        : null

                                }

                                {this.state.form.attributes.invoice_object_store_id !== 0 ? " " : null}

                                {

                                    this.state.form.attributes.invoice_object_store_id !== 0 ?

                                        <button className="btn btn-info btn-sm" onClick={this.convertInvoiceToPDFOnCLickHandler}>
                                            <i className="fa fa-download"></i> {translation[this.state.language][this.module_name].button_download_pdf_label}
                                        </button>

                                        : null

                                }

                            </div>

                            <div id="page">

                                <ModuleInvoiceComponentRenderJsx t={this} />

                            </div>

                            {/* invoice extra options / start */}
                            {

                                this.state.language === 'ro' ?

                                    <div id="container-invoice-options" className="m5 p5">

                                        <div className="body">

                                            <div className="m5 p5">

                                                <span className="m2 p2">

                                                    <input type="checkbox"
                                                        id="flag_add_receipt"
                                                        name="flag_add_receipt"
                                                        value={this.state.flag_add_receipt}
                                                        onChange={this.formCheckboxElementFlagAddReceiptChangedHandler} />

                                                </span>

                                                <span className="m2 p2">

                                                    <label htmlFor="flag_add_receipt">Adauga chitanta</label>

                                                </span>

                                            </div>

                                        </div>

                                    </div>

                                    : null

                            }
                            {/* invoice extra options / stop */}

                            {/* receipt / start */}
                            {

                                this.state.language === 'ro' && this.state.flag_add_receipt ?

                                    <div id="container-receipt" className="m5 p5">

                                        <div className="body">

                                            <ModuleReceiptV2
                                                module_origin="fromInvoice"
                                                invoice_state={this.state}
                                                invoice_hash={serviceGenerateRandomString(32)} />

                                        </div>

                                    </div>

                                    : null

                            }
                            {/* receipt / stop */}

                            <div className="options-footer">

                                <button className="btn btn-primary btn-sm col-12" onClick={(e) => { this.saveBillOnCLickHandler(e); }}>
                                    {translation[this.state.language].general.button_save_label}
                                </button>

                            </div>

                        </div>

                    </div>
                    {/* /container bill */}

                </div>
                {/* /container-invoice */}

                {/* /ModuleInvoice */}
            </div >
        );

    }

}

export default ModuleInvoice;