import React, { Component } from 'react';

import ReactSelect from 'react-select';

/*
*
* "react notification component" docs available at :
*
* https://www.npmjs.com/package/react-notifications-component
*
*/

import { store as notification } from 'react-notifications-component';// ok 14.11.2019

/*
*
* css files
*
* start
*
*/

import "./css/ModuleProvider.css";

/*
*
* css files
*
* stop
*
*/

/*
*
* custom components
*
* start
*
*/

import ConsoleService from './components/consoleServiceModules/ConsoleService';

import ConsoleServiceModuleProvider from "./components/consoleServiceModules/ConsoleServiceModuleProvider";// ok 2.12.2019

import default_language, { arr_languages } from './LanguageComponent';

import translation from './TranslationComponent';

import arr_country from './components/dataProviders/const_arr_country_data';// ok 14.11.2019

import component_form_submit_validation from './components/moduleProvider/component_form_submit_validation';// ok 14.11.2019

import component_form_attributes_to_be_validated from './components/moduleProvider/component_form_attributes_to_be_validated';// ok 2.12.2019

/*
*
* custom components
*
* stop
*
*/

/*
*
* Indexed DB custom components
*
* start
*
*/

import IndexedDbComponent from './IndexedDbComponent';// ok 19.12.2019

import DexieDbComponent from './DexieDbComponent';

import IndexedDbModuleProviderComponent from './IndexedDbModuleProviderComponent';

import IndexedDbProviderCreateOrUpdateComponent from './components/IndexedDbProviderCreateOrUpdateComponent';

import IndexedDbProviderReturnProviderComponent from './components/IndexedDbProviderReturnProviderComponent';

/*
*
* Indexed DB custom components
*
* stop
*
*/

class ModuleProvider extends Component {

    module_name = 'ModuleProvider';

    idb_for_module_provider = null;// ok 2.12.2019

    flag_console_log = ConsoleServiceModuleProvider;// ok 14.11.2019

    default_language = default_language;

    component_form_attributes_to_be_validated = component_form_attributes_to_be_validated;// ok 2.12.2019

    state = {};// ok 14.11.2019

    /* DELIMITER */

    getInitialState() {

        //

        const initialState = {

            language: default_language,

            form: {

                select: {

                    /*
                    *
                    * provider attributes
                    * 
                    * start
                    * 
                    */

                    /*
                    *
                    * provider address country key
                    *
                    * start
                    * 
                    */

                    provider_address_country_key: null,

                    provider_address_country_key_select_options: arr_country,

                    /*
                    *
                    * provider address country key
                    *
                    * stop
                    * 
                    */

                    /*
                    *
                    * provider attributes
                    * 
                    * stop
                    * 
                */

                },

                attributes: {

                    // set the PK
                    provider_object_store_id: 1,

                    provider_external_id: 0,

                    provider_company_name: '',

                    provider_vat_nr: '',

                    provider_phone_nr: '',

                    provider_bank_account: '',

                    provider_bank_name: '',

                    provider_address_country_key: '',

                    provider_address_county: '',

                    provider_address_city: '',

                    provider_address_street_address: '',

                    provider_address_zip: '',

                },

                errors: {

                    provider_company_name: true,

                    provider_vat_nr: true,

                    provider_phone_nr: true,

                    provider_bank_account: true,

                    provider_bank_name: true,

                    provider_address_country_key: true,

                    provider_address_county: true,

                    provider_address_city: true,

                    provider_address_street_address: true,

                    provider_address_zip: true,

                },

                touched: {

                    provider_company_name: false,

                    provider_vat_nr: false,

                    provider_phone_nr: false,

                    provider_bank_account: false,

                    provider_bank_name: false,

                    provider_address_country_key: false,

                    provider_address_county: false,

                    provider_address_city: false,

                    provider_address_street_address: false,

                    provider_address_zip: false,

                },

            },

            arr_languages: arr_languages,// ok 2.12.2019

        };

        return initialState;

        //

    }

    /* DELIMITER */

    constructor(props) {

        super(props);

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleProvider / construct', this.flag_console_log);

        ConsoleService('ModuleProvider / props', this.flag_console_log);
        ConsoleService(props, this.flag_console_log);

        this.default_language = props.language;

        let state = this.getInitialState();

        ConsoleService('ModuleProvider / initial state', this.flag_console_log);
        ConsoleService(state, this.flag_console_log);

        state.language = props.language;

        this.state = state;

    }

    /* DELIMITER */

    componentDidMount() {

        ConsoleService('ModuleProvider / component did mount', this.flag_console_log);

        ConsoleService('this.state', this.flag_console_log);
        ConsoleService(this.state, this.flag_console_log);

        if (
            !('indexedDB' in window)
        ) {

            ConsoleService('This browser doesn\'t support IndexedDB', this.flag_console_log);

        } else {

            ConsoleService('This browser supports IndexedDB', this.flag_console_log);

            /*
            * !! DO NOT DELETE !! THE DB SHOULD BE CREATED FOR ANY NEW USER !!
            */

            new IndexedDbComponent();

            new DexieDbComponent();

            this.idb_for_module_provider = new IndexedDbModuleProviderComponent();

        }

        this.query_selector_all_textarea_elements();

        this.load_provider();

    }

    /* DELIMITER */

    set_provider_country_key = (payload) => {


        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleProvider / set_provider_country_key', this.flag_console_log);

        ConsoleService('payload', this.flag_console_log);
        ConsoleService(payload, this.flag_console_log);

        let option = {};

        Object.keys(arr_country).map(
            (index) => {

                ConsoleService('ModuleProvider / set_provider_country_key / object.keys.map / index', this.flag_console_log);
                ConsoleService(index, this.flag_console_log);

                ConsoleService('ModuleProvider / set_provider_country_key / object.keys.map / object', this.flag_console_log);
                ConsoleService(arr_country[index], this.flag_console_log);

                if (
                    arr_country[index].value === payload
                ) {
                    option = arr_country[index];
                }

                return true;

            }
        );

        ConsoleService('option', this.flag_console_log);
        ConsoleService(option, this.flag_console_log);

        return option;

    }

    load_provider = () => {

        let t = this;

        // the PK is hardcoded => 1 because there should always be only one provider
        IndexedDbProviderReturnProviderComponent(1).then(
            (data) => {

                /*
                *
                * ok
                * 
                */

                ConsoleService('ModuleProvider / getInitialState() / IndexedDbProviderReturnProviderComponent / new promise then', t.flag_console_log);

                ConsoleService('ModuleProvider / getInitialState() / IndexedDbProviderReturnProviderComponent / new promise => then / data', t.flag_console_log);
                ConsoleService(data, t.flag_console_log);

                if (
                    data.target.result !== undefined
                ) {

                    // ok

                    let newState = Object.assign({}, this.state);

                    let data_provider = data.target.result;

                    newState.form.attributes.provider_object_store_id = data_provider.provider_object_store_id;

                    newState.form.attributes.provider_external_id = data_provider.provider_external_id;

                    newState.form.attributes.provider_company_name = data_provider.provider_company_name;

                    newState.form.attributes.provider_vat_nr = data_provider.provider_vat_nr;
                    newState.form.attributes.provider_phone_nr = data_provider.provider_phone_nr;
                    newState.form.attributes.provider_bank_account = data_provider.provider_bank_account;
                    newState.form.attributes.provider_bank_name = data_provider.provider_bank_name;

                    // set the value as a json for the select element
                    newState.form.select.provider_address_country_key = this.set_provider_country_key(data_provider.provider_address_country_key);
                    // set the value as "fr" for the attribute
                    newState.form.attributes.provider_address_country_key = data_provider.provider_address_country_key;
                    // set the value as "France" for the attribute
                    newState.form.attributes.provider_address_country_name = data_provider.provider_address_country_name;

                    newState.form.attributes.provider_address_county = data_provider.provider_address_county;
                    newState.form.attributes.provider_address_city = data_provider.provider_address_city;
                    newState.form.attributes.provider_address_street_address = data_provider.provider_address_street_address;
                    newState.form.attributes.provider_address_zip = data_provider.provider_address_zip;

                    this.setState(newState);

                }

                return true;

                //

            }
        ).catch(
            (data) => {

                /*
                *
                * error
                * 
                */

                ConsoleService('ModuleProvider / getInitialState() / IndexedDbProviderReturnProviderComponent / new promise => catch', t.flag_console_log);

                ConsoleService('ModuleProvider / getInitialState() / IndexedDbProviderReturnProviderComponent / new promise => catch / data', t.flag_console_log);
                ConsoleService(data, t.flag_console_log);

                return false;

                //

            }
        );

        //

    }

    /* DELIMITER */

    componentDidUpdate(prevProps) {

        ConsoleService('ModuleProvider / component did update', this.flag_console_log)

        ConsoleService('prevProps', this.flag_console_log);
        ConsoleService(prevProps, this.flag_console_log);

    }

    /* DELIMITER */

    /*
    *
    * "textarea" element event listeners ( hover )
    * 
    * start
    * 
    */

    query_selector_all_textarea_elements = () => {

        /*
        * mousover/mouseout events for textatea elements
        * start
        */

        document.querySelectorAll('textarea').forEach(input => input.addEventListener('mouseover', () => { input.style.background = "rgb(249,245,198)"; }));

        document.querySelectorAll('textarea').forEach(input => input.addEventListener('mouseout', () => { input.style.background = "rgb(250,251,251)"; }));

        /*
        * mousover/mouseout events for textatea elements
        * stop
        */

        /*
        * mousover/mouseout events for input elements
        * start
        */

        document.querySelectorAll('input').forEach(input => input.addEventListener('mouseover', () => { input.style.background = "rgb(249,245,198)"; }));

        document.querySelectorAll('input').forEach(input => input.addEventListener('mouseout', () => { input.style.background = "rgb(250,251,251)"; }));

        /*
        * mousover/mouseout events for input elements
        * stop
        */

    }

    /*
    *
    * "textarea" element event listeners ( hover )
    * 
    * stop
    * 
    */

    /* DELIMITER */

    /*
    *
    * handlers
    * 
    * start
    *
    */

    /*
    *
    * save provider on click event handler
    *
    * start
    * 
    */

    saveProviderFormOnCLickHandler = (event) => {

        event.preventDefault();

        ConsoleService('ModuleProvider / saveProviderFormOnCLickHandler', this.flag_console_log);

        let flag_permission = true;

        if (
            flag_permission
        ) {

            /*
            * ok
            */

            if (
                parseInt(this.state.form.attributes.provider_object_store_id) === parseInt(0)
            ) {

                /*
                *
                * ok
                *
                * a new provider will be created
                * 
                */

            } else {

                /*
                *
                * ok
                * 
                * a old provider will be edited
                * 
                * we should always be on this scenario
                * 
                */

            }

        }

        if (
            flag_permission
        ) {

            // ok

            // we presume that the form is valid

            // we reset the errors and touched

            let newState = Object.assign({}, this.state);

            let flag_empty = false;

            // validating the attributes
            Object.keys(this.component_form_attributes_to_be_validated).map(
                (attribute) => {

                    ConsoleService('attribute', this.flag_console_log);
                    ConsoleService(attribute, this.flag_console_log);

                    if (
                        this.state.form.attributes[attribute] === ''
                    ) {

                        // error

                        // don't do anything for now

                        //

                    } else {

                        // ok

                        newState.form.errors[attribute] = false;

                        //

                    }

                    return true;

                    //
                }
            );

            if (
                flag_empty
            ) {

                // error
                // the attribute value is empty

                this.setState(newState);

                //

            }

        }

        if (
            flag_permission
        ) {

            /*
            * ok
            */

            if (
                component_form_submit_validation(this)
            ) {

                /*
                * ok
                */

                ConsoleService('invoice form submit validation => validated', this.flag_console_log);

            } else {

                /*
                * error ?
                */

                ConsoleService('invoice form submit validation => !validated', this.flag_console_log);

                flag_permission = false;

            }

        }

        if (
            flag_permission
        ) {

            /*
            * ok
            */

            if (
                !('indexedDB' in window)
            ) {

                /*
                * error ?
                *
                * indexedDB is not available
                *
                */

                ConsoleService('This browser doesn\'t support IndexedDB', this.flag_console_log);

                flag_permission = false;

            } else {

                /*
                * ok
                */

                ConsoleService('This browser supports IndexedDB', this.flag_console_log);

            }

        }

        if (
            flag_permission
        ) {

            /*
            * ok
            */

            console.log(this.idb_for_module_provider);

            if (
                this.idb_for_module_provider !== null
            ) {

                /*
                * ok
                */

                let t = this;

                let json_data = {
                    attributes: t.state.form.attributes
                };

                ConsoleService('ModuleProvider / saveProviderFormOnCLickHandler() json_data', this.flag_console_log);
                ConsoleService(json_data, this.flag_console_log);

                IndexedDbProviderCreateOrUpdateComponent(json_data).then(
                    (data) => {

                        /*
                        * ok
                        */

                        ConsoleService('ModuleProvider / saveProviderFormOnCLickHandler() / ... / promise result => then / data', this.flag_console_log);
                        ConsoleService(data, this.flag_console_log);

                        if (
                            t.state.form.attributes.provider_object_store_id > 0
                        ) {

                            // ok

                            // update scenario

                            // should we do something here ?

                        } else {

                            // ok

                            // create scenario

                            /*
                            * reset the form data
                            */

                            // extract the PK allocated to the new invoice
                            //let provider_object_store_id = data.target.result;
                            //let provider_object_store_id = 1;

                            //ConsoleService('invoice_object_store_id', this.flag_console_log);
                            //ConsoleService(invoice_object_store_id, this.flag_console_log);

                            /*
                            *
                            * assign the new state
                            *
                            * start
                            * 
                            */

                            let newState = Object.assign({}, t.state);

                            //newState.form.attributes.provider_object_store_id = provider_object_store_id;
                            newState.loading_element = false;

                            t.setState(newState);

                            /*
                            *
                            * assign the new state
                            *
                            * stop
                            * 
                            */

                        }

                        /*
                        * Success notification bill saved
                        */

                        let message = translation[this.props.language][this.module_name].notification_provider_saved;

                        notification.addNotification(
                            {
                                title: "Succes",
                                message: message,
                                type: "success",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animated", "fadeIn"],
                                animationOut: ["animated", "fadeOut"],
                                dismiss: {
                                    duration: 1000,
                                    onScreen: true
                                }
                            }
                        );

                        //

                    }
                ).catch(
                    (data) => {

                        /*
                        * error
                        */

                        ConsoleService('ModuleProvider / saveProviderFormOnCLickHandler() / ... / promise result => catch / data', this.flag_console_log);
                        ConsoleService(data, this.flag_console_log);

                        /*
                        *
                        * assign the new state
                        *
                        * start
                        * 
                        */

                        let newState = Object.assign({}, t.state);

                        newState.loading_element = false;

                        t.setState(newState);

                        /*
                        *
                        * assign the new state
                        *
                        * stop
                        * 
                        */

                    }
                );

            } else {

                // error ?

                // this browser does not support indexedDb

            }

            //

        }

        //

    }

    /*
    *
    * save provider on click event handler
    *
    * stop
    * 
    */

    /*
     *
     * general handler
     * 
     * form react select element on blur handler
     * 
     * start
     * 
     */

    formReactSelectElementOnBlurHandler = (event, attribute = '') => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleProvider / formReactSelectElementOnBlurHandler', this.flag_console_log);

        ConsoleService('event', this.flag_console_log);
        ConsoleService(event, this.flag_console_log);

        ConsoleService('attribute', this.flag_console_log);
        ConsoleService(attribute, this.flag_console_log);

        let flag_permission = true;

        if (
            attribute.length
        ) {

            // ok

        } else {

            // error

            flag_permission = false;

        }

        if (
            flag_permission
        ) {

            // ok

            if (
                this.state.form.touched.hasOwnProperty(attribute)
            ) {

                // ok

            } else {

                // error

                flag_permission = false;

            }

        }

        if (
            flag_permission
        ) {

            // ok

            /*
            *
            * assign the new state
            *
            * start
            * 
            */

            let newState = Object.assign({}, this.state);

            newState.form.touched[attribute] = true;

            ConsoleService('newState', this.flag_console_log);
            ConsoleService(newState, this.flag_console_log);

            this.setState(newState);

            /*
            *
            * assign the new state
            *
            * stop
            * 
            */

        }

        //

    }

    /*
     *
     * general handler
     * 
     * form react select element on blur handler
     * 
     * stop
     * 
     */

    // form element changed handler
    formElementChangedHandler = (event) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleProviders / formElementChangedHandler', this.flag_console_log);

        ConsoleService('event', this.flag_console_log);
        ConsoleService(event, this.flag_console_log);

        ConsoleService('event.target.name', this.flag_console_log);
        ConsoleService(event.target.name, this.flag_console_log);

        ConsoleService('event.target.value', this.flag_console_log);
        ConsoleService(event.target.value, this.flag_console_log);

        /*
        *
        * assign the new state
        *
        * start
        * 
        */

        let newState = Object.assign({}, this.state);

        newState.form.errors[event.target.name] = (event.target.value === '' ? true : false);

        newState.form.touched[event.target.name] = true;

        // ONLY "attributes"
        newState.form.attributes[event.target.name] = event.target.value;

        this.setState(newState);

        /*
        *
        * assign the new state
        *
        * stop
        * 
        */

    }

    // form react select element "provider addresses country key" changed handler
    formReactSelectElementProviderAddressCountryKeyChangedHandler = (event) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleProvider / formReactSelectElementProviderAddressCountryKeyChangedHandler', this.flag_console_log);

        ConsoleService('event', this.flag_console_log);
        ConsoleService(event, this.flag_console_log);

        /*
        *
        * assign the new state
        *
        * start
        * 
        */

        let newState = Object.assign({}, this.state);

        newState.form.attributes.provider_address_country_key = event.value;

        newState.form.select.provider_address_country_key = event;

        newState.form.errors.provider_address_country_key = false;

        newState.form.touched.provider_address_country_key = true;

        this.setState(newState);

        /*
        *
        * assign the new state
        *
        * stop
        * 
        */

    }

    /*
    *
    * handlers
    * 
    * stop
    *
    */

    shouldMarkError = (field) => {

        //ConsoleService('', t.flag_console_log);
        //ConsoleService('shouldMarkError', t.flag_console_log);

        //ConsoleService('field', t.flag_console_log);
        //ConsoleService(field, t.flag_console_log);

        const hasError = this.state.form.errors[field];

        //ConsoleService('hasError', t.flag_console_log);
        //ConsoleService(hasError, t.flag_console_log);

        const shouldShow = this.state.form.touched[field];

        //ConsoleService('shouldShow', t.flag_console_log);
        //ConsoleService(shouldShow, t.flag_console_log);

        return hasError ? shouldShow : false;

        //return true;// force error

        //

    }

    render() {

        return (
            <div id="ModuleProvider">
                {/* container module provider / start */}

                {/* container-provider */}
                <div id="container-provider-form">

                    <div className="">

                        <div className="" >
                            <h1>{translation[this.props.language].general.my_details}</h1>
                        </div>

                        <div className="">

                            <div className="container-alert">

                                <div className="alert alert-success" role="alert">
                                    <h4 className="alert-heading">Info ;)</h4>
                                    <p>text goes here</p>
                                </div>

                            </div>

                        </div>

                        <div className="align-center">

                            <div className="providerForm" id="outerProviderFormContainer">

                                <div className="providerFormContainer">

                                    <div id="innerProviderFormContainer">

                                        <form id="providerForm">

                                            <div className="m5 p5">

                                                {/* provider attributes / start */}
                                                <table id="provider" className="align-center">

                                                    <tbody>

                                                        {/* provider vat nr / start */}
                                                        <tr>

                                                            <td>

                                                                <div className="m5">

                                                                    <input
                                                                        type="text"
                                                                        id="provider_company_name"
                                                                        name="provider_company_name"
                                                                        className={this.shouldMarkError('provider_company_name') ? "field_error" : ""}
                                                                        autoComplete="off"
                                                                        value={this.state.form.attributes.provider_company_name}
                                                                        onChange={this.formElementChangedHandler}
                                                                        placeholder={translation[this.props.language][this.module_name].placeholder_provider_company_name}
                                                                        onBlur={this.formInputElementOnBlurHandler} />

                                                                </div>

                                                            </td>

                                                        </tr>
                                                        {/* provider vat nr / stop */}

                                                        {/* provider vat nr / start */}
                                                        <tr>

                                                            <td>

                                                                <div className="m5">

                                                                    <input
                                                                        type="text"
                                                                        id="provider_vat_nr"
                                                                        name="provider_vat_nr"
                                                                        className={this.shouldMarkError('provider_vat_nr') ? "field_error" : ""}
                                                                        autoComplete="off"
                                                                        value={this.state.form.attributes.provider_vat_nr}
                                                                        onChange={this.formElementChangedHandler}
                                                                        placeholder={translation[this.props.language][this.module_name].placeholder_provider_vat_nr}
                                                                        onBlur={this.formInputElementOnBlurHandler} />

                                                                </div>

                                                            </td>

                                                        </tr>
                                                        {/* provider vat nr / stop */}

                                                        {/* provider phone nr / start */}
                                                        <tr>

                                                            <td>

                                                                <div className="m5">

                                                                    <input
                                                                        type="text"
                                                                        id="provider_phone_nr"
                                                                        name="provider_phone_nr"
                                                                        className={this.shouldMarkError('provider_phone_nr') ? "field_error" : ""}
                                                                        autoComplete="off"
                                                                        value={this.state.form.attributes.provider_phone_nr}
                                                                        onChange={this.formElementChangedHandler}
                                                                        placeholder={translation[this.props.language][this.module_name].placeholder_provider_phone_nr}
                                                                        onBlur={this.formInputElementOnBlurHandler} />

                                                                </div>

                                                            </td>

                                                        </tr>
                                                        {/* provider phone nr / stop */}

                                                        {/* provider bank account / start */}
                                                        <tr>

                                                            <td>

                                                                <div className="m5">

                                                                    <input
                                                                        type="text"
                                                                        id="provider_bank_account"
                                                                        name="provider_bank_account"
                                                                        className={this.shouldMarkError('provider_bank_account') ? "field_error" : ""}
                                                                        autoComplete="off"
                                                                        value={this.state.form.attributes.provider_bank_account}
                                                                        onChange={this.formElementChangedHandler}
                                                                        placeholder={translation[this.props.language][this.module_name].placeholder_provider_bank_account}
                                                                        onBlur={this.formInputElementOnBlurHandler} />

                                                                </div>

                                                            </td>

                                                        </tr>
                                                        {/* provider bank account / stop */}

                                                        {/* provider bank name / start */}
                                                        <tr>

                                                            <td>

                                                                <div className="m5">

                                                                    <input
                                                                        type="text"
                                                                        id="provider_bank_name"
                                                                        name="provider_bank_name"
                                                                        className={this.shouldMarkError('provider_bank_name') ? "field_error" : ""}
                                                                        autoComplete="off"
                                                                        value={this.state.form.attributes.provider_bank_name}
                                                                        onChange={this.formElementChangedHandler}
                                                                        placeholder={translation[this.props.language][this.module_name].placeholder_provider_bank_name}
                                                                        onBlur={this.formInputElementOnBlurHandler} />

                                                                </div>

                                                            </td>

                                                        </tr>
                                                        {/* provider bank name / stop */}

                                                        {/* provider address country key / start */}
                                                        <tr>

                                                            <td>

                                                                <div className="m5">

                                                                    <ReactSelect
                                                                        id="provider_address_country_key"
                                                                        name="provider_address_country_key"
                                                                        className={this.shouldMarkError('provider_address_country_key') ? "field_error" : ""}
                                                                        value={this.state.form.select.provider_address_country_key}
                                                                        onChange={this.formReactSelectElementProviderAddressCountryKeyChangedHandler}
                                                                        options={this.state.form.select.provider_address_country_key_select_options}
                                                                        placeholder={translation[this.props.language][this.module_name].placeholder_provider_address_country_key}
                                                                        onBlur={(e) => { this.formReactSelectElementOnBlurHandler(e, 'provider_address_country_key') }} />

                                                                </div>

                                                            </td>

                                                        </tr>
                                                        {/* provider address country key / stop */}

                                                        {/* provider address county / start */}
                                                        <tr>

                                                            <td>

                                                                <div className="m5">

                                                                    <input
                                                                        type="text"
                                                                        id="provider_address_county"
                                                                        name="provider_address_county"
                                                                        className={this.shouldMarkError('provider_address_county') ? "field_error" : ""}
                                                                        autoComplete="off"
                                                                        value={this.state.form.attributes.provider_address_county}
                                                                        onChange={this.formElementChangedHandler}
                                                                        placeholder={translation[this.props.language][this.module_name].placeholder_provider_address_county}
                                                                        onBlur={this.formInputElementOnBlurHandler} />

                                                                </div>

                                                            </td>

                                                        </tr>
                                                        {/* provider address county / stop */}

                                                        {/* provider address city / start */}
                                                        <tr>

                                                            <td>

                                                                <div className="m5">

                                                                    <input
                                                                        type="text"
                                                                        id="provider_address_city"
                                                                        name="provider_address_city"
                                                                        className={this.shouldMarkError('provider_address_city') ? "field_error" : ""}
                                                                        autoComplete="off"
                                                                        value={this.state.form.attributes.provider_address_city}
                                                                        onChange={this.formElementChangedHandler}
                                                                        placeholder={translation[this.props.language][this.module_name].placeholder_provider_address_city}
                                                                        onBlur={this.formInputElementOnBlurHandler} />

                                                                </div>

                                                            </td>

                                                        </tr>
                                                        {/* provider address city / stop */}

                                                        {/* provider address street address / start */}
                                                        <tr>

                                                            <td>

                                                                <div className="m5">

                                                                    <input
                                                                        type="text"
                                                                        id="provider_address_street_address"
                                                                        name="provider_address_street_address"
                                                                        className={this.shouldMarkError('provider_address_street_address') ? "field_error" : ""}
                                                                        autoComplete="off"
                                                                        value={this.state.form.attributes.provider_address_street_address}
                                                                        onChange={this.formElementChangedHandler}
                                                                        placeholder={translation[this.props.language][this.module_name].placeholder_provider_address_street_address}
                                                                        onBlur={this.formInputElementOnBlurHandler} />

                                                                </div>

                                                            </td>

                                                        </tr>
                                                        {/* provider address street_address / stop */}

                                                        {/* provider address zip / start */}
                                                        <tr>

                                                            <td>

                                                                <div className="m5">

                                                                    <input
                                                                        type="text"
                                                                        id="provider_address_zip"
                                                                        name="provider_address_zip"
                                                                        className={this.shouldMarkError('provider_address_zip') ? "field_error" : ""}
                                                                        autoComplete="off"
                                                                        value={this.state.form.attributes.provider_address_zip}
                                                                        onChange={this.formElementChangedHandler}
                                                                        placeholder={translation[this.props.language][this.module_name].placeholder_provider_address_zip}
                                                                        onBlur={this.formInputElementOnBlurHandler} />

                                                                </div>

                                                            </td>

                                                        </tr>
                                                        {/* provider address zip / stop */}

                                                    </tbody>

                                                </table>
                                                {/* provider attributes / stop */}

                                                <div className="m5 options-footer">

                                                    <button className="btn btn-primary btn-sm col-12" onClick={this.saveProviderFormOnCLickHandler}>
                                                        {translation[this.props.language].general.button_save_label}
                                                    </button>

                                                </div>

                                            </div>

                                        </form>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>
                {/* /container-provider */}

                {/* container module provider / stop */}
            </div >
        );

    }

}

export default ModuleProvider;