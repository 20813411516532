/*

language : EN

*/

export default {

    required: 'The field [attribute] is required. EN',

    email: 'Make sure that you enter a valid email address. EN'

}