import ConsoleService from '../consoleServiceModules/ConsoleService';

function get_total_vat(subtotal, item) {

    let line_vat = (
        (
            (
                parseFloat(item.quantity) * parseFloat(item.price)
            ) * parseFloat(item.vat)
        ) / 100
    );

    return (
        subtotal + line_vat
    );

}

const execute = (t, invoice_items) => {

    ConsoleService('', t.flag_console_log);
    ConsoleService('ModuleInvoice / component_compute_total_vat / execute', t.flag_console_log);

    let total_vat = parseFloat((invoice_items.reduce(get_total_vat, 0)).toFixed(2));

    ConsoleService('total VAT', t.flag_console_log);
    ConsoleService(total_vat, t.flag_console_log);

    return total_vat;

}

export default execute;