import translationFRComponent from "./components/translation/translationFRComponent";
import translationENComponent from "./components/translation/translationENComponent";
import translationROComponent from "./components/translation/translationROComponent";

const translation = {

    en: translationENComponent,

    fr: translationFRComponent,

    ro: translationROComponent

};

export default translation;