/*
 *
 * This is the `IndexedDb SettingsCreateOrUpdateComponent` component.
 *
 */

/*
 *
 * custom components
 *
 * start
 *
 */

import ConsoleService from './consoleServiceModules/ConsoleService';

import ConsoleServiceIndexedDbModuleSettingsComponent from "./consoleServiceModules/ConsoleServiceIndexedDbModuleSettingsComponent";

import { indexed_db_name, indexed_db_version } from './../IndexedDbComponent';

// indexed db settings components / start

//// create component
import IndexedDbSettingsCreateComponent from "./IndexedDbSettingsCreateComponent";
//// update component
import IndexedDbSettingsUpdateComponent from "./IndexedDbSettingsUpdateComponent";

// indexed db settings components / stop

/*
 *
 * custom components
 *
 * stop
 *
 */

// the main class

const execute = (payload) => {

    let flag_console_log = ConsoleServiceIndexedDbModuleSettingsComponent;

    ConsoleService('', flag_console_log);
    ConsoleService('IndexedDbModuleSettingsCreateOrUpdateComponent', flag_console_log);

    ConsoleService('IndexedDbModuleSettingsCreateOrUpdateComponent / payload', flag_console_log);
    ConsoleService(payload, flag_console_log);

    let idb_name = indexed_db_name;

    let version = indexed_db_version;

    /*
     *
     * trying to save the settings data
     * 
     * we still have to figure out if we are on the "create" or "update" scenario
     *
     * start
     * 
     */

    return new Promise(
        (resolve, reject) => {

            ConsoleService('IndexedDbModuleSettingsCreateOrUpdateComponent / new promise', flag_console_log);

            let indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;

            let flag_scenario_create_or_update = null;

            /*
             *
             * request
             *
             * start
             * 
             */

            let request = indexedDB.open(idb_name, version);

            /*
             * request / on success / callback
             *
             * start
             * 
             */

            request.onsuccess = function (event) {

                ConsoleService('IndexedDbModuleSettingsCreateOrUpdateComponent / request / on success', flag_console_log);

                ConsoleService('IndexedDbModuleSettingsCreateOrUpdateComponent / request / on success / event', flag_console_log);
                ConsoleService(event, flag_console_log);

                ConsoleService('IndexedDbModuleSettingsCreateOrUpdateComponent / request / on success / payload', flag_console_log);
                ConsoleService(payload, flag_console_log);

                let db = event.target.result;

                ConsoleService('IndexedDbModuleSettingsCreateOrUpdateComponent / request / on success / db', flag_console_log);
                ConsoleService(db, flag_console_log);

                /*
                 *
                 * transaction
                 *
                 * start
                 * 
                 */

                /*
                 *
                 * transaction
                 * 
                 * open a read/write db transaction, ready for adding the data
                 * 
                 * https://developer.mozilla.org/en-US/docs/Web/API/IDBDatabase/transaction
                 * 
                 */

                let transaction = db.transaction(['settings_object_store'], 'readonly');

                ConsoleService('IndexedDbModuleSettingsCreateOrUpdateComponent / request / on success / transaction', flag_console_log);
                ConsoleService(transaction, flag_console_log);

                /*
                 *
                 * transaction / on complete
                 * 
                 * report on the success of opening the transaction
                 * 
                 * start
                 * 
                 */

                transaction.oncomplete = function (event) {

                    ConsoleService('IndexedDbModuleSettingsCreateOrUpdateComponent / request / on success / transaction / on complete', flag_console_log);

                    ConsoleService('IndexedDbModuleSettingsCreateOrUpdateComponent / request / on success / transaction / on complete / event', flag_console_log);
                    ConsoleService(event, flag_console_log);

                    if (
                        flag_scenario_create_or_update === 'update'
                    ) {

                        /*
                        * ok
                        * 
                        * update scenario
                        * 
                        */

                        ConsoleService('IndexedDbModuleSettingsCreateOrUpdateComponent / request / on success / transaction / on complete / update scenario', flag_console_log);

                        IndexedDbSettingsUpdateComponent(payload).then(
                            (data) => {

                                /*
                                *
                                * ok
                                * 
                                */

                                ConsoleService('IndexedDbModuleSettingsCreateOrUpdateComponent / IndexedDbSettingsUpdateComponent / then', flag_console_log);

                                ConsoleService('IndexedDbModuleSettingsCreateOrUpdateComponent / IndexedDbSettingsUpdateComponent / then / data', flag_console_log);
                                ConsoleService(data, flag_console_log);

                                resolve(data);

                            }
                        ).catch(
                            (data) => {

                                /*
                                *
                                * error
                                * 
                                */

                                ConsoleService('IndexedDbModuleSettingsCreateOrUpdateComponent / IndexedDbSettingsUpdateComponent / catch', flag_console_log);

                                ConsoleService('IndexedDbModuleSettingsCreateOrUpdateComponent / IndexedDbSettingsUpdateComponent / catch / data', flag_console_log);
                                ConsoleService(data, flag_console_log);

                                reject(data);

                            }
                        );

                        //


                    }

                    if (
                        flag_scenario_create_or_update === 'create'
                    ) {

                        /*
                        * ok
                        * 
                        * create scenario
                        * 
                        */

                        ConsoleService('IndexedDbModuleSettingsCreateOrUpdateComponent / request / on success / transaction / on complete / create scenario', flag_console_log);

                        IndexedDbSettingsCreateComponent(payload).then(
                            (data) => {

                                /*
                                *
                                * ok
                                * 
                                */

                                ConsoleService('IndexedDbModuleSettingsCreateOrUpdateComponent / IndexedDbSettingsCreateComponent / then', flag_console_log);

                                ConsoleService('IndexedDbModuleSettingsCreateOrUpdateComponent / IndexedDbSettingsCreateComponent / then / data', flag_console_log);
                                ConsoleService(data, flag_console_log);

                                resolve(data);

                            }
                        ).catch(
                            (data) => {

                                /*
                                *
                                * error
                                * 
                                */

                                ConsoleService('IndexedDbModuleSettingsCreateOrUpdateComponent / IndexedDbSettingsCreateComponent / catch', flag_console_log);

                                ConsoleService('IndexedDbModuleSettingsCreateOrUpdateComponent / IndexedDbSettingsCreateComponent / catch / data', flag_console_log);
                                ConsoleService(data, flag_console_log);

                                reject(data);

                            }
                        );

                        //

                    }

                }

                /*
                 *
                 * transaction / on complete
                 * 
                 * stop
                 * 
                 */

                /*
                 *
                 * transaction / on error
                 * 
                 * start
                 * 
                 */

                transaction.onerror = function (event) {

                    ConsoleService('IndexedDbModuleSettingsCreateOrUpdateComponent / request / on success / transaction / on error', flag_console_log);

                    ConsoleService('IndexedDbModuleSettingsCreateOrUpdateComponent / request / on success / transaction / on error / event', flag_console_log);
                    ConsoleService(event, flag_console_log);

                    reject(event);

                }

                /*
                 *
                 * transaction / on error
                 * 
                 * stop
                 * 
                 */

                /*
                 *
                 * transaction
                 *
                 * stop
                 * 
                 */

                let objectStore = transaction.objectStore('settings_object_store');

                ConsoleService('IndexedDbModuleSettingsCreateOrUpdateComponent / request / on success / transaction / objectStore', flag_console_log);
                ConsoleService(objectStore, flag_console_log);

                let index = objectStore.index('group, key');

                ConsoleService('IndexedDbModuleSettingsCreateOrUpdateComponent / request / on success / transaction / objectStore / index', flag_console_log);
                ConsoleService(index, flag_console_log);

                /*
                 *
                 * req
                 *
                 * trying to load the settings object by using the PK
                 * 
                 * start
                 * 
                 */

                let req = index.get([payload.group, payload.key]);

                ConsoleService('IndexedDbModuleSettingsCreateOrUpdateComponent / request / on success / transaction / objectStore / index / req', flag_console_log);
                ConsoleService(req, flag_console_log);

                /*
                 *
                 * req / on success
                 *
                 * start
                 * 
                 */

                req.onsuccess = function (event) {

                    ConsoleService('IndexedDbModuleSettingsCreateOrUpdateComponent / request / on success / transaction / objectStore / index / req / on success', flag_console_log);

                    ConsoleService('IndexedDbModuleSettingsCreateOrUpdateComponent / request / on success / transaction / objectStore / index / req / on success / event', flag_console_log);
                    ConsoleService(event, flag_console_log);

                    let result = event.target.result;

                    ConsoleService('IndexedDbModuleSettingsCreateOrUpdateComponent / request / on success / transaction / objectStore / index / req / on success / result', flag_console_log);
                    ConsoleService(result, flag_console_log);

                    if (result) {

                        /*
                        * ok
                        * 
                        * update scenario
                        * 
                        */

                        ConsoleService('IndexedDbModuleSettingsCreateOrUpdateComponent / request / on success / transaction / objectStore / index / req / on success / result / if => update scenario', flag_console_log);

                        flag_scenario_create_or_update = 'update';

                        //

                    } else {

                        /*
                        * ok
                        * 
                        * create scenario
                        * 
                        */

                        ConsoleService('IndexedDbModuleSettingsCreateOrUpdateComponent / request / on success / transaction / objectStore / index / req / on success / result / else => create scenario', flag_console_log);

                        flag_scenario_create_or_update = 'create';

                        //

                    }

                }

                /*
                 *
                 * req / on success
                 *
                 * stop
                 * 
                 */

                /*
                 *
                 * req / on error
                 *
                 * start
                 * 
                 */

                req.onerror = function (event) {

                    /*
                    *
                    * error
                    * 
                    */

                    ConsoleService('IndexedDbModuleSettingsCreateOrUpdateComponent / request / on success / transaction / objectStore / index / req / on error');

                    ConsoleService('IndexedDbModuleSettingsCreateOrUpdateComponent / request / on success / transaction / objectStore / index / req / on error / event', flag_console_log);
                    ConsoleService(event, flag_console_log);

                    reject(event);

                }

                /*
                 *
                 * req / on error
                 *
                 * stop
                 * 
                 */

                /*
                 *
                 * req
                 *
                 * stop
                 * 
                 */

            }

            /*
             * request / on success / callback
             *
             * stop
             * 
             */

            /*
             * request / on error / callback
             *
             * start
             * 
             */

            request.onerror = function (event) {

                /*
                *
                * error
                * 
                */

                ConsoleService('IndexedDbModuleSettingsCreateOrUpdateComponent / request / on error', flag_console_log);

                ConsoleService('IndexedDbModuleSettingsCreateOrUpdateComponent / request / on error / event', flag_console_log);
                ConsoleService(event, flag_console_log);

                reject();

            }

            /*
             * request / on error / callback
             *
             * stop
             * 
             */

            /*
             * request
             *
             * stop
             * 
             */

        }
    ).then(
        (data) => {

            /*
            *
            * ok
            * 
            */

            ConsoleService('IndexedDbModuleSettingsCreateOrUpdateComponent / new promise result => then', flag_console_log);

            ConsoleService('IndexedDbModuleSettingsCreateOrUpdateComponent / new promise result => then / data', flag_console_log);
            ConsoleService(data, flag_console_log);

            return data;

        }
    ).catch(
        (data) => {

            /*
            *
            * error
            * 
            */

            ConsoleService('IndexedDbModuleSettingsCreateOrUpdateComponent / new promise result => catch', flag_console_log);

            ConsoleService('IndexedDbModuleSettingsCreateOrUpdateComponent / new promise result => catch / data', flag_console_log);
            ConsoleService(data, flag_console_log);

            return data;

        }
    );

    // !!! CAUTION !!! unreacheble code, because of return !!!

    /*
     *
     * trying to save the settings data
     *
     * stop
     * 
     */

}

export default execute;