/*
 *
 * custom components
 *
 * start
 *
 */

import ConsoleService from './consoleServiceModules/ConsoleService';

import ConsoleServiceIndexedDbModuleSettingsComponent from "./consoleServiceModules/ConsoleServiceIndexedDbModuleSettingsComponent";

import { indexed_db_name, indexed_db_version } from '../IndexedDbComponent';

/*
 *
 * custom components
 *
 * stop
 *
 */

const execute = (payload) => {

    let flag_console_log = ConsoleServiceIndexedDbModuleSettingsComponent;

    ConsoleService('', flag_console_log);
    ConsoleService('IndexedDbSettingsCreateComponent', flag_console_log);

    ConsoleService('IndexedDbSettingsCreateComponent / payload', flag_console_log);
    ConsoleService(payload, flag_console_log);

    let idb_name = indexed_db_name;

    ConsoleService('IndexedDbSettingsCreateComponent / IndexedDb / idb name', flag_console_log);
    ConsoleService(idb_name, flag_console_log);

    let version = indexed_db_version;

    ConsoleService('IndexedDbSettingsCreateComponent / IndexedDb / version', flag_console_log);
    ConsoleService(version, flag_console_log);

    return new Promise(
        (resolve, reject) => {

            ConsoleService('IndexedDbSettingsCreateComponent / new promise', flag_console_log);

            let indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;

            /*
             *
             * request
             *
             * start
             * 
             */

            let request = indexedDB.open(idb_name, version);

            /*
             * request / on success / callback
             *
             * start
             * 
             */

            request.onsuccess = function (event) {

                ConsoleService('IndexedDbSettingsCreateComponent / request / on success', flag_console_log);

                ConsoleService('IndexedDbSettingsCreateComponent / request / on success / event', flag_console_log);
                ConsoleService(event, flag_console_log);

                let db = event.target.result;

                /*
                 *
                 * transaction
                 *
                 * start
                 * 
                 */

                /*
                 *
                 * transaction
                 * 
                 * open a read/write db transaction, ready for adding the data
                 * 
                 * https://developer.mozilla.org/en-US/docs/Web/API/IDBDatabase/transaction
                 * 
                 */

                let transaction = db.transaction(['settings_object_store'], 'readwrite');

                ConsoleService('IndexedDbSettingsCreateComponent / request / on success / transaction', flag_console_log);
                ConsoleService(transaction, flag_console_log);

                /*
                 * report on the success of opening the transaction
                 */

                transaction.oncomplete = function (event) {

                    ConsoleService('IndexedDbSettingsCreateComponent / request / on success / transaction / on complete', flag_console_log);

                    ConsoleService('IndexedDbSettingsCreateComponent / request / on success / transaction / on complete / event', flag_console_log);
                    ConsoleService(event, flag_console_log);

                    // !! SHOULD THE "RESOLVE" EVENT BE HERE ? !!

                    //resolve(event);

                }

                transaction.onerror = function (event) {

                    ConsoleService('IndexedDbSettingsCreateComponent / request / on success / transaction / on error', flag_console_log);

                    ConsoleService('IndexedDbSettingsCreateComponent / request / on success / transaction / on error / event', flag_console_log);
                    ConsoleService(event, flag_console_log);

                    reject(event);

                    //

                }

                /*
                 *
                 * transaction
                 *
                 * stop
                 * 
                 */

                /*
                 *
                 * object store
                 *
                 * start
                 * 
                 */

                let objectStore = transaction.objectStore('settings_object_store');

                ConsoleService('IndexedDbSettingsCreateComponent / objectStore', flag_console_log);
                ConsoleService(objectStore, flag_console_log);

                ConsoleService('IndexedDbSettingsCreateComponent / objectStore.indexNames', flag_console_log);
                ConsoleService(objectStore.indexNames, flag_console_log);

                ConsoleService('IndexedDbSettingsCreateComponent / objectStore.keyPath', flag_console_log);
                ConsoleService(objectStore.keyPath, flag_console_log);

                ConsoleService('IndexedDbSettingsCreateComponent / objectStore.name', flag_console_log);
                ConsoleService(objectStore.name, flag_console_log);

                ConsoleService('IndexedDbSettingsCreateComponent / objectStore.transaction', flag_console_log);
                ConsoleService(objectStore.transaction, flag_console_log);

                ConsoleService('IndexedDbSettingsCreateComponent / objectStore.autoIncrement', flag_console_log);
                ConsoleService(objectStore.autoIncrement, flag_console_log);

                /*
                 *
                 * object store request "objectStoreRequest"
                 *
                 * start
                 * 
                 */

                var item = {

                    group: payload.group,
                    key: payload.key,
                    value: payload.value,

                };

                ConsoleService('IndexedDbSettingsCreateComponent / request / on success / item', flag_console_log);
                ConsoleService(item, flag_console_log);

                /*
                * we need to save the data
                * a new settings entry will be created
                */

                let objectStoreRequest = objectStore.add(item);

                /*
                 *
                 * objectStoreRequest / on success
                 *
                 * start
                 * 
                 */

                objectStoreRequest.onsuccess = function (event) {

                    ConsoleService('IndexedDbSettingsCreateComponent / request / on success / object store request / on success', flag_console_log);

                    ConsoleService('IndexedDbSettingsCreateComponent / request / on success / object store request / on success / event', flag_console_log);
                    ConsoleService(event, flag_console_log);

                    // !! SHOULD THE "RESOLVE" EVENT BE HERE ? !!

                    resolve(event);

                    //

                }

                /*
                 *
                 * objectStoreRequest / on success
                 *
                 * stop
                 * 
                 */

                /*
                 *
                 * objectStoreRequest / on error
                 *
                 * start
                 * 
                 */

                objectStoreRequest.onerror = function (event) {

                    ConsoleService('IndexedDbSettingsCreateComponent / request / on success / object store request / on error', flag_console_log);

                    ConsoleService('IndexedDbSettingsCreateComponent / request / on success / object store request / on error / event', flag_console_log);
                    ConsoleService(event, flag_console_log);

                    reject(event);

                    //

                }

                /*
                 *
                 * objectStoreRequest / on error
                 *
                 * stop
                 * 
                 */

                /*
                 *
                 * object store
                 *
                 * stop
                 * 
                 */

            };

            /*
             * request / on success / callback
             *
             * stop
             * 
             */

            /*
             * request / on error / callback
             *
             * start
             * 
             */

            request.onerror = function (event) {

                ConsoleService('IndexedDbSettingsCreateComponent / request / on error', flag_console_log);

                ConsoleService('IndexedDbSettingsCreateComponent / request / on error / event', flag_console_log);
                ConsoleService(event, flag_console_log);

                reject(event);

            };

            /*
             * request / on error / callback
             *
             * stop
             * 
             */

            /*
             *
             * request
             *
             * stop
             * 
             */

        }
    ).then(
        (data) => {

            /*
            *
            * ok
            * 
            */

            ConsoleService('IndexedDbSettingsCreateComponent / new promise then', flag_console_log);

            ConsoleService('IndexedDbSettingsCreateComponent / new promise => then / data', flag_console_log);
            ConsoleService(data, flag_console_log);

            return data;

            //

        }
    ).catch(
        (data) => {

            /*
            *
            * error
            * 
            */

            ConsoleService('IndexedDbSettingsCreateComponent / new promise => catch', flag_console_log);

            ConsoleService('IndexedDbSettingsCreateComponent / new promise => catch / data', flag_console_log);
            ConsoleService(data, flag_console_log);

            return data;

            //

        }

        //

    );

    //

}

export default execute;