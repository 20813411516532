import attribute_to_word_en from './attribute_to_word_en';
import attribute_to_word_fr from './attribute_to_word_fr';
import attribute_to_word_ro from './attribute_to_word_ro';

const execute = (language) => {

    let obj = {
        'en': attribute_to_word_en,
        'fr': attribute_to_word_fr,
        'ro': attribute_to_word_ro,
    };

    return obj[language];

}

export default execute;