import DexieDbComponent from './DexieDbComponent';

const serviceAddEventLogElement = (payload) => {
    console.log('serviceAddEventLogElement payload', payload);

    new DexieDbComponent();

    return true;
}

export default serviceAddEventLogElement;