import ConsoleService from './../../consoleServiceModules/ConsoleService';

import ConsoleServiceIndexedDbModuleReceiptComponent from "./../../consoleServiceModules/ConsoleServiceIndexedDbModuleReceiptComponent";

import { indexed_db_name, indexed_db_version } from './../../../IndexedDbComponent';

//// create component
import IndexedDbReceiptV2CreateComponent from "./IndexedDbReceiptV2CreateComponent";
//// update component
import IndexedDbReceiptV2UpdateComponent from "./IndexedDbReceiptV2UpdateComponent";

import IndexedDbSettingsDetectRecordReturnRecordComponent from './../../IndexedDbSettingsDetectRecordReturnRecordComponent';

// the main method
const execute = (payload) => {

    let component_name = 'IndexedDbReceiptV2CreateOrUpdateComponent';

    let flag_console_log = ConsoleServiceIndexedDbModuleReceiptComponent;

    ConsoleService('', flag_console_log);
    ConsoleService(component_name, flag_console_log);

    ConsoleService(component_name + ' / payload', flag_console_log);
    ConsoleService(payload, flag_console_log);

    let idb_name = indexed_db_name;

    let version = indexed_db_version;

    /*
     *
     * trying to save the receipt V2 data
     * 
     * we still have to figure out if we are on the "create" or "update" scenario
     *
     * start
     * 
     */

    return new Promise(
        (resolve, reject) => {

            ConsoleService(component_name + ' / new promise', flag_console_log);

            let indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;

            let flag_scenario_create_or_update = null;

            /*
             *
             * request
             *
             * start
             * 
             */

            let request = indexedDB.open(idb_name, version);

            /*
             * request / on success / callback
             *
             * start
             * 
             */

            request.onsuccess = function (event) {

                ConsoleService(component_name + ' / request / on success', flag_console_log);

                ConsoleService(component_name + ' / request / on success / event', flag_console_log);
                ConsoleService(event, flag_console_log);

                ConsoleService(component_name + ' / request / on success / payload', flag_console_log);
                ConsoleService(payload, flag_console_log);

                let db = event.target.result;

                ConsoleService(component_name + ' / request / on success / db', flag_console_log);
                ConsoleService(db, flag_console_log);

                /*
                 *
                 * transaction
                 *
                 * start
                 * 
                 */

                let transaction = db.transaction(['receipt_v2_object_store'], 'readonly');

                ConsoleService(component_name + ' / request / on success / transaction', flag_console_log);
                ConsoleService(transaction, flag_console_log);

                /*
                 *
                 * transaction / on complete
                 * 
                 * report on the success of opening the transaction
                 * 
                 * start
                 * 
                 */

                transaction.oncomplete = function (event) {

                    ConsoleService(component_name + ' / request / on success / transaction / on complete', flag_console_log);

                    ConsoleService(component_name + ' / request / on success / transaction / on complete / event', flag_console_log);
                    ConsoleService(event, flag_console_log);

                    let item = {

                        /*
                        
                        receipt attributes
                        
                        start
                        
                        */

                        // set the invoice FK
                        //receipt_object_store_id: payload.receipt_object_store_id,

                        receipt_date: payload.receipt_date,

                        receipt_serial: payload.receipt_serial,

                        receipt_number: payload.receipt_number,

                        receipt_total: payload.receipt_total,

                        receipt_currency: payload.receipt_currency,

                        receipt_total_details: payload.receipt_total_details,

                        receipt_extra_details: payload.receipt_extra_details,

                        /*
                        
                        receipt attributes
                        
                        stop
                        
                        */

                        /* DELIMITER */

                        /*
                        
                        recipient attributes
                        
                        start
                        
                        */

                        recipient_object_store_id: payload.recipient_object_store_id,

                        recipient_company_name: payload.recipient_company_name,

                        /*
                        
                        recipient attributes
                        
                        stop
                        
                        */

                        /* DELIMITER */

                        /*
                        
                        invoice attributes
                        
                        start
                        
                        */

                        // set the invoice FK
                        invoice_object_store_id: payload.invoice_object_store_id,

                        invoice_serial_and_invoice_number: payload.invoice_serial_and_invoice_number,

                        /*
                        
                        invoice attributes
                        
                        stop
                        
                        */

                        associate_the_invoice: payload.associate_the_invoice

                    };

                    if (
                        flag_scenario_create_or_update === 'update'
                    ) {

                        /*
                        * ok
                        * 
                        * update scenario
                        * 
                        */

                        ConsoleService(component_name + ' / request / on success / transaction / on complete / update scenario', flag_console_log);

                        item.receipt_object_store_id = payload.receipt_object_store_id;

                        IndexedDbReceiptV2UpdateComponent(item).then(
                            (data) => {

                                /*
                                *
                                * ok
                                * 
                                */

                                ConsoleService(component_name + ' / IndexedDbReceiptUpdateComponent / then', flag_console_log);

                                ConsoleService(component_name + ' / IndexedDbReceiptUpdateComponent / then / data', flag_console_log);
                                ConsoleService(data, flag_console_log);

                                let payload_ = {
                                    group: 'receipt_serial_and_receipt_number',
                                    key: payload.receipt_serial,
                                    value: payload.receipt_number
                                };

                                IndexedDbSettingsDetectRecordReturnRecordComponent(payload_);

                                resolve(data);

                            }
                        ).catch(
                            (data) => {

                                /*
                                *
                                * error
                                * 
                                */

                                ConsoleService(component_name + ' / IndexedDbReceiptUpdateComponent / catch', flag_console_log);

                                ConsoleService(component_name + ' / IndexedDbReceiptUpdateComponent / catch / data', flag_console_log);
                                ConsoleService(data, flag_console_log);

                                reject(data);

                            }
                        );

                        //


                    }

                    if (
                        flag_scenario_create_or_update === 'create'
                    ) {

                        /*
                        * ok
                        * 
                        * create scenario
                        * 
                        */

                        ConsoleService(component_name + ' / request / on success / transaction / on complete / create scenario', flag_console_log);

                        IndexedDbReceiptV2CreateComponent(item).then(
                            (data) => {

                                /*
                                *
                                * ok
                                * 
                                */

                                ConsoleService(component_name + ' / IndexedDbReceiptCreateComponent / then', flag_console_log);

                                ConsoleService(component_name + ' / IndexedDbReceiptCreateComponent / then / data', flag_console_log);
                                ConsoleService(data, flag_console_log);

                                let payload_ = {
                                    group: 'receipt_serial_and_receipt_number',
                                    key: payload.receipt_serial,
                                    value: payload.receipt_number
                                };

                                IndexedDbSettingsDetectRecordReturnRecordComponent(payload_);

                                resolve(data);

                            }
                        ).catch(
                            (data) => {

                                /*
                                *
                                * error
                                * 
                                */

                                ConsoleService(component_name + ' / IndexedDbReceiptCreateComponent / catch', flag_console_log);

                                ConsoleService(component_name + ' / IndexedDbReceiptCreateComponent / catch / data', flag_console_log);
                                ConsoleService(data, flag_console_log);

                                reject(data);

                            }
                        );

                        //

                    }

                }

                /*
                 *
                 * transaction / on complete
                 * 
                 * stop
                 * 
                 */

                /*
                 *
                 * transaction / on error
                 * 
                 * start
                 * 
                 */

                transaction.onerror = function (event) {

                    ConsoleService(component_name + ' / request / on success / transaction / on error', flag_console_log);

                    ConsoleService(component_name + ' / request / on success / transaction / on error / event', flag_console_log);
                    ConsoleService(event, flag_console_log);

                    reject(event);

                }

                /*
                 *
                 * transaction / on error
                 * 
                 * stop
                 * 
                 */

                /*
                 *
                 * transaction
                 *
                 * stop
                 * 
                 */

                let objectStore = transaction.objectStore('receipt_v2_object_store');

                ConsoleService(component_name + ' / request / on success / transaction / objectStore', flag_console_log);
                ConsoleService(objectStore, flag_console_log);

                let index = objectStore.index('receipt_object_store_id');

                ConsoleService(component_name + ' / request / on success / transaction / objectStore / index', flag_console_log);
                ConsoleService(index, flag_console_log);

                /*
                 *
                 * req
                 *
                 * trying to load the receipt object by using the PK
                 * 
                 * start
                 * 
                 */

                let req = index.get(payload.receipt_object_store_id);

                ConsoleService(component_name + ' / request / on success / transaction / objectStore / index / req', flag_console_log);
                ConsoleService(req, flag_console_log);

                /*
                 *
                 * req / on success
                 *
                 * start
                 * 
                 */

                req.onsuccess = function (event) {

                    ConsoleService(component_name + ' / request / on success / transaction / objectStore / index / req / on success', flag_console_log);

                    ConsoleService(component_name + ' / request / on success / transaction / objectStore / index / req / on success / event', flag_console_log);
                    ConsoleService(event, flag_console_log);

                    let result = event.target.result;

                    ConsoleService(component_name + ' / request / on success / transaction / objectStore / index / req / on success / result', flag_console_log);
                    ConsoleService(result, flag_console_log);

                    if (result) {

                        /*
                        * ok
                        * 
                        * update scenario
                        * 
                        */

                        ConsoleService(component_name + ' / request / on success / transaction / objectStore / index / req / on success / result / if => update scenario', flag_console_log);

                        flag_scenario_create_or_update = 'update';

                        //

                    } else {

                        /*
                        * ok
                        * 
                        * create scenario
                        * 
                        */

                        ConsoleService(component_name + ' / request / on success / transaction / objectStore / index / req / on success / result / else => create scenario', flag_console_log);

                        flag_scenario_create_or_update = 'create';

                        //

                    }

                }

                /*
                 *
                 * req / on success
                 *
                 * stop
                 * 
                 */

                /*
                 *
                 * req / on error
                 *
                 * start
                 * 
                 */

                req.onerror = function (event) {

                    /*
                    *
                    * error
                    * 
                    */

                    ConsoleService(component_name + ' / request / on success / transaction / objectStore / index / req / on error');

                    ConsoleService(component_name + ' / request / on success / transaction / objectStore / index / req / on error / event', flag_console_log);
                    ConsoleService(event, flag_console_log);

                    reject(event);

                }

                /*
                 *
                 * req / on error
                 *
                 * stop
                 * 
                 */

                /*
                 *
                 * req
                 *
                 * stop
                 * 
                 */

            }

            /*
             * request / on success / callback
             *
             * stop
             * 
             */

            /*
             * request / on error / callback
             *
             * start
             * 
             */

            request.onerror = function (event) {

                /*
                *
                * error
                * 
                */

                ConsoleService(component_name + ' / request / on error', flag_console_log);

                ConsoleService(component_name + ' / request / on error / event', flag_console_log);
                ConsoleService(event, flag_console_log);

                reject();

            }

            /*
             * request / on error / callback
             *
             * stop
             * 
             */

            /*
             * request
             *
             * stop
             * 
             */

        }
    );

    // !!! CAUTION !!! unreacheble code, because of return !!!

    /*
     *
     * trying to save the receipt V2 data
     *
     * stop
     * 
     */

}

export default execute;