/*
 *
 * custom components
 *
 * start
 *
 */

import ConsoleService from './consoleServiceModules/ConsoleService';

import ConsoleServiceIndexedDbModuleReceiptComponent from "./consoleServiceModules/ConsoleServiceIndexedDbModuleReceiptComponent";

import { indexed_db_name, indexed_db_version } from './../IndexedDbComponent';

/*
 *
 * custom components
 *
 * stop
 *
 */

const execute = (receipt_id = 0) => {

    let component_name = 'IndexedDbReturnReceiptsComponent';

    let flag_console_log = ConsoleServiceIndexedDbModuleReceiptComponent;

    ConsoleService('', flag_console_log);
    ConsoleService(component_name, flag_console_log);

    let idb_name = indexed_db_name;

    ConsoleService(component_name + ' / idb name', flag_console_log);
    ConsoleService(idb_name, flag_console_log);

    let version = indexed_db_version;

    ConsoleService(component_name + ' / IndexedDb / version', flag_console_log);
    ConsoleService(version, flag_console_log);

    return new Promise(
        (resolve, reject) => {

            ConsoleService(component_name + ' / new promise', flag_console_log);

            let indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;

            /*
             *
             * request
             *
             * start
             * 
             */

            let request = indexedDB.open(idb_name, version);

            /*
             * request / on success / callback
             *
             * start
             * 
             */

            request.onsuccess = function (event) {

                ConsoleService(component_name + ' / request / on success', flag_console_log);

                ConsoleService(component_name + ' / request / on success / event', flag_console_log);
                ConsoleService(event, flag_console_log);

                let db = event.target.result;

                ConsoleService(component_name + ' / request / on success / db', flag_console_log);
                ConsoleService(db, flag_console_log);

                /*
                 *
                 * transaction
                 *
                 * start
                 * 
                 */

                /*
                 *
                 * transaction
                 * 
                 * open a read/write db transaction, ready for adding the data
                 * 
                 * https://developer.mozilla.org/en-US/docs/Web/API/IDBDatabase/transaction
                 * 
                 */

                let transaction = db.transaction(['receipt_v2_object_store'], 'readonly');

                ConsoleService(component_name + ' / request / on success / transaction', flag_console_log);
                ConsoleService(transaction, flag_console_log);

                /*
                 *
                 * transaction / on complete
                 * 
                 * report on the success of opening the transaction
                 * 
                 * start
                 * 
                 */

                transaction.oncomplete = function (event) {

                    ConsoleService(component_name + ' / request / on success / transaction / on complete', flag_console_log);

                    ConsoleService(component_name + ' / request / on success / transaction / on complete / event', flag_console_log);
                    ConsoleService(event, flag_console_log);

                    //

                }

                /*
                 *
                 * transaction / on complete
                 * 
                 * stop
                 * 
                 */

                /*
                 *
                 * transaction / on error
                 * 
                 * start
                 * 
                 */

                transaction.onerror = function (event) {

                    ConsoleService(component_name + ' / request / on success / transaction / on error', flag_console_log);

                    ConsoleService(component_name + ' / request / on success / transaction / on error / event', flag_console_log);
                    ConsoleService(event, flag_console_log);

                    reject(event);

                    //

                }

                /*
                 *
                 * transaction / on error
                 * 
                 * stop
                 * 
                 */

                /*
                 *
                 * transaction
                 *
                 * stop
                 * 
                 */

                /*
                 *
                 * "receipt" object store
                 *
                 * start
                 * 
                 */

                let store = transaction.objectStore('receipt_v2_object_store');

                ConsoleService(component_name + ' / request / on success / store', flag_console_log);
                ConsoleService(store, flag_console_log);

                /*
                 *
                 * "receipt" object store
                 *
                 * stop
                 * 
                 */

                /*
                 *
                 * req
                 *
                 * start
                 *
                 */

                let req = store.openCursor();

                ConsoleService(component_name + ' / request / on success / req', flag_console_log);
                ConsoleService(req, flag_console_log);

                let items = [];

                ConsoleService(component_name + ' / request / on success / items', flag_console_log);
                ConsoleService(items, flag_console_log);

                /*
                 *
                 * req / on success
                 *
                 * start
                 * 
                 */

                req.onsuccess = function (event) {

                    ConsoleService(component_name + ' / request / on success / req / on success', flag_console_log);

                    let cursor = event.target.result;

                    ConsoleService(component_name + ' / request / on success / req / on success / cursor', flag_console_log);
                    ConsoleService(cursor, flag_console_log);

                    if (cursor != null) {

                        ConsoleService(component_name + ' / request / on success / req / on success / cursor / if', flag_console_log);

                        ConsoleService(component_name + ' / request / on success / req / on success / cursor / if / cursor.value', flag_console_log);
                        ConsoleService(cursor.value, flag_console_log);

                        if (receipt_id === 0) {

                            /*
                            
                            load all items

                            */

                            items.push(cursor.value);

                            //

                        } else {

                            /*
                            
                            load all items by using the receipt FK

                            */

                            if (
                                parseInt(receipt_id) === parseInt(cursor.value.receipt_object_store_id)
                            ) {

                                items.push(cursor.value);

                            }

                            //

                        }

                        cursor.continue();

                    } else {

                        ConsoleService(component_name + ' / request / on success / req / on success / cursor / else', flag_console_log);

                        resolve(items);

                    }

                }

                /*
                 *
                 * req / on success
                 *
                 * stop
                 * 
                 */

                /*
                 *
                 * req / on error
                 *
                 * start
                 * 
                 */

                req.onerror = function (event) {

                    /*

                    error

                    */

                    ConsoleService(component_name + ' / request / on success / req / on error', flag_console_log);

                    ConsoleService(component_name + ' / request / on success / transaction / objectStore / index / req / on error / event', flag_console_log);
                    ConsoleService(event, flag_console_log);

                    reject([]);

                    //

                }

                /*
                 *
                 * req / on error
                 *
                 * stop
                 * 
                 */

                /*
                 *
                 * req
                 *
                 * stop
                 *
                 */

            }

            /*
             * request / on success / callback
             *
             * stop
             * 
             */

            /*
             * request / on error / callback
             *
             * start
             * 
             */

            request.onerror = function (event) {

                ConsoleService(component_name + ' / request / on error', flag_console_log);

                reject([]);

            }

            /*
             * request / on error / callback
             *
             * stop
             * 
             */

            /*
             * request
             *
             * stop
             * 
             */

        }
    ).then(
        (data) => {

            ConsoleService(component_name + ' / new promise => then', flag_console_log);

            ConsoleService(component_name + ' / new promise => then / data', flag_console_log);
            ConsoleService(data, flag_console_log);

            return data;

        }
    ).catch(
        (data) => {

            ConsoleService(component_name + ' / new promise => catch', flag_console_log);

            ConsoleService(component_name + ' / new promise => catch / data', flag_console_log);
            ConsoleService(data, flag_console_log);

            return data;

        }
    );

    //

}

export default execute;