import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import MODULE_SETTINGS_LANGUAGES_COMPONENT from './ModuleSettings_Languages_Component';
import MODULE_SETTINGS_GENERAL_SETTINGS_COMPONENT from './ModuleSettings_GeneralSettings_Component';
import MODULE_SETTINGS_INVOICES_DETAILS_COMPONENT from './ModuleSettings_InvoicesDetails_Component';

import "./../../css/VerticalTabs.css";

import translation from './../../TranslationComponent';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        //height: 224,// !!! DO NOT ACTIVATE !!!
        //width: 286
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        width: 286
    },
}));

export default function VerticalTabs(props) {

    console.log('VerticalTabs / props');
    console.log(props);

    var language = props.language;

    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>

            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabs}>

                <Tab label={translation[language]['settings']['general_settings']} {...a11yProps(0)} />
                <Tab label={translation[language]['settings']['languages']} {...a11yProps(1)} />
                <Tab label={translation[language]['settings']['invoices']} {...a11yProps(2)} />

            </Tabs>

            <TabPanel value={value} index={0}>

                <MODULE_SETTINGS_GENERAL_SETTINGS_COMPONENT language={language} />

            </TabPanel>

            <TabPanel value={value} index={1}>

                <MODULE_SETTINGS_LANGUAGES_COMPONENT language={language} />

            </TabPanel>

            <TabPanel value={value} index={2}>

                <MODULE_SETTINGS_INVOICES_DETAILS_COMPONENT language={language} />

            </TabPanel>

        </div>
    );
}