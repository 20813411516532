import React, { Component } from "react";

import ReactSelect from 'react-select';

import ReactPaginate from 'react-paginate';

import Modal from 'react-bootstrap/Modal';

/*

import ModalDialog from 'react-bootstrap/ModalDialog';

import ModalHeader from 'react-bootstrap/ModalHeader';

import ModalTitle from 'react-bootstrap/ModalTitle';

import ModalBody from 'react-bootstrap/ModalBody';

import ModalFooter from 'react-bootstrap/ModalFooter';

*/

/*
*
* css files
*
* start
*
*/

import "./css/ModuleInvoices.css";

/*
*
* css files
*
* stop
*
*/

/*
*
* custom components
*
* start
*
*/

import ConsoleService from "./components/consoleServiceModules/ConsoleService";

import ConsoleServiceModuleInvoices from "./components/consoleServiceModules/ConsoleServiceModuleInvoices";// ok 14.11.2019

import ElementItemInvoice from "./components/moduleInvoice/ElementItemInvoice";

import ModuleInvoice from './ModuleInvoice';

import ModuleInvoiceReadOnly from './components/moduleInvoiceReadOnly/ModuleInvoiceReadOnly';

import translation from './TranslationComponent';

import serviceGenerateRandomString from './serviceGenerateRandomString';

import arr_items_perPage from './components/dataProviders/const_arr_items_per_page_data';

/*
*
* custom components
*
* stop
*
*/

//import convertInvoiceToPDF from './components/convertInvoiceToPDF';

import convertInvoiceToPdfRo from './components/convertInvoiceToPdfRo';
/*

Indexed DB custom components

start

*/

import IndexedDbModuleInvoicesComponent from "./IndexedDbModuleInvoicesComponent";

/*

Indexed DB custom components

stop

*/

// the main class
class ModuleInvoices extends Component {

    module_name = 'ModuleInvoices';

    idb = null;

    flag_console_log = ConsoleServiceModuleInvoices;

    state = {};

    constructor(props) {

        super(props);

        ConsoleService("", this.flag_console_log);
        ConsoleService("ModuleInvoices / constructor", this.flag_console_log);

        ConsoleService("ModuleInvoices / constructor / props", this.flag_console_log);
        ConsoleService(props, this.flag_console_log);

        this.state = {

            flag_render_type: 'invoices',

            language: props.language,

            //currency: { value: 'EUR', symbol: '€', label: '€ ( EUR )' },
            currency: props.currency,

            invoice_items: [],
            invoice_items_count: 0,

            invoices_label: translation[props.language][this.module_name].invoices_label,
            no_invoices_label: translation[props.language][this.module_name].no_invoices_label,

            /*
            *
            * pagination info
            * 
            * start
            * 
            */

            invoices_list_pageCount: 0,
            invoices_list_perPage: this.set_invoices_list_per_page(15),
            invoices_list_perPage_select_options: arr_items_perPage,
            invoices_list_offset: 0,
            invoices_list_currentPage: 0,

            /*
            *
            * pagination info
            * 
            * stop
            * 
            */

            show_modal: false,

            invoice: {},
            invoice_hash: serviceGenerateRandomString(32),

            click_on_invoices_counter: 0,// ok 3.12.2019

        };

        ConsoleService("ModuleInvoices / constructor / this.state", this.flag_console_log);
        ConsoleService(this.state, this.flag_console_log);

    }

    /*
    *
    * Modals
    * 
    * start
    * 
    */

    set_show_modal = (payload) => {

        ConsoleService('set show modal', this.flag_console_log);

        ConsoleService('payload', this.flag_console_log);
        ConsoleService(payload, this.flag_console_log);

        let newState = Object.assign({}, this.state);

        newState.show_modal = true;

        newState.invoice = payload;

        newState.invoice_hash = serviceGenerateRandomString(32);

        this.setState(newState);

    }

    set_hide_modal = () => {

        let newState = Object.assign({}, this.state);

        newState.show_modal = false;

        newState.invoice = {};

        newState.invoice_hash = serviceGenerateRandomString(32);

        this.setState(newState);

        this.load_all_the_store_objects();

    }

    modal_close_handle = () => this.set_hide_modal()

    /*
    *
    * Modals
    * 
    * stop
    * 
    */

    load_all_the_store_objects = () => {

        ConsoleService("", this.flag_console_log);
        ConsoleService("ModuleInvoices / load_all_the_store_objects()", this.flag_console_log);

        if (
            this.idb !== null
        ) {

            /*
            * ok
            */

            this.idb.load_all_store_objects().then(
                (data) => {

                    /*
                    *
                    * ok
                    * 
                    */

                    ConsoleService("ModuleInvoices / load_all_the_store_objects() / this.idb.load_all_store_objects() / promise => then", this.flag_console_log);

                    ConsoleService("ModuleInvoices / load_all_the_store_objects() / this.idb.load_all_store_objects() / promise => then / data", this.flag_console_log);
                    ConsoleService(data, this.flag_console_log);

                    let perPage = this.state.invoices_list_perPage.value;

                    this.setState(
                        {
                            invoice_items: data,
                            invoice_items_count: data.length,
                            invoices_list_pageCount: Math.ceil(data.length / perPage)
                        }
                    );

                    //this.props.parent_callback_function(data);

                }
            ).catch(
                (data) => {

                    /*
                    *
                    * error
                    * 
                    */

                    ConsoleService("ModuleInvoices / load_all_the_store_objects() / this.idb.load_all_store_objects() / promise => catch", this.flag_console_log);

                    ConsoleService("ModuleInvoices / load_all_the_store_objects() / this.idb.load_all_store_objects() / promise => catch / data", this.flag_console_log);
                    ConsoleService(data, this.flag_console_log);

                }
            );

        } else {

            /*
            * error
            */

        }

        //

    }

    /* DELIMITER */

    set_invoices_list_per_page = (payload) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleInvoices / set_invoices_list_per_page', this.flag_console_log);

        ConsoleService('payload', this.flag_console_log);
        ConsoleService(payload, this.flag_console_log);

        let option = {};

        Object.keys(arr_items_perPage).map(
            (index) => {

                ConsoleService('ModuleInvoices / set_invoices_list_per_page / object.keys.map / index', this.flag_console_log);
                ConsoleService(index, this.flag_console_log);

                ConsoleService('ModuleInvoices / set_invoices_list_per_page / object.keys.map / object', this.flag_console_log);
                ConsoleService(arr_items_perPage[index], this.flag_console_log);

                if (
                    arr_items_perPage[index].value === payload
                ) {
                    option = arr_items_perPage[index];
                }

                return true;

            }
        );

        return option;

    }

    /* DELIMITER */

    componentDidMount() {

        ConsoleService("", this.flag_console_log);
        ConsoleService(this.module_name + " / component did mount", this.flag_console_log);

        ConsoleService(this.module_name + " / component did mount / this.state", this.flag_console_log);
        ConsoleService(this.state, this.flag_console_log);

        //this.idb = null;

        if (!("indexedDB" in window)) {

            ConsoleService("This browser doesn't support IndexedDB", this.flag_console_log);

        } else {

            ConsoleService("This browser supports IndexedDB", this.flag_console_log);
            this.idb = new IndexedDbModuleInvoicesComponent();

            ConsoleService("this.idb new IndexedDbModuleInvoicesComponent()", this.flag_console_log);
            ConsoleService(this.idb, this.flag_console_log);

            this.load_all_the_store_objects();

        }

    }

    componentDidUpdate(prevProps, prevState) {

        ConsoleService("", this.flag_console_log);
        ConsoleService("ModuleInvoices / componentDidUpdate()", this.flag_console_log);

        ConsoleService('prevState', this.flag_console_log);
        ConsoleService(prevState, this.flag_console_log);

        ConsoleService('this.state', this.flag_console_log);
        ConsoleService(this.state, this.flag_console_log);

        ConsoleService('prevProps', this.flag_console_log);
        ConsoleService(prevProps, this.flag_console_log);

        ConsoleService('this.props', this.flag_console_log);
        ConsoleService(this.props, this.flag_console_log);

        if (
            this.props.hasOwnProperty('language')
        ) {

            // ok

            /*
            *
            * within the props, we have also received the "language" prop
            * 
            */

            ConsoleService(this.module_name + ' / component did update / props.language exists', this.flag_console_log);

            ConsoleService('props.language', this.flag_console_log);
            ConsoleService(this.props.language, this.flag_console_log);

            // old language value from state VS new language value from props
            if (
                this.state.language !== this.props.language
            ) {

                // ok

                /*
                *
                * we need to update the translations
                * 
                */

                ConsoleService(this.module_name + ' / component did update / the translations will be updated', this.flag_console_log);

                //this.default_language = this.props.language;

                let newState = Object.assign({}, this.state);

                newState.language = this.props.language;

                this.setState(newState);

                //

            }

            //

        }

        if (
            this.props.hasOwnProperty('click_on_invoices_counter')
        ) {

            // ok, the prop exists

            if (
                parseInt(this.props.click_on_invoices_counter) !== parseInt(this.state.click_on_invoices_counter)
            ) {

                // ok

                // !!! PAY ATTENTION FOR ENDLESS LOOP !!!

                if (
                    parseInt(this.props.click_on_invoices_counter) > parseInt(this.state.click_on_invoices_counter)
                ) {

                    // ok

                    let newState = Object.assign({}, this.state);

                    newState.click_on_invoices_counter = parseInt(this.props.click_on_invoices_counter);

                    newState.flag_render_type = 'invoices';

                    this.setState(newState);

                }

            }

        }

        //

    }

    /* DELIMITER */

    handlePageClick = data => {

        let selected = data.selected;
        let offset = Math.ceil(selected * this.state.invoices_list_perPage.value);

        this.setState({
            invoices_list_offset: offset,
            invoices_list_currentPage: selected
        });

    };

    /* DELIMITER */

    formSelectInvoicesListPerPageChangedHandler = (event) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleInvoices formSelectInvoicesListPerPageChangedHandler', this.flag_console_log);

        ConsoleService('event', this.flag_console_log);
        ConsoleService(event, this.flag_console_log);

        let newState = Object.assign({}, this.state);

        newState.invoices_list_perPage = this.set_invoices_list_per_page(event.value);

        ConsoleService('ModuleInvoices formSelectInvoicesListPerPageChangedHandler newState', this.flag_console_log);
        ConsoleService(newState, this.flag_console_log);

        this.setState(newState);

        this.load_all_the_store_objects();

    }

    /* DELIMITER */

    callback_function = child_data => {

        ConsoleService("ModuleInvoices / callback_function()", this.flag_console_log);

        ConsoleService("ModuleInvoices / callback_function() / child data", this.flag_console_log);
        ConsoleService(child_data, this.flag_console_log);

        //this.props.parent_callback_function_return_invoice_data(child_data);

    };

    /* DELIMITER */

    callback_function_show_form_invoice_handler = (payload) => {

        ConsoleService('ModuleInvoices / callback_function_show_form_invoice_handler', this.flag_console_log);

        ConsoleService('payload', this.flag_console_log);
        ConsoleService(payload, this.flag_console_log);

        let newState = Object.assign({}, this.state);

        newState.flag_render_type = 'invoice';

        newState.invoice = payload;

        newState.invoice_hash = serviceGenerateRandomString(32);

        this.setState(newState);

    }

    /* DELIMITER */

    callback_function_open_modal_form_invoice_handler = (payload) => {

        ConsoleService('ModuleInvoices / callback_function_open_modal_form_invoice_handler', this.flag_console_log);

        ConsoleService('payload', this.flag_console_log);
        ConsoleService(payload, this.flag_console_log);

        this.set_show_modal(payload);

    }

    /* DELIMITER */

    callback_function_convert_invoice_to_pdf_on_click_handler = (payload) => {

        ConsoleService('ModuleInvoices / callback_function_convert_invoice_to_pdf_on_click_handler', this.flag_console_log);

        ConsoleService('payload', this.flag_console_log);
        ConsoleService(payload, this.flag_console_log);

        //if (
        //    payload.invoice_language === 'ro'
        //) {
        convertInvoiceToPdfRo(payload);
        //} else {
        //convertInvoiceToPDF(payload);
        //}

    }

    /* DELIMITER */

    mount_module_receipt = (event, payload) => {

        event.preventDefault();

        ConsoleService('ModuleInvoices / mount_module_receipt', this.flag_console_log);

        ConsoleService('payload', this.flag_console_log);
        ConsoleService(payload, this.flag_console_log);

        let newState = Object.assign({}, this.state);

        newState.flag_render_type = 'receipt';

        newState.invoice = payload;

        newState.invoice_hash = serviceGenerateRandomString(32);

        this.setState(newState);

    }

    /* DELIMITER */

    close_invoice_element = (event) => {

        event.preventDefault();

        ConsoleService('ModuleInvoices / close_invoice_element', this.flag_console_log);

        let newState = Object.assign({}, this.state);

        newState.flag_render_type = 'invoices';

        newState.invoice = {};

        newState.invoice_hash = serviceGenerateRandomString(32);

        this.setState(newState);

    }

    /* DELIMITER */

    close_receipt_element = (event) => {

        event.preventDefault();

        ConsoleService('ModuleInvoices / close_receipt_element', this.flag_console_log);

        let newState = Object.assign({}, this.state);

        newState.flag_render_type = 'invoices';

        newState.invoice = {};

        newState.invoice_hash = serviceGenerateRandomString(32);

        this.setState(newState);

    }

    /* DELIMITER */

    render() {

        ConsoleService("", this.flag_console_log);
        ConsoleService("ModuleInvoices / render()", this.flag_console_log);

        ConsoleService("ModuleInvoices / render() / this.state");

        ConsoleService(this.state);

        var w = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;

        ConsoleService('window width', this.flag_console_log);
        ConsoleService(w, this.flag_console_log);

        var h = window.innerHeight
            || document.documentElement.clientHeight
            || document.body.clientHeight;

        ConsoleService('window height', this.flag_console_log);
        ConsoleService(h, this.flag_console_log);

        // this is where we slice the invoice list
        let current_invoices = this.state.invoice_items.slice(this.state.invoices_list_offset, this.state.invoices_list_offset + this.state.invoices_list_perPage.value);

        ConsoleService('render() current_invoices', this.flag_console_log);
        ConsoleService(current_invoices, this.flag_console_log);

        let invoice_items = null;

        if (
            this.state.invoice_items.length
        ) {

            /*

             ok
             
            */

            ConsoleService("ModuleInvoices / render() / this.state.invoice_items", this.flag_console_log);
            ConsoleService(this.state.invoice_items, this.flag_console_log);

            invoice_items = (
                <React.Fragment>

                    {/* invoices a.k.a. "items" / start */}

                    <table className="table table-bordered table-striped table-hower" id="invoices_items">

                        <thead>

                            <tr>

                                <th>
                                    {translation[this.state.language].general.invoice.invoice_number}
                                </th>

                                <th>
                                    {translation[this.state.language].general.invoice.invoice_date}
                                </th>

                                <th>
                                    {translation[this.state.language].ModuleInvoices.recipient_company_name}
                                </th>

                                <th>
                                    {translation[this.state.language].general.invoice.invoice_total_without_vat}
                                </th>

                                <th>
                                    {translation[this.state.language].general.invoice.invoice_total}
                                </th>

                                <th>
                                    {translation[this.state.language].general.actions}
                                </th>

                            </tr>

                        </thead>

                        <tbody>

                            {

                                current_invoices.map(
                                    (item, index) => {

                                        ConsoleService("ModuleInvoices / render() / items / item");
                                        ConsoleService(item);

                                        ConsoleService("ModuleInvoices / render() / items / index");
                                        ConsoleService(index);

                                        return (
                                            <React.Fragment key={index}>

                                                <tr>
                                                    <ElementItemInvoice
                                                        key={index}
                                                        item={item}
                                                        parent_callback_function={this.callback_function}
                                                        parent_callback_show_form_invoice={this.callback_function_show_form_invoice_handler}
                                                        parent_callback_open_modal_form_invoice={this.callback_function_open_modal_form_invoice_handler}
                                                        parent_callback_download_pdf_file={this.callback_function_convert_invoice_to_pdf_on_click_handler}
                                                        language={this.state.language} />
                                                </tr>

                                            </React.Fragment>
                                        );

                                        //

                                    }
                                )

                            }

                        </tbody>

                    </table>

                    {/* invoices a.k.a. "items" / stop */}

                </React.Fragment>
            );

            //

        } else {

            //

            invoice_items = (
                <React.Fragment>
                    <span>{translation[this.state.language][this.module_name].no_invoices_label}</span>
                </React.Fragment>
            )

            //

        }

        return (
            <React.Fragment>

                {/* container invoices / start */}
                {
                    this.state.flag_render_type === 'invoices' ?

                        <div id="ModuleInvoices">
                            {/* ModuleInvoices */}

                            <div className="row">

                                <div className="col-12" >

                                    <div className="m5 p5">

                                        <h1>{translation[this.state.language][this.module_name].no_invoices_label} ( {this.state.invoice_items_count} )</h1>

                                    </div>

                                </div>

                                <div className="col-12">

                                    <div className="container-alert">

                                        <div className="alert alert-success" role="alert">
                                            <h4 className="alert-heading">Info ;)</h4>
                                            <p>text goes here</p>
                                        </div>

                                    </div>

                                </div>

                                <div className="col-12">

                                    {

                                        this.state.invoice_items.length ?

                                            <div className="m5 p5">

                                                <ReactSelect
                                                    id="invoices_list_perPage"
                                                    name="invoices_list_perPage"
                                                    className=""
                                                    value={this.state.invoices_list_perPage}
                                                    onChange={this.formSelectInvoicesListPerPageChangedHandler}
                                                    options={this.state.invoices_list_perPage_select_options}
                                                    placeholder={this.state.invoices_list_perPage}
                                                />

                                            </div>

                                            : null

                                    }

                                    {/* invoices list / start */}

                                    <div id="invoices-container">

                                        {invoice_items}

                                    </div>

                                    {/* invoices list / stop */}

                                    {

                                        this.state.invoices_list_pageCount > 1 ?

                                            /* ReactPaginate / start */

                                            <div className="m5 p5">

                                                <ReactPaginate
                                                    previousLabel={translation[this.state.language].general.previous}
                                                    nextLabel={translation[this.state.language].general.next}
                                                    breakLabel={'...'}
                                                    breakClassName={'break-me'}
                                                    breakLinkClassName={'page-link'}
                                                    pageCount={this.state.invoices_list_pageCount}
                                                    forcePage={this.state.invoices_list_currentPage}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={5}
                                                    onPageChange={this.handlePageClick}
                                                    containerClassName={'pagination page-' + this.state.invoices_list_pageCount}
                                                    subContainerClassName={'pages pagination'}
                                                    activeClassName={'active'}
                                                    pageClassName={'page-item'}
                                                    pageLinkClassName={'page-link'}
                                                    previousLinkClassName={'page-link'}
                                                    nextLinkClassName={'page-link'}
                                                />

                                            </div>

                                            /* ReactPaginate / stop */

                                            : null

                                    }

                                </div>

                            </div>

                            {/* modal form invoice / start */}

                            {

                                this.state.show_modal ?

                                    <Modal show={this.state.show_modal} onHide={this.modal_close_handle}>

                                        <Modal.Header closeButton>

                                            <Modal.Title>Invoice {this.state.invoice.hasOwnProperty('invoice_number') ? '# ' + this.state.invoice.invoice_number : ''}</Modal.Title>

                                        </Modal.Header>

                                        <Modal.Body>

                                            {/* DELIMITER */}

                                            {/* container-invoice */}
                                            {

                                                <ModuleInvoiceReadOnly
                                                    language={this.state.language}
                                                    invoice={this.state.invoice}
                                                    currency={this.state.currency}
                                                    invoice_hash={this.state.invoice_hash} />

                                            }
                                            {/* /container-invoice */}

                                            {/* DELIMITER */}

                                        </Modal.Body>

                                        <Modal.Footer>

                                            <button variant="secondary" onClick={this.modal_close_handle}>
                                                Close
                                            </button>

                                        </Modal.Footer>

                                    </Modal>

                                    : null

                            }

                            {/* modal form invoice / stop */}

                            {/* /ModuleInvoices */}
                        </div>

                        : null

                }
                {/* container invoices / stop */}

                {/* container invoice / start */}
                {

                    this.state.flag_render_type === 'invoice' ?

                        <div id="container-invoice-main">

                            <div className="row">

                                <div className="col-6">

                                    <h1>Invoices / Invoice update</h1>

                                </div>

                                <div className="col-6">

                                    <div className="loadingElementClose">
                                        <span onClick={this.close_invoice_element}><i className="fa fa-times" aria-hidden="true"></i></span>
                                    </div>

                                </div>

                                <div className="col-12">

                                    <ModuleInvoice
                                        language={this.state.language}
                                        invoice={this.state.invoice}
                                        currency={this.state.currency}
                                        invoice_hash={this.state.invoice_hash} />

                                </div>

                            </div>

                        </div>

                        : null

                }
                {/* container invoice / stop */}

            </React.Fragment>
        );
    }
}

export default ModuleInvoices;