/*

language : RO

*/

export default {

    /*
    
    recipient
    
    start
    
    */

    recipient_account_type: 'attribute recipient account type ro',

    recipient_company_name: 'attribute recipient company name ro',

    recipient_vat_nr: 'attribute recipient vat number ro',

    recipient_email: 'attribute recipient email ro',

    recipient_phone_nr: 'attribute recipient phone nr ro',

    recipient_addresses_country_key: 'attribute recipient country ro',

    recipient_addresses_city: 'attribute recipient city ro',

    recipient_addresses_street_address: 'attribute recipient street address ro',

    recipient_addresses_zip: 'attribute recipient zip ro',

    /*
    
    recipient
    
    stop
    
    */

    /* DELIMITER */

    /*
    
    receipt
    
    start
    
    */

    receipt_date: 'attribute receipt date ro',

    receipt_serial: 'attribute receipt serial ro',

    receipt_number: 'attribute receipt number ro',

    receipt_total: 'suma',

    receipt_total_details: 'reprezentand',

    /*
    
    receipt
    
    stop
    
    */

}