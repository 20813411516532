const status = false;

const ConsoleService = (payload, force = false) => {

    if (
        status || force
    ) {

        console.log(payload);

    }

}

export default ConsoleService;