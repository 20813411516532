import localStorage_default_data from './LocalStorageComponent';

const result = localStorage_default_data;

const getData = () => {

    let data = localStorage.getItem('react-billableme');

    if (data) {

        /*

        ok

        some data is available

        we need to check the data and see what we have available

        */

        //console.log('data');
        //console.log(data);

        data = JSON.parse(data);

        //console.log('local storage / "data" exists');

        if (
            data.hasOwnProperty('language')
        ) {

            /*

            ok

            some data is available, regarding the languages

            */

            //console.log('local storage / "language" exists ');

            if (
                data.language.hasOwnProperty('en')
            ) {

                // ok

                //console.log('local storage / language / "en" exists ');

                result.language.en = data.language.en;

                //

            }

            if (
                data.language.hasOwnProperty('fr')
            ) {

                // ok

                //console.log('local storage / language / "fr" exists ');

                result.language.fr = data.language.fr;

                //

            }

            if (
                data.language.hasOwnProperty('ro')
            ) {

                // ok

                //console.log('local storage / language / "ro" exists ');

                result.language.ro = data.language.ro;

                //

            }

            //

        } else {

            //console.log('local storage / "language" !exists ');

        }

        //

    } else {

        /*

        ok

        the default result will be returned

        */

        //console.log('local storage "data" !exists ');

    }

    return result;

}

export default getData;