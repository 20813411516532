import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';

import translation from '../TranslationComponent';

const PdfMakeConvertREceiptToPdf = (payload) => {

    const { vfs } = vfsFonts.pdfMake;
    pdfMake.vfs = vfs;

    let d = new Date(payload.receipt_date);

    let receipt_date_format = d.getDate() + ' ' + translation['ro'].general.months[d.getMonth()] + ', ' + d.getFullYear();

    let data = {

        receipt_date: receipt_date_format,

        receipt_serial: payload.receipt_serial,

        receipt_number: payload.receipt_number,

        receipt_total: payload.receipt_total,

        receipt_currency: payload.receipt_currency,

        receipt_total_details: payload.receipt_total_details,

        receipt_extra_details: payload.receipt_extra_details,

        recipient_object_store_id: payload.recipient_object_store_id,

        recipient_company_name: payload.recipient_company_name,

        invoice_object_store_id: payload.invoice_object_store_id,

        invoice_serial_and_invoice_number: payload.invoice_serial_and_invoice_number,

        associate_the_invoice: payload.associate_the_invoice,

        receipt_object_store_id: payload.receipt_object_store_id

    };

    let arr_content = [];

    let obj = {};

    /*

    header : CHITANTA

    start

    */

    obj = { text: 'CHITANTA', margin: [0, 0, 0, 0], alignment: 'center', fontSize: 10 };

    arr_content.push(obj);

    /*

    header : CHITANTA

    stop

    */

    /* DELIMITER */

    /*
    
    receipt date / receipt serial / receipt number

    start

    */

    obj = {
        fontSize: 10,
        table: {
            body: [
                [

                    /*
                    
                    receipt data
                    
                    start
                    
                    */

                    [
                        {
                            fillColor: '#ffffff',
                            table: {
                                widths: [150],
                                body: [
                                    ['Data: ' + data.receipt_date]
                                ]
                            },
                            layout: {
                                hLineColor: 'white',
                                vLineColor: 'white',
                                paddingLeft: function (i, node) { return 3; },
                                paddingRight: function (i, node) { return 3; },
                                paddingTop: function (i, node) { return 3; },
                                paddingBottom: function (i, node) { return 3; },
                            }
                        }
                    ],

                    /*
                    
                    receipt data
                    
                    stop
                    
                    */

                    /* DELIMITER */

                    /*
                    
                    receipt serial and receipt number
                    
                    start
                    
                    */

                    [
                        {
                            fillColor: '#ffffff',
                            table: {
                                widths: [100],
                                body: [
                                    ['Seria: ' + data.receipt_serial]
                                ]
                            },
                            layout: {
                                hLineColor: 'white',
                                vLineColor: 'white',
                                paddingLeft: function (i, node) { return 3; },
                                paddingRight: function (i, node) { return 3; },
                                paddingTop: function (i, node) { return 3; },
                                paddingBottom: function (i, node) { return 3; },
                            }
                        },
                    ],

                    [
                        {
                            fillColor: '#ffffff',
                            table: {
                                widths: [100],
                                body: [
                                    ['Numar: ' + data.receipt_number]
                                ]
                            },
                            layout: {
                                hLineColor: 'white',
                                vLineColor: 'white',
                                paddingLeft: function (i, node) { return 3; },
                                paddingRight: function (i, node) { return 3; },
                                paddingTop: function (i, node) { return 3; },
                                paddingBottom: function (i, node) { return 3; },
                            }
                        },
                    ],

                    /*
                    
                    receipt serial and receipt number
                    
                    stop
                    
                    */

                ]
            ]
        },
        layout: 'noBorders'
    };

    arr_content.push(obj);

    /*
    
    receipt date / receipt serial / receipt number

    stop

    */

    /* DELIMITER */

    /*
    
    recipient company name

    start

    */

    obj = {
        fontSize: 10,
        table: {
            body: [
                [

                    [
                        {
                            fillColor: '#ffffff',
                            table: {
                                widths: [150],
                                body: [
                                    ['Client: ' + data.recipient_company_name]
                                ]
                            },
                            layout: {
                                hLineColor: 'white',
                                vLineColor: 'white',
                                paddingLeft: function (i, node) { return 3; },
                                paddingRight: function (i, node) { return 3; },
                                paddingTop: function (i, node) { return 3; },
                                paddingBottom: function (i, node) { return 3; },
                            }
                        }
                    ],

                ]
            ]
        },
        layout: 'noBorders'
    };

    arr_content.push(obj);

    /*
    
    recipient company name
    
    stop
    
    */

    /* DELIMITER */

    /*
    
    invoice serial and invoice number

    start

    */

    obj = {
        fontSize: 10,
        table: {
            body: [
                [

                    [
                        {
                            fillColor: '#ffffff',
                            table: {
                                widths: [150],
                                body: [
                                    ['Factura: ' + data.invoice_serial_and_invoice_number]
                                ]
                            },
                            layout: {
                                hLineColor: 'white',
                                vLineColor: 'white',
                                paddingLeft: function (i, node) { return 3; },
                                paddingRight: function (i, node) { return 3; },
                                paddingTop: function (i, node) { return 3; },
                                paddingBottom: function (i, node) { return 3; },
                            }
                        }
                    ],

                ]
            ]
        },
        layout: 'noBorders'
    };

    arr_content.push(obj);

    /*
    
    invoice serial and invoice number
    
    stop
    
    */

    /* DELIMITER */

    /*
    
    receipt total

    start

    */

    obj = {
        fontSize: 10,
        table: {
            body: [
                [

                    [
                        {
                            fillColor: '#ffffff',
                            table: {
                                widths: [150],
                                body: [
                                    ['Total: ' + data.receipt_total + ' ' + data.receipt_currency]
                                ]
                            },
                            layout: {
                                hLineColor: 'white',
                                vLineColor: 'white',
                                paddingLeft: function (i, node) { return 3; },
                                paddingRight: function (i, node) { return 3; },
                                paddingTop: function (i, node) { return 3; },
                                paddingBottom: function (i, node) { return 3; },
                            }
                        }
                    ],

                ]
            ]
        },
        layout: 'noBorders'
    };

    arr_content.push(obj);

    /*
    
    receipt total
    
    stop
    
    */

    /* DELIMITER */

    /*
    
    receipt total

    start

    */

    obj = {
        fontSize: 10,
        table: {
            body: [
                [

                    [
                        {
                            fillColor: '#ffffff',
                            table: {
                                widths: [150],
                                body: [
                                    ['Reprezentand: ' + data.receipt_total_details]
                                ]
                            },
                            layout: {
                                hLineColor: 'white',
                                vLineColor: 'white',
                                paddingLeft: function (i, node) { return 3; },
                                paddingRight: function (i, node) { return 3; },
                                paddingTop: function (i, node) { return 3; },
                                paddingBottom: function (i, node) { return 3; },
                            }
                        }
                    ],

                ]
            ]
        },
        layout: 'noBorders'
    };

    arr_content.push(obj);

    /*
    
    receipt total
    
    stop
    
    */

    /* DELIMITER */

    /*
    
    receipt total

    start

    */

    obj = {
        fontSize: 10,
        table: {
            body: [
                [

                    [
                        {
                            fillColor: '#ffffff',
                            table: {
                                widths: [150],
                                body: [
                                    ['Detalii: ' + data.receipt_extra_details]
                                ]
                            },
                            layout: {
                                hLineColor: 'white',
                                vLineColor: 'white',
                                paddingLeft: function (i, node) { return 3; },
                                paddingRight: function (i, node) { return 3; },
                                paddingTop: function (i, node) { return 3; },
                                paddingBottom: function (i, node) { return 3; },
                            }
                        }
                    ],

                ]
            ]
        },
        layout: 'noBorders'
    };

    arr_content.push(obj);

    /*
    
    receipt total
    
    stop
    
    */

    /* DELIMITER */

    let pdf = {
        content: arr_content
    };

    pdfMake.createPdf({ content: pdf.content }).download('chitanta-' + data.receipt_serial + '-' + data.receipt_number + '.pdf');

}

export default PdfMakeConvertREceiptToPdf;