import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';

import translation from '../TranslationComponent';

const convertInvoiceToPdfRo = (payload) => {

    const { vfs } = vfsFonts.pdfMake;
    pdfMake.vfs = vfs;

    let d = new Date(payload.invoice_date);

    let invoice_date_format = d.getDate() + ' ' + translation[payload.invoice_language].general.months[d.getMonth()] + ', ' + d.getFullYear();

    let data = {

        invoice_object_store_id: payload.invoice_object_store_id,

        invoice_currency_symbol: payload.invoice_currency_symbol,

        invoice_type: payload.invoice_type,

        flag_should_the_vat_be_taken_in_consideration: payload.flag_should_the_vat_be_taken_in_consideration,

        /*
        *
        * provider attributes
        * 
        * start
        * 
        */

        provider_company_name: payload.provider_company_name,
        provider_vat_nr: payload.provider_vat_nr,
        provider_phone_nr: payload.provider_phone_nr,
        provider_bank_account: payload.provider_bank_account,
        provider_bank_name: payload.provider_bank_name,

        provider_address_country_name: payload.provider_address_country_name,

        provider_address_county: payload.provider_address_county,
        provider_address_city: payload.provider_address_city,
        provider_address_street_address: payload.provider_address_street_address,
        provider_address_zip: payload.provider_address_zip,

        /*
        *
        * provider attributes
        * 
        * start
        * 
        */

        /*
        *
        * recipient attributes
        * 
        * start
        * 
        */

        recipient_company_name: payload.recipient_company_name,

        recipient_account_type_name: payload.recipient_account_type_name,

        recipient_vat_nr: payload.recipient_vat_nr,
        recipient_email: payload.recipient_email,
        recipient_phone_nr: payload.recipient_phone_nr,

        recipient_addresses_country_name: payload.recipient_addresses_country_name,

        recipient_addresses_city: payload.recipient_addresses_city,
        recipient_addresses_street_address: payload.recipient_addresses_street_address,
        recipient_addresses_zip: payload.recipient_addresses_zip,

        /*
        *
        * recipient attributes
        * 
        * start
        * 
        */

        invoice_number_label: payload.invoice_number_label,
        invoice_serial: payload.invoice_serial,
        invoice_number: payload.invoice_number,

        invoice_date_label: payload.invoice_date_label,
        invoice_date: invoice_date_format,

        invoice_items: payload.invoice_items,

        item_description_label: payload.item_description_label,
        item_quantity_label: payload.item_quantity_label,
        item_price_label: payload.item_price_label,
        item_vat_label: payload.item_vat_label,

        invoice_total_without_vat_label: payload.invoice_total_without_vat_label,
        // the "invoice_total_without_vat" value as a float value
        invoice_total_without_vat: payload.invoice_total_without_vat,

        invoice_total_vat_label: payload.invoice_total_vat_label,
        // the "invoice_total_vat" value as a float value
        invoice_total_vat: payload.invoice_total_vat,

        invoice_vat_type: payload.invoice_vat_type,

        invoice_subtotal_without_vat_for_vat_x: payload.invoice_subtotal_without_vat_for_vat_x,
        invoice_total_vat_for_vat_x: payload.invoice_total_vat_for_vat_x,

        invoice_total_label: payload.invoice_total_label,
        // the "invoice_total" value as a float value
        invoice_total: payload.invoice_total,

    };

    let arr_content = [];

    /*
    *
    * invoice type
    *
    * start
    *
    */

    let table_header = { text: data.invoice_type, margin: [0, 0, 0, 10], alignment: 'center', fontSize: 10 };

    /*
    *
    * invoice type
    *
    * stop
    *
    */

    arr_content.push(table_header);

    /*
    *
    * recipient info / invoice nr / invoice date / provider info
    * 
    * start
    * 
    */

    let table_recipient_date_provider = {
        fontSize: 10,
        table: {
            body: [
                [
                    [
                        {
                            fillColor: '#fafbfb',
                            table: {
                                widths: [245],
                                body: [
                                    [data.recipient_account_type_name],
                                    [data.recipient_company_name],
                                    [data.recipient_vat_nr],
                                    [data.recipient_email],
                                    [data.recipient_phone_nr],
                                    [data.recipient_addresses_country_name],
                                    [data.recipient_addresses_city],
                                    [data.recipient_addresses_street_address],
                                    [data.recipient_addresses_zip]
                                ]
                            },
                            layout: {
                                hLineColor: 'white',
                                vLineColor: 'white',
                                paddingLeft: function (i, node) { return 3; },
                                paddingRight: function (i, node) { return 3; },
                                paddingTop: function (i, node) { return 3; },
                                paddingBottom: function (i, node) { return 3; },
                            }
                        }
                    ],
                    [
                        {
                            fillColor: '#fafbfb',
                            table: {
                                widths: [118, 118],
                                body: [
                                    [data.invoice_number_label, data.invoice_serial + '-' + data.invoice_number],
                                    [data.invoice_date_label, data.invoice_date]
                                ]
                            },
                            layout: {
                                hLineColor: 'white',
                                vLineColor: 'white',
                                paddingLeft: function (i, node) { return 3; },
                                paddingRight: function (i, node) { return 3; },
                                paddingTop: function (i, node) { return 3; },
                                paddingBottom: function (i, node) { return 3; },
                            }
                        },
                        {
                            style: 'tableProvider',
                            margin: [0, 10, 0, 0],
                            fillColor: '#fafbfb',
                            table: {
                                widths: [245],
                                body: [
                                    [data.provider_company_name],
                                    [data.provider_vat_nr],
                                    [data.provider_phone_nr],
                                    [data.provider_bank_account],
                                    [data.provider_bank_name],
                                    [data.provider_address_country_name],
                                    [data.provider_address_county],
                                    [data.provider_address_city],
                                    [data.provider_address_street_address],
                                    [data.provider_address_zip]
                                ]
                            },
                            layout: {
                                hLineColor: 'white',
                                vLineColor: 'white',
                                paddingLeft: function (i, node) { return 3; },
                                paddingRight: function (i, node) { return 3; },
                                paddingTop: function (i, node) { return 3; },
                                paddingBottom: function (i, node) { return 3; },
                            }
                        }
                    ]
                ]
            ]
        },
        layout: 'noBorders'
    };

    /*
    *
    * recipient info / invoice nr / invoice date / provider info
    * 
    * stop
    * 
    */

    arr_content.push(table_recipient_date_provider);

    /*
    *
    * compose the arr invoice_items
    *
    * start
    *
    */

    let arr_header_invoice_items = [];

    arr_header_invoice_items.push(data.item_description_label.toString());
    arr_header_invoice_items.push(data.item_quantity_label.toString());
    arr_header_invoice_items.push(data.item_price_label.toString());

    if (
        data.flag_should_the_vat_be_taken_in_consideration
    ) {

        /*

        ok

        take the VAT in consideration

        insert the header column

        */

        arr_header_invoice_items.push(data.item_vat_label.toString());

        //

    }

    let arr_invoice_items = [];

    arr_invoice_items.push(arr_header_invoice_items);

    if (
        data.invoice_items.length > 0
    ) {

        // ok

        data.invoice_items.map(
            (item) => {

                let arr = [];

                arr.push(item.description);
                arr.push(item.quantity);
                arr.push(item.price);

                if (
                    data.flag_should_the_vat_be_taken_in_consideration
                ) {

                    /*
            
                    ok
            
                    take the VAT in consideration

                    insert the 'vat' column
            
                    */

                    arr.push(item.vat);

                    //

                }

                arr_invoice_items.push(arr);

                return true;

            }
        );

    }

    let table_invoice_items = {
        fontSize: 10,
        margin: [0, 10, 0, 0],
        table: {
            headerRows: 1,
            widths: [data.flag_should_the_vat_be_taken_in_consideration ? 245 : 247 + 79 + 5, 79, 79, data.flag_should_the_vat_be_taken_in_consideration ? 79 : 0],
            body: arr_invoice_items
        },
        layout: {
            fillColor: function (rowIndex) {
                return (rowIndex === 0) ? '#ced5d2' : null;
            },
            hLineColor: 'gray',
            vLineColor: 'gray',
            paddingLeft: function (i, node) { return 3; },
            paddingRight: function (i, node) { return 3; },
            paddingTop: function (i, node) { return 3; },
            paddingBottom: function (i, node) { return 3; },
        }
    };

    /*
    *
    * compose the arr invoice_items
    *
    * stop
    *
    */

    arr_content.push(table_invoice_items);

    if (
        data.flag_should_the_vat_be_taken_in_consideration
    ) {

        /*

        ok

        take the VAT in consideration

        insert the 'vat' column

        */

        /*
        *
        * invoice_total_without_vat value
        * &
        * invoice_total_vat value
        *
        * start
        *
        */

        let table_totals = {
            fontSize: 10,
            margin: [0, 5, 0, 0],
            table: {
                widths: [267, 228],
                body: [
                    [
                        [],
                        [
                            {
                                table: {
                                    widths: [111, 110],
                                    body: [
                                        [data.invoice_total_without_vat_label, data.invoice_currency_symbol + ' ' + data.invoice_total_without_vat],
                                        [data.invoice_total_vat_label, data.invoice_currency_symbol + ' ' + data.invoice_total_vat]
                                    ]
                                },
                                layout: {
                                    hLineColor: 'gray',
                                    vLineColor: 'gray',
                                    paddingLeft: function (i, node) { return 3; },
                                    paddingRight: function (i, node) { return 3; },
                                    paddingTop: function (i, node) { return 3; },
                                    paddingBottom: function (i, node) { return 3; },
                                }
                            },
                        ]
                    ]
                ],
            },
            layout: 'noBorders'
        };

        /*
        *
        * invoice_total_without_vat value
        * &
        * invoice_total_vat value
        *
        * stop
        *
        */

        arr_content.push(table_totals);

    }

    if (
        data.flag_should_the_vat_be_taken_in_consideration
    ) {

        /*

        ok

        take the VAT in consideration

        insert the 'vat' column

        */

        /*
        *
        * invoice vat type
        *
        * start
        *
        */

        if (
            Object.keys(data.invoice_vat_type).length > 0
        ) {

            Object.keys(data.invoice_vat_type).map(
                (attribute) => {

                    let table_vat = {
                        margin: [0, 5, 0, 0],
                        table: {
                            widths: [267, 228],
                            body: [
                                [
                                    [],
                                    [
                                        {
                                            fontSize: 10,
                                            table: {
                                                widths: [111, 110],
                                                body: [
                                                    [data.invoice_subtotal_without_vat_for_vat_x + ' ' + attribute + ' %', data.invoice_currency_symbol + ' ' + data.invoice_vat_type[attribute].total_without_vat],
                                                    [data.invoice_total_vat_for_vat_x + ' ' + attribute + ' %', data.invoice_currency_symbol + ' ' + data.invoice_vat_type[attribute].total_vat]
                                                ]
                                            },
                                            layout: {
                                                hLineColor: 'gray',
                                                vLineColor: 'gray',
                                                paddingLeft: function (i, node) { return 3; },
                                                paddingRight: function (i, node) { return 3; },
                                                paddingTop: function (i, node) { return 3; },
                                                paddingBottom: function (i, node) { return 3; },
                                            }
                                        },
                                    ]
                                ]
                            ],
                        },
                        layout: 'noBorders'
                    };

                    arr_content.push(table_vat);

                    return true;

                });

        }

        /*
        *
        * invoice vat type
        *
        * stop
        *
        */

    }

    /*
    *
    * invoice total
    * 
    * start
    * 
    */

    let table_total = {
        fontSize: 10,
        margin: [0, 5, 0, 0],
        table: {
            widths: [267, 228],
            body: [
                [
                    [],
                    [
                        {
                            table: {
                                widths: [111, 110],
                                body: [
                                    [data.invoice_total_label, data.invoice_currency_symbol + ' ' + data.invoice_total]
                                ]
                            },
                            layout: {
                                hLineColor: 'gray',
                                vLineColor: 'gray',
                                paddingLeft: function (i, node) { return 3; },
                                paddingRight: function (i, node) { return 3; },
                                paddingTop: function (i, node) { return 3; },
                                paddingBottom: function (i, node) { return 3; },
                            }
                        }
                    ]
                ]
            ],
        },
        layout: 'noBorders'
    };

    /*
    *
    * invoice total
    * 
    * stop
    * 
    */

    arr_content.push(table_total);

    var dd = {
        content: arr_content
    };

    pdfMake.createPdf({ content: dd.content }).download('invoice-' + data.invoice_object_store_id + '.pdf');

}

export default convertInvoiceToPdfRo;