const form_labels = {

    /* DELIMITER */

    invoice_number_label: '',// ok 20.10.2019

    invoice_date_label: '',// ok 20.10.2019

    /* DELIMITER */

    // item description
    item_description_label: '',

    // item quantity
    item_quantity_label: '',

    // item price
    item_price_label: '',

    // item vat
    item_vat_label: '',

    /* DELIMITER */

};

export default form_labels;