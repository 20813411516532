const default_language = 'en';

const arr_languages = {
    en: {
        name: "English",
        flag: 'flag_en.png',
    },
    fr: {
        name: "French",
        flag: 'flag_fr.png',
    },
    ro: {
        name: "Romana",
        flag: 'flag_ro.png',
    }
};

export default default_language;

export { arr_languages };