/*
 *
 * custom components
 *
 * start
 *
 */

import ConsoleService from './consoleServiceModules/ConsoleService';

import ConsoleServiceIndexedDbModuleProviderComponent from "./consoleServiceModules/ConsoleServiceIndexedDbModuleProviderComponent";

import { indexed_db_name, indexed_db_version } from '../IndexedDbComponent';

import get_country_name from './dataProviders/get_country_name_data';// ok 25.11.2019

/*
 *
 * custom components
 *
 * stop
 *
 */

const execute = (payload) => {

    let flag_console_log = ConsoleServiceIndexedDbModuleProviderComponent;

    ConsoleService('', flag_console_log);
    ConsoleService('IndexedDbProviderUpdateComponent', flag_console_log);

    ConsoleService('IndexedDbProviderUpdateComponent / payload', flag_console_log);
    ConsoleService(payload, flag_console_log);

    let idb_name = indexed_db_name;

    ConsoleService('IndexedDbProviderUpdateComponent / IndexedDb / idb name', flag_console_log);
    ConsoleService(idb_name, flag_console_log);

    let version = indexed_db_version;

    ConsoleService('IndexedDbProviderUpdateComponent / IndexedDb / version', flag_console_log);
    ConsoleService(version, flag_console_log);

    return new Promise(
        (resolve, reject) => {

            ConsoleService('IndexedDbProviderUpdateComponent / new promise', flag_console_log);

            let indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;

            /*
             *
             * request
             *
             * start
             * 
             */

            let request = indexedDB.open(idb_name, version);

            /*
             * request / on success / callback
             *
             * start
             * 
             */

            request.onsuccess = function (event) {

                ConsoleService('IndexedDbProviderUpdateComponent / request / on success', flag_console_log);

                ConsoleService('IndexedDbProviderUpdateComponent / request / on success / event', flag_console_log);
                ConsoleService(event, flag_console_log);

                let db = event.target.result;

                /*
                 *
                 * transaction
                 *
                 * start
                 * 
                 */

                /*
                 *
                 * transaction
                 * 
                 * open a read/write db transaction, ready for adding the data
                 * 
                 * https://developer.mozilla.org/en-US/docs/Web/API/IDBDatabase/transaction
                 * 
                 */

                let transaction = db.transaction(['provider_object_store'], 'readwrite');

                ConsoleService('IndexedDbProviderUpdateComponent / request / on success / transaction', flag_console_log);
                ConsoleService(transaction, flag_console_log);

                /*
                 * report on the success of opening the transaction
                 */

                transaction.oncomplete = function (event) {

                    ConsoleService('IndexedDbProviderUpdateComponent / request / on success / transaction / on complete', flag_console_log);

                    ConsoleService('IndexedDbProviderUpdateComponent / request / on success / transaction / on complete / event', flag_console_log);
                    ConsoleService(event, flag_console_log);

                    // !! SHOULD THE "RESOLVE" EVENT BE HERE ? !!

                    resolve(event);

                }

                transaction.onerror = function (event) {

                    ConsoleService('IndexedDbProviderUpdateComponent / request / on success / transaction / on error', flag_console_log);

                    ConsoleService('IndexedDbProviderUpdateComponent / request / on success / transaction / on error / event', flag_console_log);
                    ConsoleService(event, flag_console_log);

                    reject(event);

                }

                /*
                 *
                 * transaction
                 *
                 * stop
                 * 
                 */

                /*
                 *
                 * object store
                 *
                 * start
                 * 
                 */

                let objectStore = transaction.objectStore('provider_object_store');

                ConsoleService('store', flag_console_log);
                ConsoleService(objectStore, flag_console_log);

                ConsoleService('IndexedDbProviderUpdateComponent / objectStore.indexNames', flag_console_log);
                ConsoleService(objectStore.indexNames, flag_console_log);

                ConsoleService('IndexedDbProviderUpdateComponent / objectStore.keyPath', flag_console_log);
                ConsoleService(objectStore.keyPath, flag_console_log);

                ConsoleService('IndexedDbProviderUpdateComponent / objectStore.name', flag_console_log);
                ConsoleService(objectStore.name, flag_console_log);

                ConsoleService('IndexedDbProviderUpdateComponent / objectStore.transaction', flag_console_log);
                ConsoleService(objectStore.transaction, flag_console_log);

                ConsoleService('IndexedDbProviderUpdateComponent / objectStore.autoIncrement', flag_console_log);
                ConsoleService(objectStore.autoIncrement, flag_console_log);

                /*
                 *
                 * object store request "objectStoreRequest"
                 *
                 * start
                 * 
                 */

                var item = {

                    // set the PK
                    provider_object_store_id: 1,// this attribute value should always be equal to 1

                    provider_external_id: payload.attributes.provider_external_id,

                    provider_company_name: payload.attributes.provider_company_name,
                    provider_vat_nr: payload.attributes.provider_vat_nr,
                    provider_phone_nr: payload.attributes.provider_phone_nr,
                    provider_bank_account: payload.attributes.provider_bank_account,
                    provider_bank_name: payload.attributes.provider_bank_name,

                    provider_address_country_key: payload.attributes.provider_address_country_key,
                    provider_address_country_name: get_country_name(payload.attributes.provider_address_country_key),

                    provider_address_county: payload.attributes.provider_address_county,
                    provider_address_city: payload.attributes.provider_address_city,
                    provider_address_street_address: payload.attributes.provider_address_street_address,
                    provider_address_zip: payload.attributes.provider_address_zip,

                };

                ConsoleService('IndexedDbProviderUpdateComponent / request / on success / item', flag_console_log);
                ConsoleService(item, flag_console_log);

                /*
                * we need to save the data
                * the provider data will be updated
                */

                let objectStoreRequest = objectStore.put(item);

                /*
                 *
                 * objectStoreRequest / on success
                 *
                 * start
                 * 
                 */

                objectStoreRequest.onsuccess = function (event) {

                    ConsoleService('IndexedDbProviderUpdateComponent / request / on success / objectStoreRequest / on success', flag_console_log);

                    ConsoleService('IndexedDbProviderUpdateComponent / request / on success / objectStoreRequest / on success / event', flag_console_log);
                    ConsoleService(event, flag_console_log);

                    // !! SHOULD THE "RESOLVE" EVENT BE HERE ? !!

                    //resolve(event);

                }

                /*
                 *
                 * objectStoreRequest / on success
                 *
                 * stop
                 * 
                 */

                /*
                 *
                 * objectStoreRequest / on error
                 *
                 * start
                 * 
                 */

                objectStoreRequest.onerror = function (event) {

                    ConsoleService('IndexedDbProviderUpdateComponent / request / on success / objectStoreRequest / on error', flag_console_log);

                    ConsoleService('IndexedDbProviderUpdateComponent / request / on success / objectStoreRequest / on error / event', flag_console_log);
                    ConsoleService(event, flag_console_log);

                    reject(event);

                }

                /*
                 *
                 * objectStoreRequest / on error
                 *
                 * stop
                 * 
                 */

                /*
                 *
                 * object store request "objectStoreRequest"
                 *
                 * stop
                 * 
                 */

            }

            /*
             * request / on success / callback
             *
             * stop
             * 
             */

            /*
             * request / on error / callback
             *
             * start
             * 
             */

            request.onerror = function (event) {

                ConsoleService('IndexedDbProviderUpdateComponent / request / on error', flag_console_log);

                ConsoleService('IndexedDbProviderUpdateComponent / request / on error / event', flag_console_log);
                ConsoleService(event, flag_console_log);

                reject(event);

            }

            /*
             * request / on error / callback
             *
             * stop
             * 
             */

        }
    ).then(
        (data) => {

            ConsoleService('IndexedDbProviderUpdateComponent / new promise => then', flag_console_log);

            ConsoleService('IndexedDbProviderUpdateComponent / new promise => then / data', flag_console_log);
            ConsoleService(data, flag_console_log);

            return data;

        }
    ).catch(
        (data) => {

            ConsoleService('IndexedDbProviderUpdateComponent / new promise => catch', flag_console_log);

            ConsoleService('IndexedDbProviderUpdateComponent / new promise => catch / data', flag_console_log);
            ConsoleService(data, flag_console_log);

            return data;

        }
    );

    // !!! CAUTION !!! unreacheble code, because of return !!!

}

export default execute;