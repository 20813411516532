/*

language : FR

*/

export default {

    recipient_account_type: 'attribute recipient account type fr',

    recipient_company_name: 'attribute recipient company name fr',

    recipient_vat_nr: 'attribute recipient vat number fr',

    recipient_email: 'attribute recipient email fr',

    recipient_phone_nr: 'attribute recipient phone nr fr',

    recipient_addresses_country_key: 'attribute recipient country fr',

    recipient_addresses_city: 'attribute recipient city fr',

    recipient_addresses_street_address: 'attribute recipient street address fr',

    recipient_addresses_zip: 'attribute recipient zip fr',

}