export default {

    general: {

        recipient_account_type_select_option: [
            {
                value: 'individual', label: 'Individual'
            },
            {
                value: 'company', label: 'Companie'
            }
        ],

        actions: 'actiuni',
        previous: 'anterior',
        next: 'urmator',

        invoice: {

            invoice_number: 'Factura #',

            invoice_date: 'Data',

            invoice_company_name: 'Companie',

            invoice_company_info: 'Informații despre companie',

            invoice_total_without_vat: 'Total',

            invoice_total: 'Total + TVA',

            invoice_subtotal_without_vat_for_vat_x: 'Subtotal pt TVA',
            invoice_total_vat_for_vat_x: 'TVA pt',

        },

        item_description: 'descriere articol',

        months: [

            'ianuarie',
            'februarie',
            'martie',
            'aprilie',
            'mai',
            'iunie',
            'iulie',
            'august',
            'septembrie',
            'octombrie',
            'noiembrie',
            'decembrie'

        ],

        my_details: 'Detaliile mele',
        button_save_label: 'Salvează',

        form_company_info_label: 'Informații despre companie',

        invalid_form: 'Formular invalid. Vă rugăm să completați datele.',

        apply: 'Aplica',

        no_data_available: 'Nu exista date.',

        alert_header_info: 'Info',

        notification_info: 'Info',
        notification_success: 'Succes',
        notification_error: 'Eroare',

        login: 'Autentificare',
        close: 'Inchide',

    },

    ModuleBill: {

        button_providers_label: 'Detaliile mele',
        button_invoices_label: 'Facturi',
        button_recipients_label: 'Cumpărători',

        notification_choose_akti: 'Alegeți un serviciu web profesional de facturare. Încercați akti',
        button_new_invoice_label: 'Factură nouă',

        button_login_label: 'Autentificare',
        button_login_google_label: 'Autentificare cu google',
        button_login_facebook_label: 'Autentificare cu facebook',
        button_logout_label: 'Deconectare',

    },

    ModuleRecipients: {

        //add form
        recipient_company_name_label: 'Nume companie',
        recipient_vat_nr_label: 'CUI',
        //form_recipient_reference_nr_label: 'Reference Nr',
        recipient_account_type_label: 'Tip Cont Cumpărător',
        //form_recipient_language_key_label: 'Language Key',
        recipient_email_label: 'Email',
        recipient_phone_nr_label: 'Nr de telefon',
        //form_recipient_fax_nr_label: 'Nr de fax',
        //form_recipient_is_supplier_label: 'Is Supplier',
        //form_recipient_is_customer_label: 'Is Customer',

        recipient_addresses_label: 'Adresă',
        //form_recipient_company_info_label: 'Company info',
        //form_recipient_info_label: 'Recipient info',
        //form_recipient_addresses_is_billing_label: 'is billing',
        //form_recipient_addresses_is_primary_label: 'is primary',
        //form_recipient_addresses_is_delivery_label: 'is delivery',
        //form_recipient_addresses_is_site_label: 'is site',
        recipient_addresses_country_key_label: 'Țară',
        //form_recipient_addresses_state_label: 'State',
        recipient_addresses_street_address_label: 'Adresă',
        recipient_addresses_city_label: 'Oraș',
        recipient_addresses_zip_label: 'Cod',

        recipients_label: 'Cumpărători',
        no_recipients_label: 'Nu există cumpărători',
        button_add_recipient_label: 'adaugă cumpărător',
        button_show_recipients_label: 'Vizualizare cumpărători',
        button_save_recipient_label: 'Salvează',
        select_option_company_use: 'Companie',
        select_option_individual_use: 'Individual',
        select_option_yes: 'Da',
        select_option_no: 'Nu',

        //notification messages
        notification_vat_nr: 'Vă rugăm să completați CUI ...',
        notification_email: 'Vă rugăm să completați email ...',
        notification_phone_nr: 'Vă rugăm să completați numărul de telefon ...',
        notification_addresses_city: 'Vă rugăm să completați orașul ...',
        notification_addresses_street_address: 'Vă rugăm să completați adresa ...',
        notification_addresses_zip: 'Vă rugăm să completați codul ...',
        notification_reference_nr: 'Vă rugăm să completați reference nr ...',
        notification_recipient_saved: 'Cumpărător salvat',
        notification_company_name: 'Vă rugăm să completați numele companiei ...',
        notification_account_type: 'Vă rugăm să selectați tipul de cont ...',
        notification_addresses_country_key: 'Vă rugăm să selectați țara ...',

        label_company_name: 'Nume companie',
        label_vat_nr: 'CUI',
        label_account_type: 'Tip cumpărător',
        label_email: 'Email',
        label_phone_nr: 'Nr. de telefon',
        label_addresses_country_key: 'Țara',
        label_addresses_street_address: 'Adresa',
        label_addresses_city: 'Oraș',
        label_addresses_zip: 'Cod',

        recipient_account_type_select_option: [
            {
                value: 'individual', label: 'Individual'
            },
            {
                value: 'company', label: 'Companie'
            }
        ],

        placeholder_recipient_account_type: 'selectați tipul de cont',
        placeholder_recipient_addresses_country_key: 'selectați țara',
        placeholder_recipient_company_name: 'nume companie',
        placeholder_recipient_vat_nr: 'CUI',
        placeholder_recipient_email: 'email',
        placeholder_recipient_phone_nr: 'nr. de telefon',
        placeholder_recipient_addresses_city: 'oraș',
        placeholder_recipient_addresses_street_address: 'adresă',
        placeholder_recipient_addresses_zip: 'cod',

    },

    ModuleInvoices: {
        invoices_label: 'Facturi',
        no_invoices_label: 'Nu există facturi',
        recipient_company_name: 'Companie cumpărător',
    },

    ModuleInvoice: {

        invoice_type: 'Tip de factură',

        invoice_number_label: 'Factura #',
        invoice_date_label: 'Data',

        item_description_label: 'Articol & Descriere',
        item_quantity_label: 'Cantitate',
        item_price_label: 'Preț',
        item_vat_label: 'TVA',

        invoice_subtotal_label: 'Subtotal',
        invoice_vat_label: 'TVA',

        invoice_total_without_vat_label: 'TOTAL fără TVA',
        invoice_total_vat_label: 'TOTAL TVA',
        invoice_total_label: 'TOTAL',

        invoice_first_item_description: 'primul articol',

        invoice_item_description: 'articol nedefinit',

        button_invoices_label: 'Facturi',
        button_recipients_label: 'Cumpărători',

        button_save_invoice_label: 'Salvează factura',
        //button_new_bill_label: 'Factură nouă',
        button_new_invoice_form_label: 'Formular factură nouă',
        button_try_external_tool_label: 'sincronizează facturile acum',
        button_download_pdf_label: 'Descarcă factura în format PDF',

        recipient_account_type_select_option: [
            {
                value: 'individual', label: 'Individual'
            },
            {
                value: 'company', label: 'Companie'
            }
        ],

        recipient_account_type_label: 'Tip Cont Cumpărător',

        invoice_subtotal_without_vat_for_vat_x: 'Subtotal pt TVA',
        invoice_total_vat_for_vat_x: 'TVA pt',

        /*
        *
        * provider placeholders
        *
        * start
        *
        */

        placeholder_provider_company_name: 'numele companiei dvs. ...',
        placeholder_provider_vat_nr: 'CUI furnizor',
        placeholder_provider_phone_nr: 'nr de telefon furnizor',
        placeholder_provider_bank_account: 'cont banca furnizor',
        placeholder_provider_bank_name: 'nume banca furnizor',

        placeholder_provider_address_country_key: 'țară furnizor',
        placeholder_provider_address_county: 'județ furnizor',
        placeholder_provider_address_city: 'oraș furnizor',
        placeholder_provider_address_street_address: 'adresă furnizor',
        placeholder_provider_address_zip: 'cod furnizor',

        /*
        *
        * provider placeholders
        *
        * stop
        *
        */

        /*
        *
        * notification messages
        *
        * start
        * 
        */

        notification_invoice_data_loaded: 'Datele facturii au fost încărcate',
        notification_choose_recipient: 'Vă rugăm să alegeți un cumpărător ...',

        /* DELIMITER */

        /*
        *
        * invoice notification messages
        *
        * start
        * 
        */

        notification_invoice_type: 'Vă rugăm să completați tipul facturii.',

        notification_invoice_company_name: 'Vă rugăm să completați numele companiei.',
        notification_invoice_company_info: 'Vă rugăm să completați detaliile companiei.',

        /*
        *
        * provider notifications
        * 
        * start
        * 
        */

        notification_provider_company_name: 'Vă rugăm să completați numele companiei furnizoare.',
        notification_provider_vat_nr: 'Vă rugăm să completați CUI furnizor.',
        notification_provider_phone_nr: 'Vă rugăm să completați nr telefon furnizor.',
        notification_provider_bank_account: 'Vă rugăm să completați cont bancar furnizor.',
        notification_provider_bank_name: 'Vă rugăm să completați nume banca furmizor.',

        notification_provider_address_country_key: 'Vă rugăm să completați țară furnizor.',
        notification_provider_address_county: 'Vă rugăm să completați județ furnizor.',
        notification_provider_address_city: 'Vă rugăm să completați oraș furnizor.',
        notification_provider_address_street_address: 'Vă rugăm să completați adresă furnizor.',
        notification_provider_address_zip: 'Vă rugăm să completați cod furnizor.',

        /*
        *
        * provider notifications
        * 
        * stop
        * 
        */

        notification_recipient_account_type: 'Vă rugăm să completați tip cont cumpărător.',
        notification_recipient_company_name: 'Vă rugăm să completați nume companie cumpărător.',
        notification_recipient_vat_nr: 'Vă rugăm să completați CUI cumpărător',
        notification_recipient_email: 'Vă rugăm să completați email cumpărător.',
        notification_recipient_phone_nr: 'Vă rugăm să completați nr. de telefon cumpărător.',
        notification_recipient_addresses_country_key: 'Vă rugăm să selectați țara.',
        notification_recipient_addresses_city: 'Vă rugăm să completați orașul.',
        notification_recipient_addresses_street_address: 'Vă rugăm să completați adresa.',
        notification_recipient_addresses_zip: 'Vă rugăm să completați codul.',

        notification_invoice_number: 'Vă rugăm să completați numărul facturii.',
        notification_invoice_date: 'Vă rugăm să completați data facturii.',

        notification_invoice_vat: 'Vă rugăm să completați tva-ul facturii.',

        /*
        *
        * invoice notification messages
        *
        * stop
        * 
        */

        /* DELIMITER */

        notification_bill_saved: 'Factură salvată',
        notification_recipient_data_loaded: 'Datele cumpărătorului au fost încărcate',

        /*
        *
        * notification messages
        *
        * stop
        * 
        */

        /*
        *
        * placeholders
        * 
        * start
        * 
        */

        /*
        *
        * placeholders for invoice
        *
        * start
        * 
        */

        placeholder_invoice_type: 'Vă rugăm să completați tipul facturii',// ok 30.10.2019
        placeholder_invoice_company_name: 'Numele companiei ...',// ok 30.10.2019
        placeholder_invoice_company_info: 'Detaliile companiei ...',// ok 30.10.2019

        placeholder_invoice_number: '123',
        placeholder_invoice_number_label: 'Factura #',

        placeholder_invoice_date: 'ex : 31 noiembrie 2019',
        placeholder_invoice_date_label: 'Data',

        /*
        *
        * placeholders for invoice
        *
        * stop
        * 
        */

        /*
        *
        * placeholders for recipient
        *
        * start
        * 
        */

        placeholder_recipient_account_type: 'selectați tip cont',
        placeholder_recipient_company_name: 'selectați nume companie',
        placeholder_recipient_vat_nr: 'CUI',
        placeholder_recipient_email: 'email',
        placeholder_recipient_phone_nr: 'nr. de telefon',
        placeholder_recipient_addresses_country_key: 'selectați țară',
        placeholder_recipient_addresses_city: 'oraș',
        placeholder_recipient_addresses_street_address: 'adresă',
        placeholder_recipient_addresses_zip: 'cod',

        /*
        *
        * placeholders for recipient
        *
        * stop
        * 
        */

        placeholder_invoice_subtotal: 'Subtotal',
        placeholder_invoice_vat: 'TVA',

        placeholder_invoice_total_without_vat: 'TOTAL fără TVA',
        placeholder_invoice_total_vat: 'TOTAL TVA',
        placeholder_invoice_total: 'Total',

        /*
        *
        * placeholders for invoice item labels
        *
        * start
        *
        */

        placeholder_item_description_label: 'Articol & Descriere',
        placeholder_item_quantity_label: 'Cantitate',
        placeholder_item_price_label: 'Preț',
        placeholder_item_vat_label: 'TVA',

        /*
        *
        * placeholders for invoice item labels
        *
        * stop
        *
        */

        /*
        *
        * placeholders
        * 
        * stop
        * 
        */

    },

    ElementItemRecipient: {
        label_recipient_info: 'Informații despre cumpărător',
        label_addresses: 'Adresă',
        label_button_load_data: 'încarcă date despre cumpărătorul de mai sus',
        label_button_edit_recipient: 'editează'
    },

    ElementItemInvoice: {

        company_info_label: 'detalii companie ...',
        company_name_label: 'nume companie ...',
        invoice_date_label: 'dată factură ...',
        invoice_number_label: 'număr factură ...',
        invoice_type_label: 'tip factură ...',
        recipient_info_label: 'detalii cumpărător ...',
        subtotal_label: 'subtotal ...',
        total_label: 'total ...',
        vat_label: 'tva ...',
        vat_value_label: 'valoare tva ...',

        invoice_details_label: 'Detalii factură',
        button_load_data: 'încarcă factura de mai sus',
        label_button_show_invoice: 'vizualizează',
        label_button_edit_invoice: 'editează',
        label_button_download_pdf_invoice: 'descarcă PDF',
        label_button_receipts: 'chitanțe'

    },

    ModuleProvider: {

        /*
        *
        * provider placeholders
        *
        * start
        *
        */

        placeholder_provider_company_name: 'numele companiei ...',
        placeholder_provider_vat_nr: 'CUI furnizor',
        placeholder_provider_phone_nr: 'nr de telefon furnizor',
        placeholder_provider_bank_account: 'cont banca furnizor',
        placeholder_provider_bank_name: 'nume banca furnizor',

        placeholder_provider_address_country_key: 'țară furnizor',
        placeholder_provider_address_county: 'județ furnizor',
        placeholder_provider_address_city: 'oraș furnizor',
        placeholder_provider_address_street_address: 'adresă furnizor',
        placeholder_provider_address_zip: 'cod furnizor',

        /*
        *
        * provider placeholders
        *
        * stop
        *
        */

        /*
        *
        * provider notifications
        * 
        * start
        * 
        */

        notification_provider_company_name: 'Vă rugăm să completați numele companiei furnizoare.',// ok 20.11.2019
        notification_provider_vat_nr: 'Vă rugăm să completați CUI furnizor.',// ok 20.11.2019
        notification_provider_phone_nr: 'Vă rugăm să completați nr de telefon furnizor',// ok 20.11.2019
        notification_provider_bank_account: 'Vă rugăm să completați cont bancar furnizor.',// ok 20.11.2019
        notification_provider_bank_name: 'Vă rugăm să completați nume banca furnizor.',// ok 20.11.2019

        notification_provider_address_country_key: 'Vă rugăm să completați țară furnizor.',// ok 20.11.2019
        notification_provider_address_county: ' Vă rugăm să completați județ furnizor.',// ok 20.11.2019
        notification_provider_address_city: 'Vă rugăm să completați oraș furnizor.',// ok 20.11.2019
        notification_provider_address_street_address: 'Vă rugăm să completați adresă furnizor.',// ok 20.11.2019
        notification_provider_address_zip: 'Vă rugăm să completați cod furnizor.',

        /*
        *
        * provider notifications
        * 
        * stop
        * 
        */

        notification_provider_saved: 'furnizorul a fost salvat',

    },

    settings: {

        settings: 'Setari',

        /* general settings / start */

        general_settings: 'Setari generale',// tab/h element

        general_settings_info_content: 'general settings info content ro',// alert content

        vat_status: 'Status TVA',// option
        default_vat_value: 'Valoarea standard TVA',// option

        notification_success_update_vat_status: 'Setarea a fost salvata.',// notification text
        notification_error_update_vat_status: 'Setarea nu a fost modificata !',// notification text

        notification_success_update_variables_vat: 'Setarea a fost salvata.',// notification text
        notification_error_update_variables_vat: 'Setarea nu a fost modificata !',// notification text

        /* general settings / stop */

        /* langauges / start */

        languages: 'Limbi',// tab/h element

        languages_info_content: 'languages info content ro',// alert content

        notification_success_update_language: 'Setarea a fost salvata.',// notification text

        /* langauges / stop */

        /* invoices / start */

        invoices: 'Facturi',// tab element

        invoices_page_title: 'Detalii despre seriile sinumere facturilor',// h element

        invoices_info_content: 'settings invoices info content',

        invoices_serial: 'Serie',
        invoices_number: 'Numar',

        notification_success_update_invoice_serial_and_number: 'Setarile au fost salvate.',// notification text
        notification_error_update_invoice_serial_and_number: 'Setarile nu au fost salvate.'// notification text

        /* invoices / stop */

    }

};