import React, { Component } from 'react';

import "./../../css/ModuleSettings.css";

import ConsoleService from '../consoleServiceModules/ConsoleService';//ok 12.12.2019

//ES6
import ToggleButton from 'react-toggle-button';

import IndexedDbLoadAllComponent from '../IndexedDbLoadAllComponent';

import IndexedDbLoadOneComponent from '../IndexedDbLoadOneComponent';

import IndexedDbSettingsCreateOrUpdateComponent from '../IndexedDbSettingsCreateOrUpdateComponent';

import translation from './../../TranslationComponent';

/*
*
* "react notification component" docs available at :
*
* https://www.npmjs.com/package/react-notifications-component
*
*/

import { store as notification } from 'react-notifications-component';// ok 14.01.2019

class ModuleSettings_GeneralSettings_GeneralSettings extends Component {

    module_name = 'ModuleSettings_GeneralSettings';

    flag_console_log = true;

    state = {}

    constructor(props) {

        super(props);

        ConsoleService('ModuleSettings_GeneralSettings / constructor', this.flag_console_log);

        ConsoleService('props', this.flag_console_log);
        ConsoleService(props, this.flag_console_log);

        this.state = {

            language: props.language,

            local_storage: {},

            arr_invoice_serial_and_number: [],

            flag_available_option_vat: 'active',

            variables_vat: 0

        };

    }

    /* DELIMITER */

    componentDidMount() {

        //

        //console.log('ModuleSettings_GeneralSettings / componentDidMount');

        //console.log('this.state');
        //console.log(this.state);

        IndexedDbLoadAllComponent('settings_object_store')
            .then(
                (data) => {

                    /*
                    *
                    * ok
                    * 
                    */

                    ConsoleService('ModuleSettings_GeneralSettings / IndexedDbLoadAllComponent / new promise then', this.flag_console_log);

                    ConsoleService('ModuleSettings_GeneralSettings / IndexedDbLoadAllComponent / new promise => then / data', this.flag_console_log);
                    ConsoleService(data, this.flag_console_log);

                    if (
                        data.length > 0
                    ) {

                        /*
    
                        ok
    
                        */

                        let newState = Object.assign({}, this.state);

                        for (let i = 0; i < data.length; i++) {

                            //

                            if (
                                data[i]['group'] === 'invoice_serial_and_invoice_number'
                            ) {

                                //

                                newState.arr_invoice_serial_and_number.push(data[i]);

                                //

                            }

                            if (
                                data[i]['group'] === 'flag_available_option'
                            ) {

                                //

                                if (
                                    data[i]['key'] === 'vat'
                                ) {

                                    //

                                    newState.flag_available_option_vat = data[i]['value'];

                                    //

                                }

                                //

                            }

                            if (
                                data[i]['group'] === 'variables'
                            ) {

                                //

                                if (
                                    data[i]['key'] === 'vat'
                                ) {

                                    //

                                    newState.variables_vat = data[i]['value'];

                                    //

                                }

                                //

                            }

                            //

                        }

                        ConsoleService('newState', this.flag_console_log);
                        ConsoleService(newState, this.flag_console_log);

                        this.setState(newState);

                        //

                    }

                }
            )
            .catch(
                (data) => {

                    /*
                    *
                    * error
                    * 
                    */

                    ConsoleService('ModuleSettings_GeneralSettings / IndexedDbLoadAllComponent / new promise => catch', this.flag_console_log);

                    ConsoleService('ModuleSettings_GeneralSettings / IndexedDbLoadAllComponent / new promise => catch / data', this.flag_console_log);
                    ConsoleService(data, this.flag_console_log);

                }
            );

        //

    }

    /* DELIMITER */

    componentDidUpdate(prevProps, prevState) {

        ConsoleService('ModuleSettings_GeneralSettings / component did update', this.flag_console_log);

        ConsoleService('prevState', this.flag_console_log);
        ConsoleService(prevState, this.flag_console_log);

        ConsoleService('this.state', this.flag_console_log);
        ConsoleService(this.state, this.flag_console_log);

        ConsoleService('prevProps', this.flag_console_log);
        ConsoleService(prevProps, this.flag_console_log);

        ConsoleService('this.props', this.flag_console_log);
        ConsoleService(this.props, this.flag_console_log);

        if (
            this.props.hasOwnProperty('language')
        ) {

            // ok

            /*
            *
            * within the props, we have also received the "language" prop
            * 
            */

            ConsoleService('ModuleSettings_GeneralSettings / component did update / props.language exists', this.flag_console_log);

            ConsoleService('props.language', this.flag_console_log);
            ConsoleService(this.props.language, this.flag_console_log);

            if (
                this.state.language !== this.props.language
            ) {

                // ok

                /*
                *
                * we need to update the translations
                * 
                */

                ConsoleService('ModuleSettings_GeneralSettings / component did update / the translations will be updated', this.flag_console_log);

                let newState = Object.assign({}, this.state);

                newState.language = this.props.language;

                this.setState(newState);

                //

            }

            //

        }

        //

    }

    flag_available_option_vat_OnToggle = () => {

        let newState = Object.assign({}, this.state);

        newState.flag_available_option_vat = (this.state.flag_available_option_vat === 'active' ? 'inactive' : 'active');

        this.setState(newState);

        /*
        
        loading the setting record
        
        start
        
        */

        let payload = {
            object_store: 'settings_object_store',
            index_name: 'group, key',
            value: [
                'flag_available_option',
                'vat'
            ]
        }

        IndexedDbLoadOneComponent(payload)
            .then(
                (data) => {

                    /*
                    *
                    * ok
                    * 
                    */

                    ConsoleService('ModuleSettings_GeneralSettings / IndexedDbLoadOneComponent / new promise then', this.flag_console_log);

                    ConsoleService('ModuleSettings_GeneralSettings / IndexedDbLoadOneComponent / new promise => then / data', this.flag_console_log);
                    ConsoleService(data, this.flag_console_log);

                    data.value = this.state.flag_available_option_vat;

                    /*
                    
                    update the setting record
                    
                    start
                    
                    */

                    IndexedDbSettingsCreateOrUpdateComponent(data).then(
                        (data) => {

                            /*
                            *
                            * ok
                            * 
                            */

                            ConsoleService('ModuleSettings_GeneralSettings / IndexedDbSettingsCreateOrUpdateComponent / new promise then', this.flag_console_log);

                            ConsoleService('ModuleSettings_GeneralSettings / IndexedDbSettingsCreateOrUpdateComponent / new promise => then / data', this.flag_console_log);
                            ConsoleService(data, this.flag_console_log);

                            notification.addNotification(
                                {
                                    title: translation[this.state.language]['general']['notification_success'],
                                    message: translation[this.state.language]['settings']['notification_success_update_vat_status'],
                                    type: "success",
                                    insert: "top",
                                    container: "top-right",
                                    animationIn: ["animated", "fadeIn"],
                                    animationOut: ["animated", "fadeOut"],
                                    dismiss: {
                                        duration: 4000,
                                        onScreen: true
                                    }
                                }
                            );

                            //

                        }
                    ).catch(
                        (data) => {

                            /*
                            *
                            * error
                            * 
                            */

                            ConsoleService('ModuleSettings_GeneralSettings / IndexedDbSettingsCreateOrUpdateComponent / new promise => catch', this.flag_console_log);

                            ConsoleService('ModuleSettings_GeneralSettings / IndexedDbSettingsCreateOrUpdateComponent / new promise => catch / data', this.flag_console_log);
                            ConsoleService(data, this.flag_console_log);

                            notification.addNotification(
                                {
                                    title: translation[this.state.language]['general']['notification_error'],
                                    message: translation[this.state.language]['settings']['notification_error_update_vat_status'],
                                    type: "success",
                                    insert: "top",
                                    container: "top-right",
                                    animationIn: ["animated", "fadeIn"],
                                    animationOut: ["animated", "fadeOut"],
                                    dismiss: {
                                        duration: 4000,
                                        onScreen: true
                                    }
                                }
                            );

                            //

                        }
                    );

                    /*
                    
                    update the setting record
                    
                    stop
                    
                    */

                }
            )
            .catch(
                (data) => {

                    /*
                    *
                    * error
                    * 
                    */

                    ConsoleService('ModuleSettings_GeneralSettings / IndexedDbLoadOneComponent / new promise => catch', this.flag_console_log);

                    ConsoleService('ModuleSettings_GeneralSettings / IndexedDbLoadOneComponent / new promise => catch / data', this.flag_console_log);
                    ConsoleService(data, this.flag_console_log);

                    notification.addNotification(
                        {
                            title: translation[this.state.language]['general']['notification_error'],
                            message: translation[this.state.language]['settings']['notification_error_update_vat_status'],
                            type: "success",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 4000,
                                onScreen: true
                            }
                        }
                    );

                    //

                }
            );

        /*
        
        loading the setting record
        
        stop
        
        */

    }

    /* DELIMITER */

    itemOnChangeHandler = (event) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleSettings_InvoiceSettings / itemOnChangeHandler', this.flag_console_log);

        ConsoleService('event', this.flag_console_log);
        ConsoleService(event, this.flag_console_log);

        ConsoleService('event.target', this.flag_console_log);
        ConsoleService(event.target, this.flag_console_log);

        ConsoleService('event.target.name', this.flag_console_log);
        ConsoleService(event.target.name, this.flag_console_log);

        let name = event.target.name;

        ConsoleService('event.target.value', this.flag_console_log);
        ConsoleService(event.target.value, this.flag_console_log);

        let value = event.target.value;

        let newState = Object.assign({}, this.state);

        newState[name] = value;

        this.setState(newState);

        //

    }

    /* DELIMITER */

    update_variables_vat = (event) => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleSettings_InvoiceSettings / update_invoice_serial_and_number', this.flag_console_log);

        ConsoleService('event', this.flag_console_log);
        ConsoleService(event, this.flag_console_log);

        ConsoleService('event.target', this.flag_console_log);
        ConsoleService(event.target, this.flag_console_log);

        /*
        
        loading the setting record
        
        start
        
        */

        let payload = {
            object_store: 'settings_object_store',
            index_name: 'group, key',
            value: [
                'variables',
                'vat'
            ]
        }

        IndexedDbLoadOneComponent(payload)
            .then(
                (data) => {

                    /*
                    *
                    * ok
                    * 
                    */

                    ConsoleService('ModuleSettings_GeneralSettings / IndexedDbLoadOneComponent / new promise then', this.flag_console_log);

                    ConsoleService('ModuleSettings_GeneralSettings / IndexedDbLoadOneComponent / new promise => then / data', this.flag_console_log);
                    ConsoleService(data, this.flag_console_log);

                    data.value = this.state.variables_vat;

                    /*
                    
                    update the setting record
                    
                    start
                    
                    */

                    IndexedDbSettingsCreateOrUpdateComponent(data).then(
                        (data) => {

                            /*
                            *
                            * ok
                            * 
                            */

                            ConsoleService('ModuleSettings_GeneralSettings / IndexedDbSettingsCreateOrUpdateComponent / new promise then', this.flag_console_log);

                            ConsoleService('ModuleSettings_GeneralSettings / IndexedDbSettingsCreateOrUpdateComponent / new promise => then / data', this.flag_console_log);
                            ConsoleService(data, this.flag_console_log);

                            notification.addNotification(
                                {
                                    title: translation[this.state.language]['general']['notification_success'],
                                    message: translation[this.state.language]['settings']['notification_success_update_variables_vat'],
                                    type: "success",
                                    insert: "top",
                                    container: "top-right",
                                    animationIn: ["animated", "fadeIn"],
                                    animationOut: ["animated", "fadeOut"],
                                    dismiss: {
                                        duration: 4000,
                                        onScreen: true
                                    }
                                }
                            );

                            //

                        }
                    ).catch(
                        (data) => {

                            /*
                            *
                            * error
                            * 
                            */

                            ConsoleService('ModuleSettings_GeneralSettings / IndexedDbSettingsCreateOrUpdateComponent / new promise => catch', this.flag_console_log);

                            ConsoleService('ModuleSettings_GeneralSettings / IndexedDbSettingsCreateOrUpdateComponent / new promise => catch / data', this.flag_console_log);
                            ConsoleService(data, this.flag_console_log);

                            notification.addNotification(
                                {
                                    title: translation[this.state.language]['general']['notification_error'],
                                    message: translation[this.state.language]['settings']['notification_error_update_variables_vat'],
                                    type: "success",
                                    insert: "top",
                                    container: "top-right",
                                    animationIn: ["animated", "fadeIn"],
                                    animationOut: ["animated", "fadeOut"],
                                    dismiss: {
                                        duration: 4000,
                                        onScreen: true
                                    }
                                }
                            );

                            //

                        }
                    );

                    /*
                    
                    update the setting record
                    
                    stop
                    
                    */

                }
            )
            .catch(
                (data) => {

                    /*
                    *
                    * error
                    * 
                    */

                    ConsoleService('ModuleSettings_GeneralSettings / IndexedDbLoadOneComponent / new promise => catch', this.flag_console_log);

                    ConsoleService('ModuleSettings_GeneralSettings / IndexedDbLoadOneComponent / new promise => catch / data', this.flag_console_log);
                    ConsoleService(data, this.flag_console_log);

                    notification.addNotification(
                        {
                            title: translation[this.state.language]['general']['notification_error'],
                            message: translation[this.state.language]['settings']['notification_error_update_variables_vat'],
                            type: "success",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 4000,
                                onScreen: true
                            }
                        }
                    );

                    //

                }
            );

        /*
        
        loading the setting record
        
        stop
        
        */

        //

    }

    /* DELIMITER */

    render() {

        ConsoleService('', this.flag_console_log);
        ConsoleService('ModuleSettings_GeneralSettings render', this.flag_console_log);

        ConsoleService('this.state');
        ConsoleService(this.state);

        ConsoleService('this.state.arr_languages', this.flag_console_log);
        ConsoleService(this.state.arr_languages, this.flag_console_log);

        ConsoleService('local_storge', this.flag_console_log);
        ConsoleService(this.state.local_storage, this.flag_console_log);

        return (
            <div id="ModuleSettings_GeneralSettings" >
                {/* module settings - general settings / start */}

                <div className="row" >

                    {/* general settings / start */}
                    <div className="col-12">

                        <div className="m5 p5">

                            <div className="m5 p5">

                                <h2>
                                    {translation[this.state.language]['settings']['general_settings']}:
                                </h2>

                                <div className="container-alert">

                                    <div className="alert alert-success" role="alert">

                                        <h4 className="alert-heading">
                                            {translation[this.state.language]['general']['alert_header_info']}
                                        </h4>

                                        <p>
                                            {translation[this.state.language]['settings']['general_settings_info_content']}
                                        </p>

                                    </div>

                                </div>

                            </div>

                            <div className="m5 p5">

                                <table className="table">

                                    <tbody>

                                        <tr>

                                            <td>
                                                {translation[this.state.language]['settings']['vat_status']}
                                            </td>

                                            <td>
                                                <ToggleButton
                                                    value={this.state.flag_available_option_vat === 'active' ? true : false}
                                                    onToggle={() => { this.flag_available_option_vat_OnToggle(); }} />
                                            </td>

                                            <td></td>

                                        </tr>

                                        <tr>

                                            <td>
                                                {translation[this.state.language]['settings']['default_vat_value']}
                                            </td>

                                            <td>
                                                <input
                                                    type="text"
                                                    name="variables_vat"
                                                    value={this.state.variables_vat}
                                                    onChange={(event) => { this.itemOnChangeHandler(event) }}
                                                    placeholder="VAT" /> %
                                            </td>

                                            <td>

                                                <button variant="secondary" onClick={(event) => { this.update_variables_vat(event) }}>
                                                    {translation[this.state.language]['general']['apply']}
                                                </button>

                                            </td>

                                        </tr>

                                    </tbody>

                                </table>

                            </div>

                        </div>

                    </div>
                    {/* general settings / stop */}

                </div >

                {/* module settings - general settings / stop */}
            </div >
        )

    }

}

export default ModuleSettings_GeneralSettings_GeneralSettings;