import React from 'react';

import ReactSelect from 'react-select';

import ReactSelectCreatable from 'react-select/creatable';

import ConsoleService from '../consoleServiceModules/ConsoleService';

import translation from './../../TranslationComponent';

import ElementItemBill from '../../ElementItemBill';

import DatePicker, { registerLocale } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import fr from "date-fns/locale/fr";

import ro from "date-fns/locale/ro";

registerLocale("fr", fr);

registerLocale("ro", ro);

const execute = (props) => {

    ConsoleService('', props.t.flag_console_log);
    ConsoleService('ModuleInvoice / module_invoice_component_render_jsx / execute', props.t.flag_console_log);

    ConsoleService('props', props.t.flag_console_log);
    ConsoleService(props, props.t.flag_console_log);

    let t = props.t;

    let elements_table_invoice_vat_type = null;

    if (
        Object.keys(t.state.form.attributes.invoice_vat_type).length > 0
    ) {

        elements_table_invoice_vat_type = Object.keys(t.state.form.attributes.invoice_vat_type).map(
            (key, index) => {

                return (
                    <div className="row" key={index}>

                        <div className="col-6">

                        </div>

                        <div className="col-6">

                            <div className="m5 p5">

                                <table className="invoice_summary invoice_vat_type">

                                    <tbody>

                                        <tr>

                                            <td className="w65">
                                                {t.state.form.labels.invoice_subtotal_without_vat_for_vat_x + ' ' + key + ' %'}
                                            </td>

                                            <td className="w35">
                                                {t.state.form.attributes.invoice_currency_symbol + ' ' + t.state.form.attributes.invoice_vat_type[key].total_without_vat}
                                            </td>

                                        </tr>

                                        <tr>

                                            <td className="w65">
                                                {t.state.form.labels.invoice_total_vat_for_vat_x + ' ' + key + ' %'}
                                            </td>

                                            <td className="w35">
                                                {t.state.form.attributes.invoice_currency_symbol + ' ' + t.state.form.attributes.invoice_vat_type[key].total_vat}
                                            </td>

                                        </tr>

                                    </tbody>

                                </table>

                            </div>

                        </div>

                    </div>
                );

            }
        );

    }

    let invoice_itemsElement = null;

    if (
        t.state.form.attributes.invoice_items.length
    ) {

        invoice_itemsElement = (
            <tbody>

                {
                    t.state.form.attributes.invoice_items.map(
                        (item, index) => {

                            ConsoleService('invoice item', t.flag_console_log);
                            ConsoleService(item, t.flag_console_log);

                            ConsoleService('index', t.flag_console_log);
                            ConsoleService(index, t.flag_console_log);

                            return <ElementItemBill

                                item={item}

                                key={index}

                                item_index={index}

                                invoice_items_length={t.state.form.attributes.invoice_items.length}

                                invoice_items_obj_errors={t.state.form.errors.invoice_items_data[index] ? t.state.form.errors.invoice_items_data[index] : { description: false, quantity: false, price: false, vat: false }}

                                invoice_items_obj_touched={t.state.form.touched.invoice_items_data[index] ? t.state.form.touched.invoice_items_data[index] : { description: false, quantity: false, price: false, vat: false }}

                                language={t.state.language}

                                flag_should_the_vat_be_taken_in_consideration={t.state.form.attributes.flag_should_the_vat_be_taken_in_consideration}

                                itemDescriptionChange={(event) => t.itemDescriptionChangeHandler(event, index)}

                                eventItemQuantityChange={(event) => t.itemChangeHandler(event, 'quantity', index)}
                                eventItemQuantityOnBlur={(event) => t.itemOnBlurHandler(event, 'quantity', index)}

                                itemPriceChange={(event) => t.itemChangeHandler(event, 'price', index)}
                                eventItemPriceOnBlur={(event) => t.itemOnBlurHandler(event, 'price', index)}

                                itemVatChange={(event) => t.itemChangeHandler(event, 'vat', index)}
                                eventItemVatOnBlur={(event) => t.itemOnBlurHandler(event, 'vat', index)}

                                itemRemoveClick={() => t.itemRemoveHandler(index)} />

                        }
                    )
                }

                <tr>

                    <td className="action"></td>

                    <td colSpan={t.state.form.attributes.flag_should_the_vat_be_taken_in_consideration ? 4 : 3}>

                        <a href="#addItem" onClick={t.addItemHandler}>
                            [ + ]
                        </a>

                    </td>

                    <td className="action"></td>

                </tr>

            </tbody>
        )

    } else {

        invoice_itemsElement = (
            <tbody>

                <tr>

                    <td className="action"></td>

                    <td colSpan={t.state.form.attributes.flag_should_the_vat_be_taken_in_consideration ? 4 : 3}>

                        <a href="#addItem" onClick={t.addItemHandler}>
                            [ + ]
                        </a>

                    </td>

                    <td className="action"></td>

                </tr>

            </tbody>
        )

    }

    const shouldMarkError = (field) => {

        //ConsoleService('', t.flag_console_log);
        //ConsoleService('shouldMarkError', t.flag_console_log);

        //ConsoleService('field', t.flag_console_log);
        //ConsoleService(field, t.flag_console_log);

        const hasError = t.state.form.errors[field];

        //ConsoleService('hasError', t.flag_console_log);
        //ConsoleService(hasError, t.flag_console_log);

        const shouldShow = t.state.form.touched[field];

        //ConsoleService('shouldShow', t.flag_console_log);
        //ConsoleService(shouldShow, t.flag_console_log);

        return hasError ? shouldShow : false;

        //return true;// force error

        //

    }

    return (

        <div className="body" >

            <div id="invoice_object_store_id">{t.state.form.attributes.invoice_object_store_id}</div>

            <div className="letterhead">

                <div className="row">

                    <div className="col-12">

                        <input type="text"
                            id="invoice_type"
                            name="invoice_type"
                            className={shouldMarkError('invoice_type') ? "field_error" : ""}
                            autoComplete="off"
                            value={t.state.form.attributes.invoice_type}
                            onChange={t.formElementChangedHandler}
                            placeholder={t.state.form.placeholders.invoice_type}
                            onBlur={t.formInputElementOnBlurHandler} />

                    </div>

                </div>

                {/* recipient and provider section / start */}
                <div className="row">

                    {/* left side / start */}
                    <div className="col-6">

                        {/* recipient attributes / start */}
                        <table id="recipient" className="left">

                            <tbody>

                                {/* DELIMITER */}

                                {/* recipient account type / start */}
                                <tr>

                                    <td>

                                        {/**!!DO NOT DELETE!! code may be used in the future */}

                                        {/*
                                        <div className={shouldMarkError('recipient_account_type') ? "field_error" : ""}>

                                            <ReactSelect
                                                id="recipient_account_type"
                                                name="recipient_account_type"
                                                value={t.state.form.select.recipient_account_type}
                                                onChange={t.formReactSelectElementRecipientAccountTypeChangedHandler}
                                                options={t.state.form.select.recipient_account_type_select_options}
                                                placeholder={t.state.form.placeholders.recipient_account_type}
                                                onBlur={(e) => { t.formReactSelectElementOnBlurHandler(e, 'recipient_account_type') }} />

                                        </div>
                                        */}

                                        <div className={shouldMarkError('recipient_account_type') ? "field_error" : ""}>

                                            <div className="m2">

                                                <div>{t.props.invoice ? t.props.invoice.recipient_account_type_label : t.state.form.labels.recipient_account_type_label}:</div>

                                                <div className="recipient_input_radio">

                                                    <input type="radio"
                                                        id="individual"
                                                        name="recipient_account_type"
                                                        value={'individual'}
                                                        checked={t.state.form.attributes.recipient_account_type === 'individual'}
                                                        onChange={t.formRadioInputElementRecipientAccountTypeChangedHandler} />

                                                    <label
                                                        htmlFor="individual">
                                                        {t.props.invoice ? translation[t.props.invoice.invoice_language].general.recipient_account_type_select_option[0].label : translation[t.props.language].general.recipient_account_type_select_option[0].label}
                                                    </label>

                                                    <input type="radio"
                                                        id="company"
                                                        name="recipient_account_type"
                                                        value={'company'}
                                                        checked={t.state.form.attributes.recipient_account_type === 'company'}
                                                        onChange={t.formRadioInputElementRecipientAccountTypeChangedHandler} />

                                                    <label
                                                        htmlFor="company">
                                                        {t.props.invoice ? translation[t.props.invoice.invoice_language].general.recipient_account_type_select_option[1].label : translation[t.props.language].general.recipient_account_type_select_option[1].label}
                                                    </label>

                                                </div>

                                            </div>

                                        </div>

                                    </td>

                                </tr>
                                {/* recipient account type / stop */}

                                {/* DELIMITER */}

                                {/* recipient company name / start */}

                                {/* recipient company name / type "input" / start */}
                                {

                                    t.state.flag_permission_show_form_element_recipient_company_name_input_or_react_select_creatable === 'input' ?

                                        <tr>

                                            <td>

                                                <div id="container_recipient_company_name_input" className="m2">

                                                    <input
                                                        type="text"
                                                        id="recipient_company_name"
                                                        name="recipient_company_name"
                                                        className={shouldMarkError('recipient_company_name') ? "field_error" : ""}
                                                        autoComplete="off"
                                                        value={t.state.form.attributes.recipient_company_name}
                                                        onChange={t.formElementChangedHandler}
                                                        placeholder={t.state.form.placeholders.recipient_company_name}
                                                        onBlur={t.formInputElementOnBlurHandler} />

                                                    <button className="btn btn-info btn-sm" id="edit_recipient_company_name_button" onClick={(event) => { t.editRecipientCompanyHandler(event, t.state.form.attributes.recipient_object_store_id, t.state.form.attributes.recipient_company_name) }}>
                                                        <i className="fa fa-pencil"></i>
                                                    </button>

                                                </div>

                                            </td>

                                        </tr> : null

                                }
                                {/* recipient company name / type "input" / stop */}

                                {/* recipient company name / type "react-select-creatable" / start */}
                                {

                                    t.state.flag_permission_show_form_element_recipient_company_name_input_or_react_select_creatable === 'react-select-creatable' ?

                                        <tr>

                                            <td>

                                                <div id="container_recipient_company_name_react_select_creatable" className="m2">

                                                    <ReactSelectCreatable
                                                        placeholder={t.state.form.placeholders.recipient_company_name}
                                                        value={t.state.form.select.recipient_company_name ? t.state.form.select.recipient_company_name : null}
                                                        onChange={t.formSelectElementRecipientCompanyNameChangeHandler}
                                                        onInputChange={t.formSelectElementRecipientCompanyNameInputChangeHandler}
                                                        options={t.state.form.select.recipient_company_name_select_options}
                                                        className={shouldMarkError('recipient_company_name') ? "field_error" : ""} />

                                                </div>

                                            </td>

                                        </tr> : null

                                }
                                {/* recipient company name / type "react-select-creatable" / stop */}

                                {/* recipient company name / stop */}

                                {/* DELIMITER */}

                                {/* recipient vat nr / start */}
                                <tr>

                                    <td>

                                        <div className="m2">

                                            <input
                                                type="text"
                                                id="recipient_vat_nr"
                                                name="recipient_vat_nr"
                                                className={shouldMarkError('recipient_vat_nr') ? "field_error" : ""}
                                                autoComplete="off"
                                                value={t.state.form.attributes.recipient_vat_nr}
                                                onChange={t.formElementChangedHandler}
                                                placeholder={t.state.form.placeholders.recipient_vat_nr}
                                                onBlur={t.formInputElementOnBlurHandler} />

                                        </div>

                                    </td>

                                </tr>
                                {/* recipient vat nr / stop */}

                                {/* recipient email / start */}
                                <tr>

                                    <td>

                                        <div className="m2">

                                            <input
                                                type="text"
                                                id="recipient_email"
                                                name="recipient_email"
                                                className={shouldMarkError('recipient_email') ? "field_error" : ""}
                                                autoComplete="off"
                                                value={t.state.form.attributes.recipient_email}
                                                onChange={t.formElementChangedHandler}
                                                placeholder={t.state.form.placeholders.recipient_email}
                                                onBlur={t.formInputElementOnBlurHandler} />

                                        </div>

                                    </td>

                                </tr>
                                {/* recipient email / stop */}

                                {/* recipient phone nr / start */}
                                <tr>

                                    <td>

                                        <div className="m2">

                                            <input
                                                type="text"
                                                id="recipient_phone_nr"
                                                name="recipient_phone_nr"
                                                className={shouldMarkError('recipient_phone_nr') ? "field_error" : ""}
                                                autoComplete="off"
                                                value={t.state.form.attributes.recipient_phone_nr}
                                                onChange={t.formElementChangedHandler}
                                                placeholder={t.state.form.placeholders.recipient_phone_nr}
                                                onBlur={t.formInputElementOnBlurHandler} />

                                        </div>

                                    </td>

                                </tr>
                                {/* recipient phone_nr / stop */}

                                {/* recipient addresses country key / start */}
                                <tr>

                                    <td>

                                        <div className="m2">

                                            <ReactSelect
                                                id="recipient_addresses_country_key"
                                                name="recipient_addresses_country_key"
                                                className={shouldMarkError('recipient_addresses_country_key') ? "field_error" : ""}
                                                value={t.state.form.select.recipient_addresses_country_key}
                                                onChange={t.formSelectElementRecipientAddressesCountryKeyChangedHandler}
                                                options={t.state.form.select.recipient_addresses_country_key_select_options}
                                                placeholder={t.state.form.placeholders.recipient_addresses_country_key}
                                                onBlur={(e) => { t.formReactSelectElementOnBlurHandler(e, 'recipient_addresses_country_key') }} />

                                        </div>

                                    </td>

                                </tr>
                                {/* recipient addresses country key / stop */}

                                {/* recipient addresses city / start */}
                                <tr>

                                    <td>

                                        <div className="m2">

                                            <input
                                                type="text"
                                                id="recipient_addresses_city"
                                                name="recipient_addresses_city"
                                                className={shouldMarkError('recipient_addresses_city') ? "field_error" : ""}
                                                autoComplete="off"
                                                value={t.state.form.attributes.recipient_addresses_city}
                                                onChange={t.formElementChangedHandler}
                                                placeholder={t.state.form.placeholders.recipient_addresses_city}
                                                onBlur={t.formInputElementOnBlurHandler} />

                                        </div>

                                    </td>

                                </tr>
                                {/* recipient addresses city / stop */}

                                {/* recipient addresses street address / start */}
                                <tr>

                                    <td>

                                        <div className="m2">

                                            <input
                                                type="text"
                                                id="recipient_addresses_street_address"
                                                name="recipient_addresses_street_address"
                                                className={shouldMarkError('recipient_addresses_street_address') ? "field_error" : ""}
                                                autoComplete="off"
                                                value={t.state.form.attributes.recipient_addresses_street_address}
                                                onChange={t.formElementChangedHandler}
                                                placeholder={t.state.form.placeholders.recipient_addresses_street_address}
                                                onBlur={t.formInputElementOnBlurHandler} />

                                        </div>

                                    </td>

                                </tr>
                                {/* recipient addresses street address / stop */}

                                {/* recipient addresses zip / start */}
                                <tr>

                                    <td>

                                        <div className="m2">

                                            <input
                                                type="text"
                                                id="recipient_addresses_zip"
                                                name="recipient_addresses_zip"
                                                className={shouldMarkError('recipient_addresses_zip') ? "field_error" : ""}
                                                autoComplete="off"
                                                value={t.state.form.attributes.recipient_addresses_zip}
                                                onChange={t.formElementChangedHandler}
                                                placeholder={t.state.form.placeholders.recipient_addresses_zip}
                                                onBlur={t.formInputElementOnBlurHandler} />

                                        </div>

                                    </td>

                                </tr>
                                {/* recipient addresses zip / stop */}

                            </tbody>

                        </table>
                        {/* recipient attributes / stop */}

                    </div>
                    {/* left side / stop */}

                    {/* right side / start */}
                    <div className="col-6">

                        {/* invoice number/serial and date / start */}
                        <table id="invoice_number_date" className="right">

                            <tbody>

                                <tr>

                                    <td>

                                        <div className="m2">

                                            <input
                                                type="text"
                                                id="invoice_number_label"
                                                name="invoice_number_label"
                                                className={shouldMarkError('invoice_number_label') ? "field_error" : ""}
                                                value={t.state.form.labels.invoice_number_label}
                                                onChange={t.formElementLabelChangedHandler}
                                                placeholder={t.state.form.placeholders.invoice_number_label}
                                                onBlur={t.formInputElementOnBlurHandler} />

                                        </div>

                                    </td>

                                    {/* invoice serial / start */}

                                    {/* invoice serial / type "input" / start */}
                                    {

                                        t.state.flag_permission_show_form_element_invoice_serial_input_or_react_select_creatable === 'input' ?

                                            <td>

                                                <div id="container_invoice_serial_input" className="m2">

                                                    <input
                                                        type="text"
                                                        id="invoice_serial"
                                                        name="invoice_serial"
                                                        className={shouldMarkError('invoice_serial') ? "field_error" : ""}
                                                        value={t.state.form.attributes.invoice_serial}
                                                        onChange={t.formElementChangedHandler}
                                                        placeholder={t.state.form.placeholders.invoice_serial}
                                                        onBlur={t.formInputElementOnBlurHandler} />

                                                </div>

                                            </td> : null

                                    }
                                    {/* invoice serial / type "input" / stop */}

                                    {/* invoice serial / type "react-select-creatable" / start */}
                                    {

                                        t.state.flag_permission_show_form_element_invoice_serial_input_or_react_select_creatable === 'react-select-creatable' ?

                                            <td>

                                                <div id="container_invoice_serial_react_select_creatable" className="m2">

                                                    <ReactSelectCreatable
                                                        placeholder={t.state.form.placeholders.invoice_serial}
                                                        value={t.state.form.attributes.invoice_serial ? t.state.form.attributes.invoice_serial : null}
                                                        onChange={t.formSelectElementInvoiceSerialChangeHandler}
                                                        onInputChange={t.formSelectElementInvoiceSerialInputChangeHandler}
                                                        options={t.state.form.select.invoice_serial_select_options}
                                                        className={shouldMarkError('invoice_serial') ? "field_error" : ""} />

                                                </div>

                                            </td> : null

                                    }
                                    {/* invoice serial / type "react-select-creatable" / stop */}

                                    {/* invoice serial / stop */}

                                    <td>
                                        -
                                    </td>

                                    <td>

                                        <div id="container_invoice_number" className="m2">

                                            <input
                                                type="text"
                                                id="invoice_number"
                                                name="invoice_number"
                                                className={shouldMarkError('invoice_number') ? "field_error" : ""}
                                                value={t.state.form.attributes.invoice_number}
                                                onChange={t.formElementChangedHandler}
                                                placeholder={t.state.form.placeholders.invoice_number}
                                                onBlur={t.formInputElementOnBlurHandler} />

                                        </div>

                                    </td>

                                </tr>

                                <tr>

                                    <td>

                                        <div className="m2">

                                            <input
                                                type="text"
                                                id="invoice_date_label"
                                                name="invoice_date_label"
                                                className={shouldMarkError('invoice_date_label') ? "field_error" : ""}
                                                value={t.state.form.labels.invoice_date_label}
                                                onChange={t.formElementLabelChangedHandler}
                                                placeholder={t.state.form.placeholders.invoice_date_label}
                                                onBlur={t.formInputElementOnBlurHandler} />

                                        </div>

                                    </td>

                                    <td colSpan="3">

                                        <div className="m2">

                                            <DatePicker
                                                id="invoice_date"
                                                name="invoice_date"
                                                className={shouldMarkError('invoice_date') ? "field_error" : ""}
                                                selected={t.state.form.attributes.invoice_date}
                                                value={t.state.form.attributes.invoice_date}
                                                dateFormat="d MMMM, yyyy"
                                                locale={t.state.locale ? t.state.locale : t.props.invoice.invoice_language}
                                                onChange={t.formElementDateChangedHandler} />

                                        </div>

                                    </td>

                                </tr>

                            </tbody>

                        </table>
                        {/* invoice number/serial and date / stop */}

                        {/* provider attributes / start */}
                        <table id="provider" className="right">

                            <tbody>

                                {/* provider vat nr / start */}
                                <tr>

                                    <td>

                                        <div className="m2">

                                            <input
                                                type="text"
                                                id="provider_company_name"
                                                name="provider_company_name"
                                                className={shouldMarkError('provider_company_name') ? "field_error" : ""}
                                                autoComplete="off"
                                                value={t.state.form.attributes.provider_company_name}
                                                onChange={t.formElementChangedHandler}
                                                placeholder={t.state.form.placeholders.provider_company_name}
                                                onBlur={t.formInputElementOnBlurHandler} />

                                        </div>

                                    </td>

                                </tr>
                                {/* provider vat nr / stop */}

                                {/* provider vat nr / start */}
                                <tr>

                                    <td>

                                        <div className="m2">

                                            <input
                                                type="text"
                                                id="provider_vat_nr"
                                                name="provider_vat_nr"
                                                className={shouldMarkError('provider_vat_nr') ? "field_error" : ""}
                                                autoComplete="off"
                                                value={t.state.form.attributes.provider_vat_nr}
                                                onChange={t.formElementChangedHandler}
                                                placeholder={t.state.form.placeholders.provider_vat_nr}
                                                onBlur={t.formInputElementOnBlurHandler} />

                                        </div>

                                    </td>

                                </tr>
                                {/* provider vat nr / stop */}

                                {/* provider phone nr / start */}
                                <tr>

                                    <td>

                                        <div className="m2">

                                            <input
                                                type="text"
                                                id="provider_phone_nr"
                                                name="provider_phone_nr"
                                                className={shouldMarkError('provider_phone_nr') ? "field_error" : ""}
                                                autoComplete="off"
                                                value={t.state.form.attributes.provider_phone_nr}
                                                onChange={t.formElementChangedHandler}
                                                placeholder={t.state.form.placeholders.provider_phone_nr}
                                                onBlur={t.formInputElementOnBlurHandler} />

                                        </div>

                                    </td>

                                </tr>
                                {/* provider phone nr / stop */}

                                {/* provider bank account / start */}
                                <tr>

                                    <td>

                                        <div className="m2">

                                            <input
                                                type="text"
                                                id="provider_bank_account"
                                                name="provider_bank_account"
                                                className={shouldMarkError('provider_bank_account') ? "field_error" : ""}
                                                autoComplete="off"
                                                value={t.state.form.attributes.provider_bank_account}
                                                onChange={t.formElementChangedHandler}
                                                placeholder={t.state.form.placeholders.provider_bank_account}
                                                onBlur={t.formInputElementOnBlurHandler} />

                                        </div>

                                    </td>

                                </tr>
                                {/* provider bank account / stop */}

                                {/* provider bank name / start */}
                                <tr>

                                    <td>

                                        <div className="m2">

                                            <input
                                                type="text"
                                                id="provider_bank_name"
                                                name="provider_bank_name"
                                                className={shouldMarkError('provider_bank_name') ? "field_error" : ""}
                                                autoComplete="off"
                                                value={t.state.form.attributes.provider_bank_name}
                                                onChange={t.formElementChangedHandler}
                                                placeholder={t.state.form.placeholders.provider_bank_name}
                                                onBlur={t.formInputElementOnBlurHandler} />

                                        </div>

                                    </td>

                                </tr>
                                {/* provider bank name / stop */}

                                {/* provider address country key / start */}
                                <tr>

                                    <td>

                                        <div className="m2">

                                            <ReactSelect
                                                id="provider_address_country_key"
                                                name="provider_address_country_key"
                                                className={shouldMarkError('provider_address_country_key') ? "field_error" : ""}
                                                value={t.state.form.select.provider_address_country_key}
                                                onChange={t.formReactSelectElementProviderAddressCountryKeyChangedHandler}
                                                options={t.state.form.select.provider_address_country_key_select_options}
                                                placeholder={t.state.form.placeholders.provider_address_country_key}
                                                onBlur={(e) => { t.formReactSelectElementOnBlurHandler(e, 'provider_address_country_key') }} />

                                        </div>

                                    </td>

                                </tr>
                                {/* provider address country key / stop */}

                                {/* provider address county / start */}
                                <tr>

                                    <td>

                                        <div className="m2">

                                            <input
                                                type="text"
                                                id="provider_address_county"
                                                name="provider_address_county"
                                                className={shouldMarkError('provider_address_county') ? "field_error" : ""}
                                                autoComplete="off"
                                                value={t.state.form.attributes.provider_address_county}
                                                onChange={t.formElementChangedHandler}
                                                placeholder={t.state.form.placeholders.provider_address_county}
                                                onBlur={t.formInputElementOnBlurHandler} />

                                        </div>

                                    </td>

                                </tr>
                                {/* provider address county / stop */}

                                {/* provider address city / start */}
                                <tr>

                                    <td>

                                        <div className="m2">

                                            <input
                                                type="text"
                                                id="provider_address_city"
                                                name="provider_address_city"
                                                className={shouldMarkError('provider_address_city') ? "field_error" : ""}
                                                autoComplete="off"
                                                value={t.state.form.attributes.provider_address_city}
                                                onChange={t.formElementChangedHandler}
                                                placeholder={t.state.form.placeholders.provider_address_city}
                                                onBlur={t.formInputElementOnBlurHandler} />

                                        </div>

                                    </td>

                                </tr>
                                {/* provider address city / stop */}

                                {/* provider address street address / start */}
                                <tr>

                                    <td>

                                        <div className="m2">

                                            <input
                                                type="text"
                                                id="provider_address_street_address"
                                                name="provider_address_street_address"
                                                className={shouldMarkError('provider_address_street_address') ? "field_error" : ""}
                                                autoComplete="off"
                                                value={t.state.form.attributes.provider_address_street_address}
                                                onChange={t.formElementChangedHandler}
                                                placeholder={t.state.form.placeholders.provider_address_street_address}
                                                onBlur={t.formInputElementOnBlurHandler} />

                                        </div>

                                    </td>

                                </tr>
                                {/* provider address street_address / stop */}

                                {/* provider address zip / start */}
                                <tr>

                                    <td>

                                        <div className="m2">

                                            <input
                                                type="text"
                                                id="provider_address_zip"
                                                name="provider_address_zip"
                                                className={shouldMarkError('provider_address_zip') ? "field_error" : ""}
                                                autoComplete="off"
                                                value={t.state.form.attributes.provider_address_zip}
                                                onChange={t.formElementChangedHandler}
                                                placeholder={t.state.form.placeholders.provider_address_zip}
                                                onBlur={t.formInputElementOnBlurHandler} />

                                        </div>

                                    </td>

                                </tr>
                                {/* provider address zip / stop */}

                            </tbody>

                        </table>
                        {/* provider attributes / stop */}

                    </div>  {/* right side / start */}
                    {/* right side / stop */}

                </div>
                {/* recipient and provider section / stop */}

            </div>

            <table id="items">

                <thead>

                    <tr>

                        <td className="action"></td>

                        <th className="description">

                            <div className={shouldMarkError('item_description_label') ? "field_error" : ""}>

                                <input
                                    type="text"
                                    id="item_description_label"
                                    name="item_description_label"
                                    value={t.state.form.labels.item_description_label}
                                    onChange={t.formElementLabelChangedHandler}
                                    placeholder={t.state.form.placeholders.item_description_label}
                                    onBlur={t.formInputElementOnBlurHandler} />

                            </div>

                        </th>

                        <th>

                            <div className={shouldMarkError('item_quantity_label') ? "field_error" : ""}>

                                <input
                                    type="text"
                                    id="item_quantity_label"
                                    name="item_quantity_label"
                                    value={t.state.form.labels.item_quantity_label}
                                    onChange={t.formElementLabelChangedHandler}
                                    placeholder={t.state.form.placeholders.item_quantity_label}
                                    onBlur={t.formInputElementOnBlurHandler} />

                            </div>

                        </th>

                        <th>

                            <div className={shouldMarkError('item_price_label') ? "field_error" : ""}>

                                <input
                                    type="text"
                                    id="item_price_label"
                                    name="item_price_label"
                                    value={t.state.form.labels.item_price_label}
                                    onChange={t.formElementLabelChangedHandler}
                                    placeholder={t.state.form.placeholders.item_price_label}
                                    onBlur={t.formInputElementOnBlurHandler} />

                            </div>

                        </th>

                        {/* VAT / start */}
                        {

                            t.state.form.attributes.flag_should_the_vat_be_taken_in_consideration ?

                                <th>

                                    <div className={shouldMarkError('item_vat_label') ? "field_error" : ""}>

                                        <input
                                            type="text"
                                            id="item_vat_label"
                                            name="item_vat_label"
                                            value={t.state.form.labels.item_vat_label}
                                            onChange={t.formElementLabelChangedHandler}
                                            placeholder={t.state.form.placeholders.item_vat_label}
                                            onBlur={t.formInputElementOnBlurHandler} />

                                    </div>

                                </th>

                                : null

                        }
                        {/* VAT / stop */}

                        <td className="action"></td>

                    </tr>

                </thead>

                {invoice_itemsElement}

            </table>

            {/* DELIMITER */}

            <div className="col-12 text-right" >

                <div className="row">

                    <div className="col-6">

                    </div>

                    <div className="col-6">

                        <div className="m5 p5">

                            <table className="invoice_summary">

                                <tbody>

                                    {/* total without VAT / start */}
                                    {

                                        t.state.form.attributes.flag_should_the_vat_be_taken_in_consideration ?

                                            <tr>

                                                <th className="w65">

                                                    <div className={shouldMarkError('invoice_total_without_vat_label') ? "field_error" : ""}>

                                                        <input
                                                            type="text"
                                                            id="invoice_total_without_vat_label"
                                                            name="invoice_total_without_vat_label"
                                                            autoComplete="off"
                                                            value={t.state.form.labels.invoice_total_without_vat_label}
                                                            onChange={t.formElementLabelChangedHandler}
                                                            placeholder={t.state.form.placeholders.invoice_total_without_vat_label}
                                                            onBlur={t.formInputElementOnBlurHandler} />

                                                    </div>

                                                </th>

                                                <td className="w35">
                                                    {t.state.form.attributes.invoice_currency_symbol + ' ' + t.state.form.attributes.invoice_total_without_vat}
                                                </td>

                                            </tr>

                                            : null

                                    }
                                    {/* total without VAT / stop */}

                                    {/* total VAT / start */}
                                    {

                                        t.state.form.attributes.flag_should_the_vat_be_taken_in_consideration ?

                                            <tr>

                                                <th className="w65">

                                                    <div className={shouldMarkError('invoice_total_vat_label') ? "field_error" : ""}>

                                                        <input
                                                            type="text"
                                                            id="invoice_total_vat_label"
                                                            name="invoice_total_vat_label"
                                                            autoComplete="off"
                                                            value={t.state.form.labels.invoice_total_vat_label}
                                                            onChange={t.formElementLabelChangedHandler}
                                                            placeholder={t.state.form.placeholders.invoice_total_vat_label}
                                                            onBlur={t.formInputElementOnBlurHandler} />

                                                    </div>

                                                </th>

                                                <td className="w35">
                                                    {t.state.form.attributes.invoice_currency_symbol + ' ' + t.state.form.attributes.invoice_total_vat}
                                                </td>

                                            </tr>

                                            : null

                                    }
                                    {/* total VAT / stop */}

                                </tbody>

                            </table>

                        </div>

                    </div>

                </div>

            </div >

            {/* DELIMITER */}

            {

                t.state.form.attributes.flag_should_the_vat_be_taken_in_consideration ?

                    Object.keys(t.state.form.attributes.invoice_vat_type).length > 0 ?

                        <div className="col-12 text-right">

                            {elements_table_invoice_vat_type}

                        </div> : null

                    : null

            }

            {/* DELIMITER */}

            <div className="col-12 text-right">

                <div className="row">

                    <div className="col-6">

                    </div>

                    <div className="col-6">

                        <div className="m5 p5">

                            <table className="invoice_summary">

                                <tbody>

                                    <tr>

                                        <th className="w65">

                                            <div className={shouldMarkError('invoice_total_label') ? "field_error" : ""}>

                                                <input
                                                    type="text"
                                                    id="invoice_total_label"
                                                    name="invoice_total_label"
                                                    autoComplete="off"
                                                    value={t.state.form.labels.invoice_total_label}
                                                    onChange={t.formElementLabelChangedHandler}
                                                    placeholder={t.state.form.placeholders.invoice_total_label}
                                                    onBlur={t.formInputElementOnBlurHandler} />

                                            </div>

                                        </th>

                                        <td className="w35">
                                            {t.state.form.attributes.invoice_currency_symbol + ' ' + t.state.form.attributes.invoice_total}
                                        </td>

                                    </tr>

                                </tbody>

                            </table>

                        </div>

                    </div>

                </div>

            </div>

            {/* DELIMITER */}

        </div >
    )

}

export default execute;