/*

language : RO

*/

export default {

    required: 'The field [attribute] is required. RO',

    email: 'Make sure that you enter a valid email address. RO'

}