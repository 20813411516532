/*
 *
 * This is the `IndexedDb ModuleInvoices` component.
 *
 */

import { Component } from 'react';

/*
 *
 * custom components
 *
 * start
 *
 */

import ConsoleService from './components/consoleServiceModules/ConsoleService';

import ConsoleServiceIndexedDbModuleInvoicesReturnStoreCountComponent from "./components/consoleServiceModules/ConsoleServiceIndexedDbModuleInvoicesReturnStoreCountComponent";

import { indexed_db_name, indexed_db_version } from './IndexedDbComponent';

/*
 *
 * custom components
 *
 * stop
 *
 */

// the main class

class IndexedDbModuleInvoicesReturnStoreCountComponent extends Component {

    idb_name = indexed_db_name;

    version = indexed_db_version;

    flag_console_log = ConsoleServiceIndexedDbModuleInvoicesReturnStoreCountComponent;

    /*
     *
     * constructor
     *
     * start
     * 
     */

    constructor(props) {

        super(props);

        ConsoleService('', this.flag_console_log);
        ConsoleService('IndexedDbModuleInvoicesReturnStoreCountComponent / constructor', this.flag_console_log);

        ConsoleService('IndexedDbModuleInvoicesReturnStoreCountComponent / constructor / props', this.flag_console_log);
        ConsoleService(props, this.flag_console_log);

        ConsoleService('IndexedDbModuleInvoicesComponent / constructor / idb name', this.flag_console_log);
        ConsoleService(this.idb_name, this.flag_console_log);

        ConsoleService('IndexedDbModuleInvoicesReturnStoreCountComponent / constructor / version', this.flag_console_log);
        ConsoleService(this.version, this.flag_console_log);

    }

    /*
     *
     * constructor
     *
     * stop
     * 
     */

    /*
     *
     * execute
     *
     * start
     * 
     */

    execute = () => {

        ConsoleService('', this.flag_console_log);
        ConsoleService('IndexedDbModuleInvoicesReturnStoreCountComponent / execute()', this.flag_console_log);

        let t = this;

        return new Promise(
            (resolve, reject) => {

                ConsoleService('IndexedDbModuleInvoicesReturnStoreCountComponent / execute() / new promise', this.flag_console_log);

                let indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;

                /*
                 *
                 * request
                 *
                 * start
                 * 
                 */

                let request = indexedDB.open(t.idb_name, t.version);

                /*
                 * request / on success / callback
                 *
                 * start
                 * 
                 */

                request.onsuccess = function (event) {

                    ConsoleService('IndexedDbModuleInvoicesReturnStoreCountComponent / execute() / request / on success', t.flag_console_log);

                    ConsoleService('IndexedDbModuleInvoicesReturnStoreCountComponent / execute() / request / on success / event', t.flag_console_log);
                    ConsoleService(event, t.flag_console_log);

                    let db = event.target.result;

                    /*
                     *
                     * transaction
                     *
                     * start
                     * 
                     */

                    /*
                     *
                     * transaction
                     * 
                     * open a read/write db transaction, ready for adding the data
                     * 
                     * https://developer.mozilla.org/en-US/docs/Web/API/IDBDatabase/transaction
                     * 
                     */

                    let transaction = db.transaction(['invoice_object_store'], 'readonly');

                    ConsoleService('IndexedDbModuleInvoicesReturnStoreCountComponent / execute() / request / on success / transaction', t.flag_console_log);
                    ConsoleService(transaction, t.flag_console_log);

                    /*
                     * report on the success of opening the transaction
                     */

                    transaction.oncomplete = function (event) {

                        /*
                        *
                        * ok
                        * 
                        */

                        ConsoleService('IndexedDbModuleInvoicesReturnStoreCountComponent / execute() / request / on success / transaction / on complete', t.flag_console_log);

                        ConsoleService('IndexedDbModuleInvoicesReturnStoreCountComponent / execute() / request / on success / transaction / on complete / event', t.flag_console_log);
                        ConsoleService(event, t.flag_console_log);

                    }

                    transaction.onerror = function (event) {

                        /*
                        *
                        * error
                        * 
                        */

                        ConsoleService('IndexedDbModuleInvoicesReturnStoreCountComponent / execute() / request / on success / transaction / on error', t.flag_console_log);

                        ConsoleService('IndexedDbModuleInvoicesReturnStoreCountComponent / execute() / request / on success / transaction / on error / event', t.flag_console_log);
                        ConsoleService(event, t.flag_console_log);

                        reject({ permission: false });

                    }

                    /*
                     *
                     * transaction
                     *
                     * stop
                     * 
                     */

                    /*
                     *
                     * "bill" object store
                     *
                     * start
                     * 
                     */

                    let store = transaction.objectStore('invoice_object_store');

                    ConsoleService('IndexedDbModuleInvoicesReturnStoreCountComponent / execute() / request / on success / store', t.flag_console_log);
                    ConsoleService(store, t.flag_console_log);

                    ConsoleService('store.count', t.flag_console_log);
                    ConsoleService(store.count(), t.flag_console_log);

                    var countRequest = store.count();
                    countRequest.onsuccess = function () {

                        ConsoleService(countRequest.result, t.flag_console_log);

                        if (countRequest.result < 5) {

                            ConsoleService('permission granted', t.flag_console_log);
                            resolve({ permission: true });

                        } else {

                            ConsoleService('permission denied', t.flag_console_log);
                            resolve({ permission: false });

                        }

                    }

                    /*
                     *
                     * "bill" object store
                     *                 
                     * stop
                     * 
                     */

                }

                /*
                 *
                 * request / on success / callback
                 *              
                 * stop
                 * 
                 */

                /*
                 *
                 * request / on error / callback
                 *
                 * start
                 * 
                 */

                request.onerror = function (event) {

                    /*
                    *
                    * error
                    * 
                    */

                    ConsoleService('IndexedDbModuleInvoicesReturnStoreCountComponent / execute() / request / on error', t.flag_console_log);

                    reject({ permission: false });

                }

                /*
                 * request / on error / callback
                 *
                 * stop
                 * 
                 */

                /*
                 * request
                 *
                 * stop
                 * 
                 */

            }
        ).then(
            (data) => {

                /*
                *
                * ok
                * 
                */

                ConsoleService('IndexedDbModuleInvoicesReturnStoreCountComponent execute() promise then', t.flag_console_log);

                ConsoleService('data', t.flag_console_log);
                ConsoleService(data, t.flag_console_log);

                return data;

            }

        ).catch(
            (data) => {

                /*
                *
                * error
                * 
                */

                ConsoleService('IndexedDbModuleInvoicesReturnStoreCountComponent execute() promise catch', t.flag_console_log);

                ConsoleService('data', t.flag_console_log);
                ConsoleService(data, t.flag_console_log);

                return data;

            }
        );

        // !!! CAUTION !!! unreacheble code, because of return !!!

    }

    /*
     *
     * execute
     *
     * stop
     * 
     */

}

export default IndexedDbModuleInvoicesReturnStoreCountComponent;