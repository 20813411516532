import React from 'react';

import ConsoleService from '../consoleServiceModules/ConsoleService';

import translation from '../../TranslationComponent';

import ElementItemBillReadOnly from './ElementItemBillReadOnly';

const execute = (props) => {

    ConsoleService('', props.t.flag_console_log);
    ConsoleService('ModuleInvoiceReadOnly / module_invoice_component_render_jsx / execute', props.t.flag_console_log);

    ConsoleService('props', props.t.flag_console_log);
    ConsoleService(props, props.t.flag_console_log);

    let t = props.t;

    let elements_table_invoice_vat_type = null;

    let d = new Date(t.props.invoice.invoice_date);

    let invoice_date_format = d.getDate() + ' ' + translation[t.props.invoice.invoice_language].general.months[d.getMonth()] + ', ' + d.getFullYear();

    if (
        Object.keys(t.state.form.attributes.invoice_vat_type).length > 0
    ) {

        elements_table_invoice_vat_type = Object.keys(t.state.form.attributes.invoice_vat_type).map(
            (key, index) => {

                return (
                    <div className="row" key={index}>

                        <div className="col-6">

                        </div>

                        <div className="col-6">

                            <div className="m5 p5">

                                <table className="invoice_summary invoice_vat_type read_only_table">

                                    <tbody>

                                        <tr>

                                            <td className="w65">
                                                {translation[t.props.invoice.invoice_language].general.invoice.invoice_subtotal_without_vat_for_vat_x + ' ' + key + ' %'}
                                            </td>

                                            <td className="w35">
                                                {t.state.form.attributes.invoice_currency_symbol + ' ' + t.state.form.attributes.invoice_vat_type[key].total_without_vat}
                                            </td>

                                        </tr>

                                        <tr>

                                            <td className="w65">
                                                {translation[t.props.invoice.invoice_language].general.invoice.invoice_total_vat_for_vat_x + ' ' + key + ' %'}
                                            </td>

                                            <td className="w35">
                                                {t.state.form.attributes.invoice_currency_symbol + ' ' + t.state.form.attributes.invoice_vat_type[key].total_vat}
                                            </td>

                                        </tr>

                                    </tbody>

                                </table>

                            </div>

                        </div>

                    </div>
                );

            }
        );

    }

    let invoice_itemsElement = null;

    if (
        t.state.form.attributes.invoice_items.length
    ) {

        invoice_itemsElement = (
            <tbody>

                {
                    t.state.form.attributes.invoice_items.map((item, index) => {

                        ConsoleService('invoice item', t.flag_console_log);
                        ConsoleService(item, t.flag_console_log);

                        ConsoleService('index', t.flag_console_log);
                        ConsoleService(index, t.flag_console_log);

                        return <ElementItemBillReadOnly

                            item={item}

                            key={index}

                            item_index={index} />

                    })
                }


            </tbody>
        )

    } else {

        invoice_itemsElement = (
            <tbody>

                <tr>

                    <td className="action"></td>

                    <td colSpan="4"></td>

                    <td className="action"></td>

                </tr>

            </tbody>
        )

    }

    return (

        <div className="body" >

            <div id="invoice_object_store_id">{t.state.form.attributes.invoice_object_store_id}</div>

            <div className="letterhead">

                <div className="row">

                    <div className="col-12">

                        <input type="text"
                            readOnly
                            id="invoice_type_read_only"
                            name="invoice_type"
                            value={t.state.form.attributes.invoice_type}
                            placeholder={t.state.form.placeholders.invoice_type} />

                    </div>

                </div>

                <div className="row">

                    <div className="col-6">

                        {/* recipient attributes / start */}
                        <table id="recipient_read_only" className="left">

                            <tbody>

                                {/* DELIMITER */}

                                {/* recipient account type / start */}
                                <tr>
                                    <td>
                                        {t.state.form.attributes.recipient_account_type_name}
                                    </td>
                                </tr>
                                {/* recipient account type / stop */}

                                {/* DELIMITER */}

                                {/* recipient company name / start */}
                                <tr>
                                    <td>
                                        {t.state.form.attributes.recipient_company_name}
                                    </td>
                                </tr>
                                {/* recipient company name / stop */}

                                {/* DELIMITER */}

                                {/* recipient vat nr / start */}
                                <tr>
                                    <td>
                                        {t.state.form.attributes.recipient_vat_nr}
                                    </td>
                                </tr>
                                {/* recipient vat nr / stop */}

                                {/* recipient email / start */}
                                <tr>
                                    <td>
                                        {t.state.form.attributes.recipient_email}
                                    </td>
                                </tr>
                                {/* recipient email / stop */}

                                {/* recipient phone nr / start */}
                                <tr>
                                    <td>
                                        {t.state.form.attributes.recipient_phone_nr}
                                    </td>
                                </tr>
                                {/* recipient phone_nr / stop */}

                                {/* recipient addresses country key / start */}
                                <tr>
                                    <td>
                                        {t.state.form.attributes.recipient_addresses_country_key}
                                    </td>
                                </tr>
                                {/* recipient addresses country key / stop */}

                                {/* recipient addresses city / start */}
                                <tr>
                                    <td>
                                        {t.state.form.attributes.recipient_addresses_city}
                                    </td>
                                </tr>
                                {/* recipient addresses city / stop */}

                                {/* recipient addresses street address / start */}
                                <tr>
                                    <td>
                                        {t.state.form.attributes.recipient_addresses_street_address}
                                    </td>
                                </tr>
                                {/* recipient addresses street address / stop */}

                                {/* recipient addresses zip / start */}
                                <tr>
                                    <td>
                                        {t.state.form.attributes.recipient_addresses_zip}
                                    </td>
                                </tr>
                                {/* recipient addresses zip / stop */}

                            </tbody>

                        </table>
                        {/* recipient attributes / stop */}

                    </div>

                    <div className="col-6">

                        {/* invoice # and date / start */}
                        <table id="invoice_number_date_read_only" className="right">

                            <tbody>

                                <tr>

                                    <td>
                                        <b>
                                            {t.state.form.labels.invoice_number_label}
                                        </b>
                                    </td>

                                    <td>
                                        {t.state.form.attributes.invoice_serial + '-' + t.state.form.attributes.invoice_number}
                                    </td>

                                </tr>

                                <tr>
                                    <td>
                                        <b>
                                            {t.state.form.labels.invoice_date_label}
                                        </b>
                                    </td>
                                    <td>
                                        {invoice_date_format}
                                    </td>
                                </tr>

                            </tbody>

                        </table>
                        {/* invoice # and date / stop */}

                        {/* provider attributes / start */}
                        <table id="provider_read_only" className="right">

                            <tbody>

                                {/* provider vat nr / start */}
                                <tr>
                                    <td>
                                        {t.state.form.attributes.provider_company_name}
                                    </td>
                                </tr>
                                {/* provider vat nr / stop */}

                                {/* provider vat nr / start */}
                                <tr>
                                    <td>
                                        {t.state.form.attributes.provider_vat_nr}
                                    </td>
                                </tr>
                                {/* provider vat nr / stop */}

                                {/* provider phone nr / start */}
                                <tr>
                                    <td>
                                        {t.state.form.attributes.provider_phone_nr}
                                    </td>
                                </tr>
                                {/* provider phone nr / stop */}

                                {/* provider bank account / start */}
                                <tr>
                                    <td>
                                        {t.state.form.attributes.provider_bank_account}
                                    </td>
                                </tr>
                                {/* provider bank account / stop */}

                                {/* provider bank name / start */}
                                <tr>
                                    <td>
                                        {t.state.form.attributes.provider_bank_name}
                                    </td>
                                </tr>
                                {/* provider bank name / stop */}

                                {/* provider address country key / start */}
                                <tr>
                                    <td>
                                        {t.state.form.attributes.provider_address_country_name}
                                    </td>
                                </tr>
                                {/* provider address country key / stop */}

                                {/* provider address county / start */}
                                <tr>
                                    <td>
                                        {t.state.form.attributes.provider_address_county}
                                    </td>
                                </tr>
                                {/* provider address county / stop */}

                                {/* provider address city / start */}
                                <tr>
                                    <td>
                                        {t.state.form.attributes.provider_address_city}
                                    </td>
                                </tr>
                                {/* provider address city / stop */}

                                {/* provider address street address / start */}
                                <tr>
                                    <td>
                                        {t.state.form.attributes.provider_address_street_address}
                                    </td>
                                </tr>
                                {/* provider address street_address / stop */}

                                {/* provider address zip / start */}
                                <tr>
                                    <td>
                                        {t.state.form.attributes.provider_address_zip}
                                    </td>
                                </tr>
                                {/* provider address zip / stop */}

                            </tbody>

                        </table>
                        {/* provider attributes / stop */}

                    </div>

                </div>

            </div>

            <div id="items_read_only">

                <table id="items">

                    <thead>

                        <tr>

                            <td className="action"></td>

                            <th className="description">
                                {t.state.form.labels.item_description_label}
                            </th>

                            <th>
                                {t.state.form.labels.item_quantity_label}
                            </th>

                            <th>
                                {t.state.form.labels.item_price_label}
                            </th>

                            <th>
                                {t.state.form.labels.item_vat_label}
                            </th>

                            <td className="action"></td>

                        </tr>

                    </thead>

                    {invoice_itemsElement}

                </table>

            </div>

            {/* DELIMITER */}

            <div className="col-12 text-right" >

                <div className="row">

                    <div className="col-6">

                    </div>

                    <div className="col-6">

                        <div className="m5 p5">

                            <table className="invoice_summary read_only_table">

                                <tbody>

                                    <tr>

                                        <th className="w65">
                                            <input
                                                readOnly
                                                type="text"
                                                id="invoice_total_without_vat_label"
                                                name="invoice_total_without_vat_label"
                                                value={t.state.form.labels.invoice_total_without_vat_label}
                                                placeholder={t.state.form.placeholders.invoice_total_without_vat_label} />
                                        </th>

                                        <td className="w35">
                                            {t.state.form.attributes.invoice_currency_symbol + ' ' + t.state.form.attributes.invoice_total_without_vat}
                                        </td>

                                    </tr>

                                    <tr>

                                        <th className="w65">
                                            <input
                                                readOnly
                                                type="text"
                                                id="invoice_total_vat_label"
                                                name="invoice_total_vat_label"
                                                value={t.state.form.labels.invoice_total_vat_label}
                                                placeholder={t.state.form.placeholders.invoice_total_vat_label} />
                                        </th>

                                        <td className="w35">
                                            {t.state.form.attributes.invoice_currency_symbol + ' ' + t.state.form.attributes.invoice_total_vat}
                                        </td>

                                    </tr>

                                </tbody>

                            </table>

                        </div>

                    </div>

                </div>

            </div >

            {/* DELIMITER */}

            {

                Object.keys(t.state.form.attributes.invoice_vat_type).length > 0 ?

                    <div className="col-12 text-right">

                        {elements_table_invoice_vat_type}

                    </div> : null

            }

            {/* DELIMITER */}

            <div className="col-12 text-right">

                <div className="row">

                    <div className="col-6">

                    </div>

                    <div className="col-6">

                        <div className="m5 p5">

                            <table className="invoice_summary read_only_table">

                                <tbody>

                                    <tr>

                                        <th className="w65">
                                            <input
                                                readOnly
                                                type="text"
                                                id="invoice_total_label"
                                                name="invoice_total_label"
                                                value={t.state.form.labels.invoice_total_label}
                                                placeholder={t.state.form.placeholders.invoice_total_label} />
                                        </th>

                                        <td className="w35">
                                            {t.state.form.attributes.invoice_currency_symbol + ' ' + t.state.form.attributes.invoice_total}
                                        </td>

                                    </tr>

                                </tbody>

                            </table>

                        </div>

                    </div>

                </div>

            </div>

            {/* DELIMITER */}

        </div >
    )

}

export default execute;