import ConsoleService from '../consoleServiceModules/ConsoleService';

const execute = (t, invoice_items) => {

    ConsoleService('', t.flag_console_log);
    ConsoleService('ModuleInvoice / component_compute_vat_type / execute', t.flag_console_log);

    ConsoleService('invoice items', t.flag_console_log);
    ConsoleService(invoice_items, t.flag_console_log);

    let arr_vat_type = [];

    let i = 0;

    for (i in invoice_items) {

        ConsoleService('invoice items index', t.flag_console_log);
        ConsoleService(i, t.flag_console_log);

        ConsoleService('invoice_items[i]', t.flag_console_log);
        ConsoleService(invoice_items[i], t.flag_console_log);

        invoice_items[i].vat = parseFloat(invoice_items[i].vat).toFixed(2);

        if (
            !arr_vat_type[invoice_items[i].vat]
        ) {

            arr_vat_type[invoice_items[i].vat] = {};

            arr_vat_type[invoice_items[i].vat] = {
                total_without_vat: 0.0,
                total_vat: 0.0
            };

            ConsoleService('arr_vat_type[invoice_items[i].vat]', t.flag_console_log);
            ConsoleService(arr_vat_type[invoice_items[i].vat], t.flag_console_log);

        }

        /*
        *
        * item quantity
        * 
        * start
        *
        */

        ConsoleService('invoice_items[i].quantity', t.flag_console_log);
        ConsoleService(invoice_items[i].quantity, t.flag_console_log);

        invoice_items[i].quantity = parseFloat(invoice_items[i].quantity);

        ConsoleService('parseFloat(invoice_items[i].quantity)', t.flag_console_log);
        ConsoleService(invoice_items[i].quantity, t.flag_console_log);

        /*
        *
        * item quantity
        * 
        * stop
        *
        */

        /*
        *
        * item price
        * 
        * start
        *
        */

        ConsoleService('invoice_items[i].price', t.flag_console_log);
        ConsoleService(invoice_items[i].price, t.flag_console_log);

        invoice_items[i].price = parseFloat(invoice_items[i].price);

        ConsoleService('parseFloat(invoice_items[i].price)', t.flag_console_log);
        ConsoleService(invoice_items[i].price, t.flag_console_log);

        /*
        *
        * item price
        * 
        * start
        *
        */

        // vat of a certain type type => total without vat
        arr_vat_type[invoice_items[i].vat].total_without_vat += (invoice_items[i].quantity * invoice_items[i].price);// (float) value * (float) value

        ConsoleService('arr_vat_type[invoice_items[i].vat].total_without_vat', t.flag_console_log);
        ConsoleService(arr_vat_type[invoice_items[i].vat].total_without_vat, t.flag_console_log);

        arr_vat_type[invoice_items[i].vat].total_without_vat = parseFloat(parseFloat(arr_vat_type[invoice_items[i].vat].total_without_vat).toFixed(2));

        ConsoleService('arr_vat_type[invoice_items[i].vat].total_without_vat', t.flag_console_log);
        ConsoleService(arr_vat_type[invoice_items[i].vat].total_without_vat, t.flag_console_log);

        // vat of a certain type => total vat
        arr_vat_type[invoice_items[i].vat].total_vat += parseFloat(((invoice_items[i].quantity * invoice_items[i].price) * parseFloat(invoice_items[i].vat)) / 100);

        ConsoleService('arr_vat_type[invoice_items[i].vat].total_vat', t.flag_console_log);
        ConsoleService(arr_vat_type[invoice_items[i].vat].total_vat, t.flag_console_log);

        arr_vat_type[invoice_items[i].vat].total_vat = parseFloat(parseFloat(arr_vat_type[invoice_items[i].vat].total_vat).toFixed(2));

        ConsoleService('arr_vat_type[invoice_items[i].vat].total_vat', t.flag_console_log);
        ConsoleService(arr_vat_type[invoice_items[i].vat].total_vat, t.flag_console_log);

    }

    ConsoleService('arr_vat_type', t.flag_console_log);
    ConsoleService(arr_vat_type, t.flag_console_log);

    return arr_vat_type;

}

export default execute;