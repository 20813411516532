const form_attributes = {

    invoice_type: '',// ok 20.10.2019

    /* DELIMITER */

    /*
    *
    * recipient attributes
    * 
    * start
    * 
    */

    recipient_account_type: '',// ok 20.10.2019

    recipient_company_name: '',// ok 20.10.2019

    recipient_vat_nr: '',// ok 20.10.2019
    recipient_email: '',// ok 20.10.2019
    recipient_phone_nr: '',// ok 20.10.2019

    recipient_addresses_country_key: '',// ok 20.10.2019
    recipient_addresses_city: '',// ok 20.10.2019
    recipient_addresses_street_address: '',// ok 20.10.2019
    recipient_addresses_zip: '',// ok 20.10.2019

    /*
    *
    * recipient attributes
    * 
    * stop
    * 
    */

    /* DELIMITER */

    invoice_serial: '',// ok 06.01.2020
    invoice_number: '',// ok 20.10.2019

    invoice_date: '',// ok 20.10.2019

    /* DELIMITER */

    /*
    *
    * provider attributes
    * 
    * start
    * 
    */

    provider_company_name: '',// ok 20.11.2019
    provider_vat_nr: '',// ok 20.11.2019
    provider_phone_nr: '',// ok 20.11.2019
    provider_bank_account: '',// ok 20.11.2019
    provider_bank_name: '',// ok 20.11.2019

    provider_address_country_key: '',// ok 20.11.2019
    provider_address_county: '',// ok 20.11.2019
    provider_address_city: '',// ok 20.11.2019
    provider_address_street_address: '',// ok 20.11.2019
    provider_address_zip: '',// ok 20.11.2019

    /*
    *
    * provider attributes
    * 
    * stop
    * 
    */

};

export default form_attributes;