const execute = (resource, index) => {

    //console.log('resource');
    //console.log(resource);

    //console.log('index');
    //console.log(index);

    let result = null;

    Object.keys(resource).map(
        (key) => {

            //console.log('key');
            //console.log(key);

            if (
                resource[key].value === index
            ) {

                //console.log(resource[key].label);

                result = { value: index, label: resource[key].label };

            }

            return true;

        }
    );

    return result;

}

export default execute;