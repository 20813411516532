export default {

    general: {

        recipient_account_type_select_option: [
            {
                value: 'individual', label: 'Individual'
            },
            {
                value: 'company', label: 'Société'
            }
        ],

        actions: 'actions',
        previous: 'précédente',
        next: 'suivante',

        invoice: {

            invoice_number: 'Facture #',

            invoice_date: 'Date',

            invoice_company_name: 'Votre entreprise',

            invoice_company_info: 'Information de société',

            invoice_total_without_vat: 'TOTAL sans VAT',

            invoice_total: 'TOTAL',

            invoice_subtotal_without_vat_for_vat_x: 'Sous-total TVA',
            invoice_total_vat_for_vat_x: 'TVA pour',

        },

        item_description: "description de l'article",

        months: [

            'janvier',
            'février',
            'mars',
            'avril',
            'mai',
            'juin',
            'juillet',
            'août',
            'septembre',
            'octobre',
            'novembre',
            'décembre'

        ],

        my_details: 'mes Details',
        button_save_label: 'Sauvegarder',

        form_company_info_label: 'Informations sur le destinataire',

        invalid_form: 'Forme non valide. Veuillez remplir les données.',

        apply: 'Appliquer',

        no_data_available: 'Pas de données disponibles.',

        alert_header_info: 'Info',

        notification_info: 'Info',
        notification_success: 'Succès',
        notification_error: 'Erreur',

        login: 'Se connecter',
        close: 'Proche',

    },

    ModuleBill: {

        button_providers_label: 'mes Details',
        button_invoices_label: 'Factures',
        button_recipients_label: 'Destinataires',

        notification_choose_akti: 'Choisissez un service web de facturation professionnel. Essayez akti',
        button_new_invoice_label: 'Nouvelle facture',

        button_login_label: 'Connectez-vous',
        button_login_google_label: 'Se connecter avec google',
        button_login_facebook_label: 'Se connecter avec facebook',
        button_logout_label: 'Se déconnecter',

    },

    ModuleRecipients: {

        //add form
        recipient_company_name_label: 'Nom de la société',
        recipient_vat_nr_label: 'Numéro de TVA',
        //form_recipient_reference_nr_label: 'Numéro de référence',
        recipient_account_type_label: 'Type de compte du destinataire',
        //form_recipient_language_key_label: 'Clé de langue',
        recipient_email_label: 'Email',
        recipient_phone_nr_label: 'Numéro de téléphone',
        //form_recipient_fax_nr_label: 'Numéro de fax',
        //form_recipient_is_supplier_label: 'Est le fournisseur',
        //form_recipient_is_customer_label: 'Est client',

        recipient_addresses_label: 'Adresse',
        //form_recipient_company_info_label: 'Company info fr',
        //form_recipient_info_label: 'Informations sur le destinataire',
        //form_recipient_addresses_is_billing_label: 'est la facturation',
        //form_recipient_addresses_is_primary_label: 'est primaire',
        //form_recipient_addresses_is_delivery_label: 'est la livraison',
        //form_recipient_addresses_is_site_label: 'est le site',
        recipient_addresses_country_key_label: 'Pays',
        //form_recipient_addresses_state_label: 'Etat',
        recipient_addresses_street_address_label: 'Adresse de rue',
        recipient_addresses_city_label: 'Ville',
        recipient_addresses_zip_label: 'Zip',

        recipients_label: 'Destinataires',
        no_recipients_label: 'Aucun destinataire',
        button_add_recipient_label: 'ajouter un destinataire',
        button_show_recipients_label: 'montrer les destinataires',
        button_save_recipient_label: 'Sauvegarder',
        select_option_company_use: 'Société',
        select_option_individual_use: 'Individual',
        select_option_yes: 'Oui',
        select_option_no: 'Non',

        //notification messages
        notification_vat_nr: 'Veuillez remplir le numéro de TVA ...',
        notification_email: 'Veuillez remplir email ...',
        notification_phone_nr: 'Veuillez remplir le numéro de téléphone ...',
        notification_addresses_city: 'Veuillez renseigner la ville ...',
        notification_addresses_street_address: "Veuillez renseigner l'adresse postale ...",
        notification_addresses_zip: 'Veuillez remplir le zip ...',
        notification_reference_nr: 'Veuillez remplir le numéro de référence ...',
        notification_recipient_saved: 'Destinataire enregistré',
        notification_company_name: 'Veuillez remplir le nom de la société ...',
        notification_account_type: 'Veuillez sélectionner un type de compte',
        notification_addresses_country_key: 'Veuillez sélectionner un pays',

        label_company_name: 'Nom de la société',
        label_vat_nr: 'Numéro de TVA',
        label_account_type: 'Type de destinataire',
        label_email: 'Email',
        label_phone_nr: 'Numéro de téléphone',
        label_addresses_country_key: 'Pays',
        label_addresses_street_address: 'Adresse de rue',
        label_addresses_city: 'Ville',
        label_addresses_zip: 'Zip',

        recipient_account_type_select_option: [
            {
                value: 'individual', label: 'Individual'
            },
            {
                value: 'company', label: 'Société'
            }
        ],

        placeholder_recipient_account_type: 'sélectionnez le type de compte',
        placeholder_recipient_addresses_country_key: 'sélectionnez le pays',
        placeholder_recipient_company_name: "nom de l'entreprise",
        placeholder_recipient_vat_nr: 'numéro de TVA',
        placeholder_recipient_email: 'email',
        placeholder_recipient_phone_nr: 'numéro de téléphone',
        placeholder_recipient_addresses_city: 'ville',
        placeholder_recipient_addresses_street_address: 'adresse de rue',
        placeholder_recipient_addresses_zip: 'zip',

    },

    ModuleInvoices: {
        invoices_label: 'Factures',
        no_invoices_label: 'Aucune facture',
        recipient_company_name: 'Entreprise destinataire',
    },

    ModuleInvoice: {

        invoice_type: 'Type de facture',

        invoice_number_label: 'Facture #',
        invoice_date_label: 'Date',

        item_description_label: 'Article & Description',
        item_quantity_label: 'Quantité',
        item_price_label: 'Prix',
        item_vat_label: 'TVA',

        invoice_subtotal_label: 'Sous-total',
        invoice_vat_label: 'TVA',

        invoice_total_without_vat_label: 'Total sans TVA',
        invoice_total_vat_label: 'Total TVA',
        invoice_total_label: 'Total',

        invoice_first_item_description: 'premier article',

        invoice_item_description: 'article non défini',

        button_invoices_label: 'Factures',
        button_recipients_label: 'Destinataires',

        button_save_invoice_label: 'Enregistrer la facture',
        //button_new_bill_label: 'Nouvelle facture',
        button_new_invoice_form_label: 'Nouvelle facture',
        button_try_external_tool_label: 'synchroniser vos factures maintenant',
        button_download_pdf_label: 'Télécharger la facture au format PDF',

        recipient_account_type_select_option: [
            {
                value: 'individual', label: 'Individual'
            },
            {
                value: 'company', label: 'Société'
            }
        ],

        recipient_account_type_label: 'Type de compte du destinataire',

        invoice_subtotal_without_vat_for_vat_x: 'Sous-total TVA',
        invoice_total_vat_for_vat_x: 'TVA pour',

        /*
        *
        * provider placeholders
        *
        * start
        *
        */

        placeholder_provider_company_name: "nom de l'entreprise du fournisseur",
        placeholder_provider_vat_nr: 'numéro de TVA du fournisseur',
        placeholder_provider_phone_nr: 'numéro de téléphone du fournisseur',
        placeholder_provider_bank_account: 'compte bancaire du fournisseur',
        placeholder_provider_bank_name: 'nom de la banque du fournisseur',

        placeholder_provider_address_country_key: 'pays adresse du fournisseur',
        placeholder_provider_address_county: "comté de l'adresse du fournisseur",
        placeholder_provider_address_city: 'adresse du fournisseur ville',
        placeholder_provider_address_street_address: 'rue adresse du fournisseur',
        placeholder_provider_address_zip: 'adresse zip du fournisseur',

        /*
        *
        * provider placeholders
        *
        * stop
        *
        */

        /*
        *
        * notification messages
        *
        * start
        * 
        */

        notification_invoice_data_loaded: 'Données de facturation chargées',
        notification_choose_recipient: 'Veuillez choisir un destinataire',

        /* DELIMITER */

        /*
        *
        * invoice notification messages
        *
        * start
        * 
        */

        notification_invoice_type: "S'il vous plaît remplir le type de facture",

        notification_invoice_company_name: "S'il vous plaît remplir le nom de l'entreprise.",
        notification_invoice_company_info: "S'il vous plaît remplir les informations de l'entreprise.",

        notification_recipient_account_type: "Veuillez renseigner le type de compte du destinataire.",
        notification_recipient_company_name: "Veuillez indiquer le nom de l'entreprise destinataire.",
        notification_recipient_vat_nr: 'Veuillez renseigner le numéro de TVA du destinataire..',
        notification_recipient_email: "Veuillez renseigner l'adresse email du destinataire.",
        notification_recipient_phone_nr: "S'il vous plaît remplir le numéro de téléphone du destinataire.",
        notification_recipient_addresses_country_key: "S'il vous plaît choisir un pays.",
        notification_recipient_addresses_city: "S'il vous plaît remplir la ville.",
        notification_recipient_addresses_street_address: "S'il vous plaît remplir l'adresse.",
        notification_recipient_addresses_zip: "S'il vous plaît remplir le zip.",

        notification_invoice_number: "S'il vous plaît remplir le numéro de facture.",
        notification_invoice_date: 'Veuillez renseigner la date de facture.',

        notification_invoice_vat: "S'il vous plaît remplir la facture TVA.",

        /*
        *
        * invoice notification messages
        *
        * stop
        * 
        */

        /* DELIMITER */

        notification_bill_saved: 'Facture sauvegardé',
        notification_recipient_data_loaded: 'Données du destinataire chargées',

        /*
        *
        * notification messages
        *
        * stop
        * 
        */

        /*
        *
        * placeholders
        * 
        * start
        * 
        */

        /*
        *
        * placeholders for invoice
        *
        * start
        * 
        */

        placeholder_invoice_type: "S'il vous plaît remplir le type de facture",// ok 30.10.2019
        placeholder_invoice_company_name: 'Votre "nom de la société" va ici',// ok 30.10.2019
        placeholder_invoice_company_info: 'Votre "info société" va ici',// ok 30.10.2019

        placeholder_invoice_number: 'par exemple : facture 123',
        placeholder_invoice_number_label: 'Facture #',

        placeholder_invoice_date: 'par exemple : 31 Novembre, 2019',
        placeholder_invoice_date_label: 'Date',

        /*
        *
        * placeholders for invoice
        *
        * stop
        * 
        */

        /*
        *
        * placeholders for recipient
        *
        * start
        * 
        */

        placeholder_recipient_account_type: 'sélectionnez le type de compte',
        placeholder_recipient_company_name: "sélectionnez le nom de l'entreprise",
        placeholder_recipient_vat_nr: 'numéro de TVA',
        placeholder_recipient_email: 'email',
        placeholder_recipient_phone_nr: 'numéro de téléphone',
        placeholder_recipient_addresses_country_key: 'sélectionnez le pays',
        placeholder_recipient_addresses_city: 'ville',
        placeholder_recipient_addresses_street_address: 'adresse de rue',
        placeholder_recipient_addresses_zip: 'zip',

        /*
        *
        * placeholders for recipient
        *
        * stop
        * 
        */

        placeholder_invoice_subtotal: 'Sous-total',
        placeholder_invoice_vat: 'TVA',

        placeholder_invoice_total_without_vat: 'TOTAL sans TVA',
        placeholder_invoice_total_vat: 'TOTAL TVA',
        placeholder_invoice_total: 'TOTAL',

        /*
         *
         * placeholders for invoice item labels
         *
         * start
         *
         */

        placeholder_item_description_label: 'Article & Description',
        placeholder_item_quantity_label: 'Quantité',
        placeholder_item_price_label: 'Prix',
        placeholder_item_vat_label: 'TVA',

        /*
        *
        * placeholders for invoice item labels
        *
        * stop
        *
        */

        /*
        *
        * placeholders
        * 
        * stop
        * 
        */

    },

    ElementItemRecipient: {
        label_company_info: 'Information de la société',
        label_addresses: 'Adresse',
        label_button_load_data: 'charger les données du destinataire ci-dessus',
        label_button_edit_recipient: 'modifier'
    },

    ElementItemInvoice: {

        company_info_label: 'Information de la société',
        company_name_label: 'Le nom de la société',
        invoice_date_label: 'Date de facture',
        invoice_number_label: 'Numéro de facture',
        invoice_type_label: 'Type de facture',
        recipient_info_label: 'Informations sur le destinataire',
        subtotal_label: 'sous-total',
        total_label: 'Total',
        vat_label: 'TVA',
        vat_value_label: 'Valeur de la TVA',

        invoice_details_label: 'Détails de la facture',
        button_load_data: 'charger les données de facture ci-dessus',
        label_button_show_invoice: 'montrer',
        label_button_edit_invoice: 'modifier',
        label_button_download_pdf_invoice: 'télécharger PDF',
        label_button_receipts: 'recettes'

    },

    ModuleProvider: {

        /*
        *
        * provider placeholders
        *
        * start
        *
        */

        placeholder_provider_company_name: "nom de l'entreprise du fournisseur",
        placeholder_provider_vat_nr: 'numéro de TVA du fournisseur',
        placeholder_provider_phone_nr: 'numéro de téléphone du fournisseur',
        placeholder_provider_bank_account: 'compte bancaire du fournisseur',
        placeholder_provider_bank_name: 'nom de la banque du fournisseur',

        placeholder_provider_address_country_key: 'pays adresse du fournisseur',
        placeholder_provider_address_county: "comté de l'adresse du fournisseur",
        placeholder_provider_address_city: 'adresse du fournisseur ville',
        placeholder_provider_address_street_address: 'rue adresse du fournisseur',
        placeholder_provider_address_zip: 'adresse zip du fournisseur',

        /*
        *
        * provider placeholders
        *
        * stop
        *
        */

        /*
        *
        * invoice notification messages
        *
        * start
        * 
        */

        notification_invoice_type: "S'il vous plaît remplir le type de facture",

        notification_invoice_company_name: "S'il vous plaît remplir le nom de l'entreprise.",
        notification_invoice_company_info: "S'il vous plaît remplir les informations de l'entreprise.",

        notification_recipient_account_type: "Veuillez renseigner le type de compte du destinataire.",
        notification_recipient_company_name: "Veuillez indiquer le nom de l'entreprise destinataire.",
        notification_recipient_vat_nr: 'Veuillez renseigner le numéro de TVA du destinataire..',
        notification_recipient_email: "Veuillez renseigner l'adresse email du destinataire.",
        notification_recipient_phone_nr: "S'il vous plaît remplir le numéro de téléphone du destinataire.",
        notification_recipient_addresses_country_key: "S'il vous plaît choisir un pays.",
        notification_recipient_addresses_city: "S'il vous plaît remplir la ville.",
        notification_recipient_addresses_street_address: "S'il vous plaît remplir l'adresse.",
        notification_recipient_addresses_zip: "S'il vous plaît remplir le zip.",

        notification_invoice_number: "S'il vous plaît remplir le numéro de facture.",
        notification_invoice_date: 'Veuillez renseigner la date de facture.',

        notification_invoice_vat: "S'il vous plaît remplir la facture TVA.",

        /*
        *
        * invoice notification messages
        *
        * stop
        * 
        */

        notification_provider_saved: 'Le fournisseur a été enregistré.',

    },

    settings: {

        settings: 'Réglages',

        /* general settings / start */

        general_settings: 'Réglages généraux',// tab/h element

        general_settings_info_content: 'general settings info content fr',// alert content

        vat_status: 'Statut TVA',// option
        default_vat_value: 'Le statut de TVA par défaut',// option

        notification_success_update_vat_status: 'Le statut TVA a été mis à jour.',// notification text
        notification_error_update_vat_status: "En raison de certaines erreurs, le statut TVA n'a pas pu être mis à jour.",// notification text

        notification_success_update_variables_vat: 'La valeur de TVA par défaut a été mise à jour.',// notification text
        notification_error_update_variables_vat: "En raison de certaines erreurs, la valeur de TVA par défaut n'a pas pu être mise à jour.",// notification text

        /* general settings / stop */

        /* langauges / start */

        languages: 'Langues',// tab/h element

        languages_info_content: 'languages info content fr',// alert content

        notification_success_update_language: 'Le paramètre des langues a été mis à jour.',// notification text

        /* langauges / stop */

        /* invoices / start */

        invoices: 'Factures',// tab element

        invoices_page_title: 'Détails des numéros de série et des factures',// h element

        invoices_info_content: 'settings invoices info content fr',

        invoices_serial: 'En série',
        invoices_number: 'Nombre',

        notification_success_update_invoice_serial_and_number: 'Le paramètre de numéro de série de la facture a été mis à jour.',// notification text
        notification_error_update_invoice_serial_and_number: "En raison de certaines erreurs, le paramètre de numéro de série de la facture n'a pas pu être mis à jour."// notification text

        /* invoices / stop */

    }

};