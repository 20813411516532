/*
 *
 * This is the `IndexedDb ModuleRecipient` component.
 *
 */

import { Component } from 'react';

/*
 *
 * custom components
 *
 * start
 *
 */

import ConsoleService from './components/consoleServiceModules/ConsoleService';

import ConsoleServiceIndexedDbModuleProviderComponent from "./components/consoleServiceModules/ConsoleServiceIndexedDbModuleProviderComponent";

import { indexed_db_name, indexed_db_version } from './IndexedDbComponent';


/*
 *
 * custom components
 *
 * stop
 *
 */

// the main class

class IndexedDbModuleProviderComponent extends Component {

    idb_name = indexed_db_name;

    version = indexed_db_version;

    flag_console_log = ConsoleServiceIndexedDbModuleProviderComponent;

    /*
     *
     * constructor
     *
     * start
     * 
     */

    constructor(props) {

        super(props);

        ConsoleService('', this.flag_console_log);
        ConsoleService('IndexedDbModuleProviderComponent / constructor', this.flag_console_log);

        ConsoleService('IndexedDbModuleProviderComponent / constructor / props', this.flag_console_log);
        ConsoleService(props, this.flag_console_log);

        ConsoleService('IndexedDbModuleProviderComponent / constructor / idb name', this.flag_console_log);
        ConsoleService(this.idb_name, this.flag_console_log);

        ConsoleService('IndexedDbModuleProviderComponent / constructor / IndexedDb / version', this.flag_console_log);
        ConsoleService(this.version, this.flag_console_log);

    }

    /*
     *
     * constructor
     *
     * stop
     * 
     */

}

export default IndexedDbModuleProviderComponent;