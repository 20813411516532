import React from 'react';

/*
 *
 * custom components
 *
 * start
 *
 */

import ConsoleService from '../consoleServiceModules/ConsoleService';

import ConsoleServiceModuleInvoice from "../consoleServiceModules/ConsoleServiceModuleInvoice";

import translation from './../../TranslationComponent';

/*
 *
 * custom components
 *
 * stop
 *
 */

const flag_console_log = ConsoleServiceModuleInvoice;

const showItem = (event, props) => {

    event.preventDefault();

    ConsoleService('ElementItemInvoice / showItem', flag_console_log);

    return props.parent_callback_open_modal_form_invoice(props.item);

}

// crud operation handler
const updateItem = (event, props) => {

    event.preventDefault();

    ConsoleService('ElementItemInvoice / updateItem', flag_console_log);

    ConsoleService('ElementItemInvoice / updateItem props.item', flag_console_log);
    ConsoleService(props.item, flag_console_log);

    return props.parent_callback_show_form_invoice(props.item);

}

const downloadItem = (event, props) => {

    event.preventDefault();

    ConsoleService('ElementItemInvoice / downloadItem', flag_console_log);

    return props.parent_callback_download_pdf_file(props.item);

}

const execute = (props) => {

    ConsoleService('', flag_console_log);
    ConsoleService('ModuleInvoices / ElementItemInvoice / execute', flag_console_log);

    ConsoleService('props', flag_console_log);
    ConsoleService(props, flag_console_log);

    let d = new Date(props.item.invoice_date);

    let invoice_date_format = d.getDate() + ' ' + translation[props.item.invoice_language].general.months[d.getMonth()] + ', ' + d.getFullYear();

    //let module_name = 'ElementItemInvoice';

    return (

        <React.Fragment>

            <td>
                {props.item.invoice_serial}-{props.item.invoice_number}
            </td>

            <td>
                {invoice_date_format}
            </td>

            <td>
                {props.item.recipient_company_name}
            </td>

            <td>
                {props.item.invoice_currency_symbol + ' ' + props.item.invoice_total_without_vat}
            </td>

            <td>
                {props.item.invoice_currency_symbol + ' ' + props.item.invoice_total}
            </td>

            <td>

                <button className="btn btn-info btn-sm" onClick={(event) => { showItem(event, props) }}>
                    <i className="fa fa-eye"></i>
                </button>

                &nbsp;

                <button className="btn btn-info btn-sm" onClick={(event) => { updateItem(event, props) }}>
                    <i className="fa fa-pencil"></i>
                </button>

                &nbsp;

                <button className="btn btn-info btn-sm" onClick={(event) => { downloadItem(event, props) }}>
                    <i className="fa fa-download"></i> PDF
                </button>

            </td>

        </React.Fragment>

    )

}

export default execute;