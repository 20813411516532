/*
 *
 * custom components
 *
 * start
 *
 */

import ConsoleService from './consoleServiceModules/ConsoleService';

import ConsoleServiceGeneral from "./consoleServiceModules/ConsoleServiceGeneral";

import { indexed_db_name, indexed_db_version } from '../IndexedDbComponent';

/*
 *
 * custom components
 *
 * stop
 *
 */

const execute = (object_store) => {

    let flag_console_log = ConsoleServiceGeneral;

    ConsoleService('', flag_console_log);
    ConsoleService('IndexedDbLoadAllComponent', flag_console_log);

    ConsoleService('IndexedDbLoadAllComponent / object_store', flag_console_log);
    ConsoleService(object_store, flag_console_log);

    let idb_name = indexed_db_name;

    let version = indexed_db_version;

    let items = [];

    /*
     *
     * trying to save the settings data
     * 
     * we still have to figure out if we are on the "create" or "update" scenario
     *
     * start
     * 
     */

    return new Promise(
        (resolve, reject) => {

            ConsoleService('IndexedDbLoadAllComponent / new promise', flag_console_log);

            let indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;

            /*
             *
             * request
             *
             * start
             * 
             */

            let request = indexedDB.open(idb_name, version);

            /*
             *
             * request / on success / callback
             *
             * start
             * 
             */

            request.onsuccess = function (event) {

                ConsoleService('IndexedDbLoadAllComponent / request / on success', flag_console_log);

                ConsoleService('IndexedDbLoadAllComponent / request / on success / event', flag_console_log);
                ConsoleService(event, flag_console_log);

                let db = event.target.result;

                ConsoleService('IndexedDbLoadAllComponent / request / on success / db', flag_console_log);
                ConsoleService(db, flag_console_log);

                /*
                 *
                 * transaction
                 *
                 * start
                 * 
                 */

                /*
                 *
                 * transaction
                 * 
                 * open a read/write db transaction, ready for adding the data
                 * 
                 * https://developer.mozilla.org/en-US/docs/Web/API/IDBDatabase/transaction
                 * 
                 */

                let transaction = db.transaction([object_store], 'readonly');

                ConsoleService('IndexedDbLoadAllComponent / request / on success / transaction', flag_console_log);
                ConsoleService(transaction, flag_console_log);

                /*
                 *
                 * transaction / on complete
                 * 
                 * report on the success of opening the transaction
                 * 
                 * start
                 * 
                 */

                transaction.oncomplete = function (event) {

                    ConsoleService('IndexedDbLoadAllComponent / request / on success / transaction / on complete', flag_console_log);

                    ConsoleService('IndexedDbLoadAllComponent / request / on success / transaction / on complete / event', flag_console_log);
                    ConsoleService(event, flag_console_log);

                    ConsoleService('IndexedDbLoadAllComponent / items', flag_console_log);
                    ConsoleService(items, flag_console_log);

                    resolve(items);

                    //

                }

                /*
                 *
                 * transaction / on complete
                 * 
                 * stop
                 * 
                 */

                /*
                 *
                 * transaction / on error
                 * 
                 * start
                 * 
                 */

                transaction.onerror = function (event) {

                    ConsoleService('IndexedDbLoadAllComponent / request / on success / transaction / on error', flag_console_log);

                    ConsoleService('IndexedDbLoadAllComponent / request / on success / transaction / on error / event', flag_console_log);
                    ConsoleService(event, flag_console_log);

                    reject([]);

                    //

                }

                /*
                 *
                 * transaction / on error
                 * 
                 * stop
                 * 
                 */

                /*
                 *
                 * transaction
                 *
                 * stop
                 * 
                 */

                /*
                 *
                 * the dynamic "object store"
                 *
                 * start
                 * 
                 */

                let objectStore = transaction.objectStore(object_store);

                ConsoleService('IndexedDbLoadAllComponent / request / on success / transaction / objectStore', flag_console_log);
                ConsoleService(objectStore, flag_console_log);

                /*
                 *
                 * the dynamic "object store"
                 *
                 * stop
                 * 
                 */

                /*
                 *
                 * req
                 *
                 * start
                 *
                 */

                let req = objectStore.openCursor();

                ConsoleService('IndexedDbLoadAllComponent / request / on success / req', flag_console_log);
                ConsoleService(req, flag_console_log);

                /*
                 *
                 * req / on success
                 *
                 * start
                 * 
                 */

                req.onsuccess = function (event) {

                    ConsoleService('IndexedDbLoadAllComponent / request / on success / req / on success', flag_console_log);

                    let cursor = event.target.result;

                    ConsoleService('IndexedDbLoadAllComponent / request / on success / req / on success / cursor', flag_console_log);
                    ConsoleService(cursor, flag_console_log);

                    if (cursor != null) {

                        ConsoleService('IndexedDbLoadAllComponent / request / on success / req / on success / cursor / if', flag_console_log);

                        ConsoleService('IndexedDbLoadAllComponent / request / on success / req / on success / cursor / if / cursor.value', flag_console_log);
                        ConsoleService(cursor.value, flag_console_log);

                        items.push(cursor.value);

                        cursor.continue();

                        //

                    } else {

                        ConsoleService('IndexedDbLoadAllComponent / request / on success / req / on success / cursor / else', flag_console_log);

                        //resolve(items);

                    }

                    //

                }

                /*
                 *
                 * req / on success
                 *
                 * stop
                 * 
                 */

                /*
                 *
                 * req / on error
                 *
                 * start
                 * 
                 */

                req.onerror = function (event) {

                    /*
                    *
                    * error
                    * 
                    */

                    ConsoleService('IndexedDbLoadAllComponent / request / on success / transaction / objectStore / index / req / on error');

                    ConsoleService('IndexedDbLoadAllComponent / request / on success / transaction / objectStore / index / req / on error / event', flag_console_log);
                    ConsoleService(event, flag_console_log);

                    //reject([]);

                    //

                }

                /*
                 *
                 * req / on error
                 *
                 * stop
                 * 
                 */

                /*
                 *
                 * req
                 *
                 * stop
                 * 
                 */

            }

            /*
             * request / on success / callback
             *
             * stop
             * 
             */

            /*
             * request / on error / callback
             *
             * start
             * 
             */

            request.onerror = function (event) {

                /*
                *
                * error
                * 
                */

                ConsoleService('IndexedDbLoadAllComponent / request / on error', flag_console_log);

                ConsoleService('IndexedDbLoadAllComponent / request / on error / event', flag_console_log);
                ConsoleService(event, flag_console_log);

                reject([]);

                //

            }

            /*
             * request / on error / callback
             *
             * stop
             * 
             */

            /*
             * request
             *
             * stop
             * 
             */

        }
    );

    // !!! CAUTION !!! unreacheble code, because of return !!!

    /*
     *
     * trying to load the settings data
     *
     * stop
     * 
     */

}

export default execute;